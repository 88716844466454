import { Grid } from '@mui/material';
import { menuShadow, red } from '../../utils/themeContstants';

const UnreadBadge = ({ mobile = false }) => {
  const styles = {
    container: {
      position: 'absolute',
      top: mobile ? -2 : 0,
      right: mobile ? -1 : 6,
      height: 10,
      width: 10,
      backgroundColor: red,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      boxShadow: menuShadow,
    },
  };

  return <Grid item sx={styles.container} />;
};

export default UnreadBadge;
