import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { renewTokens } from '../api/auth';
import {
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '../api/useMiddleware';
import { SERVER_URL } from '../utils/constants';
import useStateCallback from '../utils/useStateCallback';
import {
  NEW_HISTORY_EVENT,
  NEW_NOTIFICATION,
  NEW_PURCHASE_EVENT,
  NEW_REMOVE_TEAM_EVENT,
  NEW_USER_UPDATE_EVENT,
} from './socketConstants';

const useNotifications = (userId, token) => {
  const socketRef = useRef();

  const [userData, setUserData] = useState(null);
  const [teamToRemove, setTeamToRemove] = useState(null);
  const [notiCount, setNotiCount] = useStateCallback(null);
  const [newPurchase, setNewPurchase] = useState(null);
  const [newHistory, setNewHistory] = useState(null);

  useEffect(() => {
    if (!userId) return;

    if (!socketRef?.current?.connected) {
      socketRef.current = socketIOClient(SERVER_URL, {
        query: { userId },
        auth: { token },
      });
    }

    socketRef.current.on('connect_error', async err => {
      if (err?.data?.needs_renew) {
        // renew tokens
        await renewTokens(getRefreshToken()).then(res => {
          if (!res?.error) {
            saveAccessToken(res?.accessToken);
            saveRefreshToken(res?.refreshToken);
          }
        });
      }

      if (err?.data?.needs_login) {
        // force new login
      }
    });

    socketRef.current.on(NEW_USER_UPDATE_EVENT, userdata => {
      if (userdata) {
        setUserData(userdata);
      }
    });

    socketRef.current.on(NEW_PURCHASE_EVENT, purchase => {
      if (purchase) {
        setNewPurchase(purchase);
      }
    });

    socketRef.current.on(NEW_REMOVE_TEAM_EVENT, teamId => {
      if (teamId) {
        setTeamToRemove(teamId);
      }
    });

    socketRef.current.on(NEW_HISTORY_EVENT, history => {
      if (history) {
        setNewHistory(history);
      }
    });

    socketRef.current.on(NEW_NOTIFICATION, () => {
      setNotiCount(oldCount => oldCount + 1);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [userId, token]);

  return {
    userData,
    teamToRemove,
    notiCount,
    newPurchase,
    newHistory,
  };
};

export default useNotifications;
