import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { deleteBan } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  cardLight,
  cardVeryLight,
  normal,
  offWhite,
  text,
} from '../../utils/themeContstants';
import CustomDropdown from '../custom/CustomDropdown';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';

const DeleteBanModal = ({ open, onClose, user, banId }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [punishment, setPunishment] = useState({ type: null, points: null });
  const [punishLoading, setPunishLoading] = useState(false);

  const handleClose = () => {
    setPunishLoading(false);
    setPunishment(null);
    onClose();
  };

  const getPunPointOptions = () => {
    const adminOptions = [
      { id: '25', title: '+25', type: 'good_karma', points: +25 },
      { id: '50', title: '+50', type: 'good_karma', points: +50 },
      { id: '75', title: '+75', type: 'good_karma', points: +75 },
      { id: '150', title: '+150', type: 'good_karma', points: +150 },
      { id: '250', title: '+250', type: 'good_karma', points: +250 },
      { id: '350', title: '+350', type: 'good_karma', points: +350 },
      { id: '400', title: '+400', type: 'good_karma', points: +400 },
    ];

    return adminOptions;
  };

  const handlePunishmentChange = id => {
    const options = getPunPointOptions();
    const selectedOption = options.find(option => option.id === id);
    if (selectedOption) {
      setPunishment({ type: selectedOption.id, points: selectedOption.points });
    }
  };

  const handlePunish = () => {
    setPunishLoading(true);
    deleteBan(middleware, punishment.points, user?._id, banId).then(res => {
      setPunishLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: normal,
      fontWeight: 800,
      color: offWhite,
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`DELETE ${user?.username}'s BAN`}
      primaryButton={
        <PrimaryButton
          label='DELETE BAN'
          loading={punishLoading}
          disabled={!punishment}
          onClick={handlePunish}
        />
      }
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>
                How much good karma should {user?.username} receive back?
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomDropdown
                options={getPunPointOptions().map(option => ({
                  ...option,
                  value: option.id,
                }))}
                placeholder='Karma'
                onChange={value => handlePunishmentChange(value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default DeleteBanModal;
