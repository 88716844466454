import { Grid } from '@mui/material';
import { AiFillEdit } from 'react-icons/ai';
import {
  cardExtraLight,
  cardVeryLight,
  text,
  transition,
} from '../../utils/themeContstants';

const EditAvatarButton = ({ onClick = () => {} }) => {
  return (
    <>
      <Grid
        item
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 60,
          borderRadius: 100,
          height: 35,
          width: 35,
          backgroundColor: cardExtraLight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: transition,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: cardVeryLight,
          },
        }}
        onClick={onClick}
      >
        <AiFillEdit style={{ fontSize: 20, color: text }} />
      </Grid>
    </>
  );
};

export default EditAvatarButton;
