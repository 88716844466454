import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { inviteUser } from '../../api/teams';
import useMiddleware from '../../api/useMiddleware';
import Avatar from '../../avatar/Avatar';
import {
  SET_ERRORS,
  SET_SEARCH_USER,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  cardLight,
  container,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import SecondaryButton from '../custom/SecondaryButton';

const UserInviteResultItem = ({
  user,
  team,
  invitedUsers,
  setInvitedUsers,
  onClick = null,
}) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [invited, setInvited] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [usernameHovered, setUsernameHovered] = useState(false);

  const handleOpenProfile = user_id => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleInvite = () => {
    setLoading(true);
    inviteUser(middleware, team?._id, user?.user_id).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        let newUsers = invitedUsers;
        newUsers.push(user?.user_id);
        setInvitedUsers(newUsers);
        setInvited(true);
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Invite sent!',
        });
      }
    });
  };

  useEffect(() => {
    if (invitedUsers?.length > 0) {
      const playerIndex = invitedUsers?.findIndex(
        invitedUser => invitedUser?.user_id === user?.user_id
      );
      if (playerIndex > -1) {
        setInvited(true);
        return;
      }
    }

    setInvited(false);
  }, [invitedUsers]);

  const styles = {
    container: {
      width: '100%',
    },
    username: {
      transition: transition,
      fontSize: normal,
      color: user?.isPremium
        ? usernameHovered
          ? yellow
          : 'rgb(255, 255, 193)'
        : usernameHovered
          ? doubtedBlue
          : text,
      textShadow: user?.isPremium ? 'rgb(255 93 0) 0px 0px 10px' : null,
      fontWeight: 800,
      cursor: usernameHovered ? 'pointer' : 'default',
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid container justifyContent='start' alignItems='center'>
            <Grid
              item
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => handleOpenProfile(user?.user_id)}
              sx={{
                marginLeft: -2,
                transition: transition,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Avatar
                size={75}
                bgColor={hovered ? cardLight : container}
                avatar={user?.avatar}
              />
            </Grid>

            <Grid
              item
              onMouseEnter={() => setUsernameHovered(true)}
              onMouseLeave={() => setUsernameHovered(false)}
              onClick={() => handleOpenProfile(user?.user_id)}
            >
              <Typography sx={styles.username}>{user?.username}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <SecondaryButton
            bg={doubtedBlue}
            label={invited ? 'Invited' : 'Invite'}
            disabled={invited}
            loading={loading}
            onClick={onClick ? () => onClick(user?.user_id) : handleInvite}
            size='small'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserInviteResultItem;
