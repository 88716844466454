import { useState } from 'react';
import { Navigate } from 'react-router-dom';

const Verify = ({ children }) => {
  const [isLogged] = useState(!!localStorage.getItem('accessToken'));

  return isLogged ? children : <Navigate to='/' replace />;
};

export default Verify;
