import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { BiPurchaseTag } from 'react-icons/bi';
import useMiddleware from '../../api/useMiddleware';
import { getUserPurchases } from '../../api/user';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import { emptyIcon, secondaryText, text } from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import Empty from '../custom/Empty';
import MatchHistoryGlimmer from '../glimmers/MatchHistoryGlimmer';
import PurchaseItem from './PurchaseItem';

const Purchases = () => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetPurchases = () => {
    getUserPurchases(middleware, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.purchases?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setPurchases(
          purchases ? [...purchases, ...res?.purchases] : res?.purchases
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(purchases?.length));

  useEffect(() => {
    if (purchases == null) {
      handleGetPurchases();
    }
  }, [purchases]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetPurchases();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <BiPurchaseTag style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Purchases</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          {loading && (
            <Grid
              item
              alignSelf='center'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 4,
              }}
            >
              <CircularProgress size={30} sx={{ color: secondaryText }} />
            </Grid>
          )}

          {!loading && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                {(purchases?.length < 1 || !purchases) && (
                  <Empty
                    title='No purchases'
                    label='All your purchases will appear here'
                    icon={<BiPurchaseTag style={emptyIcon} />}
                  />
                )}

                {purchases && purchases?.length > 0 && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      {purchases?.map((purchase, i) => (
                        <PurchaseItem purchase={purchase} key={i} />
                      ))}
                      {hasMore && (
                        <div style={{ width: '100%' }} ref={bottom}>
                          <MatchHistoryGlimmer numItems={1} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Purchases;
