export const battlePassRewards = [
  {
    tier: 1,
    reward: '5 Snipes',
    xpRequired: '200 XP',
    snipeAmount: 5,
    worth: '$0.99',
  },
  {
    tier: 2,
    reward: '$0.50',
    xpRequired: '400 XP',
    monetaryAmount: 0.5,
    worth: '$0.50',
    type: 'money',
  },
  {
    tier: 3,
    reward: 'Rare Crate',
    xpRequired: '600 XP',
    type: 'rare',
    worth: '$2.00',
  },
  {
    tier: 4,
    reward: '10 Snipes',
    xpRequired: '900 XP',
    snipeAmount: 10,
    worth: '$1.99',
  },
  {
    tier: 5,
    reward: 'Emoji Crate',
    xpRequired: '1200 XP',
    type: 'emoji',
    worth: '$1.49',
  },
  {
    tier: 6,
    reward: '10 Snipes',
    xpRequired: '1500 XP',
    snipeAmount: 10,
    worth: '$1.99',
  },
  {
    tier: 7,
    reward: 'Godlike Crate',
    xpRequired: '1900 XP',
    type: 'godlike',
    worth: '$1.99',
  },
  {
    tier: 8,
    reward: '$0.50',
    xpRequired: '2300 XP',
    monetaryAmount: 0.5,
    worth: '$0.50',
    type: 'money',
  },
  {
    tier: 9,
    reward: '10 Snipes',
    xpRequired: '2700 XP',
    snipeAmount: 10,
    worth: '$1.99',
  },
  {
    tier: 10,
    reward: 'Emote',
    xpRequired: '3200 XP',
    type: 'godlike',
    worth: '$5.00',
    avatar_type: 'fire_eyes',
  },
  {
    tier: 11,
    reward: '$1.00',
    xpRequired: '3700 XP',
    monetaryAmount: 1,
    worth: '$1.00',
    type: 'money',
  },
  {
    tier: 12,
    reward: 'Godlike Crate',
    xpRequired: '4200 XP',
    type: 'godlike',
    worth: '$2.00',
  },
  {
    tier: 13,
    reward: '25 Snipes',
    xpRequired: '4800 XP',
    snipeAmount: 25,
    worth: '$3.99',
  },
  {
    tier: 14,
    reward: 'Emote',
    xpRequired: '6000 XP',
    type: 'godlike',
    worth: '$5.00',
    avatar_type: 'alex_made_it_rain',
  },
  {
    tier: 15,
    reward: '$3',
    xpRequired: '7000 XP',
    type: 'money',
    worth: '$3.00',
    monetaryAmount: 3,
  },
];
