import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format, formatDistanceToNow } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { BiMoney } from 'react-icons/bi';
import {
  FaGamepad,
  FaMapMarkerAlt,
  FaMedal,
  FaRegClock,
  FaUsers,
  FaWallet,
} from 'react-icons/fa';
import { GiPodium, GiTrophy } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addTeamsToTournament,
  getTournament,
  leaveTournament,
  registerForTournament,
  startTournament,
} from '../../api/tournaments';
import useMiddleware from '../../api/useMiddleware';
import { getUserTeamsDetailed } from '../../api/user';
import Avatar from '../../avatar/Avatar';
import Badge from '../../components/custom/Badge';
import CustomDropdown from '../../components/custom/CustomDropdown';
import PrimaryButton from '../../components/custom/PrimaryButton';
import {
  SET_ERRORS,
  SET_SEARCH_USER,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import { gameModeOptions } from '../../utils/options';
import {
  card,
  cardDark,
  cardVeryLight,
  doubtedBlue,
  green,
  offWhite,
  red,
  secondaryText,
  text,
  yellow,
} from '../../utils/themeContstants';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import TournamentBracket from './TournamentBracket';
import TournamentForm from './TournamentForm';

// Constants for forms
const GAME_OPTIONS = [
  { title: 'Fortnite', value: 'fortnite' },
  { title: 'Warzone', value: 'warzone' },
  { title: 'Chess', value: 'chess' },
  { title: 'FIFA', value: 'fifa' },
  { title: 'Clash Royale', value: 'clashroyale' },
];

const MAX_PLAYERS_OPTIONS = [
  { title: '2 Teams', value: '2' },
  { title: '4 Teams', value: '4' },
  { title: '8 Teams', value: '8' },
  { title: '16 Teams', value: '16' },
  { title: '32 Teams', value: '32' },
  { title: '64 Teams', value: '64' },
  { title: '128 Teams', value: '128' },
  { title: '256 Teams', value: '256' },
];

const TEAM_SIZE_OPTIONS = [
  { title: '1v1', value: 1 },
  { title: '2v2', value: 2 },
  { title: '3v3', value: 3 },
  { title: '4v4', value: 4 },
];

const AddTeamsDialog = ({
  open,
  onClose,
  teams,
  onAddTeam,
  onRemoveTeam,
  onSubmit,
  newTeamName,
  onTeamNameChange,
  isAdding,
  styles,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={styles.dialog}
    maxWidth='sm'
    fullWidth
  >
    <DialogTitle sx={styles.dialogTitle}>
      Add Teams to Tournament
      <IconButton aria-label='close' onClick={onClose} sx={styles.closeButton}>
        <IoClose />
      </IconButton>
    </DialogTitle>

    <DialogContent sx={styles.dialogContent}>
      <Box sx={styles.teamInputBox}>
        <Typography sx={styles.subLabel}>Add Team</Typography>
        <Box sx={styles.teamInputWrapper}>
          <CustomInput
            placeholder='Enter team name'
            value={newTeamName}
            onChange={onTeamNameChange}
            fullWidth
          />
          <PrimaryButton
            label='Add'
            onClick={onAddTeam}
            disabled={!newTeamName}
            color={doubtedBlue}
            sx={{ minWidth: '100px' }}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography sx={styles.subLabel}>Added Teams</Typography>
          <Box sx={styles.teamChipsContainer}>
            {teams.map((team, index) => (
              <Chip
                key={index}
                label={team}
                onDelete={() => onRemoveTeam(team)}
                variant='outlined'
                sx={styles.teamChip}
              />
            ))}
            {teams.length === 0 && (
              <Typography sx={{ color: secondaryText, fontSize: 14 }}>
                No teams added yet
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <PrimaryButton
          label={isAdding ? 'Adding Teams...' : 'Submit Teams'}
          onClick={onSubmit}
          disabled={isAdding || teams.length === 0}
          fullWidth
          color={doubtedBlue}
        />
      </Box>
    </DialogContent>
  </Dialog>
);

const TournamentCountdown = ({ startTime, onCountdownEnd }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const start = new Date(startTime);
      const diffInSeconds = Math.floor((start - now) / 1000);

      if (diffInSeconds <= 0) {
        setTimeLeft('Tournament has started');
        if (!hasEnded) {
          setHasEnded(true);
          if (onCountdownEnd) {
            onCountdownEnd();
          }
        }
        return;
      }

      if (diffInSeconds < 60) {
        setTimeLeft(
          `Starts in ${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'}`
        );
      } else {
        setTimeLeft(formatDistanceToNow(start, { addSuffix: true }));
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [startTime, onCountdownEnd, hasEnded]);

  const formattedDate = format(new Date(startTime), "MMMM d, yyyy 'at' h:mm a");

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
          padding: '12px 20px',
          borderRadius: '12px',
          border: `1px solid ${cardVeryLight}20`,
        }}
      >
        <Box
          sx={{
            backgroundColor: doubtedBlue,
            padding: '8px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaRegClock size={20} color={text} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: doubtedBlue,
              fontSize: 16,
              fontWeight: 700,
              marginBottom: '2px',
            }}
          >
            {timeLeft}
          </Typography>
          <Typography
            sx={{
              color: secondaryText,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {formattedDate}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LeaveConfirmationDialog = ({ open, onClose, onConfirm, isLeaving }) => (
  <CustomModal
    open={open}
    onClose={onClose}
    title='Leave Tournament'
    maxWidth='sm'
  >
    <Box sx={{ textAlign: 'center', py: 2 }}>
      <Typography
        sx={{
          color: text,
          fontSize: 18,
          fontWeight: 600,
          mb: 2,
        }}
      >
        Are you sure you want to leave this tournament?
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <SecondaryButton
          label='Cancel'
          onClick={onClose}
          color={cardVeryLight}
          sx={{ minWidth: '120px' }}
        />
        <SecondaryButton
          label={isLeaving ? 'Leaving...' : 'Leave Tournament'}
          onClick={onConfirm}
          disabled={isLeaving}
          bg={doubtedBlue}
          sx={{ minWidth: '120px' }}
        />
      </Box>
    </Box>
  </CustomModal>
);

const TournamentDetail = () => {
  const { id } = useParams();
  const [tournament, setTournament] = useState(null);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [registering, setRegistering] = useState(false);
  const [showAddTeams, setShowAddTeams] = useState(false);
  const [newTeams, setNewTeams] = useState([]);
  const [newTeamName, setNewTeamName] = useState('');
  const [addingTeams, setAddingTeams] = useState(false);
  const [startingTournament, setStartingTournament] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [showEditTournament, setShowEditTournament] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery('(max-width:1024px)');
  const navigate = useNavigate();

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware, tournament.team_size).then(res => {
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        console.log('Teams received:', res?.teams);
        const teamOptions = res?.teams?.map(team => ({
          title: team.name,
          value: team,
          _id: team._id,
        }));
        setTeams(teamOptions);
      }
    });
  };

  const handleOpenProfile = user_id => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleRegister = () => {
    if (!selectedTeam) {
      updateStore({ type: SET_ERRORS, payload: 'Please select a team' });
      return;
    }

    setRegistering(true);
    registerForTournament(middleware, id, selectedTeam._id).then(res => {
      setRegistering(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setTournament(res);
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Successfully registered for tournament',
        });
      }
    });
  };

  const handleAddTeam = () => {
    if (newTeamName && !newTeams.includes(newTeamName)) {
      setNewTeams([...newTeams, newTeamName]);
      setNewTeamName('');
    }
  };

  const handleRemoveTeam = teamToRemove => {
    setNewTeams(newTeams.filter(team => team !== teamToRemove));
  };

  const handleSubmitTeams = async () => {
    if (newTeams.length === 0) return;

    setAddingTeams(true);
    const res = await addTeamsToTournament(middleware, id, newTeams);
    setAddingTeams(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      setNewTeams([]);
      setShowAddTeams(false);
      updateStore({ type: SET_SUCCESSES, payload: 'Teams added successfully' });
    }
  };

  const handleStartTournament = async () => {
    setStartingTournament(true);
    const res = await startTournament(middleware, id);
    setStartingTournament(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      updateStore({
        type: SET_SUCCESSES,
        payload: 'Tournament started successfully',
      });
    }
  };

  const handleLeaveTournament = async () => {
    setIsLeaving(true);
    const res = await leaveTournament(middleware, id);
    setIsLeaving(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      setShowLeaveConfirmation(false);
      updateStore({
        type: SET_SUCCESSES,
        payload: 'Successfully left the tournament',
      });
    }
  };

  const handleUpdateTournament = async updatedTournament => {
    setTournament(updatedTournament);
  };

  const handleOpenEditModal = () => {
    setShowEditTournament(true);
  };

  useEffect(() => {
    if (store?.user && !teams && tournament) {
      handleGetTeams();
    }
  }, [store?.user, tournament]);

  useEffect(() => {
    if (loading) {
      getTournament(middleware, id).then(res => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setTournament(res);
        }
      });
    }
  }, [loading, id]);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
      position: 'relative',
      padding: isTablet ? '16px' : '32px',
      backgroundColor: 'rgb(26, 44, 57)',
      backgroundImage:
        'radial-gradient(circle at 50% 0%, rgba(40, 70, 90, 0.5) 0%, rgba(26, 44, 57, 0.2) 70%)',
    },
    header: {
      fontSize: isTablet ? 28 : 38,
      fontWeight: 800,
      color: text,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
      marginBottom: 1,
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -8,
        left: 0,
        width: '60px',
        height: '4px',
        background: `linear-gradient(90deg, ${doubtedBlue}, transparent)`,
        borderRadius: '2px',
      },
    },
    subHeader: {
      fontSize: 16,
      color: secondaryText,
      fontWeight: 500,
    },
    paper: {
      backgroundColor: card,
      padding: isTablet ? '20px' : '28px',
      boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        boxShadow: '0 12px 32px rgba(0,0,0,0.2)',
        transform: 'translateY(-2px)',
      },
    },
    statLabel: {
      fontSize: 14,
      color: secondaryText,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    statValue: {
      fontSize: 18,
      color: text,
      fontWeight: 700,
    },
    subLabel: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 1,
    },
    width: {
      width: '100%',
    },
    statusChip: {
      padding: '6px 14px',
      borderRadius: '12px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 600,
      color: text,
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    playerCard: {
      backgroundColor: cardDark,
      padding: '16px',
      borderRadius: '12px',
      border: `1px solid ${cardVeryLight}`,
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
      '&:hover': {
        backgroundColor: card,
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        borderColor: doubtedBlue,
      },
    },
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: cardDark,
        borderRadius: 2,
        maxWidth: 500,
        width: '100%',
        boxShadow: '0 24px 48px rgba(0,0,0,0.3)',
      },
    },
    dialogTitle: {
      padding: '24px 28px',
      color: text,
      fontSize: 24,
      fontWeight: 700,
      borderBottom: `1px solid ${cardVeryLight}`,
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 28,
        width: '40px',
        height: '3px',
        background: doubtedBlue,
        borderRadius: '2px',
      },
    },
    dialogContent: {
      padding: '28px',
      backgroundColor: cardDark,
    },
    closeButton: {
      position: 'absolute',
      right: 12,
      top: 12,
      color: secondaryText,
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
        color: text,
      },
    },
    teamInputBox: {
      backgroundColor: cardVeryLight,
      borderRadius: 2,
      padding: 3,
      boxShadow: 'inset 0 2px 8px rgba(0,0,0,0.1)',
    },
    teamInputWrapper: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      marginTop: 1,
    },
    teamChipsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
      marginTop: 1,
      minHeight: 100,
      backgroundColor: cardDark,
      borderRadius: 2,
      padding: 2,
      boxShadow: 'inset 0 2px 8px rgba(0,0,0,0.1)',
    },
    teamChip: {
      backgroundColor: 'rgba(255,255,255,0.05)',
      color: text,
      borderColor: cardVeryLight,
      padding: '6px 12px',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderColor: doubtedBlue,
      },
    },
    submitButton: {
      marginTop: 3,
    },
    prizeCard: {
      backgroundColor: cardDark,
      padding: '20px',
      borderRadius: '12px',
      border: `1px solid ${cardVeryLight}`,
      transition: 'all 0.3s ease',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 12px 24px rgba(0,0,0,0.15)',
        borderColor: `${doubtedBlue}80`,
      },
    },
    prizeIcon: {
      fontSize: '28px',
      color: yellow,
    },
    prizeAmount: {
      fontSize: '22px',
      fontWeight: 700,
      color: text,
      textShadow: '0 1px 2px rgba(0,0,0,0.2)',
    },
    prizePosition: {
      fontSize: '15px',
      color: secondaryText,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    prizePercentage: {
      fontSize: '12px',
      color: secondaryText,
      fontStyle: 'italic',
    },
    qualifiedText: {
      color: green,
      fontSize: '14px',
      fontWeight: 600,
      textShadow: '0 1px 2px rgba(0,0,0,0.2)',
    },
  };

  const additionalStyles = {
    teamInput: {
      display: 'flex',
      gap: 1,
      marginBottom: 1,
    },
    teamChips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
      marginTop: 1,
    },
  };

  const renderPrizeStructure = () => {
    if (!tournament?.prize_structure) return null;

    const getPrizeIcon = position => {
      switch (position) {
        case 1:
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `${yellow}20`,
                borderRadius: '50%',
                padding: '8px',
                boxShadow: '0 4px 12px rgba(255, 215, 0, 0.2)',
                border: `2px solid ${yellow}80`,
              }}
            >
              <GiTrophy
                style={{ color: yellow, fontSize: isTablet ? 28 : 36 }}
              />
            </Box>
          );
        case 2:
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(220, 220, 220, 0.1)',
                borderRadius: '50%',
                padding: '8px',
                boxShadow: '0 4px 12px rgba(220, 220, 220, 0.1)',
                border: `2px solid rgba(220, 220, 220, 0.4)`,
              }}
            >
              <FaMedal
                style={{ color: offWhite, fontSize: isTablet ? 24 : 32 }}
              />
            </Box>
          );
        case 3:
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(205, 127, 50, 0.1)',
                borderRadius: '50%',
                padding: '8px',
                boxShadow: '0 4px 12px rgba(205, 127, 50, 0.1)',
                border: `2px solid rgba(205, 127, 50, 0.4)`,
              }}
            >
              <FaMedal
                style={{ color: '#CD7F32', fontSize: isTablet ? 24 : 32 }}
              />
            </Box>
          );
        default:
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                borderRadius: '50%',
                padding: '8px',
                border: `2px solid rgba(255, 255, 255, 0.1)`,
              }}
            >
              <GiPodium
                style={{ color: secondaryText, fontSize: isTablet ? 22 : 28 }}
              />
            </Box>
          );
      }
    };

    // Sort prizes by place
    const sortedPrizes = [...tournament.prize_structure].sort(
      (a, b) => a.place - b.place
    );

    return (
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            mt: 2,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: 0,
              right: 0,
              height: '1px',
              background: `linear-gradient(90deg, transparent, ${doubtedBlue}40, transparent)`,
              zIndex: 0,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: isTablet ? 20 : 24,
              fontWeight: 700,
              color: text,
              mb: 3,
              textAlign: 'center',
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '40px',
                height: '3px',
                background: doubtedBlue,
                borderRadius: '2px',
              },
            }}
          >
            Prize Structure
          </Typography>
          <Grid
            container
            spacing={isTablet ? 2 : 3}
            sx={{
              maxWidth: isTablet ? '100%' : '1200px',
              margin: '0',
              padding: isTablet ? 1 : 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {sortedPrizes.map((prize, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={prize._id}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: isTablet ? '4px' : '8px',
                }}
              >
                <Box
                  sx={{
                    ...styles.prizeCard,
                    padding: isTablet ? '12px' : '24px',
                    minWidth: 0,
                    width: '100%',
                    maxWidth: isTablet ? '180px' : 'none',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before':
                      prize.place <= 3
                        ? {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '4px',
                            background:
                              prize.place === 1
                                ? `linear-gradient(90deg, ${yellow}, ${yellow}50)`
                                : prize.place === 2
                                  ? 'linear-gradient(90deg, #E0E0E0, rgba(224, 224, 224, 0.5))'
                                  : 'linear-gradient(90deg, #CD7F32, rgba(205, 127, 50, 0.5))',
                          }
                        : {},
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: isTablet ? 1.5 : 2,
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: isTablet ? '40px' : '52px',
                        flexShrink: 0,
                      }}
                    >
                      {getPrizeIcon(prize.place)}
                    </Box>
                    <Box
                      sx={{
                        minWidth: 0,
                        overflow: 'hidden',
                        flex: 1,
                        maxWidth: isTablet ? 'calc(100% - 50px)' : 'auto',
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.prizePosition,
                          fontSize: isTablet ? '13px' : '15px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: isTablet ? 'center' : 'left',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'flex-start',
                          gap: 1,
                        }}
                      >
                        <span style={{ flexShrink: 0 }}>
                          {prize.place === 1
                            ? '1st Place'
                            : prize.place === 2
                              ? '2nd Place'
                              : prize.place === 3
                                ? '3rd Place'
                                : `${prize.place}th Place`}
                        </span>
                        {tournament.name.toLowerCase().includes('qualifiers') &&
                          !tournament.name.toLowerCase().includes('finals') &&
                          prize.place <= 3 && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                color: green,
                                fontSize: '14px',
                                fontWeight: 600,
                                flexShrink: 0,
                                ml: 'auto',
                              }}
                            >
                              <span style={{ fontSize: '14px' }}>✓</span>
                              Qualify
                            </Box>
                          )}
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.prizeAmount,
                          fontSize: isTablet ? '15px' : '22px',
                          textAlign: isTablet ? 'center' : 'left',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.3,
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                          width: '100%',
                          overflow: 'hidden',
                          minWidth: 0,
                        }}
                      >
                        <RivoxCoin
                          size={isTablet ? 14 : 22}
                          style={{ flexShrink: 0 }}
                        />
                        <span
                          style={{
                            minWidth: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {(prize.amount * tournament.team_size).toFixed(2)}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid container sx={styles.container} spacing={3}>
        <Grid item xs={12}>
          <Skeleton
            variant='text'
            width={300}
            height={60}
            sx={{ bgcolor: cardVeryLight }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant='rectangular'
            height={200}
            sx={{ bgcolor: cardVeryLight, borderRadius: 2 }}
          />
        </Grid>
      </Grid>
    );
  }

  if (!tournament) return null;

  const isRegistered = tournament.registered_players.some(
    player => player._id === store?.user?._id
  );

  const canRegister =
    (tournament.status === 'REGISTRATION' || tournament.status === 'PENDING') &&
    !isRegistered &&
    tournament.registered_teams.length < tournament.max_players;

  const getStatusColor = status => {
    switch (status) {
      case 'REGISTRATION':
        return green;
      case 'IN_PROGRESS':
        return yellow;
      case 'COMPLETED':
        return red;
      default:
        return offWhite;
    }
  };

  const getStatusLabel = status => {
    switch (status) {
      case 'PENDING':
        return 'Registration Open';
      case 'REGISTRATION':
        return 'Registration Open';
      case 'IN_PROGRESS':
        return 'Tournament Live';
      case 'COMPLETED':
        return 'Tournament Ended';
      default:
        return status;
    }
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 2,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: -20,
                left: -20,
                width: '200px',
                height: '200px',
                background: `radial-gradient(circle, ${doubtedBlue}20 0%, transparent 70%)`,
                zIndex: 0,
                pointerEvents: 'none',
              },
            }}
          >
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography sx={styles.header}>
                {tournament?.name || <Skeleton width={300} />}
              </Typography>
              {tournament.start_time && tournament.status !== 'COMPLETED' && (
                <TournamentCountdown
                  startTime={tournament.start_time}
                  onCountdownEnd={() => {
                    if (tournament.status === 'PENDING') {
                      getTournament(middleware, id).then(res => {
                        if (res?.error) {
                          updateStore({
                            type: SET_ERRORS,
                            payload: res?.message,
                          });
                        } else {
                          setTournament(res);
                        }
                      });
                    }
                  }}
                />
              )}
            </Box>
            <Box
              sx={{ display: 'flex', gap: 2, position: 'relative', zIndex: 1 }}
            >
              {(store?.user?.account_type >= AccountTypeEnum.GOD ||
                (store?.user?.account_type >= AccountTypeEnum.LEAD_ADMIN &&
                  new Date(tournament?.start_time) <= new Date())) &&
                tournament?.status === 'PENDING' && (
                  <PrimaryButton
                    label={
                      startingTournament ? 'Starting...' : 'Start Tournament'
                    }
                    onClick={handleStartTournament}
                    disabled={
                      startingTournament ||
                      tournament?.registered_teams?.length < 2
                    }
                    color={green}
                  />
                )}

              {store?.user?.account_type >= AccountTypeEnum.LEAD_ADMIN && (
                <PrimaryButton
                  label='Edit Tournament'
                  onClick={handleOpenEditModal}
                  color={doubtedBlue}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <BiMoney size={18} />
                      Prize Pool
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      ${tournament.prize_pool.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaWallet size={16} />
                      Entry Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.entry_fee === 0
                        ? 'Free'
                        : `$${tournament.entry_fee.toFixed(2)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaUsers size={16} />
                      Teams
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.registered_teams.length} /{' '}
                      {tournament.max_players}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaUsers size={16} />
                      Team Size
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.team_size}V{tournament.team_size}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaGamepad size={16} />
                      Game Mode
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {
                        gameModeOptions.find(
                          option => option.value === tournament.game_match_type
                        )?.title
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaMapMarkerAlt size={16} />
                      Region
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.region.toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaGamepad size={16} />
                      Status
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        ...styles.statusChip,
                        backgroundColor: `${getStatusColor(tournament.status)}20`,
                        border: `1px solid ${getStatusColor(tournament.status)}`,
                      }}
                    >
                      {getStatusLabel(tournament.status)}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {store?.user?.account_type >= AccountTypeEnum.SENIOR_ADMIN && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 1,
                    }}
                  >
                    <Typography sx={styles.subLabel}>
                      Tournament Teams
                    </Typography>
                    {(tournament.status === 'PENDING' ||
                      tournament.status === 'REGISTRATION') && (
                      <Button
                        variant='contained'
                        onClick={() => setShowAddTeams(true)}
                        sx={styles.addButton}
                      >
                        Add Teams
                      </Button>
                    )}
                  </Box>
                  <Box sx={styles.teamChipsContainer}>
                    {tournament.registered_teams.map((team, index) => (
                      <Chip
                        key={index}
                        label={team.name}
                        variant='outlined'
                        sx={styles.teamChip}
                      />
                    ))}
                    {tournament.registered_teams.length === 0 && (
                      <Typography sx={{ color: secondaryText, fontSize: 14 }}>
                        No teams registered yet
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
              {renderPrizeStructure()}
            </Grid>
          </Paper>
        </Grid>

        {canRegister && (
          <Grid item xs={12}>
            <Paper
              sx={{
                ...styles.paper,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: `linear-gradient(135deg, ${doubtedBlue}10 0%, transparent 50%)`,
                  zIndex: 0,
                },
              }}
            >
              <Grid
                container
                direction='column'
                spacing={2}
                sx={{ position: 'relative', zIndex: 1 }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      ...styles.statValue,
                      marginBottom: 1,
                      fontSize: isTablet ? 20 : 24,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: `${doubtedBlue}20`,
                        borderRadius: '50%',
                        padding: '8px',
                        boxShadow: '0 4px 12px rgba(0, 120, 255, 0.1)',
                      }}
                    >
                      <FaUsers size={20} color={doubtedBlue} />
                    </Box>
                    Register for Tournament
                  </Typography>
                  <Typography
                    sx={{
                      ...styles.subHeader,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '8px 12px',
                      backgroundColor: 'rgba(255,255,255,0.03)',
                      borderRadius: '8px',
                      border: `1px solid ${doubtedBlue}20`,
                      width: 'fit-content',
                    }}
                  >
                    <FaWallet size={16} color={yellow} />
                    Entry Fee:{' '}
                    <span
                      style={{
                        color: yellow,
                        fontWeight: 600,
                        marginLeft: '4px',
                      }}
                    >
                      {tournament.entry_fee === 0
                        ? 'Free'
                        : `$${tournament.entry_fee.toFixed(2)}`}
                    </span>
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      ...styles.subLabel,
                      fontSize: 15,
                      marginBottom: 1.5,
                    }}
                  >
                    Select your team
                  </Typography>
                  <CustomDropdown
                    options={teams || []}
                    placeholder='Select team'
                    onChange={value => {
                      console.log('Selected team:', value);
                      setSelectedTeam(value);
                    }}
                    backgroundColor={cardDark}
                    borderColor={cardVeryLight}
                  />
                </Grid>

                <Grid item>
                  <PrimaryButton
                    label={registering ? 'Registering...' : 'Register Now'}
                    onClick={handleRegister}
                    disabled={registering || !selectedTeam}
                    fullWidth
                    color={doubtedBlue}
                    sx={{
                      padding: '12px',
                      fontSize: '16px',
                      fontWeight: 600,
                      boxShadow: '0 4px 12px rgba(0, 120, 255, 0.2)',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 8px 16px rgba(0, 120, 255, 0.3)',
                      },
                      transition: 'all 0.3s ease',
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {isRegistered &&
          tournament.status !== 'IN_PROGRESS' &&
          tournament.status !== 'COMPLETED' && (
            <Grid item xs={12}>
              <Paper
                sx={{
                  ...styles.paper,
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(135deg, ${green}10 0%, transparent 50%)`,
                    zIndex: 0,
                  },
                }}
              >
                <Grid
                  container
                  direction='column'
                  spacing={2}
                  sx={{ position: 'relative', zIndex: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        ...styles.statValue,
                        marginBottom: 1,
                        fontSize: isTablet ? 20 : 24,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: `${green}20`,
                          borderRadius: '50%',
                          padding: '8px',
                          boxShadow: '0 4px 12px rgba(0, 200, 83, 0.1)',
                        }}
                      >
                        <FaUsers size={20} color={green} />
                      </Box>
                      Tournament Registration
                    </Typography>
                    <Typography
                      sx={{
                        ...styles.subHeader,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        padding: '8px 12px',
                        backgroundColor: 'rgba(255,255,255,0.03)',
                        borderRadius: '8px',
                        border: `1px solid ${green}20`,
                        width: 'fit-content',
                        color: green,
                        fontWeight: 600,
                      }}
                    >
                      <span style={{ fontSize: '16px' }}>✓</span> You are
                      registered for this tournament
                    </Typography>
                  </Grid>

                  <Grid item>
                    <PrimaryButton
                      label='Leave Tournament'
                      onClick={() => setShowLeaveConfirmation(true)}
                      fullWidth
                      color={red}
                      sx={{
                        padding: '12px',
                        fontSize: '16px',
                        fontWeight: 600,
                        boxShadow: '0 4px 12px rgba(255, 0, 0, 0.1)',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 8px 16px rgba(255, 0, 0, 0.2)',
                        },
                        transition: 'all 0.3s ease',
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

        {(tournament.status === 'PENDING' ||
          tournament.status === 'REGISTRATION') && (
          <Grid item xs={12}>
            <Paper
              sx={{
                ...styles.paper,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: `radial-gradient(circle at top right, ${doubtedBlue}10, transparent 70%)`,
                  zIndex: 0,
                },
              }}
            >
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                  sx={{
                    ...styles.statValue,
                    marginBottom: 3,
                    fontSize: isTablet ? 20 : 24,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -10,
                      left: 0,
                      width: '40px',
                      height: '3px',
                      background: doubtedBlue,
                      borderRadius: '2px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: `${doubtedBlue}15`,
                      borderRadius: '50%',
                      padding: '8px',
                      boxShadow: '0 4px 12px rgba(0, 120, 255, 0.1)',
                    }}
                  >
                    <FaUsers size={20} color={doubtedBlue} />
                  </Box>
                  Registered Players
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(255,255,255,0.05)',
                      borderRadius: '12px',
                      padding: '4px 10px',
                      marginLeft: 1,
                      fontSize: isTablet ? 14 : 16,
                      border: `1px solid ${doubtedBlue}30`,
                    }}
                  >
                    {tournament.registered_players.length}
                  </Box>
                </Typography>
                <Grid container spacing={2}>
                  {tournament.registered_players.map(player => (
                    <Grid item xs={12} sm={6} md={4} key={player._id}>
                      <Box
                        sx={{
                          ...styles.playerCard,
                          position: 'relative',
                          overflow: 'hidden',
                          '&::before':
                            tournament.winner_id === player._id
                              ? {
                                  content: '""',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '4px',
                                  height: '100%',
                                  background: yellow,
                                  borderRadius: '4px 0 0 4px',
                                }
                              : {},
                        }}
                        onClick={() => {
                          handleOpenProfile(player?._id);
                        }}
                      >
                        <Avatar
                          size={48}
                          avatar={player.new_avatar}
                          bgColor={cardVeryLight}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: text,
                              fontWeight: 600,
                              fontSize: 16,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            {player.username}
                            {tournament.winner_id === player._id && (
                              <Badge label='Winner' size='small' sx={{ ml: 1 }}>
                                <GiTrophy
                                  style={{ color: yellow, fontSize: 14 }}
                                />
                              </Badge>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        )}

        {(tournament.status === 'IN_PROGRESS' ||
          tournament.status === 'COMPLETED') && (
          <Grid item xs={12}>
            <Paper
              sx={{
                ...styles.paper,
                padding: '24px 0',
                height: 'auto',
                overflow: 'visible',
              }}
            >
              <Typography
                sx={{
                  ...styles.statValue,
                  marginBottom: 3,
                  paddingLeft: 3,
                }}
              >
                Tournament Bracket
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  overflowX: 'auto',
                  height: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: cardDark,
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: cardVeryLight,
                    borderRadius: '4px',
                  },
                }}
              >
                <TournamentBracket tournament={tournament} />
              </Box>
            </Paper>
          </Grid>
        )}

        <AddTeamsDialog
          open={showAddTeams}
          onClose={() => setShowAddTeams(false)}
          teams={newTeams}
          onAddTeam={handleAddTeam}
          onRemoveTeam={handleRemoveTeam}
          onSubmit={handleSubmitTeams}
          newTeamName={newTeamName}
          onTeamNameChange={setNewTeamName}
          isAdding={addingTeams}
          styles={styles}
        />
      </Grid>

      <LeaveConfirmationDialog
        open={showLeaveConfirmation}
        onClose={() => setShowLeaveConfirmation(false)}
        onConfirm={handleLeaveTournament}
        isLeaving={isLeaving}
      />
      <TournamentForm
        open={showEditTournament}
        onClose={() => setShowEditTournament(false)}
        tournament={tournament}
        onUpdate={handleUpdateTournament}
      />
    </Box>
  );
};

export default TournamentDetail;
