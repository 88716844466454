import { Divider, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import {
  FaDoorOpen,
  FaHistory,
  FaLink,
  FaTshirt,
  FaUsers,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useMiddleware from '../../../api/useMiddleware';
import { logout } from '../../../api/user';
import Avatar from '../../../avatar/Avatar';
import {
  CLEAR_STORE,
  initialStore,
  SET_ERRORS,
  StoreContext,
  StoreDispatch,
} from '../../../store/Store';
import { numFormatter } from '../../../utils/helpers';
import {
  cardDark,
  cardExtraLight,
  cardVeryLight,
  lightGrey,
  text,
} from '../../../utils/themeContstants';
import CustomMenu from '../../custom/CustomMenu';
import NewListItem from '../../custom/NewListItem';
import RivoxCoin from '../../custom/RivoxCoin';
import SnipeCoin from '../../custom/SnipeCoin';

const AccountMenu = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const handleLogout = () => {
    logout(middleware).then(res => {
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        localStorage.clear();
        updateStore({ type: CLEAR_STORE, payload: initialStore });
        handleClose();
        navigate('/');
      }
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
    profileContainer: {
      borderRadius: 2,
      width: '100%',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardVeryLight,
      },
    },
    icon: {
      fontSize: 20,
      color: lightGrey,
    },
  };

  return (
    <CustomMenu anchor={anchor} handleClose={handleClose} minWidth={280}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={styles.profileContainer}
          onClick={() => {
            navigate('/profile/teams');
            handleClose();
          }}
        >
          <Grid container justifyContent='start' alignItems='center'>
            <Grid item sx={{ marginTop: -0.5 }}>
              <Avatar
                size={65}
                avatar={store?.user?.new_avatar}
                bgColor={cardDark}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: store?.user?.premium_info?.active
                          ? 'rgb(255, 255, 193)'
                          : text,
                        textShadow: store?.user?.premium_info?.active
                          ? 'rgb(255 93 0) 0px 0px 10px'
                          : null,
                      }}
                    >
                      {store?.user?.username}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    justifyContent='start'
                    alignItems='start'
                    gap={{ xs: 0.5 }}
                  >
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 0.5 }}
                    >
                      <RivoxCoin size={18} />

                      <Grid item>
                        <Typography
                          sx={{ fontSize: 14, fontWeight: 500, color: text }}
                        >
                          {numFormatter(
                            store?.user?.funds?.balance < 1 ? 4 : 2
                          ).format(store?.user?.funds?.balance)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Duplicate balance section */}
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 0.5 }}
                    >
                      <SnipeCoin size={16} />

                      <Grid item>
                        <Typography
                          sx={{ fontSize: 14, fontWeight: 500, color: text }}
                        >
                          {store?.user?.premium_info?.snipes}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardExtraLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <NewListItem
              title='Locker'
              icon={<FaTshirt style={styles.icon} />}
              backgroundHover={cardVeryLight}
              onClick={() => {
                navigate('/locker');
                handleClose();
              }}
            />
            <NewListItem
              title='Teams'
              icon={<FaUsers style={styles.icon} />}
              backgroundHover={cardVeryLight}
              onClick={() => {
                navigate('/profile/teams');
                handleClose();
              }}
            />
            <NewListItem
              title='Linked Accounts'
              icon={<FaLink style={styles.icon} />}
              backgroundHover={cardVeryLight}
              onClick={() => {
                navigate('/profile/accounts');
                handleClose();
              }}
            />
            <NewListItem
              title='Match History'
              icon={<FaHistory style={styles.icon} />}
              backgroundHover={cardVeryLight}
              onClick={() => {
                navigate('/profile/history');
                handleClose();
              }}
            />
            <NewListItem
              title='Log Out'
              icon={<FaDoorOpen style={styles.icon} />}
              backgroundHover={cardVeryLight}
              onClick={handleLogout}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomMenu>
  );
};

export default AccountMenu;
