import {
  Divider,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FaCrown, FaShieldAlt } from 'react-icons/fa';
import { MdGames } from 'react-icons/md';
import { TbSocialOff } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useMiddleware from '../../api/useMiddleware';
import { getUserProfile } from '../../api/user';
import Avatar from '../../avatar/Avatar';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import {
  getDateFromId,
  getFee,
  getReadableDateString,
  getReadableTime,
  isWithinOneMinute,
  numFormatter,
} from '../../utils/helpers';
import {
  arcteryx,
  badgeIconSize,
  blue,
  cardDark,
  cardLight,
  green,
  large,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import UpgradeModal from '../app/UpgradeModal';
import ACCoin from '../custom/ACCoin';
import Badge from '../custom/Badge';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import SocialBadge from '../leaderboard/SocialBadge';
import AddNoteModal from '../staff/AddNoteModal';
import PunishModal from '../staff/PunishModal';
import UserTipModal from '../tip/UserTipModal';
import ResetEarningsModal from './ResetEarningsModal';
import ResetStatsModal from './ResetStatsModal';

const PublicProfileModal = ({ open, onClose }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [searchedUser, setSearchedUser] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tipOpen, setTipOpen] = useState(false);
  const [punishOpen, setPunishOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [recentMatches, setRecentMatches] = useState([]);

  const handleClose = () => {
    setSearchedUser(null);
    setLoading(true);
    setUser(null);
    setTipOpen(false);
    setPunishOpen(false);
    setNoteOpen(false);
    setResetOpen(false);
    setUpgradeOpen(false);
    setRecentMatches([]);
    onClose();
  };

  useEffect(() => {
    setSearchedUser(store?.searchUser);
  }, [store?.searchUser]);

  useEffect(() => {
    if (open && searchedUser && loading) {
      if (user == null) {
        getUserProfile(middleware, searchedUser).then(res => {
          setLoading(false);
          if (res?.error) {
            updateStore({ type: SET_ERRORS, payload: res?.message });
            handleClose();
          } else {
            setUser(res.userdata);
            setRecentMatches(res.recentMatches || []);
          }
        });
      }
    }
  }, [open, middleware, searchedUser, user, loading, updateStore]);

  const handleNavigate = match_id => {
    handleClose();
    return navigate(`/token/${match_id}`);
  };

  const getCoinAmount = match => {
    if (match?.state === 'cancel') return 0;

    const isWinner = isUserWinner(match);

    if (!isWinner) {
      return match?.entry_fee;
    }

    return match?.entry_fee * getFee(match?.game);
  };

  const formatMatchDate = isoDate => {
    if (!isoDate) return '';

    const date = new Date(isoDate);
    const now = new Date();
    const diffMs = now - date;
    const diffHours = diffMs / (1000 * 60 * 60);

    if (diffHours < 1) return 'Less than an hour ago';
    if (diffHours < 24)
      return `${Math.floor(diffHours)} hour${Math.floor(diffHours) > 1 ? 's' : ''} ago`;
    if (diffHours < 48) return 'Yesterday';
    if (diffHours < 168) return `${Math.floor(diffHours / 24)} days ago`;

    return `${getReadableDateString(date)} at ${getReadableTime(date)}`;
  };

  // Function to get opponent names from match data
  const getOpponentName = match => {
    if (!match || !user) return 'Unknown Opponent';

    // Handle team-based matches
    if (match.team_size && parseInt(match.team_size) > 1) {
      const teamSize = parseInt(match.team_size);
      const totalPlayers = match.user_ids.length;
      const userIndex = match.user_ids.indexOf(user._id);

      if (userIndex !== -1) {
        const userTeamStartIndex = Math.floor(userIndex / teamSize) * teamSize;
        const userTeamEndIndex = userTeamStartIndex + teamSize;

        // Get all users who are not on the same team as the current user
        const opponentIds = match.user_ids.filter((id, index) => {
          return index < userTeamStartIndex || index >= userTeamEndIndex;
        });

        // Map opponent IDs to usernames
        const opponentNames = opponentIds.map(id =>
          match.users && match.users[id]
            ? match.users[id].username
            : 'Unknown Player'
        );

        return opponentNames.length > 0
          ? opponentNames.join(', ')
          : 'Unknown Opponent';
      }
    }

    // For 1v1 matches or fallback
    const opponentIds = match.user_ids.filter(id => id !== user._id);
    const opponentNames = opponentIds.map(id =>
      match.users && match.users[id]
        ? match.users[id].username
        : 'Unknown Player'
    );

    return opponentNames.length > 0
      ? opponentNames.join(', ')
      : 'Unknown Opponent';
  };

  // Function to determine if current user won the match
  const isUserWinner = match => {
    if (!match || !user) return false;
    return match.winner_ids.includes(user._id);
  };

  // Function to format match type for display
  const getMatchTypeDisplay = match => {
    if (!match) return '';

    // Check if it's a tournament match
    if (match.tournament_id) {
      return 'Tournament Match';
    }

    // Default to token match
    return 'Token Match';
  };

  const styles = {
    width: {
      width: '100%',
    },
    username: {
      fontSize: large,
      fontWeight: 800,
      color: user?.premium_info?.active ? 'rgb(255, 255, 193)' : text,
      textShadow: user?.premium_info?.active
        ? 'rgb(255 93 0) 0px 0px 10px'
        : null,
    },
    epic: {
      fontWeight: 500,
      color: secondaryText,
      fontSize: normal,
    },
    tipContainer: {
      borderRadius: 1,
      padding: 1,
      backgroundColor: 'rgba(255, 229, 0, 0.13)',
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 229, 0, 0.23)',
      },
    },
    stats: {
      padding: 2,
      width: '100%',
      borderRadius: 1,
      backgroundColor: cardLight,
    },
    label: {
      fontSize: 13,
      color: offWhite,
      fontWeight: 600,
    },
    winLossBar: {
      height: 6,
      borderRadius: 3,
      width: '100%',
      overflow: 'hidden',
    },
    chartContainer: {
      padding: '8px 0',
      width: '100%',
    },
    winSection: {
      backgroundColor: green,
    },
    lossSection: {
      backgroundColor: red,
    },
    recentMatchesContainer: {
      backgroundColor: cardLight,
      borderRadius: 1,
      padding: 2,
      width: '100%',
      marginTop: 2,
    },
    matchItem: {
      padding: '8px 12px',
      borderRadius: 1,
      backgroundColor: cardDark,
      transition: transition,
      marginBottom: 1,
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.9,
      },
    },
    matchResult: {
      fontWeight: 800,
      fontSize: small,
    },
    winResult: {
      color: green,
    },
    lossResult: {
      color: red,
    },
    sectionTitle: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
      marginBottom: 1,
    },
    matchDate: {
      fontSize: 12,
      color: secondaryText,
    },
    socialActionBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: cardDark,
      borderRadius: '50%',
      width: 36,
      height: 36,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: blue,
      },
    },
    divider: {
      backgroundColor: 'rgba(255,255,255,0.1)',
      margin: '16px 0',
    },
  };

  return (
    <>
      <UpgradeModal
        open={!store?.user?.premium_info?.active ? tipOpen : false}
        onClose={handleClose}
      />

      <UserTipModal
        open={store?.user?.premium_info?.active ? tipOpen : false}
        onClose={() => setTipOpen(false)}
        closeSearch={() => {}}
        user={
          user
            ? {
                user_id: user?._id,
                username: user?.username,
                avatar: user?.avatar,
                isPremium: user?.premium_info?.active,
                epic: user?.connections?.epic?.name,
              }
            : null
        }
      />

      <PunishModal
        open={punishOpen}
        onClose={() => setPunishOpen(false)}
        user={user}
      />
      <AddNoteModal
        open={noteOpen}
        onClose={() => setNoteOpen(false)}
        user={user}
      />
      <ResetStatsModal
        open={resetOpen}
        onClose={() => setResetOpen(false)}
        closeProfile={handleClose}
      />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <CustomModal
        loading={loading}
        open={open}
        onClose={handleClose}
        minWidth={600}
      >
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid container justifyContent='start' alignItems='center'>
              <Grid item sx={{ marginLeft: -2, marginTop: -2 }}>
                <Avatar
                  size={250}
                  avatar={user?.new_avatar}
                  bgColor={cardDark}
                />
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              justifyContent='start'
                              alignItems='center'
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.username}>
                                  {user?.username}
                                </Typography>
                              </Grid>

                              {(user?.connections?.twitch?.name ||
                                user?.connections?.twitter?.name ||
                                user?.connections?.discord?.name ||
                                user?.account_type >=
                                  AccountTypeEnum.MODERATOR) && (
                                <>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent='start'
                                      alignItems='center'
                                      gap={{ xs: 1 }}
                                    >
                                      {user?.account_type >=
                                        AccountTypeEnum.MODERATOR && (
                                        <Badge label='STAFF'>
                                          <FaShieldAlt
                                            style={{
                                              color: blue,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {/* {(user?.account_type ===
                                        AccountTypeEnum.MODERATOR ||
                                        user?.account_type ===
                                          AccountTypeEnum.SENIOR_MODERATOR) && (
                                        <Badge label="MODERATOR">
                                          <FaShieldAlt
                                            style={{
                                              color: blue,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.GOD && (
                                        <Badge label="OWNER">
                                          <ImPower
                                            style={{
                                              color: green,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.SENIOR_ADMIN && (
                                        <Badge label="SR. ADMIN">
                                          <GiSwordsEmblem
                                            style={{
                                              color: "white",
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.ADMIN && (
                                        <Badge label="ADMIN">
                                          <TbSwords
                                            style={{
                                              color: red,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.JUNIOR_ADMIN && (
                                        <Badge label="JR. ADMIN">
                                          <TbSwords
                                            style={{
                                              color: purple,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )}
                                      {user?.account_type ===
                                        AccountTypeEnum.ANTICHEAT && (
                                        <Badge label="ANTICHEAT STAFF">
                                          <TiCloudStorage
                                            style={{
                                              color: muffinsIdea,
                                              fontSize: badgeIconSize,
                                            }}
                                          />
                                        </Badge>
                                      )} */}

                                      {user?.connections?.twitter && (
                                        <SocialBadge
                                          type='twitter'
                                          style={{}}
                                          onClick={() =>
                                            window.open(
                                              `${user?.connections?.twitter?.external_link}`,
                                              '_blank'
                                            )
                                          }
                                        />
                                      )}
                                      {user?.connections?.discord && (
                                        <SocialBadge
                                          type='discord'
                                          style={{}}
                                          user={
                                            user?.connections?.discord?.name
                                          }
                                        />
                                      )}

                                      {user?.connections?.twitch && (
                                        <SocialBadge
                                          type='twitch'
                                          onClick={() =>
                                            window.open(
                                              `${user?.connections?.twitch?.external_link}`,
                                              '_blank'
                                            )
                                          }
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* {user?.match_info?.match_id && (
                                <Badge
                                  label="In Match"
                                  onClick={() =>
                                    handleNavigate(user?.match_info?.match_id)
                                  }
                                >
                                  <MdOutlineGamepad
                                    style={{
                                      color: text,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )} */}
                              {user?.anticheat?.must_run_ac &&
                                isWithinOneMinute(
                                  user?.anticheat?.last_heartbeat_timestamp
                                ) && (
                                  <Badge label='Running Rivox AC'>
                                    <Grid
                                      item
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <ACCoin size={16} />
                                    </Grid>
                                  </Badge>
                                )}
                              {user?.stats?.rank > 0 && (
                                <Badge label={'Top ' + user?.stats?.rank}>
                                  <MdGames
                                    style={{
                                      color: arcteryx,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )}
                              {user?.premium_info?.active && (
                                <Badge label='VIP'>
                                  <FaCrown
                                    style={{
                                      color: yellow,
                                      fontSize: badgeIconSize,
                                    }}
                                  />
                                </Badge>
                              )}

                              {!user?.connections?.twitch?.name &&
                                !user?.connections?.twitter?.name &&
                                !user?.connections?.discord?.name && (
                                  <Badge label='No Socials Linked'>
                                    <TbSocialOff
                                      style={{
                                        color: secondaryText,
                                        fontSize: badgeIconSize,
                                      }}
                                    />
                                  </Badge>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {store?.user?.account_type >=
                        AccountTypeEnum.SENIOR_MODERATOR && (
                        <Grid item>
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                            gap={{ xs: 1 }}
                          >
                            {store?.user?._id !== user?._id && (
                              <>
                                <Grid item>
                                  <SecondaryButton
                                    label='Give Karma'
                                    size='small'
                                    onClick={() => setPunishOpen(true)}
                                  />
                                </Grid>
                                {user?.match_info?.match_id !== null && (
                                  <Grid item>
                                    <SecondaryButton
                                      label='Active Match'
                                      size='small'
                                      onClick={() =>
                                        window.open(
                                          'https://rivox.gg/token/' +
                                            user?.match_info?.match_id
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                                {/* <Grid item>
                                  <SecondaryButton
                                    label="Add Note"
                                    size="small"
                                    onClick={() => setNoteOpen(true)}
                                  />
                                </Grid> */}
                              </>
                            )}

                            {store?.user?.account_type >=
                              AccountTypeEnum.JUNIOR_ADMIN && (
                              <Grid item>
                                <SecondaryButton
                                  label='View'
                                  size='small'
                                  onClick={() => {
                                    navigate('/staff/search', {
                                      state: {
                                        user_id: user?._id,
                                      },
                                    });
                                    handleClose();
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      {user?._id !== store?.user?._id && (
                        <Grid
                          item
                          sx={styles.tipContainer}
                          onClick={() => {
                            if (!store?.user) {
                              navigate('/login');
                            } else {
                              setTipOpen(true);
                            }
                          }}
                        >
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                            gap={{ xs: 0.5 }}
                          >
                            <RivoxCoin size={20} />

                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: small,
                                  fontWeight: 800,
                                  color: yellow,
                                }}
                              >
                                TIP
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.stats}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='start'
              gap={{ xs: 2 }}
            >
              {/* Left Column - Existing Stats */}
              <Grid item xs={5.5}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>
                      Joined On:{' '}
                      <span style={{ fontWeight: 800, color: text }}>
                        {getReadableDateString(getDateFromId(user?._id))}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.label}>Total Earned:</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 0.5 }}
                        >
                          <RivoxCoin size={15} />

                          <Grid item>
                            <Typography
                              sx={{
                                ...styles.label,
                                fontWeight: 800,
                                color: text,
                              }}
                            >
                              {numFormatter().format(user?.stats?.earnings)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.label}>
                      Total Wins:{' '}
                      <span style={{ fontWeight: 800, color: text }}>
                        {user?.stats?.wins}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.label}>
                      Total Losses:{' '}
                      <span style={{ fontWeight: 800, color: text }}>
                        {user?.stats?.losses}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      alignItems='center'
                      wrap='nowrap'
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      <Typography sx={{ ...styles.label, marginRight: '4px' }}>
                        Highest Match Earnings:
                      </Typography>
                      <RivoxCoin size={15} style={{ marginRight: '4px' }} />
                      <Typography
                        sx={{
                          ...styles.label,
                          fontWeight: 800,
                          color: text,
                        }}
                      >
                        {numFormatter().format(
                          user?.stats?.highest_entry_fee_match *
                            getFee('fortnite') *
                            2 || 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Right Column - New Stats */}
              <Grid item xs={5.5}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  {user?.stats?.rank > 0 && (
                    <Grid item>
                      <Typography sx={styles.label}>
                        Rank:{' '}
                        <span style={{ fontWeight: 800, color: arcteryx }}>
                          #{user?.stats?.rank}
                        </span>
                      </Typography>
                    </Grid>
                  )}

                  <Grid item>
                    <Typography sx={styles.label}>
                      Matches Played:{' '}
                      <span style={{ fontWeight: 800, color: text }}>
                        {(user?.stats?.wins || 0) + (user?.stats?.losses || 0)}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.label}>
                          Avg. Match Entry Fee:
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 0.5 }}
                        >
                          <RivoxCoin size={15} />

                          <Grid item>
                            <Typography
                              sx={{
                                ...styles.label,
                                fontWeight: 800,
                                color: text,
                              }}
                            >
                              {numFormatter().format(
                                user?.stats?.user_average_entry_fee || 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.label}>
                      Win Rate:{' '}
                      <span style={{ fontWeight: 800, color: text }}>
                        {user?.stats?.wins && user?.stats?.losses
                          ? `${Math.round(
                              (user.stats.wins /
                                (user.stats.wins + user.stats.losses)) *
                                100
                            )}%`
                          : '0%'}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item sx={styles.chartContainer}>
                    {user?.stats?.wins > 0 || user?.stats?.losses > 0 ? (
                      <Tooltip
                        title={`${user?.stats?.wins || 0} Wins / ${user?.stats?.losses || 0} Losses`}
                        arrow
                        placement='top'
                      >
                        <Grid container sx={styles.winLossBar}>
                          {user?.stats?.wins > 0 && (
                            <Grid
                              item
                              xs={
                                (user?.stats?.wins /
                                  (user?.stats?.wins + user?.stats?.losses)) *
                                12
                              }
                              sx={{
                                ...styles.winSection,
                                height: '100%',
                                borderRadius:
                                  user?.stats?.losses === 0 ? 3 : '3px 0 0 3px',
                              }}
                            />
                          )}
                          {user?.stats?.losses > 0 && (
                            <Grid
                              item
                              xs={
                                (user?.stats?.losses /
                                  (user?.stats?.wins + user?.stats?.losses)) *
                                12
                              }
                              sx={{
                                ...styles.lossSection,
                                height: '100%',
                                borderRadius:
                                  user?.stats?.wins === 0 ? 3 : '0 3px 3px 0',
                              }}
                            />
                          )}
                        </Grid>
                      </Tooltip>
                    ) : (
                      <LinearProgress variant='determinate' value={0} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Recent Matches Section */}
          <Grid item sx={styles.recentMatchesContainer}>
            <Typography sx={styles.sectionTitle}>Recent Matches</Typography>
            <Divider sx={styles.divider} />

            {recentMatches.length > 0 ? (
              <Grid container direction='column' spacing={1}>
                {recentMatches.map(match => (
                  <Grid item key={match._id}>
                    <Grid
                      container
                      sx={styles.matchItem}
                      onClick={() => handleNavigate(match.match_id)}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid item>
                        <Grid container direction='column'>
                          <Typography
                            sx={{
                              ...styles.matchResult,
                              ...(isUserWinner(match)
                                ? styles.winResult
                                : styles.lossResult),
                            }}
                          >
                            {isUserWinner(match) ? 'Victory' : 'Defeat'} vs{' '}
                            {getOpponentName(match)}
                            {
                              <span
                                style={{
                                  color: secondaryText,
                                  fontSize: '0.9em',
                                  marginLeft: '4px',
                                }}
                              >
                                • {getMatchTypeDisplay(match)}
                              </span>
                            }
                          </Typography>
                          <Typography sx={styles.matchDate}>
                            {formatMatchDate(match.date)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container alignItems='center' gap={0.5}>
                          <RivoxCoin size={15} />
                          <Typography
                            sx={{
                              fontWeight: 700,
                              color: isUserWinner(match) ? green : red,
                            }}
                          >
                            {numFormatter().format(getCoinAmount(match))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography sx={{ color: secondaryText, fontSize: small }}>
                No recent matches found.
              </Typography>
            )}
          </Grid>

          {store?.user?._id === user?._id && (
            <Grid container direction='row' alignItems='center'>
              <Grid item style={{ paddingRight: '8px' }}>
                <SecondaryButton
                  label='Reset Stats'
                  size='small'
                  onClick={() => setResetOpen(true)}
                />
              </Grid>
              <Grid item>
                <SecondaryButton
                  label='Reset Earnings'
                  size='small'
                  onClick={() => setResetEarningsOpen(true)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CustomModal>
    </>
  );
};

export default PublicProfileModal;
