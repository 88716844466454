import { Grid, Skeleton } from '@mui/material';
import { secondaryButton } from '../../utils/themeContstants';

const TeamUserGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  return (
    <Grid item>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        {array.map((_, i) => (
          <Grid item key={i}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 3 }}
            >
              <Grid item>
                <Skeleton
                  variant='circular'
                  width={75}
                  height={75}
                  sx={{ bgcolor: secondaryButton }}
                  animation='pulse'
                />
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Skeleton
                      width={100}
                      height={30}
                      sx={{ bgcolor: secondaryButton, borderRadius: 1 }}
                      animation='pulse'
                      variant='text'
                    />
                  </Grid>

                  <Grid item>
                    <Skeleton
                      width={75}
                      height={20}
                      sx={{ bgcolor: secondaryButton, borderRadius: 1 }}
                      animation='pulse'
                      variant='text'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TeamUserGlimmer;
