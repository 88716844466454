import { Grid, Typography } from '@mui/material';
import { secondaryText, text } from '../../../utils/themeContstants';
import UnreadBadge from '../../custom/UnreadBadge';

const BottomNavItem = ({ icon, label, onClick, selected, badgeCount }) => {
  const styles = {
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    label: {
      fontSize: 11,
      fontWeight: 700,
      color: selected ? text : secondaryText,
    },
  };

  return (
    <Grid item onClick={onClick}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item sx={styles.icon}>
          {badgeCount > 0 && <UnreadBadge mobile={true} />}
          {icon}
        </Grid>

        <Grid item>
          <Typography sx={styles.label}>{label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BottomNavItem;
