import { Box, Typography } from '@mui/material';

const MapItem = ({ map }) => {
  const styles = {
    mapItem: {
      position: 'relative',
      width: '100%',
      height: '250px',
      marginBottom: '20px',
      overflow: 'hidden',
      borderRadius: '10px',
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    playerCount: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
    },
    mapTitle: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '10px',
      textAlign: 'center',
    },
  };
  return (
    <Box style={styles.mapItem}>
      <div style={styles.imageContainer}>
        <img
          src={map.imgSrc}
          alt={map.title}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <Typography variant='h6' style={styles.playerCount}>
          {map.players} Players
        </Typography>
      </div>
      <Typography variant='subtitle1' style={styles.mapTitle}>
        {map.title}
      </Typography>
    </Box>
  );
};

export default MapItem;
