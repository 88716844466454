import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AiFillHome, AiOutlineHome, AiOutlineMenu } from 'react-icons/ai';
import { MdNotifications, MdOutlineNotificationsNone } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import {
  card,
  cardVeryLight,
  offWhite,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import MobileMainMenu from './MobileMainMenu';
import BottomNavItem from './mobile_custom/BottomNavItem';

const BottomNavbar = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(null);
  const [hasNoti, setHasNoti] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (store?.notiCount > 0) {
      setHasNoti(true);
    } else {
      setHasNoti(false);
    }
  }, [store?.notiCount]);

  useEffect(() => {
    const path = location?.pathname;

    if (path === '/') {
      return setSelected('home');
    }

    if (path === '/notifications') {
      return setSelected('notifications');
    }

    if (path === '/games') {
      return setSelected('games');
    }

    setSelected(null);
  }, [location?.pathname]);

  const styles = {
    container: {
      width: '100%',
      backgroundColor: card,
      position: 'fixed',
      bottom: 0,
      height: 60,
      zIndex: 100,
      borderTop: `1px solid ${cardVeryLight}`,
    },
    iconSelected: {
      fontSize: 20,
      color: text,
    },
    iconNotSelected: {
      fontSize: 20,
      color: secondaryText,
    },
  };

  return (
    <>
      <MobileMainMenu open={menuOpen} onClose={() => setMenuOpen(false)} />

      <Grid item sx={styles.container}>
        <Grid
          container
          justifyContent='space-around'
          alignItems='center'
          sx={{ height: 60 }}
        >
          <BottomNavItem
            label='Home'
            icon={
              selected === 'home' ? (
                <AiFillHome style={styles.iconSelected} />
              ) : (
                <AiOutlineHome style={styles.iconNotSelected} />
              )
            }
            onClick={() => navigate('/')}
            selected={selected === 'home'}
          />

          <BottomNavItem
            label='Notifications'
            icon={
              selected === 'notifications' ? (
                <MdNotifications style={styles.iconSelected} />
              ) : (
                <MdOutlineNotificationsNone style={styles.iconNotSelected} />
              )
            }
            onClick={() => {
              navigate('/notifications');
              setHasNoti(false);
            }}
            selected={selected === 'notifications'}
            badgeCount={hasNoti ? 1 : 0}
          />

          {/* <BottomNavItem
            label="Games"
            icon={
              selected === "games" ? (
                <IoGameController style={styles.iconSelected} />
              ) : (
                <IoGameControllerOutline style={styles.iconNotSelected} />
              )
            }
            onClick={() => navigate("/games")}
            selected={selected === "games"}
          /> */}

          <BottomNavItem
            label='Menu'
            icon={<AiOutlineMenu style={{ color: offWhite, fontSize: 20 }} />}
            onClick={() => setMenuOpen(true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BottomNavbar;
