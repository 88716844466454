import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getUserIps } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { getReadableDateString, getTime } from '../../utils/helpers';
import {
  card,
  cardLight,
  green,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import NotSelected from '../custom/NotSelected';

const UserIpLogs = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [loading, setLoading] = useState(true);
  const [ipData, setIpData] = useState(null);
  const [sortedIps, setSortedIps] = useState([]);
  const [sortType, setSortType] = useState('date');

  const handleGetIps = () => {
    if (!user?._id) {
      setLoading(false);
      return;
    }

    getUserIps(middleware, user?._id)
      .then(res => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          setIpData(res);
        }
      })
      .catch(error => {
        console.error('Error fetching IP data:', error);
        setLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to load IP data. Please try again.',
        });
      });
  };

  // Helper function to extract timestamp from MongoDB ObjectID
  const getTimestampFromObjectId = objectId => {
    if (!objectId) return null;

    try {
      // Convert to string if it's not already a string
      const idStr =
        typeof objectId === 'string' ? objectId : objectId.toString();
      // MongoDB ObjectIDs first 4 bytes represent seconds since Unix epoch
      const timestamp = parseInt(idStr.substring(0, 8), 16) * 1000;
      return new Date(timestamp);
    } catch (error) {
      console.error('Error extracting timestamp from ObjectID:', error);
      return null;
    }
  };

  // Sort IPs whenever ipData or sortType changes
  useEffect(() => {
    if (ipData?.ips && ipData.ips.length > 0) {
      try {
        // Create a copy of the ips array with timestamps
        const ipsWithDates = ipData.ips.map(ip => {
          if (!ip) return { timestamp: 0, logins: 0 };

          const timestamp = ip?._id ? getTimestampFromObjectId(ip._id) : null;
          return {
            ...ip,
            timestamp: timestamp ? timestamp.getTime() : 0, // Convert to number for sorting
          };
        });

        // Sort based on selected criterion
        let sorted;
        if (sortType === 'date') {
          // Sort by timestamp (newest first)
          sorted = ipsWithDates.sort((a, b) => b.timestamp - a.timestamp);
        } else {
          // Sort by number of logins (highest first)
          sorted = ipsWithDates.sort(
            (a, b) => (b.logins || 0) - (a.logins || 0)
          );
        }

        setSortedIps(sorted);
      } catch (error) {
        console.error('Error sorting IP data:', error);
        // Fallback to unsorted data
        setSortedIps(ipData.ips);
      }
    } else {
      setSortedIps([]);
    }
  }, [ipData, sortType]);

  useEffect(() => {
    if (ipData == null) {
      handleGetIps();
    }
  }, [user]);

  const handleSortChange = event => {
    setSortType(event.target.value);
  };

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
    ipText: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    loginText: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    dateText: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
      marginTop: 0.5,
    },
    accountInfoContainer: {
      borderRadius: 1,
      padding: 1,
      width: '100%',
      backgroundColor: card,
      boxShadow: modalShadow,
      marginBottom: 2,
    },
    infoTitle: {
      fontSize: normal,
      fontWeight: 700,
      color: yellow,
      marginBottom: 0.5,
    },
    infoText: {
      fontSize: normal,
      fontWeight: 500,
      color: green,
    },
    title: {
      fontSize: normal,
      fontWeight: 700,
      color: offWhite,
      marginBottom: 1,
    },
    sortContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 1,
      width: '100%',
    },
    formControl: {
      minWidth: 150,
      marginLeft: 'auto',
    },
    select: {
      color: offWhite,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: secondaryText,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: offWhite,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: yellow,
      },
    },
    selectLabel: {
      color: secondaryText,
      '&.Mui-focused': {
        color: yellow,
      },
    },
    menuItem: {
      fontSize: small,
    },
    ipCard: {
      borderRadius: 1,
      padding: 1,
      width: '100%',
      backgroundColor: card,
      boxShadow: modalShadow,
      transition: transition,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!ipData?.ips || ipData?.ips?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO IP HISTORY' />
        </Grid>
      )}

      {!loading && ipData?.ips && ipData?.ips?.length > 0 && (
        <Grid item sx={styles.width}>
          <Typography sx={styles.title}>
            IP History for {ipData.username}
          </Typography>

          {/* Account info section */}
          <Grid item sx={styles.accountInfoContainer}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              sx={{
                width: '100%',
                padding: 1,
                borderRadius: 1,
                backgroundColor: cardLight,
              }}
            >
              <Grid item>
                <Typography sx={styles.infoTitle}>
                  Account Information
                </Typography>
              </Grid>
              {ipData.account_creation_date && (
                <Grid item>
                  <Typography sx={styles.infoText}>
                    Account Created:{' '}
                    {getReadableDateString(
                      new Date(ipData.account_creation_date)
                    )}{' '}
                    at {getTime(new Date(ipData.account_creation_date))}
                  </Typography>
                </Grid>
              )}
              {ipData.last_activity && (
                <Grid item>
                  <Typography sx={styles.infoText}>
                    Last Activity:{' '}
                    {getReadableDateString(new Date(ipData.last_activity))} at{' '}
                    {getTime(new Date(ipData.last_activity))}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography sx={styles.infoText}>
                  Total Unique IPs: {ipData.total_unique_ips || 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={styles.infoText}>
                  Total Logins: {ipData.total_logins || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.sortContainer}>
            <Typography sx={styles.title}>IP Address Details</Typography>
            <FormControl
              variant='outlined'
              size='small'
              sx={styles.formControl}
            >
              <InputLabel id='sort-select-label' sx={styles.selectLabel}>
                Sort By
              </InputLabel>
              <Select
                labelId='sort-select-label'
                value={sortType}
                onChange={handleSortChange}
                label='Sort By'
                sx={styles.select}
              >
                <MenuItem value='date' sx={styles.menuItem}>
                  First Seen (Newest)
                </MenuItem>
                <MenuItem value='logins' sx={styles.menuItem}>
                  Number of Logins
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 0.5 }}
          >
            {sortedIps.map((ip, i) => {
              if (!ip) return null;

              // Get timestamp from ObjectID if available
              const creationDate = ip?._id
                ? getTimestampFromObjectId(ip._id)
                : null;

              return (
                <Grid item sx={styles.ipCard} key={i}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    sx={{
                      width: '100%',
                      padding: 1,
                      borderRadius: 1,
                      backgroundColor: cardLight,
                    }}
                  >
                    <Grid item>
                      <Typography sx={styles.ipText}>
                        IP Address: {ip?.ip || 'Unknown'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.loginText}>
                        Number of Logins: {ip?.logins || 0}
                      </Typography>
                    </Grid>
                    {creationDate && (
                      <Grid item>
                        <Typography sx={styles.dateText}>
                          First seen: {getReadableDateString(creationDate)} at{' '}
                          {getTime(creationDate)}
                        </Typography>
                      </Grid>
                    )}
                    {!creationDate && (
                      <Grid item>
                        <Typography sx={styles.dateText}>
                          First seen: Date information not available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserIpLogs;
