import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createAutomatedTournament,
  getAutomatedTournaments,
  updateAutomatedTournament,
} from '../../api/automatedTournaments';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { newGameModeOptions } from '../../utils/options';
import {
  card,
  cardDark,
  doubtedBlue,
  red,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import PrimaryButton from '../custom/PrimaryButton';

// Define platform options directly
const PLATFORM_OPTIONS = [
  { title: 'All Platforms', value: null },
  { title: 'Last Gen', value: 'last_gen' },
  { title: 'Current Gen', value: 'current_gen' },
  { title: 'Console', value: 'console' },
];

// Define region options directly
const REGION_OPTIONS = [
  { title: 'NA West', value: 'naw' },
  { title: 'NA East', value: 'nae' },
  { title: 'Europe', value: 'eu' },
  { title: 'Brazil', value: 'br' },
  { title: 'Asia', value: 'asia' },
  { title: 'Oceania', value: 'oce' },
  { title: 'NA Central', value: 'nac' },
  { title: 'NA', value: 'na' },
];

const DAYS_OF_WEEK = [
  { title: 'Monday', value: 'monday' },
  { title: 'Tuesday', value: 'tuesday' },
  { title: 'Wednesday', value: 'wednesday' },
  { title: 'Thursday', value: 'thursday' },
  { title: 'Friday', value: 'friday' },
  { title: 'Saturday', value: 'saturday' },
  { title: 'Sunday', value: 'sunday' },
];

const GAME_OPTIONS = [
  { title: 'Fortnite', value: 'fortnite' },
  { title: 'Warzone', value: 'warzone' },
  { title: 'Chess', value: 'chess' },
  { title: 'FIFA', value: 'fifa' },
  { title: 'Clash Royale', value: 'clashroyale' },
];

const MAX_PLAYERS_OPTIONS = [
  { title: '2 Teams', value: '2' },
  { title: '4 Teams', value: '4' },
  { title: '8 Teams', value: '8' },
  { title: '16 Teams', value: '16' },
  { title: '32 Teams', value: '32' },
  { title: '64 Teams', value: '64' },
  { title: '128 Teams', value: '128' },
  { title: '256 Teams', value: '256' },
];

const TEAM_SIZE_OPTIONS = [
  { title: '1v1', value: 1 },
  { title: '2v2', value: 2 },
  { title: '3v3', value: 3 },
  { title: '4v4', value: 4 },
];

const DEFAULT_FORM_DATA = {
  name: 'Daily Tournament',
  day_of_week: 'monday',
  start_time: '18:00',
  game: 'fortnite',
  game_modes: [{ type: 'real', weight: 100 }],
  max_players: '128',
  team_size: 1,
  first_to: 3,
  region: 'eu',
  platform: null,
  prize_structure: [
    { place: 1, amount: 9 },
    { place: 2, amount: 4 },
    { place: 3, amount: 2 },
    { place: 4, amount: 1 },
    { place: 5, amount: 0.5 },
  ],
  registration_opens_minutes_before: 90,
  is_locked: false,
  entry_fee: 0,
};

const CreateAutomatedTournament = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      // Fetch tournament data if editing
      getAutomatedTournaments(middleware).then(res => {
        if (!isMounted) return;

        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          const tournament = res.find(t => t._id === id);
          if (tournament) {
            setFormData(tournament);
          } else {
            updateStore({ type: SET_ERRORS, payload: 'Tournament not found' });
            navigate('/staff/tournaments/automation');
          }
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleAddPrizePlace = () => {
    const lastPlace =
      formData.prize_structure.length > 0
        ? formData.prize_structure[formData.prize_structure.length - 1].place
        : 0;

    setFormData(prev => ({
      ...prev,
      prize_structure: [
        ...prev.prize_structure,
        { place: lastPlace + 1, amount: 0 },
      ],
    }));
  };

  const handlePrizeChange = (index, amount) => {
    const newPrizes = [...formData.prize_structure];
    newPrizes[index].amount = parseFloat(amount) || 0;
    setFormData(prev => ({ ...prev, prize_structure: newPrizes }));
  };

  const handleRemovePrize = indexToRemove => {
    setFormData(prev => ({
      ...prev,
      prize_structure: prev.prize_structure.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleAddGameMode = () => {
    // Calculate remaining percentage
    const currentTotal = formData.game_modes.reduce(
      (sum, mode) => sum + mode.weight,
      0
    );
    const remainingPercentage = Math.max(0, 100 - currentTotal);

    setFormData(prev => ({
      ...prev,
      game_modes: [
        ...prev.game_modes,
        { type: '', weight: remainingPercentage },
      ],
    }));
  };

  const handleGameModeChange = (index, field, value) => {
    const newGameModes = [...formData.game_modes];

    if (field === 'weight') {
      // Convert to number and ensure it's between 0 and 100
      const newWeight = Math.min(100, Math.max(0, parseInt(value) || 0));

      // Calculate total weight excluding current mode
      const otherModesTotal = formData.game_modes.reduce(
        (sum, mode, i) => (i === index ? sum : sum + mode.weight),
        0
      );

      // Ensure total doesn't exceed 100%
      if (otherModesTotal + newWeight > 100) {
        updateStore({
          type: SET_ERRORS,
          payload: 'Total game mode percentages cannot exceed 100%',
        });
        return;
      }

      newGameModes[index] = {
        ...newGameModes[index],
        weight: newWeight,
      };
    } else {
      newGameModes[index] = {
        ...newGameModes[index],
        [field]: value,
      };
    }

    setFormData(prev => ({ ...prev, game_modes: newGameModes }));
  };

  const handleRemoveGameMode = indexToRemove => {
    const removedWeight = formData.game_modes[indexToRemove].weight;
    const remainingModes = formData.game_modes.filter(
      (_, index) => index !== indexToRemove
    );

    // If there are remaining modes, distribute the removed weight
    if (remainingModes.length > 0) {
      const weightPerMode = Math.floor(removedWeight / remainingModes.length);
      remainingModes.forEach(mode => {
        mode.weight += weightPerMode;
      });
      // Add any remaining weight to the first mode
      const leftover = removedWeight - weightPerMode * remainingModes.length;
      if (leftover > 0 && remainingModes.length > 0) {
        remainingModes[0].weight += leftover;
      }
    }

    setFormData(prev => ({
      ...prev,
      game_modes: remainingModes,
    }));
  };

  const handleSubmit = async () => {
    // Validate that game modes total exactly 100%
    const totalWeight = formData.game_modes.reduce(
      (sum, mode) => sum + mode.weight,
      0
    );
    if (totalWeight !== 100) {
      updateStore({
        type: SET_ERRORS,
        payload: `Game mode percentages must total 100% (currently ${totalWeight}%)`,
      });
      return;
    }

    // Validate that all game modes have a type selected
    if (formData.game_modes.some(mode => !mode.type)) {
      updateStore({
        type: SET_ERRORS,
        payload: 'All game modes must have a type selected',
      });
      return;
    }

    const res = id
      ? await updateAutomatedTournament(middleware, id, formData)
      : await createAutomatedTournament(middleware, formData);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      updateStore({
        type: SET_SUCCESSES,
        payload: `Tournament ${id ? 'updated' : 'created'} successfully`,
      });
      navigate('/staff/tournaments/automation');
    }
  };

  const styles = {
    container: {
      width: '100%',
      padding: { xs: 1, sm: 2 },
      backgroundColor: card,
      borderRadius: 1,
    },
    section: {
      backgroundColor: card,
      borderRadius: 1,
      padding: 2,
      marginBottom: 2,
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    prizeRow: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      alignItems: { xs: 'stretch', sm: 'center' },
      gap: { xs: 1, sm: 2 },
      marginBottom: 1,
      backgroundColor: cardDark,
      padding: 1,
      borderRadius: 1,
    },
    gameModeRow: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      alignItems: { xs: 'stretch', sm: 'center' },
      gap: { xs: 1, sm: 2 },
      marginBottom: 1,
      backgroundColor: cardDark,
      padding: 1,
      borderRadius: 1,
    },
    removeButton: {
      cursor: 'pointer',
      color: red,
      padding: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
    header: {
      fontSize: 24,
      color: text,
      fontWeight: 700,
      marginBottom: 2,
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 1,
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    dropdownStyle: {
      '& .MuiSelect-select': {
        width: '100%',
        textAlign: 'left',
        paddingRight: '32px !important',
      },
      '& .MuiSelect-icon': {
        position: 'absolute',
        right: 8,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>
        {id ? 'Edit' : 'Create'} Automated Tournament
      </Typography>

      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Tournament Name</Typography>
          <CustomInput
            placeholder='Enter tournament name'
            value={formData.name}
            onChange={value => setFormData(prev => ({ ...prev, name: value }))}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Day of Week</Typography>
          <CustomDropdown
            value={formData.day_of_week}
            options={DAYS_OF_WEEK}
            onChange={value =>
              setFormData(prev => ({ ...prev, day_of_week: value }))
            }
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select day'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Start Time (HH:mm)</Typography>
          <CustomInput
            type='time'
            value={formData.start_time}
            onChange={value =>
              setFormData(prev => ({ ...prev, start_time: value }))
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>
            Registration Opens (minutes before)
          </Typography>
          <CustomInput
            type='number'
            min='1'
            value={formData.registration_opens_minutes_before}
            onChange={value =>
              setFormData(prev => ({
                ...prev,
                registration_opens_minutes_before: parseInt(value) || 0,
              }))
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Game</Typography>
          <CustomDropdown
            value={formData.game}
            options={GAME_OPTIONS}
            onChange={value => {
              setFormData(prev => ({
                ...prev,
                game: value,
                game_modes: [{ type: '', weight: 100 }],
              }));
            }}
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select game'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={styles.section}>
            <Typography sx={styles.label}>Game Modes</Typography>
            {formData.game_modes.map((mode, index) => (
              <Box key={index} sx={styles.gameModeRow}>
                <Box flex={1}>
                  <Typography sx={styles.label}>Mode</Typography>
                  <CustomDropdown
                    value={mode.type}
                    options={newGameModeOptions(formData.game)}
                    onChange={value =>
                      handleGameModeChange(index, 'type', value)
                    }
                    backgroundColor={card}
                    borderColor='rgba(255, 255, 255, 0.1)'
                    placeholder='Select game mode'
                    fullWidth
                    sx={styles.dropdownStyle}
                  />
                </Box>
                <Box flex={1}>
                  <Typography sx={styles.label}>Weight (%)</Typography>
                  <CustomInput
                    type='number'
                    min='0'
                    max='100'
                    value={mode.weight}
                    onChange={value =>
                      handleGameModeChange(index, 'weight', value)
                    }
                    endAdornment='%'
                    fullWidth
                  />
                </Box>
                {formData.game_modes.length > 1 && (
                  <Box
                    sx={styles.removeButton}
                    onClick={() => handleRemoveGameMode(index)}
                  >
                    <IoClose size={18} />
                  </Box>
                )}
              </Box>
            ))}
            <PrimaryButton
              label='Add Game Mode'
              onClick={handleAddGameMode}
              disabled={
                formData.game_modes.reduce(
                  (sum, mode) => sum + mode.weight,
                  0
                ) >= 100
              }
              variant='outlined'
              sx={{ mt: 1 }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Max Players</Typography>
          <CustomDropdown
            value={formData.max_players}
            options={MAX_PLAYERS_OPTIONS}
            onChange={value =>
              setFormData(prev => ({ ...prev, max_players: value }))
            }
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select max players'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Team Size</Typography>
          <CustomDropdown
            value={formData.team_size}
            options={TEAM_SIZE_OPTIONS}
            onChange={value =>
              setFormData(prev => ({ ...prev, team_size: value }))
            }
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select team size'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>First To</Typography>
          <CustomInput
            type='number'
            min='1'
            value={formData.first_to}
            onChange={value =>
              setFormData(prev => ({ ...prev, first_to: parseInt(value) || 1 }))
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Entry Fee</Typography>
          <CustomInput
            type='number'
            min='0'
            step='0.01'
            value={formData.entry_fee}
            onChange={value =>
              setFormData(prev => ({
                ...prev,
                entry_fee: parseFloat(value) || 0,
              }))
            }
            startAdornment='$'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Region</Typography>
          <CustomDropdown
            value={formData.region}
            options={REGION_OPTIONS}
            onChange={value =>
              setFormData(prev => ({ ...prev, region: value }))
            }
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select region'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Platform</Typography>
          <CustomDropdown
            value={formData.platform}
            options={PLATFORM_OPTIONS}
            onChange={value =>
              setFormData(prev => ({ ...prev, platform: value }))
            }
            backgroundColor={card}
            borderColor='rgba(255, 255, 255, 0.1)'
            placeholder='Select platform'
            fullWidth
            sx={styles.dropdownStyle}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={styles.section}>
            <Typography sx={styles.label}>Prize Structure</Typography>
            {formData.prize_structure.map((prize, index) => (
              <Box key={index} sx={styles.prizeRow}>
                <Typography sx={{ color: text, minWidth: 80 }}>
                  {prize.place}
                  {getOrdinalSuffix(prize.place)} Place:
                </Typography>
                <Box flex={1}>
                  <Typography sx={styles.label}>Amount</Typography>
                  <CustomInput
                    type='number'
                    value={prize.amount}
                    onChange={e => handlePrizeChange(index, e.target.value)}
                    startAdornment='$'
                    fullWidth
                  />
                </Box>
                <Box
                  sx={styles.removeButton}
                  onClick={() => handleRemovePrize(index)}
                >
                  <IoClose size={18} />
                </Box>
              </Box>
            ))}
            <PrimaryButton
              label='Add Prize Place'
              onClick={handleAddPrizePlace}
              variant='outlined'
              sx={{ mt: 1 }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={styles.checkboxContainer}>
            <CustomCheckbox
              checked={formData.is_locked}
              onChange={value =>
                setFormData(prev => ({ ...prev, is_locked: value }))
              }
            />
            <Typography sx={styles.label}>Lock Tournament</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={styles.checkboxContainer}>
            <CustomCheckbox
              checked={formData.is_active}
              onChange={value =>
                setFormData(prev => ({ ...prev, is_active: value }))
              }
            />
            <Typography sx={styles.label}>Enabled</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mt: { xs: 2, sm: 3 } }}>
            <PrimaryButton
              label={id ? 'Update Tournament' : 'Create Tournament'}
              onClick={handleSubmit}
              color={doubtedBlue}
            />
            <PrimaryButton
              label='Cancel'
              onClick={() => navigate('/staff/tournaments/automation')}
              variant='outlined'
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const getOrdinalSuffix = num => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) return 'st';
  if (j === 2 && k !== 12) return 'nd';
  if (j === 3 && k !== 13) return 'rd';
  return 'th';
};

export default CreateAutomatedTournament;
