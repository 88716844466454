import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import {
  black,
  cardDark,
  cardLight,
  cardVeryLight,
  container,
  green,
  secondaryButton,
} from '../../utils/themeContstants';

const CustomCheckbox = ({
  checked,
  onChange,
  size = 30,
  disabled,
  color,
  checkColor = black,
}) => {
  const [value, setValue] = useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleChange = () => {
    if (disabled) {
      return;
    }
    onChange(!value);
    setValue(!value);
  };

  const getBackgroundColor = () => {
    if (disabled) {
      return secondaryButton;
    }

    if (color) {
      return value ? color : cardDark;
    }

    return value ? green : cardDark;
  };

  const getBackgroundColorOnHover = () => {
    if (disabled) {
      return secondaryButton;
    }

    if (color) {
      return value ? color : container;
    }

    return value ? green : container;
  };

  const styles = {
    container: {
      height: size,
      width: size,
      borderRadius: 2,
      backgroundColor: getBackgroundColor(),
      border: `2px solid ${disabled ? cardVeryLight : cardLight}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: getBackgroundColorOnHover(),
      },
    },
  };

  return (
    <Grid item sx={styles.container} onClick={handleChange}>
      {checked && (
        <BsCheck style={{ color: checkColor, fontSize: size - 10 }} />
      )}
    </Grid>
  );
};

export default CustomCheckbox;
