import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { RiTeamLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { createMatch } from '../../api/matches';
import useMiddleware from '../../api/useMiddleware';
import { getRecentMatches, getUserTeamsDetailed } from '../../api/user';
import { SET_ERRORS, StoreDispatch } from '../../store/Store';
import {
  getFee,
  getGameModeString,
  getGameModeStringFromArray,
  numFormatter,
} from '../../utils/helpers';
import {
  checkLowestGameMode,
  getTeamOptions,
  getTeamSizeOptions,
  newTeamSizeOptions,
} from '../../utils/options';
import {
  doubtedBlue,
  doubtedOrange,
  emptyIcon,
  large,
  normal,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CoinBadge from '../custom/CoinBadge';
import CurrencyInput from '../custom/CurrencyInput';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import Empty from '../custom/Empty';
import SecondaryButton from '../custom/SecondaryButton';
import StepperDialog from '../custom/StepperDialog';
import StepperItem from '../custom/StepperItem';
import TeamProfileModal from '../teams/TeamProfileModal';
import ChessCreate from './create/ChessCreate';
import ClashCreate from './create/ClashCreate';
import FifaCreate from './create/FifaCreate';
import FortniteCreate from './create/FortniteCreate';
import WarzoneCreate from './create/WarzoneCreate';

const CreateMatch = ({ open, onClose, game }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [filteredTeams, setFilteredTeams] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [showError, setShowError] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [presetsLoading, setPresetsLoading] = useState(false);
  const [presetsError, setPresetsError] = useState(false);
  const [dynamicPresets, setDynamicPresets] = useState(null);

  // stepper
  const [currentState, setCurrentState] = useState(0);

  // create state
  const [region, setRegion] = useState(null);
  const [gameMode, setGameMode] = useState(null);
  const [teamSize, setTeamSize] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [entryFee, setEntryFee] = useState('');
  const [puttingUp, setPuttingUp] = useState(false);
  const [publicMatch, setPublic] = useState(false);
  const [team, setTeam] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [bestOf, setBestOf] = useState(null);
  const [firstTo, setFirstTo] = useState(null);
  const [map, setMap] = useState(null);
  const [loot, setLoot] = useState(null);
  const [health, setHealth] = useState(null);
  const [killLead, setKillLead] = useState(null);
  const [mustStream, setMustStream] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState(null);

  // Default presets for fallback
  const defaultPresets = {
    casual: {
      name: '1v1 Realistic',
      platform: null,
      gameMode: ['real'],
      region: ['eu'],
      loot: 'default',
      map: 'finest',
      health: null,
      firstTo: 5,
      teamSize: 1,
    },
    competitive: {
      name: '1v1 BoxFights',
      platform: null,
      gameMode: ['bf'],
      region: ['eu'],
      loot: 'default',
      firstTo: 5,
      teamSize: 1,
    },
    custom: {
      name: '1v1 ZoneWars',
      platform: null,
      gameMode: ['zw'],
      region: ['eu'],
      loot: 'default',
      firstTo: 5,
      teamSize: 1,
    },
  };

  // The actual presets to use - either dynamic or default
  const presets = dynamicPresets || defaultPresets;

  // Function to fetch last 3 matches and use them as presets
  const fetchLastMatches = async () => {
    try {
      setPresetsLoading(true);
      setPresetsError(false);

      // Use the imported API function instead of direct fetch
      const data = await getRecentMatches(middleware, game, 3);

      if (data && data.matches && data.matches.length > 0) {
        // Transform matches into preset format
        const newPresets = {};

        data.matches.forEach((match, index) => {
          // Use preset keys based on index
          const presetKey =
            index === 0 ? 'casual' : index === 1 ? 'competitive' : 'custom';

          // Properly format region data
          // let regionData = ['eu']; // Default to EU
          // if (match.region) {
          //   if (Array.isArray(match.region) && match.region.length > 0) {
          //     // Ensure all elements are strings
          //     regionData = match.region.map(r => (r ? r.toString() : 'eu'));
          //   } else if (typeof match.region === 'string') {
          //     regionData = [match.region];
          //   } else if (
          //     typeof match.region === 'object' &&
          //     match.region.selected_region
          //   ) {
          //     // Handle case where region might be an object with selected_region
          //     regionData = [match.region.selected_region.toString()];
          //   }
          // }

          newPresets[presetKey] = {
            name: `${match.team_size}v${match.team_size} ${getGameModeStringFromArray(match.game_mode_options?.options) || getGameModeString(match.game_match_type)}`,
            isPrivate: match.is_private,
            platform: match.platform,
            gameMode:
              match.game_mode_options?.options.length > 0
                ? match.game_mode_options.options
                : [match.game_match_type],
            region: match.region,
            loot: match.extra_options?.loot_type || 'default',
            map: match.map,
            health: match.extra_options?.health,
            killLead: match.extra_options?.kill_lead,
            bestOf: match.best_of,
            firstTo: match.first_to,
            teamSize: match.team_size || 1,
            // Store match ID for reference
            matchId: match._id,
          };
        });

        // Only update if we have at least one match
        if (Object.keys(newPresets).length > 0) {
          setDynamicPresets(newPresets);
        } else {
          setPresetsError(true);
        }
      } else {
        setPresetsError(true);
      }
    } catch (error) {
      console.error('Error fetching recent matches:', error);
      setPresetsError(true);
      // Still fall back to default presets
    } finally {
      setPresetsLoading(false);
    }
  };

  // Function to apply preset
  const applyPreset = presetKey => {
    setSelectedPreset(presetKey);
    const preset = presets[presetKey];

    if (preset.platform) {
      setPlatform(preset.platform);
    }

    if (preset.gameMode) {
      setGameMode(preset.gameMode);
    }

    if (preset.region) {
      setRegion(preset.region);
    }

    if (preset.loot) {
      setLoot(preset.loot);
    }

    if (preset.health) {
      setHealth(preset.health);
    }

    if (preset.firstTo) {
      setFirstTo(preset.firstTo || null);
    }

    if (preset.bestOf) {
      setBestOf(preset.bestOf);
    }

    if (preset.map) {
      setMap(preset.map);
    }

    if (preset.killLead) {
      setKillLead(preset.killLead);
    }

    // Set team size if defined in preset
    if (preset.teamSize) {
      setTeamSize(preset.teamSize);
    }
  };

  const getStateTitle = () => {
    if (currentState === 0) return 'General';

    if (currentState === 1) return 'Teams';

    if (currentState === 2) return 'Entry Fee';
  };

  const getStateMeta = () => {
    if (currentState === 0) return 'Basic information about your match.';

    if (currentState === 1)
      return 'Choose the team size and your desired team.';

    if (currentState === 2) return 'Set the entry fee for the match.';
  };

  const handleClose = () => {
    setSelected(null);
    setCurrentState(0);
    setTeams(null);
    setFilteredTeams(null);
    setRegion(null);
    setGameMode(null);
    setTeamSize(null);
    setSelectedTeam(null);
    setEntryFee('');
    setPuttingUp(false);
    setPublic(false);
    setShowError(false);
    setCreateLoading(false);
    setTeamOptions(null);
    setTeam(null);
    setViewOpen(false);
    setPlatform(null);
    setBestOf(null);
    setFirstTo(null);
    setMap(null);
    setLoot(null);
    setHealth(null);
    setKillLead(null);
    setSelectedPreset(null);
    setDynamicPresets(null);
    setPresetsLoading(false);
    setPresetsError(false);
    onClose();
  };

  const isButtonDisabled = () => {
    // if (gameMode === "zbm") {
    //   return (
    //     !region ||
    //     !bestOf ||
    //     !teamSize ||
    //     !selectedTeam ||
    //     !gameMode ||
    //     entryFee === "" ||
    //     entryFee == null ||
    //     parseFloat(entryFee) <= 0
    //   );
    // }

    // return (
    //   !region ||
    //   !teamSize ||
    //   !selectedTeam ||
    //   !gameMode ||
    //   !firstTo ||
    //   entryFee === "" ||
    //   entryFee == null ||
    //   parseFloat(entryFee) <= 0
    // );
    if (currentState === 1) {
      return !teamSize || !selectedTeam;
    }

    if (currentState === 2) {
      return entryFee === '' || entryFee == null || parseFloat(entryFee) < 0.5;
    }

    if (game === 'clashroyale' || game === 'chess') {
      if (currentState === 0) {
        return !gameMode || !bestOf;
      }
    }

    if (game === 'fifa') {
      if (currentState === 0) {
        return (
          !gameMode || !region || region?.length < 1 || !platform || !bestOf
        );
      }
    }

    if (game === 'warzone') {
      if (currentState === 0) {
        return !gameMode || !region || region?.length < 1 || !bestOf;
      }
    }

    if (game === 'fortnite') {
      if (currentState === 0) {
        // if (gameMode === "real") {
        //   return !gameMode || !map || !region || (!firstTo && !bestOf);
        // }

        if (gameMode === 'build' || gameMode?.includes('build')) {
          return (
            !gameMode ||
            !loot ||
            !health ||
            !region ||
            region?.length < 1 ||
            (!firstTo && !bestOf)
          );
        }
        if (gameMode?.includes('esl_hp') || gameMode?.includes('esl_ctf')) {
          return !gameMode || !region || region?.length < 1;
        }

        return (
          !gameMode || !region || region?.length < 1 || (!firstTo && !bestOf)
        );
      }
    }
  };

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        setTeams(res?.teams);
      }
    });
  };

  const handleCreateMatch = () => {
    if (currentState !== 2) {
      setCurrentState(currentState + 1);
      return;
    }
    setCreateLoading(true);
    createMatch(
      middleware,
      parseFloat(entryFee),
      region,
      null,
      selectedTeam,
      firstTo ?? 5,
      !publicMatch,
      'token',
      puttingUp ? true : null,
      teamSize,
      platform,
      bestOf,
      game,
      map,
      !loot && !health && !killLead && !mustStream
        ? null
        : {
            loot_type: loot,
            health,
            kill_lead: killLead,
            must_stream: mustStream,
          },
      gameMode
    ).then(res => {
      setCreateLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        handleClose();
        navigate(`/token/${res?.match?._id}`);
      }
    });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      if (teams == null) {
        handleGetTeams();
      }

      // Fetch recent matches for presets when dialog opens
      fetchLastMatches();
    }
  }, [open, game]);

  useEffect(() => {
    const newFilteredTeams = teams?.filter(
      team => team?.users?.length === teamSize
    );
    setFilteredTeams(newFilteredTeams);
    setPuttingUp(false);
    setSelectedTeam(null);
    setTeamOptions(null);
    setTeam(null);
    setViewOpen(false);
  }, [teamSize]);

  useEffect(() => {
    if (filteredTeams && filteredTeams?.length > 0) {
      const newTeamOptions = getTeamOptions(filteredTeams);
      setTeamOptions(newTeamOptions);
    }
  }, [filteredTeams]);

  useEffect(() => {
    if (entryFee && parseFloat(entryFee) < 0.5) {
      return setShowError(true);
    }

    return setShowError(false);
  }, [entryFee]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: large,
      color: text,
      fontWeight: 700,
    },
    meta: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: '#d7e4f1',
    },
    checkLabel: {
      fontSize: 14,
      color: '#d7e4f1',
      fontWeight: 600,
      maxWidth: 400,
    },
    error: {
      fontSize: small,
      fontWeight: 500,
      color: doubtedOrange,
    },
  };

  return (
    <>
      <TeamProfileModal
        viewOnly={true}
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        team={team}
      />

      <StepperDialog
        title='Create Match'
        open={open}
        onClose={handleClose}
        game={game}
        loading={loading || presetsLoading}
        minWidth={600}
        stepperItems={[
          <StepperItem
            title='General'
            finished={currentState > 0}
            current={currentState === 0}
            isLast={false}
          />,
          <StepperItem
            title='Teams'
            finished={currentState > 1}
            current={currentState === 1}
            isLast={false}
          />,
          <StepperItem
            title='Entry Fee'
            finished={currentState > 2}
            current={currentState === 2}
            isLast={true}
          />,
        ]}
        primaryButton={
          <SecondaryButton
            label={currentState === 2 ? 'Create Match' : 'Next'}
            bg={doubtedBlue}
            loading={createLoading}
            onClick={handleCreateMatch}
            disabled={isButtonDisabled()}
          />
        }
        secondaryButton={
          currentState > 0 ? (
            <SecondaryButton
              label='Back'
              onClick={() => {
                if (currentState === 1) {
                  setTeamSize(null);
                  setSelectedTeam(null);
                  setTeam(null);
                  setGameMode(null);
                  setRegion(null);
                  setFirstTo(null);
                  setBestOf(null);
                  setPlatform(null);
                  setMap(null);
                  setLoot(null);
                  setHealth(null);
                }

                if (currentState === 2) {
                  setPuttingUp(null);
                  setEntryFee('');
                  setTeamSize(null);
                  setSelectedTeam(null);
                  setTeam(null);
                }

                setCurrentState(currentState - 1);
              }}
            />
          ) : null
        }
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 3 }}
          >
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>{getStateTitle()}</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.meta}>{getStateMeta()}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {currentState === 0 && (
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.subLabel}>
                        Quick Presets {presetsLoading && '(Loading...)'}
                        {presetsError && ' (Using defaults)'}
                      </Typography>
                    </Grid>
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='stretch'
                        wrap='nowrap'
                        spacing={1}
                        sx={{ width: '100%' }}
                      >
                        {Object.keys(presets).map(key => (
                          <Grid
                            item
                            key={key}
                            sx={{ flex: '1 1 0', minWidth: 0 }}
                          >
                            <div
                              onClick={() => applyPreset(key)}
                              style={{
                                padding: '15px',
                                background:
                                  selectedPreset === key
                                    ? doubtedBlue
                                    : '#1e293b',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                border:
                                  selectedPreset === key
                                    ? `1px solid ${doubtedBlue}`
                                    : '1px solid #2c3b52',
                                transition: 'all 0.2s ease',
                                opacity: presetsLoading ? 0.7 : 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 600,
                                  color: '#fff',
                                  mb: 1,
                                }}
                              >
                                {presets[key].name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: small,
                                  color: secondaryText,
                                }}
                              >
                                {presets[key].firstTo &&
                                  `First To ${presets[key].firstTo} • `}
                                {presets[key].bestOf &&
                                  `Best Of${presets[key].bestOf} • `}
                                {presets[key].killLead &&
                                  `Round Lead${presets[key].killLead} • `}

                                {presets[key].region && (
                                  <>
                                    {Array.isArray(presets[key].region) &&
                                    presets[key].region.length > 0
                                      ? typeof presets[key].region[0] ===
                                        'string'
                                        ? presets[key].region[0].toUpperCase()
                                        : 'EU'
                                      : typeof presets[key].region === 'string'
                                        ? presets[key].region.toUpperCase()
                                        : 'EU'}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <CustomCheckbox
                        checked={publicMatch}
                        onChange={value => setPublic(value)}
                        color={doubtedBlue}
                        checkColor={'#fff'}
                      />
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.checkLabel}>
                        Private Match (You are inviting your opponents)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {game === 'clashroyale' && (
                  <ClashCreate
                    game={game}
                    setGameMode={setGameMode}
                    gameMode={gameMode}
                    setBestOf={setBestOf}
                    bestOf={bestOf}
                  />
                )}

                {game === 'fifa' && (
                  <FifaCreate
                    game={game}
                    setGameMode={setGameMode}
                    gameMode={gameMode}
                    setRegion={setRegion}
                    region={region}
                    platform={platform}
                    setPlatform={setPlatform}
                    setBestOf={setBestOf}
                    bestOf={bestOf}
                  />
                )}

                {game === 'chess' && (
                  <ChessCreate
                    game={game}
                    setGameMode={setGameMode}
                    gameMode={gameMode}
                    setBestOf={setBestOf}
                    bestOf={bestOf}
                  />
                )}

                {game === 'warzone' && (
                  <WarzoneCreate
                    game={game}
                    setGameMode={setGameMode}
                    gameMode={gameMode}
                    platform={platform}
                    setPlatform={setPlatform}
                    setRegion={setRegion}
                    region={region}
                    setBestOf={setBestOf}
                    bestOf={bestOf}
                    mustStream={mustStream}
                    setMustStream={setMustStream}
                  />
                )}

                {game === 'fortnite' && (
                  <FortniteCreate
                    game={game}
                    setGameMode={setGameMode}
                    gameMode={gameMode}
                    setBestOf={setBestOf}
                    bestOf={bestOf}
                    setRegion={setRegion}
                    region={region}
                    platform={platform}
                    setPlatform={setPlatform}
                    setMap={setMap}
                    map={map}
                    setLoot={setLoot}
                    loot={loot}
                    setHealth={setHealth}
                    health={health}
                    setFirstTo={setFirstTo}
                    firstTo={firstTo}
                    setKillLead={setKillLead}
                    killLead={killLead}
                  />
                )}
              </>
            )}

            {currentState === 1 && (
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.subLabel}>Team size</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <CustomDropdown
                        options={
                          game === 'fortnite'
                            ? getTeamSizeOptions(checkLowestGameMode(gameMode))
                            : newTeamSizeOptions(game, gameMode[0])
                        }
                        value={teamSize}
                        placeholder='Team size'
                        onChange={value => setTeamSize(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {!teamSize && (
                  <Empty
                    title='No team size selected'
                    label='Try selecting a team size first.'
                    icon={<RiTeamLine style={emptyIcon} />}
                  />
                )}

                {teamSize && (
                  <>
                    {filteredTeams?.length < 1 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Empty
                            title={`You have no ${teamSize}v${teamSize} teams`}
                            label='Try creating a team.'
                            icon={<RiTeamLine style={emptyIcon} />}
                          />

                          <Grid item>
                            <SecondaryButton
                              label='Create Team'
                              fullWidth
                              onClick={() =>
                                navigate('/profile/teams', {
                                  state: {
                                    create: true,
                                  },
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {filteredTeams?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography sx={styles.subLabel}>
                              Select your team
                            </Typography>
                          </Grid>

                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent='start'
                              alignItems='center'
                              gap={{ xs: 1 }}
                            >
                              <Grid item sx={{ flexGrow: 1 }}>
                                <CustomDropdown
                                  options={teamOptions}
                                  placeholder='Teams'
                                  onChange={value => {
                                    setSelectedTeam(value?._id);
                                    setTeam(value);
                                  }}
                                />
                              </Grid>

                              {team && (
                                <Grid item>
                                  <SecondaryButton
                                    label='View Team'
                                    onClick={() => setViewOpen(true)}
                                    fullWidth
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}

            {currentState === 2 && (
              <>
                {teamSize > 1 && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <CustomCheckbox
                          checked={puttingUp}
                          onChange={value => setPuttingUp(value)}
                          color={doubtedBlue}
                          checkColor={'#fff'}
                        />
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.checkLabel}>
                          Cover Entry Fee for Teammates
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.subLabel}>
                        Entry fee per player
                      </Typography>
                    </Grid>

                    {/* <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <RadioListItem
                          title="0.50 Coins"
                          value={0.5}
                          selected={selected === 0.5}
                          onClick={() => setSelected(0.5)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="1 Coin"
                          value={1}
                          selected={selected === 1}
                          onClick={() => setSelected(1)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="3 Coins"
                          value={3}
                          selected={selected === 3}
                          onClick={() => setSelected(3)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="5 Coins"
                          value={5}
                          selected={selected === 5}
                          onClick={() => setSelected(5)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                      </Grid>
                    </Grid> */}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='start'
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={{ flexGrow: 1 }}>
                          <CurrencyInput
                            value={entryFee}
                            onChange={value => setEntryFee(value)}
                            autoFocus={false}
                          />
                        </Grid>

                        <CoinBadge
                          type='prize'
                          insideLabel='Prize'
                          label='The amount a player will receive from this match after winning.'
                          amount={
                            entryFee > 0
                              ? numFormatter().format(
                                  parseFloat(entryFee) * getFee(game) +
                                    parseFloat(entryFee)
                                )
                              : '0.00'
                          }
                          size='small'
                        />
                      </Grid>
                    </Grid>

                    {showError && (
                      <Grid item>
                        <Typography sx={styles.error}>
                          Minimum entry fee is 0.50 Coins
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </StepperDialog>
    </>
  );
};

export default CreateMatch;
