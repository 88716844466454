import { Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import {
  getGameModeString,
  getRegionString,
  getTeamSizeString,
} from '../../utils/helpers';
import {
  cardDark,
  cardVeryLight,
  container,
  doubtedBlue,
  menuShadow,
  purple,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import useOutsideClick from '../../utils/useOutsideClick';
import CustomPortal from './CustomPortal';

// const styles
const DropDownContainer = styled.div`
  font-family: 'Inter';
  min-width: 100%;
  border: none;
  position: relative;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
`;
const DropDownHeaderContainer = styled.button`
  font-family: 'Inter';
  min-height: 40px;
  min-width: 100%;
  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
  padding: 8px 12px 8px 12px;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 2px solid ${props => props.border};
`;
const DropDownList = styled.div`
  font-family: 'Inter';
  border: none;
  border-radius: 6px;
  box-shadow: ${props => props.shadow};
  background-color: ${props => props.background};
  overflow-y: auto;
  max-height: 300px;
  min-width: ${props => props.containerWidth}px;
  position: absolute;
  z-index: 1000000;
  margin-top: 2px;
`;
const DropDownListItem = styled.button`
  display: block;
  width: 100%;
  font-family: 'Inter';
  text-align: left;
  background-color: ${props => (props?.selected ? doubtedBlue : 'transparent')};
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  height: 35px;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.background};
  }
`;

const CustomDropdown = props => {
  const {
    options,
    placeholder,
    onChange,
    select = false,
    selected = null,
    removeSelected = () => {},
    clearSelected = () => {},
    backgroundColor = cardDark,
    borderColor = cardVeryLight,
    backgroundHover = container,
    value = placeholder,
    border,
    selectType = null,
    disabled = false,
  } = props;
  const wrapperRef = useRef(null);

  const [listOpen, setListOpen] = useState(false);
  const [title, setTitle] = useState(value);
  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);

  // Detect if this is a team size dropdown based on options format
  const isTeamSizeDropdown = options?.some(
    option =>
      // Check for standard team size option format
      (option.title === '1v1' && option.value === 1) ||
      (option.title === '2v2' && option.value === 2) ||
      // Also check for placeholder containing "Team Size" or "team size"
      (placeholder && placeholder.toLowerCase().includes('team size'))
  );

  // Detect if this is a round lead dropdown
  const isRoundLeadDropdown = options?.some(
    option =>
      // Check if this is using killLeadOptions or killLeadOptionsFilter format
      (option.title === 'Lead Offered' && option.value === true) ||
      // Also check for placeholder containing "Round Lead" or "round lead"
      (placeholder && placeholder.toLowerCase().includes('round lead'))
  );

  // Automatically use team_size type if detected from options
  const effectiveSelectType =
    selectType ||
    (isTeamSizeDropdown
      ? 'team_size'
      : isRoundLeadDropdown
        ? 'round_lead'
        : null);

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: 'bottom-start',
  });

  const toggleOpen = () => {
    if (!disabled) {
      setListOpen(!listOpen);
    }
  };

  useOutsideClick(wrapperRef, () => {
    if (listOpen) {
      setListOpen(false);
    }
  });

  useEffect(() => {
    // Only apply team size formatting for actual numeric team sizes (1-4)
    if (
      effectiveSelectType === 'team_size' &&
      value &&
      typeof value === 'number' &&
      [1, 2, 3, 4].includes(value)
    ) {
      setTitle(getTeamSizeString(value));
    } else if (
      // Handle round lead null values
      effectiveSelectType === 'round_lead' &&
      (value === null || value === undefined)
    ) {
      setTitle('None');
    } else {
      // Don't override with placeholder for invalid values
      if (value !== 'Team Size') {
        setTitle(value);
      } else {
        // For the literal 'Team Size' string, just keep the placeholder behavior
        setTitle(placeholder);
      }
    }
  }, [value, effectiveSelectType, placeholder]);

  const localStyles = {
    icon: {
      color: secondaryText,
      fontSize: 16,
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
      whiteSpace: 'nowrap',
    },
    placeholder: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
      whiteSpace: 'nowrap',
    },
    options: {
      fontSize: 14,
      fontWeight: 500,
      color: text,
    },
    selectedOptions: {
      fontSize: 14,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <div ref={wrapperRef}>
      <DropDownContainer shadow={menuShadow} disabled={disabled}>
        <DropDownHeaderContainer
          ref={setRefEl}
          type='button'
          onClick={disabled ? null : toggleOpen}
          color={cardVeryLight}
          backgroundColor={hovered ? backgroundHover : backgroundColor}
          blue={purple}
          onMouseEnter={() => !disabled && setHovered(true)}
          onMouseLeave={() => !disabled && setHovered(false)}
          border={border ? border : hovered ? doubtedBlue : cardVeryLight}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.7 : 1,
          }}
        >
          <Grid item sx={{ width: '100%' }}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
              wrap='nowrap'
            >
              {select ? (
                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                    wrap='nowrap'
                  >
                    <Grid item>
                      <Typography
                        sx={
                          selected?.length > 0
                            ? localStyles.title
                            : localStyles.placeholder
                        }
                      >
                        {selected?.length > 0 ? (
                          <>
                            {title}:{' '}
                            {selected?.map((option, i) => (
                              <span style={{ fontWeight: 700, color: text }}>
                                {effectiveSelectType === 'region' &&
                                  getRegionString(option)}

                                {effectiveSelectType === 'gameMode' &&
                                  getGameModeString(option)}

                                {effectiveSelectType === 'team_size' &&
                                  typeof option === 'number' &&
                                  [1, 2, 3, 4].includes(option) &&
                                  getTeamSizeString(option)}

                                {effectiveSelectType === 'round_lead' &&
                                  (option === null || option === undefined) &&
                                  'None'}

                                {!(
                                  (effectiveSelectType === 'region' &&
                                    option) ||
                                  (effectiveSelectType === 'gameMode' &&
                                    option) ||
                                  (effectiveSelectType === 'team_size' &&
                                    typeof option === 'number' &&
                                    [1, 2, 3, 4].includes(option)) ||
                                  (effectiveSelectType === 'round_lead' &&
                                    (option === null || option === undefined))
                                ) &&
                                  option !== 'Team Size' &&
                                  option}

                                {i === selected?.length - 1 ? null : (
                                  <>{', '}</>
                                )}
                              </span>
                            ))}
                          </>
                        ) : (
                          <>
                            {title}:{' '}
                            <span style={{ fontWeight: 700 }}>None</span>
                          </>
                        )}
                      </Typography>
                    </Grid>

                    {selected?.length > 0 && (
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BsCheck style={{ fontSize: 22, color: doubtedBlue }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                    wrap='nowrap'
                  >
                    <Grid item>
                      <Typography
                        sx={
                          title !== placeholder
                            ? localStyles.title
                            : localStyles.placeholder
                        }
                      >
                        {placeholder}:{' '}
                        <span
                          style={{
                            color: title === placeholder ? secondaryText : text,
                            fontWeight: 700,
                          }}
                        >
                          {title === placeholder ||
                          title === 'Team Size' ||
                          (effectiveSelectType === 'round_lead' &&
                            (title === null || title === undefined))
                            ? 'None'
                            : effectiveSelectType === 'region'
                              ? getRegionString(title)
                              : effectiveSelectType === 'gameMode'
                                ? getGameModeString(title)
                                : effectiveSelectType === 'team_size' &&
                                    typeof title === 'number' &&
                                    [1, 2, 3, 4].includes(title)
                                  ? getTeamSizeString(title)
                                  : title}
                        </span>
                      </Typography>
                    </Grid>

                    {title !== placeholder && (
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BsCheck style={{ fontSize: 22, color: doubtedBlue }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                }}
              >
                {listOpen ? (
                  <FaAngleUp style={localStyles.icon} />
                ) : (
                  <FaAngleDown style={localStyles.icon} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </DropDownHeaderContainer>
        {listOpen && (
          <>
            <CustomPortal>
              <DropDownList
                shadow={'0 2px 8px rgb(0 0 0 / 15%)'}
                background={cardDark}
                containerWidth={wrapperRef.current.offsetWidth}
                ref={setPopperEl}
                style={styles.popper}
                {...attributes.popper}
              >
                {options?.map((option, i) => (
                  <DropDownListItem
                    type='button'
                    onClick={() => {
                      if (select) {
                        if (option.title === 'None') {
                          clearSelected();
                          return;
                        } else {
                          if (selected?.includes(option?.value)) {
                            // remove option
                            return removeSelected(option?.value);
                          } else {
                            onChange(option.value);
                          }
                          return;
                        }
                      }

                      if (option.title === 'None') {
                        setTitle(placeholder);
                        onChange(null);
                        setListOpen(false);
                        return;
                      } else {
                        setTitle(option.title);
                        onChange(option.value);
                        setListOpen(false);
                      }
                    }}
                    key={i}
                    selected={option.title === title}
                    background={doubtedBlue}
                  >
                    {selected?.length > 0 ? (
                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                          wrap='nowrap'
                        >
                          <Grid item>
                            <span
                              style={
                                selected?.includes(option?.value)
                                  ? localStyles.selectedOptions
                                  : localStyles.options
                              }
                            >
                              {option?.title}
                            </span>
                          </Grid>

                          {selected?.includes(option?.value) && (
                            <Grid
                              item
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <BsCheck
                                style={{ color: doubtedBlue, fontSize: 22 }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <span
                        style={
                          option?.title === title
                            ? localStyles.selectedOptions
                            : localStyles.options
                        }
                      >
                        {option?.title}
                      </span>
                    )}
                  </DropDownListItem>
                ))}
              </DropDownList>
            </CustomPortal>
          </>
        )}
      </DropDownContainer>
    </div>
  );
};

export default CustomDropdown;
