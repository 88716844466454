import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { MatchStateEnum } from '../../utils/enums';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  text,
} from '../../utils/themeContstants';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';
import MatchTimer from './MatchTimer';
import SubmitResultsModal from './SubmitResultsModal';

const SubmitNoticeModal = ({
  open,
  onClose,
  match,
  hasSeenSubmit,
  setHasSeenSubmit,
}) => {
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const [submitOpen, setSubmitOpen] = useState(false);

  const handleOpenSubmitResults = () => {
    setSubmitOpen(true);
    onClose();
  };

  useEffect(() => {
    if (match?.state === MatchStateEnum.SUBMIT) {
      if (open && !hasSeenSubmit) {
        setHasSeenSubmit(true);
      }
    } else {
      onClose();
    }
  }, [open]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: isDesktop ? 450 : '100%',
    },
  };

  return (
    <>
      <SubmitResultsModal
        open={submitOpen}
        onClose={() => setSubmitOpen(false)}
        token={match}
      />

      <CustomModal
        open={open}
        onClose={onClose}
        title='Submit Match Results'
        titleSize='large'
        description='Your opponent has submitted a win.'
        primaryButton={
          <SecondaryButton
            label='Submit Results'
            fullWidth
            bg={doubtedBlue}
            onClick={handleOpenSubmitResults}
          />
        }
        primaryButtonFullWidth
      >
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
          </Grid>

          <Grid item sx={styles.width}>
            <Typography sx={styles.label}>
              Your opponent has submitted a win. Submit your results of the
              match before the timer runs out.
            </Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <MatchTimer match={match} />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default SubmitNoticeModal;
