import React, { Suspense } from 'react';

const mouthComponents = {
  lipstick: React.lazy(() => import('./types/RedLipstick')),
  flat_mouth: React.lazy(() => import('./types/Slant')),
  frown: React.lazy(() => import('./types/Frown')),
  frown_2: React.lazy(() => import('./types/FrownTwo')),
  ahhh: React.lazy(() => import('./types/OHHH')),
  blunt: React.lazy(() => import('./types/Blunt')),
  cig: React.lazy(() => import('./types/Cig')),
  default: React.lazy(() => import('./types/Default')),
  fury: React.lazy(() => import('./types/Fury')),
  growl: React.lazy(() => import('./types/Growl')),
  joint: React.lazy(() => import('./types/Joint')),
  joy: React.lazy(() => import('./types/Joy')),
  lipstick_2: React.lazy(() => import('./types/LipstickTwo')),
  lipstick_3: React.lazy(() => import('./types/LipstickThree')),
  meh: React.lazy(() => import('./types/Meh')),
  nauseous: React.lazy(() => import('./types/Nauseous')),
  scared: React.lazy(() => import('./types/Scared')),
  smirk: React.lazy(() => import('./types/Smirk')),
  swearing: React.lazy(() => import('./types/Swearing')),
  whine: React.lazy(() => import('./types/Whine')),
  lips: React.lazy(() => import('./types/Lips')),
  yawn: React.lazy(() => import('./types/Yawn')),
  yum: React.lazy(() => import('./types/Yum')),
  rawr: React.lazy(() => import('./types/Rawr')),
  wrap_it_up: React.lazy(() => import('./types/wrap_it_up/WrapItUp')),
  wrap_it_up_2: React.lazy(() => import('./types/wrap_it_up/WrapItUpTwo')),
  wrap_it_up_3: React.lazy(() => import('./types/wrap_it_up/WrapItUpThree')),
  kunai: React.lazy(() => import('./types/Kunai')),
  ghost: React.lazy(() => import('./types/Ghost')),
  blowin_bubs: React.lazy(() => import('./types/BlowinBubs')),
  fatty: React.lazy(() => import('./types/Fatty')),
  grillz: React.lazy(() => import('./types/Grillz')),
  tooth_pick: React.lazy(() => import('./types/ToothPick')),
  pipe: React.lazy(() => import('./types/Pipe')),
};

const Mouth = ({ type }) => {
  const MouthComponent = mouthComponents[type];

  if (!MouthComponent) return null;

  return (
    <Suspense fallback={null}>
      <MouthComponent />
    </Suspense>
  );
};

export default Mouth;
