import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { RiVipCrownLine } from 'react-icons/ri';
import Avatar from '../../avatar/Avatar';
import { SET_SEARCH_USER, StoreDispatch } from '../../store/Store';
import { hexToRgbA, numFormatter } from '../../utils/helpers';
import {
  card,
  cardDark,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  doubtedMenuShadow,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';
import SocialBadge from './SocialBadge';

const LeaderboardItem = ({ user, i }) => {
  const updateStore = useContext(StoreDispatch);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const handleOpenProfile = user_id => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const getRankColor = () => {
    switch (i) {
      case 0:
        return '#cbb765';
      case 1:
        return '#8e91a7';
      case 2:
        return '#a86243';
      default:
        return;
    }
  };

  const getBorderColor = () => {
    switch (i) {
      case 0:
        return '#4d4526';
      case 1:
        return '#4c4d59';
      case 2:
        return '#5c3525';
      default:
        return;
    }
  };

  const styles = {
    container: {
      width: '100%',
      padding: 1,
      paddingLeft: 2,
      borderRadius: 2,
      backgroundColor: card,
      border: `1px solid ${cardVeryLight}`,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: card,
      },
    },
    containerBanned: {
      width: '100%',
      padding: 1,
      borderRadius: 1,
      boxShadow: modalShadow,
      backgroundColor: card,
      transition: transition,
    },
    rank: {
      fontSize: i < 3 ? 32 : 16,
      color: i < 3 ? yellow : text,
      fontWeight: i < 3 ? 800 : 600,
    },
    username: {
      fontSize: i < 3 ? 22 : 16,
      fontWeight: i < 3 ? 800 : 600,
      color: text,
    },
    epic: {
      fontSize: i < 3 ? normal : small,
      fontWeight: 500,
      color: secondaryText,
    },
    earnings: {
      fontSize: i < 3 ? 22 : 16,
      color: text,
      fontWeight: 500,
    },
    premiumUsername: {
      fontSize: i < 3 ? 22 : 16,
      fontWeight: i < 3 ? 800 : 600,
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
    },
  };

  const newStyles = {
    container: {
      backgroundColor: i % 2 === 0 ? card : cardDark,
      position: 'relative',
      width: '100%',
      borderRadius: 2,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        transform: 'translateY(-3px)',
        boxShadow: doubtedMenuShadow,
      },
    },
    rankContainer: {
      position: 'absolute',
      top: 'calc(50% - 18px)',
      left: -6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 36,
      padding: '6px 8px',
      borderRadius: '2px 4px 4px 0',
    },
    rankContainerAfter: {
      position: 'absolute',
      top: '100%',
      width: 0,
      left: 0,
      height: 0,
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: '0 6px 8px 0',
      borderRightColor: 'red',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    username: {
      fontSize: 18,
      fontWeight: 600,
      color: text,
    },
    premiumUsername: {
      fontSize: 18,
      fontWeight: 600,
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
    },
  };

  return (
    <Grid
      item
      sx={newStyles.container}
      onClick={() => {
        if (user?.behavior_info?.banned) return;

        handleOpenProfile(user?._id);
      }}
    >
      {i < 3 && (
        <Grid
          item
          sx={{ ...newStyles.rankContainer, backgroundColor: getRankColor() }}
        >
          <Grid
            item
            sx={{
              ...newStyles.rankContainerAfter,
              borderRightColor: getBorderColor(),
            }}
          />

          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            <Grid item sx={newStyles.icon}>
              <RiVipCrownLine
                style={{ fontSize: 24, color: hexToRgbA('#000', 0.25) }}
              />
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: 24, fontWeight: 800, color: text }}>
                {i + 1}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: isDesktop ? 4 : 1 }}
        sx={{ paddingLeft: 3 }}
      >
        {i > 2 && (
          <Grid item>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: text }}>
              {i + 1}
            </Typography>
          </Grid>
        )}

        <Grid item sx={{ paddingLeft: i < 3 ? 8 : 1 }}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            <Grid
              item
              sx={{ marginBottom: 0.5, marginLeft: i < 3 ? -1.5 : null }}
            >
              <Avatar
                avatar={!user?.behavior_info?.banned ? user?.new_avatar : null}
                bgColor={i % 2 === 0 ? cardDark : card}
                size={75}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography
                        sx={
                          user?.premium_info?.active &&
                          !user?.behavior_info.banned
                            ? newStyles.premiumUsername
                            : newStyles.username
                        }
                      >
                        {user?.username}
                      </Typography>
                    </Grid>

                    {!isDesktop && (
                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 0.5 }}
                          wrap='nowrap'
                        >
                          <RivoxCoin size={14} />

                          <Grid item>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 800,
                                color: text,
                              }}
                            >
                              {numFormatter().format(
                                parseFloat(user?.stats?.earnings)
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {isDesktop && (
                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      {user?.connections?.twitter && (
                        <SocialBadge
                          type='twitter'
                          onClick={e => {
                            e.stopPropagation();
                            window.open(
                              `${user?.connections?.twitter?.external_link}`,
                              '_blank'
                            );
                          }}
                          backgroundColor={i % 2 === 0 ? cardDark : card}
                          backgroundHover={
                            i % 2 === 0 ? cardLight : cardVeryLight
                          }
                        />
                      )}

                      {user?.connections?.twitch && (
                        <SocialBadge
                          type='twitch'
                          onClick={e => {
                            e.stopPropagation();
                            window.open(
                              `${user?.connections?.twitch?.external_link}`,
                              '_blank'
                            );
                          }}
                          backgroundColor={i % 2 === 0 ? cardDark : card}
                          backgroundHover={
                            i % 2 === 0 ? cardLight : cardVeryLight
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isDesktop && (
          <Grid item sx={{ marginLeft: 'auto', paddingRight: 3 }}>
            <Grid
              container
              direction='column'
              alignItems='end'
              justifyContent='center'
            >
              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 0.5 }}
                  wrap='nowrap'
                >
                  <RivoxCoin size={25} />

                  <Grid item>
                    <Typography
                      sx={{ fontSize: 22, fontWeight: 800, color: text }}
                    >
                      {numFormatter().format(parseFloat(user?.stats?.earnings))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ marginTop: -0.5 }}>
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 800,
                    color: doubtedBlue,
                  }}
                >
                  EARNINGS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
    // <Grid
    //   item
    //   sx={
    //     !user?.behavior_info.banned ? styles.container : styles.containerBanned
    //   }
    //   onClick={() =>
    //     !user?.behavior_info?.banned ? handleOpenProfile(user?._id) : false
    //   }
    // >
    //   <Grid
    //     container
    //     justifyContent="start"
    //     alignItems="center"
    //     gap={{ xs: 2 }}
    //   >
    //     <Grid item>
    //       <Grid
    //         container
    //         justifyContent="start"
    //         alignItems="center"
    //         gap={{ xs: 1 }}
    //       >
    //         {i < 3 ? null : (
    //           <Grid
    //             item
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //             }}
    //           >
    //             <AiOutlineTrophy
    //               style={{ color: secondaryText, fontSize: 20 }}
    //             />
    //           </Grid>
    //         )}

    //         <Grid item>
    //           <Typography sx={styles.rank}>
    //             {i < 3 ? "#" : null}
    //             {i + 1}
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     <Grid item>
    //       <Grid container justifyContent="start" alignItems="center">
    //         <Grid item sx={{ marginLeft: -2 }}>
    //           <Avatar
    //             avatar={!user?.behavior_info?.banned ? user?.new_avatar : null}
    //             bgColor={cardDark}
    //             size={i < 3 ? 150 : 75}
    //           />
    //         </Grid>

    //         <Grid item>
    //           <Typography
    //             sx={
    //               user?.premium_info?.active && !user?.behavior_info.banned
    //                 ? styles.premiumUsername
    //                 : styles.username
    //             }
    //           >
    //             {!user?.behavior_info?.banned
    //               ? user?.username
    //               : "Mystery Competitor"}
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     {(user?.connections?.twitch || user?.connections?.twitter) && (
    //       <Grid item>
    //         <Grid
    //           container
    //           justifyContent="start"
    //           alignItems="center"
    //           gap={{ xs: 1 }}
    //         >
    //           {user?.connections?.twitter && (
    //             <SocialBadge
    //               type="twitter"
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 window.open(
    //                   `${user?.connections?.twitter?.external_link}`,
    //                   "_blank"
    //                 );
    //               }}
    //             />
    //           )}

    //           {user?.connections?.twitch && (
    //             <SocialBadge
    //               type="twitch"
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 window.open(
    //                   `${user?.connections?.twitch?.external_link}`,
    //                   "_blank"
    //                 );
    //               }}
    //             />
    //           )}
    //         </Grid>
    //       </Grid>
    //     )}

    //     <Grid item sx={{ marginLeft: "auto" }}>
    //       <Grid
    //         container
    //         justifyContent="start"
    //         alignItems="center"
    //         gap={{ xs: 0.5 }}
    //       >
    //         <RivoxCoin size={i < 3 ? 32 : 22} />

    //         <Grid item>
    //           <Typography sx={styles.earnings}>
    //             {numFormatter().format(user?.stats?.earnings)}
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid item sx={{ marginLeft: "auto" }}>
    //       <CoinBadge
    //         amount={user?.stats?.earnings}
    //         label="Total earnings"
    //         insideLabel="Earnings"
    //         type="prize"
    //       />
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default LeaderboardItem;
