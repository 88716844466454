import { CircularProgress, Grid, Typography } from '@mui/material';
import { CDN_URL } from '../../utils/constants';
import { black, transition } from '../../utils/themeContstants';

const CardCheckout = ({ onClick, loading, disabled }) => {
  const cardLogo = `${CDN_URL}shopify-checkout-button.png`;

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: 1,
      backgroundColor: '#fff',
      opacity: disabled || loading ? 0.6 : 1,
      transition: transition,
      padding: 2,
      height: 42,
      '&:hover': {
        cursor: disabled || loading ? 'not-allowed' : 'pointer',
        backgroundColor: '#fff',
        filter: disabled || loading ? null : 'brightness(85%)',
      },
    },
    text: {
      fontSize: 16,
      fontWeight: 600,
      color: black,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const handleClick = e => {
    if (disabled || loading) {
      return;
    }
    onClick(e);
  };

  return (
    <Grid item sx={styles.container} onClick={handleClick}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        {loading && (
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={20} sx={{ color: black }} />
          </Grid>
        )}

        {!loading && (
          <>
            <Grid item>
              <Typography sx={styles.text}>
                Pay with Credit/Debit Card
              </Typography>
            </Grid>

            <Grid item sx={styles.icon}>
              <img
                src={cardLogo}
                draggable={false}
                alt='card-logo'
                width={30}
                height={30}
                style={{ marginLeft: '10px' }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CardCheckout;
