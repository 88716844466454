import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import useMiddleware from '../../api/useMiddleware';
import { tipUser } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { currencyFormatter, numFormatter } from '../../utils/helpers';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CurrencyInput from '../custom/CurrencyInput';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';

const UserTipModal = ({ user, open, onClose, closeSearch }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [amount, setAmount] = useState('');
  const [tipLoading, setTipLoading] = useState(false);
  const [understands, setUnderstands] = useState(false);

  const handleClose = () => {
    setAmount('');
    setTipLoading(false);
    setUnderstands(false);
    onClose();
  };

  const handleTip = () => {
    setTipLoading(true);
    tipUser(middleware, parseFloat(amount), user?.user_id).then(res => {
      setTipLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
        closeSearch();
      }
    });
  };

  const styles = {
    userContainer: {
      width: '100%',
    },
    width: {
      width: '100%',
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 500,
    },
    label: {
      fontWeight: 700,
      color: text,
      fontSize: normal,
    },
    vipWithdrawals: {
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
      fontSize: 11,
      fontWeight: 500,
      opacity: 0.8,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Tip ${user?.username}`}
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label='Send Tip'
          bg={doubtedBlue}
          loading={tipLoading}
          disabled={
            amount === '' ||
            amount == null ||
            (store?.user?.premium_info?.active ? amount < 0.1 : amount < 1) ||
            amount > store?.user?.funds?.balance ||
            !user ||
            !understands
          }
          onClick={handleTip}
        />
      }
      secondaryButton={<SecondaryButton label='Cancel' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.userContainer}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: normal,
                      color: offWhite,
                      fontWeight: 600,
                    }}
                  >
                    Your current balance
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 0.5 }}
                  >
                    <RivoxCoin size={32} />

                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: 32,
                          color: text,
                          fontWeight: 700,
                        }}
                      >
                        {numFormatter(
                          store?.user?.funds?.balance < 1 ? 4 : 2
                        ).format(store?.user?.funds?.balance)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <CurrencyInput
                    value={amount}
                    onChange={value => setAmount(value)}
                    autoFocus={true}
                  />
                </Grid>

                {amount !== '' &&
                  amount != null &&
                  (store?.user?.premium_info?.active
                    ? amount < 0.1
                    : amount < 1) && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <RiErrorWarningFill
                            style={{
                              color: secondaryText,
                              fontSize: 14,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: small,
                              fontWeight: 500,
                              color: secondaryText,
                            }}
                          >
                            Minimum tipping amount is{' '}
                            {store?.user?.premium_info?.active
                              ? '$0.10'
                              : '$1.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        {store?.user?.premium_info?.active
                          ? 'Fees (0%)'
                          : 'Fees (5%)'}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.label}>
                        {store?.user?.premium_info?.active
                          ? currencyFormatter().format(amount)
                          : currencyFormatter(4).format(amount * 0.05)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!store?.user?.premium_info?.active && (
                  <Grid item>
                    <Typography sx={styles.vipWithdrawals}>
                      {'VIP members get 0% fees.'}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Sending{' '}
                        <span style={{ fontWeight: 800 }}>
                          {user?.username}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 0.5 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <RivoxCoin size={20} />
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.label}>
                            {store?.user?.premium_info?.active
                              ? numFormatter(4).format(amount)
                              : numFormatter(4).format(amount - amount * 0.05)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Your balance after
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 0.5 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <RivoxCoin size={20} />
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.label}>
                            {numFormatter(
                              store?.user?.funds?.balance - amount < 1 ? 4 : 2
                            ).format(store?.user?.funds?.balance - amount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 2 }}
              >
                <Grid>
                  <CustomCheckbox
                    checked={understands}
                    onChange={value => setUnderstands(value)}
                    color={doubtedBlue}
                    checkColor={'#fff'}
                  />
                </Grid>

                <Grid>
                  <Typography
                    sx={{
                      fontSize: small,
                      color: secondaryText,
                      fontWeight: 600,
                      maxWidth: 400,
                    }}
                  >
                    By checking this box you understand that this tip is
                    irreversible and will come out of your balance.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default UserTipModal;
