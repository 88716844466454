import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useState } from 'react';
import { BiX } from 'react-icons/bi';
import {
  card,
  cardVeryLight,
  container,
  doubtedBlue,
  linearGradientToTop,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';

import { StoreContext } from '../../store/Store';
import { CDN_URL } from '../../utils/constants';
import { hexToRgbA } from '../../utils/helpers';
import CustomRadio from '../custom/CustomRadio';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import ContinueUpgradeModal from './ContinueUpgradeModal';

const UpgradeModal = ({ open, onClose }) => {
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const store = useContext(StoreContext);

  const [selected, setSelected] = useState(1);
  const [hovered, setHovered] = useState(false);
  const [continueOpen, setContinueOpen] = useState(false);

  const handleClose = () => {
    setSelected(1);
    setHovered(false);
    setContinueOpen(false);
    onClose();
  };

  const styles = {
    container: {
      backgroundColor: card,
      minWidth: isDesktop ? 450 : '97%',
      maxWidth: 800,
      boxShadow: modalShadow,
      borderRadius: 8,
    },
    width: {
      width: '100%',
    },
    title: {
      fontSize: 36,
      fontWeight: 800,
      color: text,
      textShadow: `1px 1px 12px ${container}`,
    },
    subtitle: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
      textShadow: `1px 1px 12px ${container}`,
    },
    closeButton: {
      backgroundColor: 'transparent',
      borderRadius: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 10,
      top: 10,
      zIndex: 3,
    },
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    description: {
      fontSize: normal,
      color: offWhite,
      fontWeight: 500,
      maxWidth: 450,
    },
    price: {
      fontSize: 16,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <ContinueUpgradeModal
        open={continueOpen}
        onClose={() => setContinueOpen(false)}
        type={selected}
        closeModal={handleClose}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: styles.container }}
        backdropProps={{ style: { background: 'rgba(16, 18, 27, 0.7)' } }}
      >
        <DialogTitle sx={{ position: 'relative', zIndex: 3 }}>
          <div
            style={{
              backgroundImage: `url(${CDN_URL}${`premium-header.png`})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              width: '100%',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 0,
            }}
          />

          <div
            style={{
              // backgroundColor: hexToRgbA(card, 0.6),
              zIndex: -1,
              position: 'absolute',
              width: '100%',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 2,
            }}
          />

          <div
            style={{
              background: linearGradientToTop(
                hexToRgbA(card, 1),
                hexToRgbA(card, 0)
              ),
              zIndex: -1,
              position: 'absolute',
              width: '100%',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 2,
            }}
          />

          {handleClose && (
            <IconButton
              aria-label='close-button'
              onClick={handleClose}
              style={styles.closeButton}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <BiX style={{ fontSize: 32, color: hovered ? text : offWhite }} />
            </IconButton>
          )}

          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item alignSelf='center' sx={{ zIndex: 3 }}>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                direction='column'
              >
                <Grid item>
                  <Typography sx={styles.title}>Upgrade to VIP</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.subtitle}>
                    Plans start at only $2.99 a month. Cancel anytime.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ zIndex: 3, marginTop: 2 }}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Typography sx={styles.label}>BENEFITS</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        10 "View Opponents"
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        During VIP, you will receive 10 "View Opponents" options
                        which allows you to see a team before you accept the
                        match
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>Tipping</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Enjoy no fee tipping as a VIP user!
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Faster Submit Timers
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Speed up your matches with 3 minute submit timers rather
                        than the normal 10 minutes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Two Free Avatar Pieces
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Two random avatar pieces up to the "Legendary" rarity
                        will be added to your locker
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Expedited Withdrawals
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Withdrawals made by VIP members will be received within
                        a maximum of 24 hours
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Weekly Giveaways
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Weekly giveaways in our Discord for VIP members
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        Priority Support
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.description}>
                        Benefit from having your tickets in our Discord answered
                        first
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: cardVeryLight,
                  height: 2,
                }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Typography sx={styles.label}>PLANS</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    wrap='nowrap'
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <CustomRadio
                          onClick={() => setSelected(1)}
                          selected={selected === 1}
                          color={doubtedBlue}
                          checkColor={text}
                        />

                        <Grid item>
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.value}>
                                1 month for you
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent='start'
                                alignItems='center'
                                gap={{ xs: 1 }}
                                wrap='nowrap'
                              >
                                <Grid item>
                                  <Typography sx={styles.description}>
                                    Renews every 31 days at
                                  </Typography>
                                </Grid>

                                <RivoxCoin size={14} />

                                <Grid item sx={{ marginLeft: -0.5 }}>
                                  <Typography sx={styles.description}>
                                    2.99
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 0.5 }}
                      >
                        <RivoxCoin size={22} />

                        <Grid item>
                          <Typography sx={styles.price}>2.99</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    wrap='nowrap'
                    gap={{ xs: 2 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <CustomRadio
                          onClick={() => setSelected(2)}
                          selected={selected === 2}
                          color={doubtedBlue}
                          checkColor={text}
                        />

                        <Grid item>
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.value}>
                                1 month for you and a friend
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent='start'
                                alignItems='center'
                                columnGap={{ xs: 1 }}
                              >
                                <Grid item>
                                  <Typography sx={styles.description}>
                                    Renews every 31 days at
                                  </Typography>
                                </Grid>

                                <RivoxCoin size={14} />

                                <Grid item sx={{ marginLeft: -0.5 }}>
                                  <Typography sx={styles.description}>
                                    2.99
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <Typography sx={styles.description}>
                                    (only renews for you not for your friend)
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 0.5 }}
                      >
                        <RivoxCoin size={22} />

                        <Grid item>
                          <Typography sx={styles.price}>4.99</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Grid item sx={{ width: '100%', marginTop: 2 }}>
            <SecondaryButton
              fullWidth
              size='massive'
              label='Continue'
              onClick={() => setContinueOpen(true)}
              bg={doubtedBlue}
              disabled={store?.user?.premium_info?.active}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpgradeModal;
