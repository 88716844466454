import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiMoney } from 'react-icons/bi';
import {
  FaGamepad,
  FaMapMarkerAlt,
  FaMedal,
  FaRegClock,
  FaUsers,
  FaWallet,
} from 'react-icons/fa';
import { GiPodium, GiTrophy } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5';
import { createTournament, updateTournament } from '../../api/tournaments';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import {
  newGameModeOptions,
  platformOptions,
  regionOptions,
} from '../../utils/options';
import {
  cardDark,
  cardVeryLight,
  doubtedBlue,
  offWhite,
  red,
  secondaryText,
  text,
  yellow,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const GAME_OPTIONS = [
  { title: 'Fortnite', value: 'fortnite' },
  { title: 'Warzone', value: 'warzone' },
  { title: 'Chess', value: 'chess' },
  { title: 'FIFA', value: 'fifa' },
  { title: 'Clash Royale', value: 'clashroyale' },
];

const MAX_PLAYERS_OPTIONS = [
  { title: '2 Teams', value: '2' },
  { title: '4 Teams', value: '4' },
  { title: '8 Teams', value: '8' },
  { title: '16 Teams', value: '16' },
  { title: '32 Teams', value: '32' },
  { title: '64 Teams', value: '64' },
  { title: '128 Teams', value: '128' },
  { title: '256 Teams', value: '256' },
];

const TEAM_SIZE_OPTIONS = [
  { title: '1v1', value: 1 },
  { title: '2v2', value: 2 },
  { title: '3v3', value: 3 },
  { title: '4v4', value: 4 },
];

const TournamentForm = ({ open, onClose, user, tournament, onUpdate }) => {
  const isEditMode = !!tournament;
  const isPending = isEditMode ? tournament.status === 'PENDING' : true;
  const hasTeams = isEditMode
    ? tournament.registered_teams && tournament.registered_teams.length > 0
    : false;

  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  // Check if user is allowed to edit prize structure
  const canEditPrizes =
    !isEditMode || store?.user?.account_type >= AccountTypeEnum.SENIOR_ADMIN;

  const [formData, setFormData] = useState({
    name: '1v1 Realistic',
    game: 'fortnite',
    game_match_type: 'real',
    entry_fee: 0,
    max_players: '128',
    team_size: 1,
    first_to: 3,
    region: 'eu',
    platform: null,
    prize_structure: [
      { place: 1, amount: 12 },
      { place: 2, amount: 6 },
      { place: 3, amount: 4 },
      { place: 4, amount: 2 },
      { place: 5, amount: 1 },
      { place: 6, amount: 0.5 },
    ],
    start_time: getDefaultStartTime(),
    registration_opens_at: getDefaultRegistrationTime(),
    is_locked: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const middleware = useMiddleware();
  const isTablet = useMediaQuery('(max-width:1024px)');

  // Function to get default start time (2 hours from now, rounded to nearest :00 or :30)
  function getDefaultStartTime() {
    const now = new Date();
    // Add 2 hours
    const startTime = new Date(now.getTime() + 2 * 60 * 60 * 1000);

    // Round to nearest :00 or :30
    const minutes = startTime.getMinutes();
    if (minutes < 30) {
      startTime.setMinutes(30);
    } else {
      startTime.setMinutes(0);
      startTime.setHours(startTime.getHours() + 1);
    }

    // Reset seconds and milliseconds
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    // Format for datetime-local input
    return startTime.toISOString().slice(0, 16);
  }

  // Function to get default registration time (1:30 before start time)
  function getDefaultRegistrationTime() {
    const startTime = new Date(getDefaultStartTime());
    // Subtract 1 hour and 30 minutes
    const registrationTime = new Date(startTime.getTime() - 90 * 60 * 1000);
    return registrationTime.toISOString().slice(0, 16);
  }

  const steps = [
    { label: 'Basic Info', icon: <FaGamepad /> },
    { label: 'Game Settings', icon: <FaGamepad /> },
    { label: 'Tournament Details', icon: <FaUsers /> },
    { label: 'Prize Structure', icon: <BiMoney /> },
  ];

  // Initialize form data when in edit mode
  useEffect(() => {
    if (isEditMode && tournament) {
      // Format dates for datetime-local input
      const formatDate = dateString => {
        if (!dateString) return null;
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        return date.toISOString().slice(0, 16);
      };

      setFormData({
        name: tournament.name || '',
        game: tournament.game || '',
        game_match_type: tournament.game_match_type || '',
        entry_fee: tournament.entry_fee || 0,
        max_players: tournament.max_players?.toString() || '2',
        team_size: tournament.team_size || 1,
        first_to: tournament.first_to || 1,
        region: tournament.region || '',
        platform: tournament.platform || null,
        prize_structure: tournament.prize_structure || [],
        start_time: formatDate(tournament.start_time) || getDefaultStartTime(),
        registration_opens_at:
          formatDate(tournament.registration_opens_at) ||
          getDefaultRegistrationTime(),
        is_locked: tournament.is_locked || false,
      });
    }
  }, [tournament, isEditMode, open]);

  const fillSampleData = () => {
    const today = new Date();
    const startTime = new Date();
    startTime.setDate(today.getDate() + 1); // tomorrow
    startTime.setHours(20, 0, 0, 0); // 8 PM

    const formattedDate = startTime.toISOString().slice(0, 16);

    setFormData({
      name: 'Test Tournament',
      game: 'fortnite',
      game_match_type: 'br_duos',
      entry_fee: 0,
      max_players: '4',
      team_size: 2,
      first_to: 3,
      region: 'NA',
      platform: 'crossplay',
      prize_structure: [
        { place: 1, amount: 100 },
        { place: 2, amount: 50 },
      ],
      start_time: formattedDate,
      registration_opens_at: null,
      is_locked: false,
    });
  };

  const handleAddPrize = () => {
    // Prevent adding prizes if user doesn't have permission
    if (!canEditPrizes) return;

    setFormData(prev => {
      return {
        ...prev,
        prize_structure: [
          ...prev.prize_structure,
          {
            place:
              prev.prize_structure.length > 0
                ? Math.max(...prev.prize_structure.map(prize => prize.place)) +
                  1
                : 1,
            amount: 0,
          },
        ],
      };
    });
  };

  const handleRemovePrize = index => {
    // Prevent removing prizes if user doesn't have permission
    if (!canEditPrizes) return;

    const updatedPrizes = formData.prize_structure.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, prize_structure: updatedPrizes });
  };

  const handlePrizeChange = (index, value) => {
    // Prevent editing prize amount if user doesn't have permission
    if (!canEditPrizes) return;

    const updatedPrizes = [...formData.prize_structure];
    updatedPrizes[index].amount = parseFloat(value) || 0;
    setFormData({ ...formData, prize_structure: updatedPrizes });
  };

  const handleNext = () => {
    setActiveStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep(prev => Math.max(prev - 1, 0));
  };

  const validateForm = () => {
    if (
      !formData.name ||
      !formData.game ||
      !formData.game_match_type ||
      !formData.region ||
      !formData.max_players ||
      !formData.first_to ||
      !formData.start_time
    ) {
      updateStore({
        type: SET_ERRORS,
        payload: 'Please fill in all required fields',
      });
      return false;
    }

    // If tournament is not pending, only allow changing basic information
    if (isEditMode && !isPending) {
      // Check if restricted fields were changed
      if (
        formData.game !== tournament.game ||
        formData.game_match_type !== tournament.game_match_type ||
        formData.max_players !== tournament.max_players.toString() ||
        formData.team_size !== tournament.team_size ||
        formData.entry_fee !== tournament.entry_fee ||
        formData.first_to !== tournament.first_to ||
        formData.region !== tournament.region ||
        formData.platform !== tournament.platform
      ) {
        updateStore({
          type: SET_ERRORS,
          payload:
            'You can only edit tournament information when status is pending',
        });
        return false;
      }
    }

    // If tournament has teams, don't allow changing entry fee or team size
    if (isEditMode && hasTeams) {
      if (
        formData.entry_fee !== tournament.entry_fee ||
        formData.team_size !== tournament.team_size
      ) {
        updateStore({
          type: SET_ERRORS,
          payload:
            'You cannot change entry fee or team size when teams have joined',
        });
        return false;
      }
    }

    const startTime = new Date(formData.start_time);
    if (isNaN(startTime.getTime())) {
      updateStore({
        type: SET_ERRORS,
        payload: 'Please enter a valid start time',
      });
      return false;
    }

    if (startTime < new Date() && !isEditMode) {
      updateStore({
        type: SET_ERRORS,
        payload: 'Start time must be in the future',
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    let tournamentData = {
      name: formData.name,
      start_time: formData.start_time,
      registration_opens_at: formData.registration_opens_at,
      is_locked: formData.is_locked,
    };

    // If tournament is pending or it's a new tournament, include all fields
    if (!isEditMode || isPending) {
      tournamentData = {
        ...tournamentData,
        game: formData.game,
        game_match_type: formData.game_match_type,
        region: formData.region,
        max_players: parseInt(formData.max_players),
        first_to: parseInt(formData.first_to),
        platform: formData.platform,
        prize_structure: formData.prize_structure
          .filter(prize => prize.amount)
          .map(prize => ({
            place: prize.place,
            amount: parseFloat(prize.amount),
          })),
      };
    }

    // Only include team_size and entry_fee if there are no teams or it's a new tournament
    if (!isEditMode || !hasTeams) {
      tournamentData = {
        ...tournamentData,
        team_size: parseInt(formData.team_size),
        entry_fee: parseFloat(formData.entry_fee) || 0,
      };
    }

    setIsSubmitting(true);

    if (isEditMode) {
      // Update existing tournament
      const response = await updateTournament(
        middleware,
        tournament._id,
        tournamentData
      );
      setIsSubmitting(false);

      if (response?.error) {
        updateStore({ type: SET_ERRORS, payload: response?.message });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Tournament updated successfully',
        });
        if (onUpdate) onUpdate(response);
        handleClose();
      }
    } else {
      // Create new tournament
      const response = await createTournament(middleware, tournamentData);
      setIsSubmitting(false);

      if (response?.error) {
        updateStore({ type: SET_ERRORS, payload: response?.message });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Tournament created successfully',
        });
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setFormData({
      name: '1v1 Realistic',
      game: 'fortnite',
      game_match_type: 'real',
      entry_fee: 0,
      max_players: '128',
      team_size: 1,
      first_to: 3,
      region: 'eu',
      platform: null,
      prize_structure: [
        { place: 1, amount: 12 },
        { place: 2, amount: 6 },
        { place: 3, amount: 4 },
        { place: 4, amount: 2 },
        { place: 5, amount: 1 },
        { place: 6, amount: 0.5 },
      ],
      start_time: getDefaultStartTime(),
      registration_opens_at: getDefaultRegistrationTime(),
      is_locked: false,
    });
    onClose();
    setActiveStep(0);
  };

  const dialogStyles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: cardDark,
        borderRadius: 2,
        maxWidth: 800,
        width: '100%',
        margin: '16px',
        boxSizing: 'border-box',
        overflow: 'hidden',
      },
    },
    header: {
      backgroundColor: `rgba(255, 255, 255, 0.03)`,
      padding: '24px 28px',
      borderBottom: `1px solid ${cardVeryLight}20`,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        background: `linear-gradient(90deg, ${doubtedBlue}, ${cardVeryLight}20)`,
      },
    },
    title: {
      color: text,
      fontSize: 28,
      fontWeight: 800,
      textShadow: '0 2px 4px rgba(0,0,0,0.2)',
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
    subtitle: {
      color: secondaryText,
      fontSize: 14,
      marginTop: 1,
    },
    closeButton: {
      position: 'absolute',
      right: 16,
      top: 16,
      color: secondaryText,
      transition: 'all 0.2s ease',
      '&:hover': {
        color: text,
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
    content: {
      padding: 0,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 80px)',
    },
    stepper: {
      backgroundColor: cardDark,
      padding: '12px 24px',
      borderBottom: `1px solid ${cardVeryLight}20`,
    },
    step: {
      cursor: 'pointer',
      padding: '8px 16px',
      borderRadius: '8px',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      gap: 1.5,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.05)',
      },
    },
    activeStep: {
      backgroundColor: `${doubtedBlue}20`,
      borderLeft: `3px solid ${doubtedBlue}`,
    },
    stepIcon: {
      color: secondaryText,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 18,
    },
    activeStepIcon: {
      color: doubtedBlue,
    },
    stepLabel: {
      color: secondaryText,
      fontWeight: 600,
      fontSize: 14,
    },
    activeStepLabel: {
      color: text,
      fontWeight: 700,
    },
    formContainer: {
      padding: '24px 28px',
      overflow: 'auto',
      flex: 1,
    },
    sectionTitle: {
      fontSize: 18,
      fontWeight: 700,
      color: text,
      marginBottom: 3,
      position: 'relative',
      paddingLeft: 2,
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        backgroundColor: doubtedBlue,
        borderRadius: '2px',
      },
    },
    formSection: {
      backgroundColor: 'rgba(255,255,255,0.02)',
      borderRadius: 2,
      padding: 3,
      marginBottom: 3,
      border: `1px solid ${cardVeryLight}20`,
      transition: 'all 0.3s ease',
      '&:hover': {
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
        borderColor: `${cardVeryLight}40`,
      },
    },
    fieldContainer: {
      marginBottom: 2.5,
    },
    fieldIcon: {
      color: secondaryText,
      marginRight: 1,
      fontSize: 16,
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 0.5,
      display: 'flex',
      alignItems: 'center',
    },
    inputContainer: {
      position: 'relative',
    },
    prizeHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2,
    },
    prizeGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
      gap: 3,
      width: '100%',
    },
    prizeCard: {
      backgroundColor: 'rgba(255,255,255,0.03)',
      borderRadius: 2,
      padding: 2,
      position: 'relative',
      border: `1px solid ${cardVeryLight}30`,
      transition: 'all 0.2s ease',
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&:hover': {
        borderColor: `${doubtedBlue}60`,
        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
      },
    },
    prizePlaceLabel: {
      fontSize: 16,
      fontWeight: 700,
      color: text,
      marginBottom: 1,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    prizeIconWrapper: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: `${yellow}20`,
      border: `2px solid ${yellow}`,
    },
    removeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      color: red,
      padding: 0,
      width: '20px',
      height: '20px',
      minWidth: '20px',
      zIndex: 2,
      '& svg': {
        fontSize: '14px',
      },
      '&:hover': {
        color: `${red}dd`,
        backgroundColor: 'rgba(255,70,70,0.1)',
      },
    },
    addPrizeButton: {
      borderColor: cardVeryLight,
      backgroundColor: 'rgba(255,255,255,0.05)',
      color: text,
      marginLeft: 1,
      padding: '6px 16px',
      '&:hover': {
        borderColor: doubtedBlue,
        backgroundColor: `${doubtedBlue}20`,
      },
    },
    footer: {
      padding: '16px 28px',
      borderTop: `1px solid ${cardVeryLight}20`,
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    backButton: {
      color: secondaryText,
      borderColor: cardVeryLight,
      '&:hover': {
        borderColor: text,
        color: text,
      },
    },
    sampleDataButton: {
      color: secondaryText,
      margin: '12px 0',
      fontSize: 12,
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: text,
      },
    },
    getPlaceIcon: place => {
      switch (place) {
        case 1:
          return {
            icon: <GiTrophy style={{ fontSize: 16, color: yellow }} />,
            color: yellow,
          };
        case 2:
          return {
            icon: <FaMedal style={{ fontSize: 16, color: offWhite }} />,
            color: offWhite,
          };
        case 3:
          return {
            icon: <FaMedal style={{ fontSize: 16, color: '#CD7F32' }} />,
            color: '#CD7F32',
          };
        default:
          return {
            icon: <GiPodium style={{ fontSize: 16, color: secondaryText }} />,
            color: secondaryText,
          };
      }
    },
  };

  const renderBasicInfo = () => (
    <Box sx={dialogStyles.formSection}>
      <Typography sx={dialogStyles.sectionTitle}>
        Tournament Information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <BiMoney style={dialogStyles.fieldIcon} /> Tournament Name*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomInput
                placeholder='Enter tournament name'
                value={formData.name}
                onChange={value => setFormData({ ...formData, name: value })}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaRegClock style={dialogStyles.fieldIcon} /> Start Time*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomInput
                type='datetime-local'
                placeholder='Select start time'
                value={formData.start_time}
                onChange={value =>
                  setFormData({ ...formData, start_time: value })
                }
                disabled={isEditMode && !isPending}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaRegClock style={dialogStyles.fieldIcon} /> Registration Opens
              At
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomInput
                type='datetime-local'
                placeholder='Select registration time'
                value={formData.registration_opens_at}
                onChange={value =>
                  setFormData({
                    ...formData,
                    registration_opens_at: value,
                    is_locked: false,
                  })
                }
                fullWidth
                disabled={isEditMode && !isPending}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CustomCheckbox
              checked={formData.is_locked}
              onChange={checked =>
                setFormData({
                  ...formData,
                  is_locked: checked,
                  registration_opens_at: checked
                    ? null
                    : formData.registration_opens_at,
                })
              }
            />
            <Typography sx={{ ...dialogStyles.label, mb: 0 }}>
              Lock Registration
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderGameSettings = () => (
    <Box sx={dialogStyles.formSection}>
      <Typography sx={dialogStyles.sectionTitle}>Game Settings</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaGamepad style={dialogStyles.fieldIcon} /> Game*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={GAME_OPTIONS}
                placeholder='Select game'
                value={formData.game}
                onChange={value => {
                  setFormData({
                    ...formData,
                    game: value,
                    game_match_type: '',
                  });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={isEditMode && !isPending}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaGamepad style={dialogStyles.fieldIcon} /> Game Mode*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={formData.game ? newGameModeOptions(formData.game) : []}
                placeholder='Select game mode'
                value={formData.game_match_type}
                onChange={value => {
                  setFormData({ ...formData, game_match_type: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={!formData.game || (isEditMode && !isPending)}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaMapMarkerAlt style={dialogStyles.fieldIcon} /> Region*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={regionOptions()}
                placeholder='Select region'
                value={formData.region}
                onChange={value => {
                  setFormData({ ...formData, region: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={isEditMode && !isPending}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaGamepad style={dialogStyles.fieldIcon} /> Platform
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={platformOptions}
                placeholder='Select platform'
                value={formData.platform}
                onChange={value => {
                  setFormData({ ...formData, platform: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={isEditMode && !isPending}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderTournamentDetails = () => (
    <Box sx={dialogStyles.formSection}>
      <Typography sx={dialogStyles.sectionTitle}>
        Tournament Structure
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaUsers style={dialogStyles.fieldIcon} /> Maximum Players*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={MAX_PLAYERS_OPTIONS}
                placeholder='Select max players'
                value={formData.max_players?.toString()}
                onChange={value => {
                  setFormData({ ...formData, max_players: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={isEditMode && !isPending}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaUsers style={dialogStyles.fieldIcon} /> Team Size*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomDropdown
                options={TEAM_SIZE_OPTIONS}
                placeholder='Select team size'
                value={formData.team_size}
                onChange={value => {
                  setFormData({ ...formData, team_size: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
                disabled={
                  (isEditMode && !isPending) || (isEditMode && hasTeams)
                }
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <FaWallet style={dialogStyles.fieldIcon} /> Entry Fee
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomInput
                type='number'
                placeholder='Enter entry fee (optional)'
                value={formData.entry_fee}
                onChange={value =>
                  setFormData({ ...formData, entry_fee: value })
                }
                fullWidth
                startAdornment='$'
                disabled={
                  (isEditMode && !isPending) || (isEditMode && hasTeams)
                }
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={dialogStyles.fieldContainer}>
            <Typography sx={dialogStyles.label}>
              <GiTrophy style={dialogStyles.fieldIcon} /> First To*
            </Typography>
            <Box sx={dialogStyles.inputContainer}>
              <CustomInput
                type='number'
                min='1'
                placeholder='Enter first to value'
                value={formData.first_to}
                onChange={value =>
                  setFormData({ ...formData, first_to: parseInt(value) || 1 })
                }
                fullWidth
                disabled={!isPending}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPrizeStructure = () => {
    // Calculate total prize amount
    const totalPrizeAmount = formData.prize_structure.reduce((sum, prize) => {
      if (prize.place <= 2) {
        return sum + prize.amount;
      } else {
        const numWinners = Math.pow(2, prize.place - 2);
        return sum + prize.amount * numWinners;
      }
    }, 0);

    return (
      <Box sx={dialogStyles.formSection}>
        <Typography sx={dialogStyles.sectionTitle}>Prize Structure</Typography>

        <Box sx={dialogStyles.prizeHeader}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: secondaryText,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <BiMoney /> Total Prize Pool:
            <span style={{ color: yellow, marginLeft: '8px' }}>
              ${totalPrizeAmount.toFixed(2)}
            </span>
          </Typography>
          {isPending && canEditPrizes && (
            <PrimaryButton
              label='Add Prize Place'
              onClick={handleAddPrize}
              variant='outlined'
              size='small'
              startIcon={<BiMoney />}
              sx={dialogStyles.addPrizeButton}
              disabled={isEditMode && !isPending}
            />
          )}
        </Box>

        <Box sx={dialogStyles.prizeGrid}>
          {formData.prize_structure.map((prize, index) => {
            const { icon, color } = dialogStyles.getPlaceIcon(prize.place);
            const numWinners =
              prize.place <= 2 ? 1 : Math.pow(2, prize.place - 2);
            const total = prize.amount * (prize.place <= 2 ? 1 : numWinners);

            return (
              <Box key={index} sx={dialogStyles.prizeCard}>
                <Typography sx={dialogStyles.prizePlaceLabel}>
                  <Box
                    sx={{
                      ...dialogStyles.prizeIconWrapper,
                      backgroundColor: `${color}20`,
                      borderColor: color,
                    }}
                  >
                    {icon}
                  </Box>
                  {prize.place === 1
                    ? '1st Place'
                    : prize.place === 2
                      ? '2nd Place'
                      : prize.place === 3
                        ? '3rd Place'
                        : `${prize.place}th Place`}
                </Typography>

                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: secondaryText,
                      fontSize: '16px',
                      fontWeight: 600,
                      marginRight: '4px',
                    }}
                  >
                    $
                  </Typography>
                  <CustomInput
                    type='number'
                    placeholder='Enter prize amount'
                    value={prize.amount}
                    onChange={value => handlePrizeChange(index, value)}
                    width={70}
                    disabled={!isPending || !canEditPrizes}
                  />
                </Box>

                {formData.prize_structure.length > 1 && (
                  <IconButton
                    onClick={() => handleRemovePrize(index)}
                    sx={dialogStyles.removeButton}
                    size='small'
                    disabled={!canEditPrizes}
                  >
                    <IoClose />
                  </IconButton>
                )}
              </Box>
            );
          })}

          {formData.prize_structure.length === 0 && (
            <Typography
              sx={{
                color: secondaryText,
                fontSize: 14,
                gridColumn: '1 / -1',
                textAlign: 'center',
              }}
            >
              No prizes added yet. Click "Add Prize Place" to add prizes.
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return renderBasicInfo();
      case 1:
        return renderGameSettings();
      case 2:
        return renderTournamentDetails();
      case 3:
        return renderPrizeStructure();
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={dialogStyles.dialog}
      maxWidth='md'
      fullWidth
    >
      <Box sx={dialogStyles.header}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={dialogStyles.title}>
            <FaGamepad style={{ fontSize: 24, color: doubtedBlue }} />
            {isEditMode ? 'Edit Tournament' : 'Create Tournament'}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={dialogStyles.closeButton}
          >
            <IoClose />
          </IconButton>
        </Box>
        <Typography sx={dialogStyles.subtitle}>
          {isEditMode
            ? 'Edit your tournament details and settings'
            : 'Set up a new tournament with custom settings'}
        </Typography>

        {isEditMode && !isPending && (
          <Typography
            sx={{
              color: yellow,
              fontSize: 14,
              marginTop: 1,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <FaRegClock /> Note: Only tournament name can be edited after the
            tournament has started.
          </Typography>
        )}

        {isEditMode && hasTeams && (
          <Typography
            sx={{
              color: yellow,
              fontSize: 14,
              marginTop: 1,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <FaUsers /> Note: Entry fee and team size cannot be changed when
            teams have joined.
          </Typography>
        )}
      </Box>

      <Box sx={dialogStyles.content}>
        <Box sx={dialogStyles.stepper}>
          <Grid container spacing={1}>
            {steps.map((step, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <Box
                  sx={{
                    ...dialogStyles.step,
                    ...(activeStep === index ? dialogStyles.activeStep : {}),
                  }}
                  onClick={() => setActiveStep(index)}
                >
                  <Box
                    sx={{
                      ...dialogStyles.stepIcon,
                      ...(activeStep === index
                        ? dialogStyles.activeStepIcon
                        : {}),
                    }}
                  >
                    {step.icon}
                  </Box>
                  <Typography
                    sx={{
                      ...dialogStyles.stepLabel,
                      ...(activeStep === index
                        ? dialogStyles.activeStepLabel
                        : {}),
                    }}
                  >
                    {step.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={dialogStyles.formContainer}>{getStepContent()}</Box>

        <Box sx={dialogStyles.footer}>
          <Box>
            <SecondaryButton
              label='Back'
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={dialogStyles.backButton}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {activeStep < steps.length - 1 ? (
              <PrimaryButton
                label='Next'
                onClick={handleNext}
                color={doubtedBlue}
              />
            ) : (
              <PrimaryButton
                label={
                  isSubmitting
                    ? isEditMode
                      ? 'Updating...'
                      : 'Creating...'
                    : isEditMode
                      ? 'Update Tournament'
                      : 'Create Tournament'
                }
                onClick={handleSubmit}
                color={doubtedBlue}
                disabled={isSubmitting}
                startIcon={<FaGamepad />}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TournamentForm;
