import { Grid } from '@mui/material';
import { IoSearch } from 'react-icons/io5';
import styled from 'styled-components';
import {
  cardVeryLight,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';

const SearchInput = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.placeholderColor};
    font-weight: 500;
    font-size: 22px;
  }
  :-ms-input-placeholder {
    color: ${props => props.placeholderColor};
  }
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  min-height: 55px;
  font-size: 22px;
  padding-right: 16px;
  outline: none;
  font-family: 'Inter';
  font-weight: 400;
  min-width: 100%;
  webkit-appearance: none;
  border: none;
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
  }
  box-sizing: border-box;
`;

const SearchBar = props => {
  const {
    placeholder,
    value,
    onChange,
    onKeyDown,
    onBlur,
    onFocus,
    type = 'text',
    disabled = false,
    onPaste,
  } = props;

  const styles = {
    container: {
      minWidth: '100%',
      backgroundColor: secondaryButton,
      padding: 1,
      paddingLeft: 2,
      borderRadius: 1,
      border: `2px solid ${cardVeryLight}`,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        gap={{ xs: 3 }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IoSearch
            size={32}
            color={secondaryText}
            style={{
              transition: transition,
            }}
          />
        </Grid>

        <Grid item sx={{ flexGrow: 1 }}>
          <SearchInput
            placeholderColor={secondaryText}
            backgroundColor={'transparent'}
            type={type}
            placeholder={placeholder}
            value={value}
            color={text}
            onChange={e => onChange(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onKeyDown();
              }
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            autoFocus={true}
            onPaste={e => onPaste(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
