import { CircularProgress, Grid } from '@mui/material';
import { BiEnvelope, BiEnvelopeOpen } from 'react-icons/bi';
import { FiTrash2 } from 'react-icons/fi';
import { secondaryText, text } from '../../utils/themeContstants';
import CustomMenu from '../custom/CustomMenu';
import ListItem from '../custom/ListItem';
import NotificationAction from './NotificationAction';

const NotificationItemSettings = ({
  anchor,
  handleClose,
  noti,
  handleToggleNoti,
  handleDeleteNoti,
  deleteLoading,
  handleNotificationClose,
}) => {
  const styles = {
    icon: { color: text, fontSize: 20 },
    width: {
      width: '100%',
    },
  };

  return (
    <CustomMenu anchor={anchor} handleClose={handleClose}>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          {noti?.attached && (
            <NotificationAction
              type={noti?.notification_type}
              disabled={deleteLoading}
              noti={noti}
              handleClose={handleClose}
              handleNotificationClose={handleNotificationClose}
            />
          )}
          <ListItem
            label={noti?.read ? 'Mark as unread' : 'Mark as read'}
            icon={
              noti?.read ? (
                <BiEnvelope style={styles.icon} />
              ) : (
                <BiEnvelopeOpen style={styles.icon} />
              )
            }
            onClick={() => handleToggleNoti(!noti?.read)}
            disabled={deleteLoading}
          />
          <ListItem
            label='Delete Notification'
            icon={
              deleteLoading ? (
                <CircularProgress size={20} sx={{ color: secondaryText }} />
              ) : (
                <FiTrash2 style={styles.icon} />
              )
            }
            onClick={handleDeleteNoti}
            disabled={deleteLoading}
          />
        </Grid>
      </Grid>
    </CustomMenu>
  );
};

export default NotificationItemSettings;
