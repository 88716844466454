import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { getDiscordTranscripts } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { getReadableDateString, getTime } from '../../utils/helpers';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import {
  card,
  cardLight,
  doubtedBlue,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import NotSelected from '../custom/NotSelected';
import LogGlimmer from '../glimmers/LogGlimmer';

const UserTranscripts = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetLogs = () => {
    getDiscordTranscripts(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.logs?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setLogs(logs ? [...logs, ...res?.logs] : res?.logs);
      }
    });
  };

  const handleLogClick = attached => {
    return window.open(attached);
  };

  usePagination(bottom, () => setSkip(logs?.length));

  useEffect(() => {
    if (logs == null) {
      handleGetLogs();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetLogs();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
    clashLink: {
      textDecoration: 'underline',
      color: doubtedBlue,
      fontWeight: 400,
      overflowWrap: 'break-word',
      // verticalAlign: "middle",
      lineHeight: 1.6,
      // marginLeft: 4,
      cursor: 'default',
      fontSize: normal,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!logs || logs?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO LOGS' />
        </Grid>
      )}

      {!loading && logs && logs?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 0.5 }}
          >
            {logs?.map((log, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: '100%',
                  backgroundColor: card,
                  boxShadow: modalShadow,
                  transition: transition,
                  '&:hover': {
                    cursor: 'pointer',
                    transform: 'translateY(-5px)',
                  },
                }}
                key={i}
                onClick={() => handleLogClick(log?.attached)}
              >
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  sx={{
                    width: '100%',
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>
                      {getReadableDateString(new Date(log?.timestamp)) +
                        ' at ' +
                        getTime(new Date(log?.timestamp))}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{ fontSize: normal, color: text, fontWeight: 600 }}
                    >
                      <span style={styles.clashLink}>
                        Click here to view this transcript
                      </span>
                      {log?.claimed_by &&
                        log?.claimed_by !== '{claim.user.name}' && (
                          <Typography
                            sx={{
                              fontSize: normal,
                              color: text,
                              fontWeight: 600,
                            }}
                          >
                            Claimed by: {log?.claimed_by}
                          </Typography>
                        )}

                      {/* {log?.attached} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserTranscripts;
