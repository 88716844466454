import { Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMiddleware from '../../api/useMiddleware';
import { getReceipt } from '../../api/user';
import { CDN_URL } from '../../utils/constants';
import {
  currencyFormatter,
  getReadableDateString,
  getTime,
  numFormatter,
} from '../../utils/helpers';
import {
  card,
  cardLight,
  cardVeryLight,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import LoadingScreen from '../custom/LoadingScreen';
import RivoxCoin from '../custom/RivoxCoin';

const Receipt = () => {
  const navigate = useNavigate();
  const params = useParams();
  const middleware = useMiddleware();
  const [loading, setLoading] = useState(true);
  const [receipt, setReceipt] = useState(null);

  const handleGetReceipt = () => {
    if (!params?.id) {
      navigate('/');
    } else {
      getReceipt(middleware, params?.id).then(res => {
        setLoading(false);
        if (res?.error) {
          navigate('/');
        } else {
          setReceipt(res);
        }
      });
    }
  };

  useEffect(() => {
    handleGetReceipt();
  }, []);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
    },
    receiptContainer: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      width: 600,
    },
    width: {
      width: '100%',
    },
    title: {
      fontSize: 22,
      fontWeight: 800,
      color: text,
    },
    subtitle: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    label: {
      fontSize: small,
      fontWeight: 700,
      color: offWhite,
    },
    summaryContainer: {
      width: '100%',
      padding: 1,
      paddingLeft: 2,
      paddingRight: 2,
      borderRadius: 1,
      backgroundColor: cardVeryLight,
    },
    summaryLabel: {
      fontSize: normal,
      fontWeight: 400,
      color: text,
    },
    value: {
      fontWeight: 600,
      color: text,
      fontSize: normal,
    },
    description: {
      fontSize: normal,
      fontWeight: 400,
      color: text,
    },
    text: {
      fontSize: 13,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return loading ? (
    <LoadingScreen size={150} />
  ) : (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent='center'
        alignItems='start'
        sx={{ height: '100vh', width: '100%', paddingTop: 8 }}
        wrap='nowrap'
      >
        <Grid item sx={styles.receiptContainer}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
            sx={{ padding: 2, borderRadius: 1, backgroundColor: cardLight }}
          >
            <Grid item alignSelf='center'>
              <RivoxCoin size={100} />
            </Grid>

            <Grid item alignSelf='center'>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.title}>Receipt from Rivox</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.subtitle}>
                    Receipt #{receipt?._id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.label}>SUMMARY</Typography>
                </Grid>

                <Grid item sx={styles.summaryContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <Grid item>
                          <Typography sx={styles.summaryLabel}>Date</Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.value}>
                            {getReadableDateString(new Date(receipt?.date)) +
                              ' at ' +
                              getTime(new Date(receipt?.date))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Divider
                        sx={{ width: '100%', backgroundColor: cardLight }}
                      />
                    </Grid>

                    {receipt?.payment_method === 'cashapp' && (
                      <>
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            gap={{ xs: 2 }}
                          >
                            <Grid item>
                              <Typography sx={styles.summaryLabel}>
                                Cash App Note
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography sx={styles.value}>
                                {receipt?.transaction_id}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <Divider
                            sx={{ width: '100%', backgroundColor: cardLight }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <Grid item>
                          <Typography sx={styles.summaryLabel}>
                            Account used
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.value}>
                            {receipt?.account_used}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{ width: '100%', backgroundColor: cardLight }}
                    />
                    {receipt?.transaction_id !== 'default' && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          gap={{ xs: 2 }}
                        >
                          <Grid item>
                            <Typography sx={styles.summaryLabel}>
                              Transaction ID
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.value}>
                              {receipt?.transaction_id}
                            </Typography>
                          </Grid>
                          <Grid item sx={styles.width}>
                            <Divider
                              sx={{ width: '100%', backgroundColor: cardLight }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {receipt?.ip_purchased_from && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          gap={{ xs: 2 }}
                        >
                          <Grid item>
                            <Typography sx={styles.summaryLabel}>
                              IP Purchased From
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.value}>
                              {receipt?.ip_purchased_from}
                            </Typography>
                          </Grid>
                          <Grid item sx={styles.width}>
                            <Divider
                              sx={{ width: '100%', backgroundColor: cardLight }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <Grid item>
                          <Typography sx={styles.summaryLabel}>
                            Items received
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                            gap={{ xs: 0.5 }}
                          >
                            <RivoxCoin size={18} />

                            <Grid item>
                              <Typography sx={styles.value}>
                                {numFormatter().format(receipt?.amount)} Rivox
                                Coins
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Divider
                        sx={{ width: '100%', backgroundColor: cardLight }}
                      />
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        gap={{ xs: 2 }}
                      >
                        <Grid item>
                          <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                            Amount paid
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                            {currencyFormatter().format(receipt?.amount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
            </Grid>

            <Grid item sx={styles.width}>
              <Typography sx={styles.description}>
                If you have any questions, please create a ticket in our Discord
                or send us an email at <b>support@rivox.gg</b>
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <img
                    src={`${CDN_URL}logo.svg`}
                    alt='rivox logo'
                    draggable={false}
                    style={{ width: 75 }}
                  />
                </Grid>

                <Grid item>
                  <Typography sx={styles.text}>
                    © 2024 Rivox LLC | All Rights Reserved.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.text}>
                    <span style={{ color: text, fontWeight: 800 }}>
                      support@rivox.gg
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Receipt;
