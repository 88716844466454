const Background = ({ color }) => {
  return (
    <g>
      <circle
        style={{
          fill: color,
        }}
        cx='495'
        cy='568'
        r='273'
      />
    </g>
  );
};

export default Background;
