import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { RiSwordLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import {
  doubtedBlue,
  normal,
  text,
  transition,
} from '../../utils/themeContstants';

const CurrentMatchHeader = () => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const getLeftPlacement = () => {
    if (!isDesktop) {
      return 4;
    }

    if (store.leftRailOpen) {
      return 250;
    }

    return 70;
  };

  const handleNavigate = () => {
    return navigate(`/token/${store?.user?.match_info?.match_id}`);
  };

  const styles = {
    container: {
      padding: 1,
      paddingLeft: 2,
      paddingRight: 2,
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: isTablet ? 110 : 75,
      zIndex: 4,
      left: getLeftPlacement(),
      right: isDesktop ? null : 4,
      backgroundColor: 'rgba(51, 144, 255, 0.5)',
      transition: transition,
      boxShadow: '0 6px 8px rgba(0,0,0,.1),0 0px 8px rgba(0,0,0,.1)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: doubtedBlue,
      },
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={handleNavigate}>
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RiSwordLine style={{ fontSize: 25, color: text }} />
        </Grid>

        <Grid item>
          <Typography sx={styles.label}>View your current match</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentMatchHeader;
