import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import { isDateGreaterThanCurrent } from '../../utils/helpers';
import { cardExtraLight, text } from '../../utils/themeContstants';
import UpgradeModal from '../app/UpgradeModal';
import CustomModal from '../custom/CustomModal';
import MainMenuListItem from '../custom/MainMenuListItem';
import PurchaseChatUnbanModal from '../public_profile/PurchaseChatUnbanModal';
import PurchaseUnbanModal from '../public_profile/PurchaseUnbanModal';
import ResetEarningsModal from '../public_profile/ResetEarningsModal';
import ResetStatsModal from '../public_profile/ResetStatsModal';
import BuySnipesModal from '../subscriptions/BuySnipesModal';

const MobileMainMenu = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [unbanOpen, setUnbanOpen] = useState(false);
  const [chatUnbanOpen, setChatUnbanOpen] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: text,
      paddingLeft: 1,
    },
  };

  return (
    <>
      <UpgradeModal open={premiumOpen} onClose={() => setPremiumOpen(false)} />
      <BuySnipesModal open={snipesOpen} onClose={() => setSnipesOpen(false)} />
      <ResetStatsModal open={resetOpen} onClose={() => setResetOpen(false)} />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PurchaseChatUnbanModal
        open={chatUnbanOpen}
        onClose={() => setChatUnbanOpen(false)}
      />

      <CustomModal open={open} onClose={handleClose}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          {store?.user &&
            store?.user?.account_type >= AccountTypeEnum.MODERATOR && (
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Staff</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                      >
                        <MainMenuListItem
                          title='Disputes'
                          description='See matches where both users have submitted a win.'
                          type='disputes'
                          onClick={() => {
                            navigate('/staff/disputes');
                            handleClose();
                          }}
                        />
                        {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                          <MainMenuListItem
                            title='Search'
                            description='Find information about a specific player.'
                            type='search'
                            onClick={() => {
                              navigate('/staff/search');
                              handleClose();
                            }}
                          />
                        )}
                        {(store?.user?.account_type ===
                          AccountTypeEnum.ANTICHEAT ||
                          store?.user?.account_type ===
                            AccountTypeEnum.GOD) && (
                          <MainMenuListItem
                            title='AC Dashboard'
                            description='Anti-cheat dashboard.'
                            type='AC'
                            onClick={() => {
                              navigate('/staff/ac/users');
                              handleClose();
                            }}
                          />
                        )}
                        {store?.user?.account_type >= AccountTypeEnum.GOD && (
                          <>
                            <MainMenuListItem
                              title='Withdrawals'
                              description='Withdrawals from users waiting to be sent.'
                              type='withdrawals'
                              onClick={() => {
                                navigate('/staff/withdrawals');
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title='Profit Stats'
                              description='All of the stats about the profitability of the business.'
                              type='profit'
                              onClick={() => {
                                navigate('/staff/stats');
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title='Expense Tracking'
                              description='Tracking all of the expenses of the business.'
                              type='expense'
                              onClick={() => {
                                navigate('/staff/expenses');
                                handleClose();
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: '100%', backgroundColor: cardExtraLight }}
                  />
                </Grid>
              </>
            )}

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 0.5 }}
            >
              <Grid item>
                <Typography sx={styles.header}>Compete</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <MainMenuListItem
                    title='Home'
                    description='Navigate to the home page.'
                    type='home'
                    onClick={() => {
                      navigate('/');
                      handleClose();
                    }}
                  />

                  <MainMenuListItem
                    title='Games'
                    description='Compete in your favorite tokens.'
                    type='games'
                    onClick={() => {
                      navigate('/wagers/matches');
                      handleClose();
                    }}
                  />
                  <MainMenuListItem
                    title='Tournaments'
                    description='Compete in organized tournaments.'
                    type='tournaments'
                    onClick={() => {
                      navigate('/tournaments');
                      handleClose();
                    }}
                  />
                  <MainMenuListItem
                    title='Leaderboard'
                    description='Earn your way to the top of the leaderboard.'
                    type='leaderboard'
                    onClick={() => {
                      navigate('/leaderboard');
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardExtraLight }} />
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 0.5 }}
            >
              <Grid item>
                <Typography sx={styles.header}>Shopping</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  {store?.user?.behavior_info?.has_debt && (
                    <MainMenuListItem
                      title='Unban'
                      description='Re-gain access to Rivox by purchasing an unban.'
                      type='unban'
                      onClick={() => {
                        setUnbanOpen(true);
                        handleClose();
                      }}
                    />
                  )}
                  {isDateGreaterThanCurrent(
                    new Date(
                      store?.user?.behavior_info?.match_chat_unban_timestamp
                    )
                  ) && (
                    <MainMenuListItem
                      title='Chat Unban'
                      description='Re-gain access to chatting in Rivox matches by purchasing a chat unban.'
                      type='chat-unban'
                      onClick={() => {
                        setChatUnbanOpen(true);
                        handleClose();
                      }}
                    />
                  )}
                  {!store?.user?.premium_info?.active && store?.user && (
                    <MainMenuListItem
                      title='VIP'
                      description='Upgrade to VIP and compete on Rivox at the highest level.'
                      type='premium'
                      onClick={() => {
                        setPremiumOpen(true);
                        handleClose();
                      }}
                    />
                  )}
                  <MainMenuListItem
                    title='Store'
                    description='Customize your look and shop our latest releases.'
                    type='store'
                    onClick={() => {
                      navigate('/shop/avatars');
                      handleClose();
                    }}
                  />
                  {store?.user && (
                    <>
                      <MainMenuListItem
                        title='Snipes'
                        description='View your opponent before you play them.'
                        type='snipes'
                        onClick={() => {
                          setSnipesOpen(true);
                          handleClose();
                        }}
                      />
                      <MainMenuListItem
                        title='Reset Stats'
                        description='Start from 0 by resetting your stats.'
                        type='reset'
                        onClick={() => {
                          setResetOpen(true);
                          handleClose();
                        }}
                      />
                      <MainMenuListItem
                        title='Reset Earnings'
                        description='Start from 0 by resetting your earnings.'
                        type='reset'
                        onClick={() => {
                          setResetEarningsOpen(true);
                          handleClose();
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardExtraLight }} />
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 0.5 }}
            >
              <Grid item>
                <Typography sx={styles.header}>Support</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <MainMenuListItem
                    title='Privacy Policy'
                    description='Our policy on your privacy.'
                    type='privacy'
                    onClick={() => {
                      navigate('/support/privacy');
                      handleClose();
                    }}
                  />
                  <MainMenuListItem
                    title='Terms'
                    description='Read our terms and conditions for using our platform.'
                    type='terms'
                    onClick={() => {
                      navigate('/support/terms');
                      handleClose();
                    }}
                  />
                  <MainMenuListItem
                    title='FAQ'
                    description='Questions that users frequently ask us.'
                    type='faq'
                    onClick={() => {
                      navigate('/support/faq');
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default MobileMainMenu;
