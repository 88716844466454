import { Grid, Typography, useMediaQuery } from '@mui/material';
import { normal, secondaryText, text } from '../../utils/themeContstants';

const FifaRules = ({ token }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='start'
      justifyContent='center'
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : '100%' }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MATCH RULES</Typography>
          </Grid>

          {token?.game_match_type === 'fifa_ultimate' && (
            <Grid item>
              <Typography sx={styles.nonBoldValue}>
                <li>
                  If you use loans,{' '}
                  <span style={{ fontWeight: 800 }}>
                    you forfeit the match.
                  </span>
                </li>
              </Typography>
            </Grid>
          )}

          {token?.game_match_type === 'fifa_online_friendlies' && (
            <Grid item>
              <Typography sx={styles.nonBoldValue}>
                <li>
                  You may not choose{' '}
                  <span style={{ fontWeight: 800 }}>Soccer Aid</span> or{' '}
                  <span style={{ fontWeight: 800 }}>Addidas All Stars</span> as
                  your team
                </li>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MATCH SETTINGS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Half Length: <span style={{ fontWeight: 800 }}>6 minutes</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Overtime Type:{' '}
                <span style={{ fontWeight: 800 }}>Classic/Extra Time</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Squad Type: <span style={{ fontWeight: 800 }}>Any</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Difficulty Level:{' '}
                <span style={{ fontWeight: 800 }}>Beginner</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Game Speed: <span style={{ fontWeight: 800 }}>Normal</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Injuries: <span style={{ fontWeight: 800 }}>OFF</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Offsides: <span style={{ fontWeight: 800 }}>ON</span>
              </li>
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Bookings: <span style={{ fontWeight: 800 }}>ON</span>
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>OTHER RULES</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Editing any players stats/rating will result in an automatic
                forfeit
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FifaRules;
