import { Grid, Typography, useMediaQuery } from '@mui/material';
import { normal, secondaryText, text } from '../../utils/themeContstants';

const GeneralRules = ({ game }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const styles = {
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='start'
      justifyContent='center'
      sx={{ maxWidth: isDesktop ? 275 : '100%' }}
      gap={{ xs: 2 }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>AFK/DISCONNECTION</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                You can <span style={{ fontWeight: 800 }}>NOT</span> dodge a
                match, If you cannot play, or didn't mean to join, you forfeit.
              </li>
              <li>
                If you are AFK for{' '}
                <span style={{ fontWeight: 800 }}>more than 15 minutes</span>,
                prior to first loading in, you forfeit the token.
              </li>
              <li>
                If you are AFK for{' '}
                <span style={{ fontWeight: 800 }}>
                  more than 10 minutes COMBINED
                </span>{' '}
                after the first round that all players have successfully loaded
                in,{' '}
                <span style={{ fontWeight: 800 }}>you forfeit the token.</span>
              </li>
              <li>
                If anyone on your team disconnects in the middle of the round,
                the{' '}
                {game === 'fifa' ? (
                  <span style={{ fontWeight: 800 }}>
                    match will be started with the same score before the match
                    crashed, and you will play the remaining time, PLUS extra
                    time. Whoever has the most goals wins
                  </span>
                ) : (
                  <span style={{ fontWeight: 800 }}>
                    round goes to the opposing team
                  </span>
                )}
                .
              </li>
              <li>
                Any rounds played on incorrect{' '}
                <span style={{ fontWeight: 800 }}>game or host settings </span>
                will still count.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>TOS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If TOS is said in{' '}
                <span style={{ fontWeight: 800 }}>game chat or match chat</span>
                , the player accused will be banned if there is{' '}
                <span style={{ fontWeight: 800 }}>sufficient proof</span>
              </li>
              <li>
                TOS does <span style={{ fontWeight: 800 }}>NOT</span> change the
                outcome of a round or a match.
              </li>
              <li>
                Any <span style={{ fontWeight: 800 }}>LEADS</span> given must be
                agreed upon prior to the match, with proof, preferably in an
                Rivox ticket.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralRules;
