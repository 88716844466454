import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { FaCrown } from 'react-icons/fa';
import useMiddleware from '../../api/useMiddleware';
import { purchasePremium } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  cardLight,
  cardVeryLight,
  modalShadow,
  normal,
  offWhite,
  small,
  text,
} from '../../utils/themeContstants';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import RivoxCoin from '../custom/RivoxCoin';
import TextButton from '../custom/TextButton';
import PremiumFriendModal from './PremiumFriendModal';

const ContinueUpgradeModal = ({ open, onClose, type, closeModal }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [friend, setFriend] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleClose = () => {
    setFriend(null);
    setLoading(false);
    setSearchOpen(false);
    onClose();
  };

  const handlePurchase = () => {
    setLoading(true);
    purchasePremium(
      middleware,
      friend == null ? 'regular' : 'promo',
      friend ? friend?.user_id : null
    ).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
        closeModal();
      }
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
    description: {
      fontSize: normal,
      color: offWhite,
      fontWeight: 500,
    },
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    container: {
      backgroundColor: cardLight,
      borderRadius: 1,
      width: '100%',
      padding: 2,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    leftLabel: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    rightLabel: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    price: {
      fontSize: 16,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <PremiumFriendModal
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        setFriend={setFriend}
      />

      <CustomModal
        title={'Continue to VIP'}
        description={
          'You can cancel the subscription at any time through your profile.'
        }
        open={open}
        onClose={handleClose}
      >
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item>
            <Typography sx={styles.label}>SUMMARY</Typography>
          </Grid>

          <Grid item sx={styles.container}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 2 }}
            >
              <Grid
                item
                alignSelf='center'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 3,
                  height: 40,
                  width: 40,
                  borderRadius: 1,
                  backgroundColor: '#6634D6',
                  boxShadow: modalShadow,
                }}
              >
                <FaCrown
                  style={{
                    fontSize: 20,
                    color: text,
                  }}
                />
              </Grid>

              <Grid item sx={{ maxWidth: 400 }}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.value}>
                      Rivox VIP (1 month {type === 2 && ' for you and a friend'}
                      )
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      columnGap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.description}>
                          Renews every 31 days at
                        </Typography>
                      </Grid>

                      <RivoxCoin size={14} />

                      <Grid item sx={{ marginLeft: -0.5 }}>
                        <Typography sx={styles.description}>2.99</Typography>
                      </Grid>

                      {type === 2 && (
                        <Grid item>
                          <Typography sx={styles.description}>
                            (only renews for you and not your friend)
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              {type === 2 && (
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.leftLabel}>
                        Friend to Gift
                      </Typography>
                    </Grid>

                    {friend && (
                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography
                              sx={{ ...styles.rightLabel, fontWeight: 800 }}
                            >
                              {friend?.username}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <TextButton
                              label='Edit'
                              color={offWhite}
                              icon={
                                <AiFillEdit
                                  style={{
                                    color: offWhite,
                                    fontSize: 22,
                                  }}
                                />
                              }
                              onClick={() => setSearchOpen(true)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {!friend && (
                      <Grid item>
                        <PrimaryButton
                          type='green'
                          size='small'
                          label='Choose a Friend'
                          onClick={() => setSearchOpen(true)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography sx={styles.leftLabel}>
                      Payment Method
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.rightLabel}>Rivox Wallet</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography sx={{ ...styles.rightLabel, fontWeight: 800 }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 0.5 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <RivoxCoin size={20} />
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.price}>
                          {type === 1 ? '2.99' : '4.99'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: '100%', height: 2, backgroundColor: cardVeryLight }}
            />
          </Grid>

          <Grid item sx={styles.width}>
            <PrimaryButton
              label='Buy Now'
              type='premium'
              fullWidth
              size='massive'
              onClick={handlePurchase}
              disabled={type === 2 ? !friend : false}
              loading={loading}
            />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default ContinueUpgradeModal;
