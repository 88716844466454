import React, { Suspense } from 'react';

const eyeComponents = {
  side_eye: React.lazy(() => import('./types/SideEye')),
  crying: React.lazy(() => import('./types/Crying')),
  hearts: React.lazy(() => import('./types/Hearts')),
  black_eyes: React.lazy(() => import('./types/BlackEye')),
  default: React.lazy(() => import('./types/Default')),
  smile: React.lazy(() => import('./types/Smile')),
  lashes: React.lazy(() => import('./types/Lashes')),
  anime: React.lazy(() => import('./types/Anime')),
  suspicious: React.lazy(() => import('./types/Suspicious')),
  unamused: React.lazy(() => import('./types/Unamused')),
  wink: React.lazy(() => import('./types/Wink')),
  peepers: React.lazy(() => import('./types/Peepers')),
  future_vision: React.lazy(() => import('./types/FutureVision')),
  default_happy: React.lazy(() => import('./types/DefaultHappy')),
  shaggy: React.lazy(() => import('./types/Shaggy')),
  duoma_eyes: React.lazy(() => import('./types/Duoma')),
};

const Eyes = ({ type }) => {
  const EyeComponent = eyeComponents[type];

  if (!EyeComponent) return null;

  return (
    <Suspense fallback={null}>
      <EyeComponent />
    </Suspense>
  );
};

export default Eyes;
