import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { StoreContext } from '../../store/Store';
import {
  card,
  cardVeryLight,
  doubtedBlue,
  doubtedGreen,
  doubtedOrange,
  normal,
  offWhite,
  secondaryButton,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';

const MatchReadyTeamItem = ({ team, isCreatingTeam, token }) => {
  const store = useContext(StoreContext);

  const shouldShowTeam = () => {
    if (isCreatingTeam) {
      if (token?.creating_team_ids?.includes(store?.user?._id)) {
        return true;
      }
    }

    if (!isCreatingTeam) {
      if (token?.joining_team_ids?.includes(store?.user?._id)) {
        return true;
      }
    }

    return false;
  };

  const getReadyIcon = user => {
    if (token?.readied_users?.readied[user?._id]) {
      return <BiCheck style={{ color: doubtedGreen, fontSize: 50 }} />;
    }

    return <BiX style={{ color: secondaryText, fontSize: 50 }} />;
  };

  const getTeamName = () => {
    if (isCreatingTeam) {
      if (token?.creating_team_ids?.includes(store?.user?._id)) {
        return team?.name;
      }
      return 'Opponent Team';
    }

    if (!isCreatingTeam) {
      if (token?.joining_team_ids?.includes(store?.user?._id)) {
        return team?.name;
      }
      return 'Opponent Team';
    }
  };

  const styles = {
    container: {
      border: `1px solid ${cardVeryLight}`,
      borderRadius: 2,
      flexGrow: 1,
      overflow: 'hidden',
    },
    width: {
      width: '100%',
    },
    teamName: {
      color: text,
      fontWeight: 800,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      fontSize: normal,
    },
    username: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    epic: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
    readyContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 65,
      width: 65,
      borderRadius: 100,
      backgroundColor: 'transparent',
      border: `8px solid ${secondaryButton}`,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <>
      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid
            item
            sx={{
              padding: 2,
              width: '100%',
              backgroundColor: card,
              borderBottom: `1px solid ${cardVeryLight}`,
            }}
          >
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                sx={{
                  paddingLeft: 1,
                  borderLeft: `4px solid ${isCreatingTeam ? doubtedBlue : doubtedOrange}`,
                }}
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: small, fontWeight: 600, color: offWhite }}
                  >
                    {isCreatingTeam ? 'Creating team' : 'Joining team'}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.teamName}>{getTeamName()}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ width: '100%', padding: 2 }}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              {team?.users?.map((user, i) => (
                <Grid item key={i}>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 2 }}
                  >
                    <Grid item sx={styles.readyContainer}>
                      {getReadyIcon(user)}
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={styles.username}>
                            {shouldShowTeam()
                              ? user?._id === store?.user?._id
                                ? 'Me'
                                : user?.username
                              : team?.users?.length < 2
                                ? 'Opponent'
                                : `Opponent ${i + 1}`}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              ...styles.epic,
                              color: token?.readied_users?.readied[user?._id]
                                ? doubtedGreen
                                : secondaryText,
                            }}
                          >
                            {token?.readied_users?.readied[user?._id]
                              ? 'Ready'
                              : 'Not ready'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MatchReadyTeamItem;
