import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CDN_URL } from '../../utils/constants';
import { getGameModeStringFromArray } from '../../utils/helpers';
import {
  cardDarker,
  cardVeryLight,
  large,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import CustomMenuButton from '../custom/CustomMenuButton';
import SecondaryButton from '../custom/SecondaryButton';
import BoxFightRules from './BoxFightRules';
import BuildFightRules from './BuildFightRules';
import ESLRules from './ESLRules';
import FifaRules from './FifaRules';
import GeneralRules from './GeneralRules';
import RealisticRules from './RealisticRules';
import WarzoneRules from './WarzoneRules';
import ZeroBuildRules from './ZeroBuildRules';
import ZoneWarsRules from './ZoneWarsRules';

const MatchRules = ({ token }) => {
  const discordIcon = `${CDN_URL}connections/discord-auth.svg`;

  const [selected, setSelected] = useState('game');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      if (token?.game === 'clashroyale') {
        setSelected('general');
      }
    }
  }, [token]);

  const styles = {
    container: {
      borderRadius: 2,
      width: '100%',
      border: `1px solid ${cardVeryLight}`,
    },
    top: {
      width: '100%',
      padding: 2,
      paddingBottom: 0,
      borderBottom: `1px solid ${cardVeryLight}`,
    },
    width: {
      width: '100%',
    },
    title: {
      fontWeight: 800,
      color: text,
      fontSize: large,
    },
    rulesContainer: {
      padding: 2,
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.top}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <SecondaryButton
              label='Join our Discord'
              icon={
                <img
                  src={discordIcon}
                  alt='discord-icon'
                  draggable={false}
                  height={30}
                />
              }
              fullWidth
              onClick={() => window.open('https://discord.gg/rivox')}
              bg={cardDarker}
            />

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 1 }}
              >
                {token?.game !== 'clashroyale' && (
                  <CustomMenuButton
                    label={`${getGameModeStringFromArray(
                      token?.game_mode_options?.options,
                      token?.game_mode_options?.selected_option,
                      token?.game_match_type
                    )} Rules`}
                    selected={selected === 'game'}
                    onClick={() => setSelected('game')}
                    stretch
                  />
                )}
                <CustomMenuButton
                  label='General Rules'
                  selected={selected === 'general'}
                  onClick={() => navigate('/support/rules')}
                  stretch
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.rulesContainer}>
          {token?.game_mode_options?.options?.length > 1 &&
            !token?.game_match_type &&
            !token?.game_mode_options?.selected_option &&
            selected === 'game' && (
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                sx={{ maxWidth: 275 }}
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: 11, fontWeight: 600, color: secondaryText }}
                  >
                    MATCH RULES
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={{ fontSize: normal, fontWeight: 500, color: text }}
                  >
                    <li>
                      Once an opponent selects a game mode, the match rules will
                      appear here.
                    </li>
                  </Typography>
                </Grid>
              </Grid>
            )}

          {token?.game === 'fifa' && selected === 'game' && (
            <FifaRules token={token} />
          )}

          {token?.game === 'warzone' && selected === 'game' && (
            <WarzoneRules token={token} />
          )}

          {(token?.game_match_type === 'zw' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'zw')) &&
            selected === 'game' && <ZoneWarsRules />}

          {(token?.game_match_type === 'bf' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'bf')) &&
            selected === 'game' && <BoxFightRules />}

          {(token?.game_match_type === 'real' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'real')) &&
            selected === 'game' && <RealisticRules token={token} />}

          {(token?.game_match_type === 'zbm_real' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'zbm_real')) &&
            selected === 'game' && <RealisticRules token={token} />}

          {(token?.game_match_type === 'esl_ctf' ||
            token?.game_match_type === 'esl_hp' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'esl_ctf') ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'esl_hp')) &&
            selected === 'game' && <ESLRules token={token} />}

          {(token?.game_match_type === 'zbm_bf' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'zbm_bf')) &&
            selected === 'game' && <RealisticRules token={token} />}

          {(token?.game_match_type === 'build' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'build')) &&
            selected === 'game' && <BuildFightRules match={token} />}

          {(token?.game_match_type === 'zbm' ||
            (token?.game_mode_options?.options?.length === 1 &&
              token?.game_mode_options?.options[0] === 'zbm')) &&
            selected === 'game' && <ZeroBuildRules token={token} />}

          {selected === 'general' && <GeneralRules game={token?.game} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchRules;
