import { Grid, Typography } from '@mui/material';
import {
  card,
  cardVeryLight,
  doubtedOrange,
  normal,
  offWhite,
  small,
  text,
} from '../../utils/themeContstants';
import TeamUserGlimmer from '../glimmers/TeamUserGlimmer';

const MatchWaitingTeamItem = ({ token }) => {
  const styles = {
    container: {
      border: `1px solid ${cardVeryLight}`,
      borderRadius: 2,
      flexGrow: 1,
    },
    width: {
      width: '100%',
    },
    teamName: {
      color: text,
      fontWeight: 800,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      fontSize: normal,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={{
            backgroundColor: card,
            width: '100%',
            borderBottom: `1px solid ${cardVeryLight}`,
            padding: 2,
          }}
        >
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            sx={{ borderLeft: `4px solid ${doubtedOrange}`, paddingLeft: 1 }}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: small, fontWeight: 600, color: offWhite }}
              >
                Joining team
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.teamName}>Waiting for Opponent</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ width: '100%', padding: 2 }}>
          <TeamUserGlimmer numItems={token?.team_size} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchWaitingTeamItem;
