import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { secondaryButton } from '../../utils/themeContstants';
import Hover from './Hover';

const CustomPill = ({
  label = null,
  firstTo = false,
  placement,
  hoverLabel,
  color, // New prop for the color
  glow = false, // New prop for glowing effect
}) => {
  const [hovered, setHovered] = useState(false);
  const [ref, setRef] = useState(null);

  const styles = {
    container: {
      minWidth: 40,
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.2,
      paddingBottom: 0.2,
      borderRadius: 100,
      backgroundColor: secondaryButton,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    label: {
      fontSize: 13,
      fontWeight: 700,
      color: color || '#d7e4f1',
      whiteSpace: 'nowrap',
      textShadow: glow
        ? `0 0 3px rgba(255, 255, 0, 0.5), 0 0 6px rgba(255, 255, 0, 0.5)`
        : 'none', // Apply a subtle glow effect if glow prop is true
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <Hover label={hoverLabel} placement={placement} refEl={ref} />
      )}

      <Typography sx={styles.label}>
        {label}
        {firstTo && label !== 1 && <sup>+2</sup>}
      </Typography>
    </Grid>
  );
};

export default CustomPill;
