import { Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { BsFillPatchQuestionFill } from 'react-icons/bs';
import { FaHandshake, FaPencilAlt } from 'react-icons/fa';
import { MdPrivacyTip } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SpacingWrapper from '../../utils/SpacingWrapper';
import { card, modalShadow, normal, text } from '../../utils/themeContstants';
import ListItem from '../custom/ListItem';

const Support = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const [selected, setSelected] = useState('terms');

  useEffect(() => {
    setSelected(location?.pathname?.split('/')[2]);
  }, [location]);

  const styles = {
    width: {
      width: '100%',
    },
    container: {
      width: '100%',
      minHeight: '100vh',
      // backgroundImage: `url(${background})`,
      // backgroundAttachment: "fixed",
      // backgroundSize: "cover",
    },
    rightContainer: {
      flexGrow: 1,
    },
    leftContainer: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      minWidth: isDesktop ? 250 : '100%',
    },
    icon: {
      fontSize: normal,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <SpacingWrapper>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 4 }}
            sx={{ paddingBottom: 8, maxWidth: 1200 }}
          >
            <Grid item sx={styles.container}>
              <Grid
                container
                justifyContent='start'
                alignItems='start'
                gap={{ xs: 4 }}
                wrap={isDesktop ? 'nowrap' : 'wrap'}
              >
                <Grid item sx={styles.leftContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <ListItem
                      label='Rules'
                      onClick={() => {
                        setSelected('rules');
                        navigate('/support/rules');
                      }}
                      icon={
                        <FaPencilAlt
                          style={{
                            ...styles.icon,
                            color:
                              selected === 'rules'
                                ? 'rgb(255, 255, 193)'
                                : text,
                          }}
                        />
                      }
                      selected={selected === 'rules'}
                    />
                    <ListItem
                      label='Terms of Service'
                      onClick={() => {
                        setSelected('terms');
                        navigate('/support/terms');
                      }}
                      icon={
                        <FaHandshake
                          style={{
                            ...styles.icon,
                            color:
                              selected === 'terms'
                                ? 'rgb(255, 255, 193)'
                                : text,
                          }}
                        />
                      }
                      selected={selected === 'terms'}
                    />
                    <ListItem
                      label='Privacy Policy'
                      onClick={() => {
                        setSelected('terms');
                        navigate('/support/privacy');
                      }}
                      icon={
                        <MdPrivacyTip
                          style={{
                            ...styles.icon,
                            color:
                              selected === 'privacy'
                                ? 'rgb(255, 255, 193)'
                                : text,
                          }}
                        />
                      }
                      selected={selected === 'privacy'}
                    />
                    <ListItem
                      label='FAQ'
                      onClick={() => {
                        setSelected('terms');
                        navigate('/support/faq');
                      }}
                      icon={
                        <BsFillPatchQuestionFill
                          style={{
                            ...styles.icon,
                            color:
                              selected === 'faq' ? 'rgb(255, 255, 193)' : text,
                          }}
                        />
                      }
                      selected={selected === 'faq'}
                    />
                  </Grid>
                </Grid>

                <Grid item sx={styles.rightContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 2 }}
                  >
                    <Outlet />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpacingWrapper>
      </Grid>
    </Grid>
  );
};

export default Support;
