import { Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { HiBell } from 'react-icons/hi';
import { MdAccountBalanceWallet } from 'react-icons/md';
import Avatar from '../../avatar/Avatar';
import { StoreContext } from '../../store/Store';
import { numFormatter } from '../../utils/helpers';
import {
  cardDark,
  cardExtraLight,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  lightGrey,
  text,
  transition,
} from '../../utils/themeContstants';
import CircleIconButton from '../custom/CircleIconButton';
import Hover from '../custom/Hover';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import SnipeCoin from '../custom/SnipeCoin';
import MobileWalletModal from '../mobile/wallet/MobileWalletModal';
import Notifications from '../notifications/Notifications';
import WalletMenu from '../wallet_menu/WalletMenu';
import LoginRegisterModal from './LoginRegisterModal';
import AccountMenu from './navbar/AccountMenu';
import CreateMenu from './navbar/CreateMenu';
import MainMenu from './navbar/MainMenu';
import UpgradeModal from './UpgradeModal';

const RightNavButtons = ({ loading }) => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(max-width: 768px)');

  const [loginOpen, setLoginOpen] = useState(false);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [createAnchor, setCreateAnchor] = useState(null);
  const [notiAnchor, setNotiAnchor] = useState(null);
  const [profileRef, setProfileRef] = useState(null);
  const [notiCount, setNotiCount] = useState(0);
  const [upgradeHovered, setUpgradeHovered] = useState(false);
  const [upgradeRefEl, setUpgradeRefEl] = useState(null);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [walletAnchor, setWalletAnchor] = useState(null);
  const [selected, setSelected] = useState(null);
  const [avatarHovered, setAvatarHovered] = useState(false);

  const handleProfileOpen = e => {
    e.stopPropagation();
    setProfileAnchor(e.currentTarget);
  };

  const handleProfileClose = e => {
    e?.stopPropagation();
    setProfileAnchor(null);
  };

  const handleMenuOpen = e => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = e => {
    e?.stopPropagation();
    setMenuAnchor(null);
  };

  const handleCreateOpen = e => {
    e.stopPropagation();
    setCreateAnchor(e.currentTarget);
  };

  const handleCreateClose = e => {
    e?.stopPropagation();
    setCreateAnchor(null);
  };

  const handleNotificationsOpen = e => {
    e.stopPropagation();
    setNotiAnchor(e.currentTarget);
  };

  const handleNotificationsClose = e => {
    e?.stopPropagation();
    setNotiAnchor(null);
  };

  const handleWalletOpen = e => {
    e?.stopPropagation();
    setWalletAnchor(e.currentTarget);
  };

  const handleWalletClose = e => {
    e.stopPropagation();
    setWalletAnchor(null);
  };

  useEffect(() => {
    if (store?.notiCount > 0) {
      setNotiCount(store?.notiCount);
    } else {
      setNotiCount(0);
    }
  }, [store?.notiCount]);

  useEffect(() => {
    if (store?.walletOpen) {
      if (!isDesktop) {
        return setWalletOpen(true);
      } else {
        if (!walletAnchor) {
          return setWalletAnchor(document.getElementById('wallet-anchor'));
        }
      }
    }

    setWalletAnchor(null);
    setWalletOpen(false);
  }, [store?.walletOpen]);

  useEffect(() => {
    if (notiAnchor) {
      return setSelected('notifications');
    }

    if (menuAnchor) {
      return setSelected('menu');
    }

    if (createAnchor) {
      return setSelected('create');
    }

    if (walletOpen || walletAnchor) {
      return setSelected('wallet');
    }

    return setSelected(null);
  }, [notiAnchor, menuAnchor, createAnchor, walletOpen, walletAnchor]);

  const styles = {
    container: {
      flex: isDesktop ? 0.33 : 1,
      width: '100%',
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      transition: transition,
      padding: 1,
      borderRadius: 1,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardLight,
      },
    },
    balanceContainer: {
      height: 48,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      backgroundColor: cardDark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1,
    },
    balance: {
      fontSize: 13,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <>
      <LoginRegisterModal
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
      />
      <AccountMenu anchor={profileAnchor} handleClose={handleProfileClose} />
      <Notifications
        anchor={notiAnchor}
        handleClose={handleNotificationsClose}
      />
      <UpgradeModal open={upgradeOpen} onClose={() => setUpgradeOpen(false)} />
      <MobileWalletModal
        open={walletOpen}
        onClose={() => setWalletOpen(false)}
      />
      <WalletMenu anchor={walletAnchor} handleClose={handleWalletClose} />
      <MainMenu anchor={menuAnchor} handleClose={handleMenuClose} />
      <CreateMenu anchor={createAnchor} handleClose={handleCreateClose} />

      <Grid item sx={styles.container}>
        {loading && (
          <Grid
            container
            justifyContent='end'
            alignItems='center'
            gap={{ xs: 1 }}
            wrap='nowrap'
          >
            <Grid item>
              <Skeleton
                variant='circular'
                width={40}
                height={40}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>
            <Grid item>
              <Skeleton
                variant='circular'
                width={40}
                height={40}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>
            <Grid item>
              <Skeleton
                variant='circular'
                width={40}
                height={40}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>
            <Grid item>
              <Skeleton
                variant='circular'
                width={40}
                height={40}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>
          </Grid>
        )}

        {!store?.user && !loading && (
          <Grid
            container
            justifyContent='end'
            alignItems='center'
            gap={{ xs: 2 }}
            wrap='nowrap'
          >
            <Grid item>
              <SecondaryButton
                label='Login'
                onClick={() => setLoginOpen(true)}
              />
            </Grid>

            <Grid item>
              <SecondaryButton
                label='Register'
                bg={doubtedBlue}
                onClick={() => setLoginOpen(true)}
              />
            </Grid>

            {!isTablet && (
              <CircleIconButton
                label='Menu'
                selected={selected === 'menu'}
                onClick={e => {
                  setSelected('menu');
                  handleMenuOpen(e);
                }}
              >
                <BsFillGrid3X3GapFill
                  style={{
                    color: selected === 'menu' ? doubtedBlue : lightGrey,
                    fontSize: 20,
                  }}
                />
              </CircleIconButton>
            )}
          </Grid>
        )}

        {store?.user && !loading && (
          <Grid
            container
            justifyContent='end'
            alignItems='center'
            gap={{ xs: 1 }}
            wrap='nowrap'
          >
            {/* {!isDesktop && (
              <RightNavButton
                label="Wallet"
                badgeCount={0}
                icon={<BiWallet style={{ color: text, fontSize: 24 }} />}
                onClick={() => setWalletOpen(true)}
              />
            )} */}

            {/* {!isTablet && (
              <RightNavButton
                label="Notifications"
                badgeCount={notiCount}
                icon={
                  <MdOutlineNotificationsNone
                    style={{ color: text, fontSize: 24 }}
                  />
                }
                onClick={handleNotificationsOpen}
              />
            )} */}

            {!isDesktop && (
              <CircleIconButton
                label='Create'
                selected={selected === 'create'}
                onClick={e => {
                  setSelected('create');
                  handleCreateOpen(e);
                }}
              >
                <BiPlus
                  style={{
                    color: selected === 'create' ? doubtedBlue : lightGrey,
                    fontSize: 26,
                  }}
                />
              </CircleIconButton>
            )}

            {!isTablet && (
              <CircleIconButton
                label='Menu'
                selected={selected === 'menu'}
                onClick={e => {
                  setSelected('menu');
                  handleMenuOpen(e);
                }}
              >
                <BsFillGrid3X3GapFill
                  style={{
                    color: selected === 'menu' ? doubtedBlue : lightGrey,
                    fontSize: 20,
                  }}
                />
              </CircleIconButton>
            )}

            {!isDesktop && (
              <CircleIconButton
                wallet={true}
                label='Wallet'
                selected={selected === 'wallet'}
                onClick={e => {
                  setSelected('wallet');
                  if (isDesktop) {
                    handleWalletOpen(e);
                  } else {
                    setWalletOpen(true);
                  }
                }}
                elId='wallet-anchor'
              >
                <MdAccountBalanceWallet
                  style={{
                    color: selected === 'wallet' ? doubtedBlue : lightGrey,
                    fontSize: 22,
                  }}
                />
              </CircleIconButton>
            )}

            {!isTablet && (
              <CircleIconButton
                label='Notifications'
                selected={selected === 'notifications'}
                onClick={e => {
                  setSelected('notifications');
                  handleNotificationsOpen(e);
                }}
                badgeCount={notiCount}
              >
                <HiBell
                  style={{
                    color:
                      selected === 'notifications' ? doubtedBlue : lightGrey,
                    fontSize: 22,
                  }}
                />
              </CircleIconButton>
            )}

            {/* {store?.user?.account_type >= AccountTypeEnum.MODERATOR && (
              <Grid
                item
                sx={{ ...styles.itemContainer, position: "relative" }}
                onMouseEnter={() => setHovered("staff")}
                onMouseLeave={() => setHovered(null)}
                onClick={() => navigate("/staff/disputes")}
                ref={setStaffRefEl}
              >
                {hovered === "staff" && (
                  <Hover
                    label={"Staff Dashboard"}
                    placement="bottom"
                    refEl={staffRefEl}
                  />
                )}

                <svg width={45} height={45} viewBox="0 0 50 50" fill="none">
                  <circle
                    cx="25"
                    cy="25"
                    r="23"
                    fill="none"
                    stroke={cardVeryLight}
                    strokeWidth={4}
                  />

                  <circle
                    cx="25"
                    cy="25"
                    r="23"
                    fill="none"
                    stroke={purple}
                    strokeWidth={4}
                    style={{
                      strokeDasharray: 150,
                      strokeDashoffset: hovered === "staff" ? 0 : 150,
                      transition: "all 0.4s ease-in-out",
                    }}
                  />
                </svg>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 0,
                    top: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <FaShieldAlt
                    size={22}
                    color={hovered === "staff" ? text : secondaryText}
                    style={{
                      transition: "all 0.2s ease-in-out",
                      transformOrigin: "center center",
                      transform:
                        hovered === "staff" ? "rotate(-0.1turn)" : null,
                    }}
                  />
                </div>
              </Grid>
            )}

            <Grid
              item
              sx={{ ...styles.itemContainer, position: "relative" }}
              onMouseEnter={() => setHovered("notifications")}
              onMouseLeave={() => setHovered(null)}
              onClick={handleNotificationsOpen}
              ref={setRefEl}
            >
              {hovered === "notifications" && (
                <Hover
                  label={"Notifications"}
                  placement="bottom"
                  refEl={refEl}
                />
              )}

              {hasNoti && <UnreadBadge />}

              <svg width={45} height={45} viewBox="0 0 50 50" fill="none">
                <circle
                  cx="25"
                  cy="25"
                  r="23"
                  fill="none"
                  stroke={cardVeryLight}
                  strokeWidth={4}
                />

                <circle
                  cx="25"
                  cy="25"
                  r="23"
                  fill="none"
                  stroke={purple}
                  strokeWidth={4}
                  style={{
                    strokeDasharray: 150,
                    strokeDashoffset:
                      hovered === "notifications" || notiAnchor ? 0 : 150,
                    transition: "all 0.4s ease-in-out",
                  }}
                />
              </svg>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                  top: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <IoMdNotifications
                  size={24}
                  color={
                    hovered === "notifications" || notiAnchor
                      ? text
                      : secondaryText
                  }
                  style={{
                    transition: "all 0.2s ease-in-out",
                    transformOrigin: "center center",
                    transform:
                      hovered === "notifications" ? "rotate(-0.1turn)" : null,
                  }}
                />
              </div>
            </Grid> */}

            <Grid
              item
              sx={{
                marginBottom: 1,
                marginLeft: -0.8,
                marginRight: -0.5,
                '&:hover': { cursor: 'pointer' },
                position: 'relative',
              }}
              ref={setProfileRef}
              onClick={handleProfileOpen}
              onMouseEnter={() => setAvatarHovered(true)}
              onMouseLeave={() => setAvatarHovered(false)}
            >
              {avatarHovered && (
                <Hover label='Account' placement='bottom' refEl={profileRef} />
              )}
              <Avatar
                size={95}
                avatar={store?.user?.new_avatar}
                bgColor={avatarHovered ? cardLight : cardDark}
              />
            </Grid>

            {isDesktop && (
              <>
                <Grid
                  item
                  sx={{
                    height: 30,
                    width: 1.01,
                    backgroundColor: cardExtraLight,
                  }}
                />

                <Grid item sx={{ marginLeft: 0.5 }}>
                  <Grid container justifyContent='start' alignItems='center'>
                    <Grid item sx={styles.balanceContainer}>
                      <Grid
                        container
                        direction='column'
                        justifyContent='start'
                        alignItems='start'
                        gap={{ xs: 0.5 }}
                      >
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 0.5 }}
                        >
                          <RivoxCoin size={20} />

                          <Grid item>
                            <Typography sx={styles.balance}>
                              {numFormatter(
                                store?.user?.funds?.balance === 0
                                  ? 2
                                  : store?.user?.funds?.balance < 1
                                    ? 4
                                    : 2
                              ).format(store?.user?.funds?.balance)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 0.5 }}
                          sx={{ marginLeft: 0.25 }}
                        >
                          <SnipeCoin size={17} />

                          <Grid item>
                            <Typography sx={styles.balance}>
                              {store?.user?.premium_info?.snipes}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        backgroundColor: doubtedBlue,
                        height: 48,
                        padding: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: transition,
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: '#7A3EFF',
                        },
                      }}
                      id='wallet-anchor'
                      onClick={handleWalletOpen}
                    >
                      <MdAccountBalanceWallet
                        style={{ fontSize: 24, color: text }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item sx={{ marginLeft: 0.5 }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: store?.user?.premium_info?.active
                            ? "rgb(255, 255, 193)"
                            : text,
                          textShadow: store?.user?.premium_info?.active
                            ? "rgb(255 93 0) 0px 0px 10px"
                            : null,
                        }}
                      >
                        {store?.user?.username}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 0.5 }}
                      >
                        <RivoxCoin size={12} />

                        <Grid item>
                          <Typography
                            sx={{ fontSize: 12, fontWeight: 500, color: text }}
                          >
                            {numFormatter(
                              store?.user?.funds?.balance < 1 ? 4 : 2
                            ).format(store?.user?.funds?.balance)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </>
            )}

            {/* <Grid
              item
              sx={styles.avatarContainer}
              onMouseEnter={() => setHovered("profile")}
              onMouseLeave={() => setHovered(null)}
              onClick={handleProfileOpen}
              ref={setProfileRef}
            >
              {hovered === "profile" && (
                <Hover label="Menu" placement="bottom" refEl={profileRef} />
              )}

              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Avatar
                    size={55}
                    avatar={store?.user?.new_avatar}
                    bgColor={cardDark}
                  />
                </Grid>

                {isDesktop && (
                  <>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 0.5 }}
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: normal,
                              fontWeight: 800,
                              color: store?.user?.premium_info?.active
                                ? "rgb(255, 255, 193)"
                                : text,
                              textShadow: store?.user?.premium_info?.active
                                ? "rgb(255 93 0) 0px 0px 10px"
                                : null,
                            }}
                          >
                            {store?.user?.username}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 0.5 }}
                          >
                            <RivoxCoin size={18} />

                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: small,
                                  fontWeight: 600,
                                  color: secondaryText,
                                }}
                              >
                                {numFormatter().format(
                                  store?.user?.funds?.balance
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BsCaretDownFill
                        style={{
                          fontSize: 16,
                          color: hovered === "profile" ? text : secondaryText,
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RightNavButtons;
