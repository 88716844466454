import { useContext, useEffect, useState } from 'react';
import { readyUp } from '../../api/matches';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { MatchStateEnum } from '../../utils/enums';
import { black, doubtedGreen } from '../../utils/themeContstants';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';

const ReadyUpModal = ({ open, onClose, token, setHasSeenReady }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [readyLoading, setReadyLoading] = useState(false);
  const [readyDisabled, setReadyDisabled] = useState(false);

  const handleClose = () => {
    setReadyLoading(false);
    setReadyDisabled(false);
    onClose();
  };

  const handleReadyUp = () => {
    if (token?.state === MatchStateEnum.READY) {
      setReadyLoading(true);
      readyUp(middleware, token?._id).then(res => {
        setReadyLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          setReadyDisabled(true);
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      setHasSeenReady(true);
    }
  }, [open]);

  useEffect(() => {
    setReadyDisabled(token?.readied_users?.readied[store?.user?._id]);
  }, [token]);

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title='Ready Up'
      description='You have started a new match. Ready up to start playing!'
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label={readyDisabled ? 'Ready' : 'Ready Up'}
          fullWidth
          bg={doubtedGreen}
          loading={readyLoading}
          onClick={handleReadyUp}
          disabled={readyDisabled}
          textColor={black}
        />
      }
      primaryButtonFullWidth
    ></CustomModal>
  );
};

export default ReadyUpModal;
