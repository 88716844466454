import { Divider, Grid, Typography } from '@mui/material';
import { Howl } from 'howler';
import { useContext, useState } from 'react';
import {
  getPotentialCratePieces,
  purchaseCrate,
  purchaseEmojiCrate,
  purchaseEmoteCrate,
  purchaseGodlikeCrate,
} from '../../api/avatar';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AvatarRarityEnum } from '../../utils/enums';
import { getAvatarCratePrice, numFormatter } from '../../utils/helpers';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import CrateOpeningAnimationModal from './CrateOpeningAnimationModal';

const PurchaseCrateModal = ({ type, open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [understands, setUnderstands] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animationOpen, setAnimationOpen] = useState(false);
  const [potentialItems, setPotentialItems] = useState([]);
  const [receivedItems, setReceivedItems] = useState([]);

  const handleClose = () => {
    setUnderstands(false);
    setLoading(false);
    onClose();
  };

  // Initialize the tick sound
  const purchaseSound = new Howl({
    src: [require('./purchase_sound.mp3')], // Ensure the file path is correct
    volume: 0.5,
  });

  const handlePurchase = async () => {
    setLoading(true);
    let purchaseFunction;

    switch (type) {
      case AvatarRarityEnum.GODLIKE:
        purchaseFunction = purchaseGodlikeCrate;
        break;
      case AvatarRarityEnum.EMOTE:
        purchaseFunction = purchaseEmoteCrate;
        break;
      case AvatarRarityEnum.EMOJI:
        purchaseFunction = purchaseEmojiCrate;
        break;
      default:
        purchaseFunction = purchaseCrate;
    }

    const res = await purchaseFunction(
      middleware,
      type === AvatarRarityEnum.EXOTIC ? 'drip' : 'starter'
    );

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      updateStore({ type: SET_SUCCESSES, payload: res?.message });
      const potentialRes = await getPotentialCratePieces(type); // Fetch potential items
      setPotentialItems(potentialRes?.pieces);
      setReceivedItems(res?.receivedItems); // Assuming the API returns an array of received items
      setAnimationOpen(true);
      purchaseSound.play();
      handleClose();
    }
    setLoading(false);
  };

  const getUserBalance = () => {
    if (!store?.user) {
      return 0;
    }

    return store?.user?.funds?.balance - getAvatarCratePrice(type);
  };

  const getCrateName = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 'Exotic Crate';
      case AvatarRarityEnum.RARE:
        return 'Rare Crate';
      case AvatarRarityEnum.GODLIKE:
        return 'Godlike Crate';
      case AvatarRarityEnum.EMOJI:
        return 'Emoji Crate';
      case AvatarRarityEnum.EMOTE:
        return 'Emote Crate';
      default:
        return null;
    }
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={handleClose}
        title={`Confirm Purchase for ${getCrateName()}`}
        titleSize='large'
        primaryButton={
          <SecondaryButton
            bg={doubtedBlue}
            label='Buy Now'
            loading={loading}
            disabled={!understands}
            onClick={handlePurchase}
          />
        }
        secondaryButton={
          <SecondaryButton label='Cancel' onClick={handleClose} />
        }
      >
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.width}>
            <Typography
              sx={{
                fontSize: normal,
                fontWeight: 500,
                color: text,
                maxWidth: 600,
              }}
            >
              Upon purchase, the items you unlock will be shown to you in your
              notifications. You can also view all your new items in your
              locker.
            </Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
          </Grid>

          <Grid item sx={styles.width}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography sx={styles.label}>Total</Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 0.5 }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RivoxCoin size={25} />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          {numFormatter().format(getAvatarCratePrice(type))}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 15,
                            color: red,
                            fontWeight: 600,
                            textDecoration: 'line-through',
                          }}
                        >
                          <span style={{ color: secondaryText }}>
                            {numFormatter().format(
                              getAvatarCratePrice(type) * 1.5
                            )}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography sx={styles.label}>
                  Balance After Purchase
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 0.5 }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RivoxCoin size={25} />
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {numFormatter().format(getUserBalance())}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 2 }}
            >
              <Grid>
                <CustomCheckbox
                  checked={understands}
                  onChange={value => setUnderstands(value)}
                  color={doubtedBlue}
                  disabled={loading}
                  checkColor='#fff'
                />
              </Grid>

              <Grid>
                <Typography
                  sx={{
                    fontSize: small,
                    color: offWhite,
                    fontWeight: 600,
                    maxWidth: 400,
                  }}
                >
                  I understand that the{' '}
                  <span style={{ fontWeight: 800, color: text }}>
                    {getCrateName(type)}
                  </span>
                  , is for cosmetic use only and gives no competitive advantage.
                  I also understand that I cannot refund this item once
                  purchased.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>

      {animationOpen && (
        <CrateOpeningAnimationModal
          open={animationOpen}
          onClose={() => setAnimationOpen(false)}
          potentialItems={potentialItems}
          receivedItems={receivedItems}
        />
      )}
    </>
  );
};

export default PurchaseCrateModal;
