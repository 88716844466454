import { Grid } from '@mui/material';
import { MdOutlineVideocamOff } from 'react-icons/md';
import { emptyIcon } from '../../utils/themeContstants';
import Empty from '../custom/Empty';
import TeamEvidenceItem from './TeamEvidenceItem';

const Evidence = ({ token }) => {
  const styles = {
    container: {
      flex: 1,
      width: '100%',
      maxWidth: '100%',
      overflowX: 'hidden',
      maxHeight: '100%',
      position: 'relative',
      padding: 2,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        {token?.evidence?.creating_team_links?.length < 1 &&
        token?.evidence?.joining_team_links?.length < 1 &&
        token?.evidence?.staff_links?.length < 1 ? (
          <Empty
            title='No evidence'
            label='Submit evidence and it will appear here'
            icon={<MdOutlineVideocamOff style={emptyIcon} />}
          />
        ) : (
          <>
            <TeamEvidenceItem
              isCreatingTeam={true}
              team={token?.creating_team}
              links={token?.evidence?.creating_team_links}
            />

            <TeamEvidenceItem
              isCreatingTeam={false}
              team={token?.joining_team}
              links={token?.evidence?.joining_team_links}
            />
            <TeamEvidenceItem
              isCreatingTeam={null}
              team={null}
              links={token?.evidence?.staff_links}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Evidence;
