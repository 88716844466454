import { useContext } from 'react';
import { SET_WALLET_OPEN, StoreDispatch } from '../../store/Store';
import CustomMenu from '../custom/CustomMenu';
import WalletContent from './WalletContent';

const WalletMenu = ({ anchor, handleClose }) => {
  const updateStore = useContext(StoreDispatch);

  const closeWallet = e => {
    updateStore({ type: SET_WALLET_OPEN, payload: false });
    handleClose(e);
  };

  return (
    <>
      <CustomMenu
        anchor={anchor}
        handleClose={closeWallet}
        title='Wallet'
        minWidth={360}
        horizontalOrigin='center'
      >
        <WalletContent onClose={closeWallet} />
      </CustomMenu>
    </>
  );
};

export default WalletMenu;
