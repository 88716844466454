import {
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AiFillCaretDown, AiOutlineEye } from 'react-icons/ai';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMiddleware from '../../api/useMiddleware';
import { updateAvatar } from '../../api/user';
import Avatar from '../../avatar/Avatar';
import {
  SET_ERRORS,
  SET_SEARCH_USER,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { numFormatter } from '../../utils/helpers';
import SpacingWrapper from '../../utils/SpacingWrapper';
import {
  card,
  cardDark,
  cardVeryLight,
  large,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import { useAuth } from '../../utils/useAuth';
import CustomIconButton from '../custom/CustomIconButton';
import CustomMenuButton from '../custom/CustomMenuButton';
import EditAvatarButton from '../custom/EditAvatarButton';
import RivoxCoin from '../custom/RivoxCoin';
import EditUsernameModal from './EditUsernameModal';
import MoreProfileMenu from './MoreProfileMenu';
// import { battlePassRewards } from "../shop/battlePassData/battlePassRewards";
// import { battlePassRewards } from "../shop/battlePassData/battlePassRewards";
import Countdown from 'react-countdown';
import SnipeCoin from '../custom/SnipeCoin';
import { battlePassRewards } from '../shop/battlePassData/battlePassRewards';

const Profile = () => {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading] = useAuth();
  const [selected, setSelected] = useState('teams');
  const [newAvatar, setNewAvatar] = useState(store?.user?.avatar);
  const [saveLoading, setSaveLoading] = useState(false);
  const [changeOpen, setChangeOpen] = useState(false);
  const [moreAnchor, setMoreAnchor] = useState(null);

  const handleMoreOpen = e => {
    e.stopPropagation();
    setMoreAnchor(e.currentTarget);
  };

  const handleMoreClose = e => {
    e?.stopPropagation();
    setMoreAnchor(null);
  };

  const handleOpenProfile = user_id => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleUpdateAvatar = () => {
    setSaveLoading(true);
    updateAvatar(middleware, newAvatar).then(res => {
      setSaveLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({ type: SET_USER, payload: res?.user });
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
      }
    });
  };

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span style={{ color: text }}>
        {days}d {hours}h {minutes}m {seconds}s
      </span>
    );
  };

  useEffect(() => {
    setSelected(location?.pathname?.split('/')[2]);
  }, [location]);

  useEffect(() => {
    if (store?.user) {
      setNewAvatar(store?.user?.avatar);
    }
  }, [store?.user]);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
    },
    width: {
      width: '100%',
    },
    avatarContainer: {
      padding: 1,
      width: '100%',
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      minWidth: isDesktop ? 0 : '100%',
    },
    leftContainer: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      width: '100%',
    },
    rightContainer: {
      flexGrow: 1,
    },
    icon: {
      fontSize: normal,
      color: text,
    },
    username: {
      fontSize: 28,
      color: store?.user?.premium_info?.active ? 'rgb(255, 255, 193)' : text,
      textShadow: store?.user?.premium_info?.active
        ? 'rgb(255 93 0) 0px 0px 10px'
        : null,
      fontWeight: 800,
    },
    epic: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  const calculateXPProgress = (
    currentXP,
    currentTierXP,
    nextTierXP,
    isFinalTier
  ) => {
    // console.log("Calculating XP Progress:");
    // console.log("currentXP:", currentXP);
    // console.log("currentTierXP:", currentTierXP);
    // console.log("nextTierXP:", nextTierXP);
    // console.log("isFinalTier:", isFinalTier);

    if (isFinalTier) {
      return 100;
    }

    if (nextTierXP === 0) {
      return 0;
    }

    if (isFinalTier) {
      return 100;
    }

    if (nextTierXP === 0) {
      return 0;
    }

    const progress =
      ((currentXP - currentTierXP) / (nextTierXP - currentTierXP)) * 100;
    // console.log("Calculated progress:", progress);
    return Math.min(Math.max(progress, 0), 100); // Ensure the progress is between 0 and 100
  };

  const currentTier = store?.user?.battle_pass_info?.tier || 0;
  const currentXP = store?.user?.battle_pass_info?.current_xp || 0;
  const expireTime = store?.user?.battle_pass_info?.end_date || new Date();
  const isFinalTier = currentTier >= 15;

  const currentTierXP =
    currentTier === 0
      ? 0
      : parseInt(battlePassRewards[currentTier - 1]?.xpRequired) || 0;
  const nextTierXP = isFinalTier
    ? 7500
    : parseInt(battlePassRewards[currentTier]?.xpRequired) || 0;
  const activeBattlePass =
    store?.user?.battle_pass_info?.is_active &&
    new Date(store?.user?.battle_pass_info?.end_date) > new Date();

  // console.log("Current tier:", currentTier);
  // console.log("Current XP:", currentXP);
  // console.log("Is final tier:", isFinalTier);
  // console.log("Current tier XP required:", currentTierXP);
  // console.log("Next tier XP required:", nextTierXP);

  const progressPercentage = calculateXPProgress(
    currentXP,
    currentTierXP,
    nextTierXP,
    isFinalTier
  );
  // console.log("progress %", progressPercentage);

  return loading ? null : (
    <>
      <EditUsernameModal
        open={changeOpen}
        onClose={() => setChangeOpen(false)}
      />
      <MoreProfileMenu anchor={moreAnchor} handleClose={handleMoreClose} />

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <SpacingWrapper>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 4 }}
              sx={{ paddingBottom: 8, maxWidth: 1200 }}
            >
              <Grid item sx={styles.container}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction={isDesktop ? 'row' : 'column'}
                      justifyContent={isDesktop ? 'start' : 'center'}
                      alignItems='center'
                      gap={{ xs: 2 }}
                    >
                      <Grid
                        item
                        sx={{
                          position: 'relative',
                          marginLeft: -2,
                          marginTop: -2,
                          marginRight: -2,
                        }}
                      >
                        <Avatar
                          size={isDesktop ? 250 : 300}
                          avatar={store?.user?.new_avatar}
                          bgColor={cardDark}
                        />

                        <EditAvatarButton onClick={() => navigate('/locker')} />
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                        >
                          <Grid item>
                            <Grid
                              container
                              justifyContent='start'
                              alignItems='center'
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.username}>
                                  {store?.user?.username}
                                </Typography>
                              </Grid>

                              <CustomIconButton
                                label='Edit username'
                                icon={
                                  <HiOutlinePencilAlt
                                    style={{ fontSize: 20, color: text }}
                                  />
                                }
                                onClick={() => setChangeOpen(true)}
                              />

                              <CustomIconButton
                                label='View public profile'
                                icon={
                                  <AiOutlineEye
                                    style={{ fontSize: 20, color: text }}
                                  />
                                }
                                onClick={() =>
                                  handleOpenProfile(store?.user?._id)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid
                              container
                              direction='column'
                              justifyContent='start'
                              alignItems='start'
                              gap={{ xs: 0.5 }}
                            >
                              <Grid
                                container
                                justifyContent='start'
                                alignItems='center'
                                gap={{ xs: 0.5 }}
                              >
                                <RivoxCoin size={20} />

                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: large,
                                      color: offWhite,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {numFormatter().format(
                                      store?.user?.funds?.balance
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justifyContent='start'
                                alignItems='center'
                                gap={{ xs: 0.5 }}
                              >
                                <SnipeCoin size={20} />

                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: large,
                                      color: offWhite,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {store?.user?.premium_info?.snipes}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* {activeBattlePass && (
                            <Grid item sx={{ width: "100%" }}>
                              <Grid container direction="column" alignItems="start" justifyContent="center" gap={{ xs: 0.5 }}>
                                <Grid item>
                                  <Typography sx={{ fontSize: normal, color: secondaryText, fontWeight: 600 }}>
                                    Rivox Pass Progress - {currentXP} XP
                                  </Typography>
                                </Grid>
                                <Grid item sx={{ width: "100%" }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={progressPercentage}
                                    sx={{ width: "100%", height: 10, borderRadius: 5 }}
                                  />
                                </Grid>
                                <Grid item sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                  <Typography sx={{ fontSize: small, color: secondaryText, fontWeight: 600 }}>
                                    Tier {currentTier}
                                  </Typography>
                                  <Typography sx={{ fontSize: small, color: secondaryText, fontWeight: 600 }}>
                                    {isFinalTier ? "Max Tier Achieved" : `Tier ${currentTier + 1}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )} */}

                          {activeBattlePass && (
                            <Grid item sx={{ width: '100%' }}>
                              <Grid
                                container
                                direction='column'
                                alignItems='start'
                                justifyContent='center'
                                gap={{ xs: 0.5 }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: normal,
                                      color: secondaryText,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Rivox Pass Progress - {currentXP} XP
                                  </Typography>
                                </Grid>
                                <Grid item sx={{ width: '100%' }}>
                                  <LinearProgress
                                    variant='determinate'
                                    value={progressPercentage}
                                    sx={{
                                      width: '100%',
                                      height: 10,
                                      borderRadius: 5,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: small,
                                      color: secondaryText,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Tier {currentTier}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: small,
                                      color: secondaryText,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {isFinalTier
                                      ? 'Max Tier Achieved'
                                      : `Tier ${currentTier + 1}`}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: small,
                                      color: secondaryText,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Expires In:{' '}
                                  </Typography>
                                  <Typography sx={styles.time} noWrap>
                                    <Countdown
                                      date={expireTime}
                                      renderer={renderer}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      width: '100%',
                      borderBottom: `1px solid ${cardVeryLight}`,
                      paddingBottom: 0,
                    }}
                  >
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <CustomMenuButton
                        label='Teams'
                        onClick={() => {
                          setSelected('teams');
                          navigate('/profile/teams');
                        }}
                        selected={selected === 'teams'}
                      />

                      {!small && (
                        <CustomMenuButton
                          label='Linked Accounts'
                          onClick={() => {
                            setSelected('accounts');
                            navigate('/profile/accounts');
                          }}
                          selected={selected === 'accounts'}
                        />
                      )}

                      {isDesktop && (
                        <>
                          <CustomMenuButton
                            label='Match History'
                            onClick={() => {
                              setSelected('history');
                              navigate('/profile/history');
                            }}
                            selected={selected === 'history'}
                          />

                          <CustomMenuButton
                            label='Deposits'
                            onClick={() => {
                              setSelected('deposits');
                              navigate('/profile/deposits');
                            }}
                            selected={selected === 'deposits'}
                          />

                          <CustomMenuButton
                            label='Withdrawals'
                            onClick={() => {
                              setSelected('withdrawals');
                              navigate('/profile/withdrawals');
                            }}
                            selected={selected === 'withdrawals'}
                          />

                          <CustomMenuButton
                            label='Referrals'
                            onClick={() => {
                              setSelected('referrals');
                              navigate('/profile/referrals');
                            }}
                            selected={selected === 'referrals'}
                          />
                        </>
                      )}

                      <CustomMenuButton
                        label='More'
                        onClick={handleMoreOpen}
                        icon={
                          <AiFillCaretDown
                            style={{ color: text, fontSize: 12 }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 2 }}
                    >
                      <Outlet />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item sx={styles.container}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="start"
                  gap={{ xs: 2 }}
                  wrap={isDesktop ? "nowrap" : "wrap"}
                >
                  <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                    <Grid
                      container
                      direction="column"
                      alignItems={isDesktop ? "start" : "center"}
                      justifyContent="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid
                        item
                        sx={{
                          position: "relative",
                          marginLeft: -2,
                          marginTop: -2,
                        }}
                      >
                        <Avatar
                          size={isDesktop ? 275 : 300}
                          avatar={store?.user?.new_avatar}
                          bgColor={cardDark}
                        />

                        <EditAvatarButton onClick={() => navigate("/locker")} />
                      </Grid>

                      <Grid item sx={styles.avatarContainer}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          sx={{
                            padding: 2,
                            backgroundColor: cardLight,
                            borderRadius: 1,
                            width: "100%",
                          }}
                        >
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                              gap={{ xs: 2 }}
                            >
                              <Grid item>
                                <Typography sx={styles.username}>
                                  {store?.user?.username}
                                </Typography>
                              </Grid>

                              <Grid item sx={styles.width}>
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap={{ xs: 1 }}
                                >
                                  <SecondaryButton
                                    label="Edit Username"
                                    fullWidth
                                    size="small"
                                    onClick={() => setChangeOpen(true)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sx={{ width: "100%" }}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.width}>
                            <SecondaryButton
                              type="secondary"
                              label="View Public Profile"
                              fullWidth
                              onClick={() =>
                                handleOpenProfile(store?.user?._id)
                              }
                            />
                          </Grid>

                          <Grid item sx={{ width: "100%" }}>
                            <SecondaryButton
                              type="secondary"
                              label="View My Locker"
                              fullWidth
                              onClick={() => navigate("/locker")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {store?.user?.avatar !== newAvatar && (
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item sx={styles.width}>
                              <PrimaryButton
                                label="cancel"
                                size="small"
                                onClick={() =>
                                  setNewAvatar(store?.user?.avatar)
                                }
                                fullWidth
                              />
                            </Grid>

                            <Grid item sx={styles.width}>
                              <PrimaryButton
                                type="green"
                                label="save"
                                size="small"
                                fullWidth
                                loading={saveLoading}
                                onClick={handleUpdateAvatar}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item sx={styles.leftContainer}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <ListItem
                            label="Teams"
                            icon={
                              <FaUsers
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "teams"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("teams");
                              navigate("/profile/teams");
                            }}
                            selected={selected === "teams"}
                          />
                          <ListItem
                            label="Linked Accounts"
                            icon={
                              <IoMdLink
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "accounts"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("accounts");
                              navigate("/profile/accounts");
                            }}
                            selected={selected === "accounts"}
                          />
                          <ListItem
                            label="Token History"
                            icon={
                              <FaHistory
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "history"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("history");
                              navigate("/profile/history");
                            }}
                            selected={selected === "history"}
                          />
                          <ListItem
                            label="Deposits"
                            icon={
                              <FaCoins
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "deposits"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("deposits");
                              navigate("/profile/deposits");
                            }}
                            selected={selected === "deposits"}
                          />
                          <ListItem
                            label="Withdrawals"
                            icon={
                              <FaCoins
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "withdrawals"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("withdrawals");
                              navigate("/profile/withdrawals");
                            }}
                            selected={selected === "withdrawals"}
                          />
                          <ListItem
                            label="Tips"
                            icon={
                              <FaExchangeAlt
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "tips"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("tips");
                              navigate("/profile/tips");
                            }}
                            selected={selected === "tips"}
                          />
                          <ListItem
                            label="Purchases"
                            icon={
                              <BiPurchaseTag
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "purchases"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("purchases");
                              navigate("/profile/purchases");
                            }}
                            selected={selected === "purchases"}
                          />

                          <ListItem
                            label="Subscriptions"
                            icon={
                              <FaCrown
                                style={{
                                  ...styles.icon,
                                  color:
                                    selected === "subscriptions"
                                      ? "rgb(255, 255, 193)"
                                      : text,
                                }}
                              />
                            }
                            onClick={() => {
                              setSelected("subscriptions");
                              navigate("/profile/subscriptions");
                            }}
                            selected={selected === "subscriptions"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.rightContainer}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 2 }}
                    >
                      <Outlet />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
