import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { deleteLogs, getACFlags } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';

import { getReadableDateString, getTime } from '../../utils/helpers';
import { FLAGS_LIMIT } from '../../utils/limits';
import {
  card,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import NotSelected from '../custom/NotSelected';
import LogGlimmer from '../glimmers/LogGlimmer';

const UserACFlags = ({ user, onClick }) => {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetLogs = () => {
    getACFlags(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.logs?.length >= FLAGS_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setLogs(res?.logs); // Replace existing logs
      }
    });
  };

  const handleDeleteLogs = log => {
    deleteLogs(middleware, log).then(res => {
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setLogs([]); // clear the logs state
        handleGetLogs(); // Refetch the logs
      }
    });
  };

  usePagination(bottom, () => setSkip(logs?.length));

  useEffect(() => {
    if (logs == null) {
      handleGetLogs();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetLogs();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!logs || logs?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO LOGS' />
        </Grid>
      )}

      {!loading && logs && logs?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 0.5 }}
          >
            {logs?.map((log, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: '100%',
                  backgroundColor: card,
                  boxShadow: modalShadow,
                  transition: transition,
                  '&:hover': {
                    cursor: 'pointer',
                    transform: 'translateY(-5px)',
                  },
                }}
                key={i}
                onClick={() => {
                  navigate('/staff/search', {
                    state: {
                      user_id: log?.user_id,
                    },
                  });
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    width: '100%',
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>
                      {getReadableDateString(new Date(log?.timestamp)) +
                        ' at ' +
                        getTime(new Date(log?.timestamp))}
                    </Typography>

                    <Typography
                      sx={{ fontSize: normal, color: text, fontWeight: 600 }}
                    >
                      {log?.log}
                      {log?.count > 1 && (
                        <span
                          style={{
                            color:
                              log?.count > 5
                                ? 'red'
                                : log?.count > 3
                                  ? 'yellow'
                                  : 'green',
                          }}
                        >
                          {` (${log?.count}x)`}
                        </span>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation();
                        handleDeleteLogs(log?.log);
                      }}
                    >
                      <FiTrash2 />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserACFlags;
