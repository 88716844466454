import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  doubtedBlue,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';

const BoxFightRules = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='start'
      justifyContent='center'
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : '100%' }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MAP</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <span style={{ color: doubtedBlue }}>2724-8626-1374</span>{' '}
              <span style={{ color: text, fontWeight: 500 }}>
                (Token Boxfights)
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>SHOTGUN</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>Gold Pumps</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>TEAMS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Host is <span style={{ fontWeight: 700 }}>Team 1</span>
              </li>
              <li>
                Non-Host is <span style={{ fontWeight: 700 }}>Team 2</span>
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoxFightRules;
