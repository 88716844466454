import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { secondaryText, small } from '../../utils/themeContstants';
import DepositItem from './DepositItem';
import PayPalDepositModal from './PayPalDepositModal';

const WalletDeposit = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(null);

  const styles = {
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: 'center',
      maxWidth: 400,
    },
    width: {
      width: '100%',
    },
    container: {
      width: '100%',
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: 'center',
      maxWidth: 400,
    },
  };

  const handleOpenPayPal = depoAmount => {
    setAmount(depoAmount);
    setOpen(true);
  };

  return (
    <>
      <PayPalDepositModal
        open={open}
        onClose={() => setOpen(false)}
        amount={amount}
      />

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={{ xs: 3 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <DepositItem amount={5} onClick={() => handleOpenPayPal(5)} />
                  <DepositItem
                    amount={10}
                    onClick={() => handleOpenPayPal(10)}
                  />
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <DepositItem
                    amount={25}
                    onClick={() => handleOpenPayPal(25)}
                  />
                  <DepositItem
                    amount={50}
                    onClick={() => handleOpenPayPal(50)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography sx={styles.meta}>
              Funds will not be available for withdrawal immediately once you
              deposit. Any funds won through playing Tokens will become
              available to withdraw.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WalletDeposit;
