import { CircularProgress, Divider, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getACRecentActivity } from '../../../api/staff';
import useMiddleware from '../../../api/useMiddleware';
import { SET_ERRORS, StoreDispatch } from '../../../store/Store';
import { secondaryText, text } from '../../../utils/themeContstants';
import OutlineButton from '../../custom/OutlineButton';
import ActivityItem from './ActivityItem';

const ACActivity = () => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [activityLoading, setActivityLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [activity, setActivity] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const handleGetACActivity = () => {
    setActivityLoading(true);
    getACRecentActivity(middleware, skip).then(res => {
      setLoading(false);
      setActivityLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.activity?.length >= 9) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }

      setActivity(activity ? [...activity, ...res?.activity] : res?.activity);
    });
  };

  useEffect(() => {
    if (loading) {
      if (activity == null) {
        handleGetACActivity();
        return;
      }
    }
  }, [loading, activity]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetACActivity();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && activity?.length > 0 && (
        <Grid item sx={styles.width}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 2fr))',
              gap: 12,
            }}
          >
            {activity?.map((activityItem, i) => (
              <ActivityItem activityItem={activityItem} key={i} />
            ))}
          </div>

          {hasMore && (
            <Grid item sx={{ width: '100%', marginTop: 1 }}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={{ flexGrow: 1 }}>
                  <Divider
                    sx={{
                      width: '100%',
                      backgroundColor: text,
                      opacity: 0.1,
                    }}
                  />
                </Grid>

                <Grid item>
                  <OutlineButton
                    label='Show more'
                    onClick={() => {
                      setSkip(activity?.length);
                    }}
                    loading={activityLoading}
                  />
                </Grid>

                <Grid item sx={{ flexGrow: 1 }}>
                  <Divider
                    sx={{
                      width: '100%',
                      backgroundColor: text,
                      opacity: 0.1,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default ACActivity;
