import { Grid, Menu, Typography } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import {
  card,
  cardLight,
  doubtedMenuShadow,
  text,
} from '../../utils/themeContstants';
import CustomIconButton from './CustomIconButton';

const CustomMenu = ({
  anchor,
  handleClose,
  children,
  horizontalOrigin = 'right',
  verticalOrigin = 'bottom',
  minWidth = null,
  title = null,
  rightAddOn,
  leftAddOn,
}) => {
  const styles = {
    container: {
      borderRadius: 6,
      boxShadow: doubtedMenuShadow,
      backgroundColor: card,
      minWidth,
      maxWidth: 350,
      marginTop: 6,
      maxHeight: 897,
    },
    header: {
      fontSize: 15,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      PaperProps={{ style: styles.container }}
      anchorOrigin={{ vertical: verticalOrigin, horizontal: horizontalOrigin }}
      transformOrigin={{ vertical: 'top', horizontal: horizontalOrigin }}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
      >
        {title && (
          <Grid
            item
            sx={{
              width: '100%',
              boxShadow: title
                ? '0 1px 2px rgba(0,0,0,.9),0 0px 2px rgba(0,0,0,.9)'
                : null,
              marginTop: title ? -1 : 0,
              padding: title ? 1.5 : 0,
              backgroundColor: cardLight,
            }}
          >
            <Grid container justifyContent='start' alignItems='center'>
              <Grid item sx={{ marginRight: 'auto' }}>
                <Typography sx={styles.header}>{title}</Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  {leftAddOn && <>{leftAddOn}</>}
                  {rightAddOn && <>{rightAddOn}</>}
                  <CustomIconButton
                    onClick={handleClose}
                    icon={<IoClose style={{ color: text, fontSize: 20 }} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
          {children}
        </Grid>
      </Grid>
    </Menu>
  );
};

export default CustomMenu;
