import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLIENT_URL } from '../../utils/constants';
import {
  getReadableDateString,
  getTime,
  numFormatter,
} from '../../utils/helpers';
import {
  black,
  card,
  cardLight,
  cardVeryLight,
  green,
  normal,
  offWhite,
  red,
  secondaryButton,
  secondaryText,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';

const TransactionItem = ({ transaction }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);

  const getAmount = () => {
    if (
      transaction?.type !== 'rivoxpoints' &&
      transaction?.type !== 'rrcoinssellix' &&
      transaction?.status !== 'canceled'
    ) {
      return (
        <Grid item>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 0.5 }}
          >
            <RivoxCoin size={18} />
            <Grid item>
              <Typography sx={{ ...styles.value, color: red }}>
                - {numFormatter().format(transaction?.amount_prior_tax)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 0.5 }}
        >
          <RivoxCoin size={18} />
          <Grid item>
            <Typography sx={{ ...styles.value, color: green }}>
              +{' '}
              {transaction?.status === 'canceled'
                ? numFormatter().format(transaction?.amount_prior_tax)
                : numFormatter().format(transaction?.amount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getStatusColor = () => {
    if (transaction?.type === 'rivoxpoints') {
      return secondaryButton;
    }

    switch (transaction?.status) {
      case 'pending':
        return yellow;
      case 'PENDING':
        return yellow;
      case 'canceled':
      case 'completed':
        return secondaryButton;
      case 'failed':
        return red;
      default:
        return null;
    }
  };

  const getStatusTextColor = () => {
    if (transaction?.type === 'rivoxpoints') {
      return secondaryText;
    }

    switch (transaction?.status) {
      case 'pending':
        return black;
      case 'canceled':
      case 'COMPLETED':
        return secondaryText;
      case 'completed':
        return secondaryText;
      case 'failed':
        return black;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      width: '100%',
      transition: transition,
      border: `1px solid ${cardVeryLight}`,
      backgroundColor: card,
      '&:hover': {
        cursor: transaction?.receipt_id ? 'pointer' : 'default',
        backgroundColor: transaction?.receipt_id ? cardLight : card,
      },
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    resultText: {
      fontSize: 12,
      color: getStatusTextColor(),
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    status: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      backgroundColor: getStatusColor(),
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (transaction?.receipt_id) {
          window.open(`${CLIENT_URL}/receipt/${transaction?.receipt_id}`);
        } else {
          return;
        }
      }}
    >
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={styles.label}>Amount</Typography>
            </Grid>

            {getAmount()}
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Status</Typography>
                </Grid>

                <Grid item sx={styles.status}>
                  <Typography sx={styles.resultText}>
                    {transaction?.status
                      ? transaction?.status?.toUpperCase()
                      : 'COMPLETED'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {transaction?.custom_id !== 'default' &&
              transaction?.custom_id?.length <= 17 && (
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    <Grid item>
                      <Typography sx={styles.label}>Transaction id</Typography>
                    </Grid>

                    <Grid item sx={styles.status}>
                      <Typography sx={styles.resultText}>
                        {transaction?.custom_id
                          ? transaction?.custom_id?.toUpperCase()
                          : 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {/* {transaction?.type !== "rivoxpoints" && ( */}
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Payment ID</Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={styles.value}
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.textToCopy);
                    }}
                  >
                    {transaction?.note
                      ? transaction?.note
                      : transaction?.custom_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* )} */}
          </>
        )}
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={{ ...styles.label, textAlign: 'right' }}>
                Type
              </Typography>
            </Grid>
            <Grid item sx={styles.status}>
              <Typography sx={styles.resultText}>
                {transaction?.payment_method?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item alignSelf='end'>
              <Typography sx={{ ...styles.label, textAlign: 'right' }}>
                Date
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {getReadableDateString(new Date(transaction?.date)) +
                  ' at ' +
                  getTime(new Date(transaction?.date))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransactionItem;
