import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { submitResults } from '../../api/matches';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreDispatch } from '../../store/Store';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  small,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';
import SubmitLossModal from './SubmitLossModal';

const SubmitResultsModal = ({ open, onClose, token }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [submitLoading, setSubmitLoading] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);

  const handleClose = () => {
    setSubmitLoading(null);
    setCanSubmit(false);
    setAreYouSureOpen(false);
    onClose();
  };

  const handleSubmit = result => {
    setSubmitLoading(result);
    submitResults(middleware, result, token?._id).then(res => {
      setSubmitLoading(false);
      setCanSubmit(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setAreYouSureOpen(false);
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <SubmitLossModal
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        submitLoading={submitLoading}
        submitLoss={() => handleSubmit('loss')}
      />

      <CustomModal
        open={open}
        onClose={handleClose}
        title={`Submit Match Results`}
        titleSize='large'
        description='Submit results to conclude the match.'
        primaryButton={
          <SecondaryButton
            bg={doubtedBlue}
            label='My Team Won'
            loading={submitLoading === 'win'}
            disabled={submitLoading === 'loss' || !canSubmit}
            onClick={() => handleSubmit('win')}
          />
        }
        secondaryButton={
          <SecondaryButton
            label='My Team Lost'
            disabled={submitLoading === 'win' || !canSubmit}
            loading={submitLoading === 'loss'}
            onClick={() => setAreYouSureOpen(true)}
          />
        }
      >
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.description}>
              You are about to submit the results for the entire match
            </Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 2 }}
            >
              <Grid>
                <CustomCheckbox
                  checked={canSubmit}
                  onChange={value => setCanSubmit(value)}
                  color={doubtedBlue}
                  disabled={submitLoading}
                  checkColor={text}
                />
              </Grid>

              <Grid>
                <Typography
                  sx={{
                    fontSize: small,
                    color: offWhite,
                    fontWeight: 600,
                    maxWidth: 400,
                  }}
                >
                  I understand that the submission of incorrect results, or
                  failure to follow rules will result in my account being
                  banned.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default SubmitResultsModal;
