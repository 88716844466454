import React, { Suspense } from 'react';

const tattooComponents = {
  neck_tattoo: React.lazy(() => import('./types/NeckTattoo')),
  revenge_face: React.lazy(() => import('./types/RevengeFace')),
  uzi_diamond: React.lazy(() => import('./types/Uzi')),
  tear_drop: React.lazy(() => import('./types/TearDrop')),
  anime_scar: React.lazy(() => import('./types/AnimeScar')),
  savage_sword: React.lazy(() => import('./types/IssaKnife')),
  kiss_tattoo: React.lazy(() => import('./types/KissTattoo')),
  yuji_tattoo: React.lazy(() => import('./types/Yuji')),
};

const Tattoo = ({ type }) => {
  const TattooComponent = tattooComponents[type];

  if (!TattooComponent) return null;

  return (
    <Suspense fallback={null}>
      <TattooComponent />
    </Suspense>
  );
};

export default Tattoo;
