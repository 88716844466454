import { Button, CircularProgress, Grid } from '@mui/material';
import {
  normal,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';

const SecondaryButton = ({
  label = null,
  loading = false,
  disabled = false,
  onClick = () => {},
  fullWidth = false,
  size = 'large',
  type = null,
  bg = secondaryButton,
  icon = null,
  textColor = text,
  borderRadius = 1.5,
}) => {
  const styles = {
    container: {
      fontFamily: 'Nunito Sans',
      opacity: disabled || loading ? 0.3 : 1,
      padding: 2,
      paddingLeft: size === 'small' ? 1 : 2,
      paddingRight: size === 'small' ? 1 : 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      transition: transition,
      border: 'none',
      letterSpacing: 0.5,
      textTransform: 'none',
      height: size === 'small' ? 24 : size === 'massive' ? 48 : 40,
      borderRadius: borderRadius,
      fontWeight: 800,
      minWidth: fullWidth ? '100%' : 0,
      fontSize: size === 'massive' ? normal : size === 'small' ? 13 : 14,
      color: type === 'gold' ? 'rgb(255, 255, 193)' : textColor,
      textShadow: type === 'gold' ? 'rgb(255 93 0) 0px 0px 10px' : null,
      backgroundColor: bg,
      boxShadow: 'none',
      '&:hover': {
        cursor: disabled || loading ? 'not-allowed' : 'pointer',
        backgroundColor: bg,
        filter: loading || disabled ? null : 'brightness(115%)',
        boxShadow: 'none',
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Button
      variant='contained'
      size='large'
      sx={styles.container}
      onClick={e => {
        if (loading || disabled) return;

        onClick(e);
      }}
    >
      {loading && (
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={20} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          {icon && (
            <Grid item sx={styles.icon}>
              {icon}
            </Grid>
          )}

          {label}
        </Grid>
      )}
    </Button>
  );
};

export default SecondaryButton;
