import { Grid } from '@mui/material';
import { CDN_URL } from '../../utils/constants';

const RivoxLogo = ({ size }) => {
  const Logo = `${CDN_URL}logo.svg`;

  return (
    <Grid
      item
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img draggable={false} height={size} width={size} src={Logo} />
      {/* <img
        src={`${CDN_URL}christmas/santa_hat.png`}
        alt="Santa Hat"
        style={{
          position: "absolute",
          top: -13,
          right: -35,
          width: "90%",
          pointerEvents: "none",
        }}
      /> */}
    </Grid>
  );
};

export default RivoxLogo;
