import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { renewTokens } from './auth';

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const saveAccessToken = token => {
  localStorage.setItem('accessToken', token);
};

export const saveRefreshToken = token => {
  localStorage.setItem('refreshToken', token);
};

const useMiddleware = (token = null) => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    async config => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      } else {
        const localToken = getAccessToken();

        if (localToken) {
          const decoded = jwt_decode(localToken);
          if (Date.now() >= decoded?.exp * 1000) {
            const refreshToken = getRefreshToken();
            // renew token
            await renewTokens(refreshToken).then(res => {
              if (res?.error) {
                return Promise.reject(config);
              }

              saveAccessToken(res?.accessToken);
              saveRefreshToken(res?.refreshToken);
              config.headers.Authorization = `Bearer ${res?.accessToken}`;
            });
          } else {
            config.headers.Authorization = `Bearer ${localToken}`;
          }
        }

        return config;
      }
    },
    error => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useMiddleware;
