import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { addExpense } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { cardLight, offWhite, small } from '../../utils/themeContstants';
import CurrencyInput from '../custom/CurrencyInput';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const AddExpenseModal = ({ open, onClose, user }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [type, setType] = useState(null);
  const [filteredDescriptionOptions, setFilteredDescriptionOptions] =
    useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paidTo, setPaidTo] = useState(null);
  const [description, setDescription] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAmount('');
    setPaidTo('');
    setPaymentMethod('');
    setDescription('');
    setType('');
    setLoading(false);
    onClose();
  };

  const styles = {
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    width: {
      width: '100%',
    },
  };
  let descriptionOptionsFiltered1 = [
    { title: 'Admin Expense', value: 'Administrator Services' },
    { title: 'Marketing', value: 'Marketing Expense' },
    { title: 'MongoDB Bill', value: 'MongoDB Expense' },
    { title: 'Heroku Bill', value: 'Heroku Expense' },
    { title: 'Debt Payment', value: 'Debt Payment' },
    { title: 'Graphics Payment', value: 'Graphics Payment' },
    { title: 'Mod Of The Week', value: 'Mod Of The Week' },
    { title: 'Other', value: 'Other' },
  ];
  let descriptionOptionsFiltered2 = [
    { title: 'Owner Payout', value: 'Owner Payout' },
    { title: 'Other Payout', value: 'Other' },
  ];
  let typeOptions = [
    { title: 'Expense', value: 'expense' },
    { title: 'Payout', value: 'payout' },
    { title: 'User Pool Payout', value: 'user_pool_payout' },
    // { title: "Withdrawal To Bank", value: "withdrawal" },
  ];
  let paymentMethodOptions = [
    { title: 'Bank Account', value: 'bank' },
    { title: 'PayPal', value: 'paypal' },
    { title: 'Cash App', value: 'cashapp' },
    { title: 'Venmo', value: 'venmo' },
  ];
  const handleAddExpense = () => {
    setLoading(true);

    addExpense(
      middleware,
      type,
      paymentMethod,
      paidTo,
      description,
      parseFloat(amount)
    ).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });

        handleClose();
      }
    });
  };
  useEffect(() => {
    if (type && type == 'payout') {
      setFilteredDescriptionOptions(descriptionOptionsFiltered2);
    } else {
      setFilteredDescriptionOptions(descriptionOptionsFiltered1);
    }
  }, [type]);

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Add An Expense Or Payout`}
      primaryButton={
        <PrimaryButton
          label='Add Expense / Payout'
          disabled={amount === '' || amount == null || amount <= 0}
          loading={loading}
          onClick={handleAddExpense}
        />
      }
      secondaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.label}>EXPENSE TYPE</Typography>
        </Grid>
        <CustomDropdown
          options={typeOptions}
          placeholder='Expense Type'
          onChange={value => {
            setType(value);
            setDescription('');
          }}
        />

        <Grid item>
          <Typography sx={styles.label}>DESCRIPTION</Typography>
        </Grid>
        <CustomDropdown
          options={filteredDescriptionOptions}
          placeholder='Description'
          onChange={value => {
            setDescription(value);
          }}
        />
        <Grid item>
          <Typography sx={styles.label}>PAYMENT METHOD</Typography>
        </Grid>
        <CustomDropdown
          options={paymentMethodOptions}
          placeholder='Payment Method'
          onChange={value => setPaymentMethod(value)}
        />

        <Grid item>
          <Typography sx={styles.label}>PAID TO</Typography>
        </Grid>
        <Grid item sx={styles.width}>
          <CustomInput onChange={setPaidTo} value={paidTo} />
        </Grid>

        <Grid item>
          <Typography sx={styles.label}>AMOUNT PAID</Typography>
        </Grid>
        <Grid item sx={styles.width}>
          <CurrencyInput
            value={amount}
            onChange={value => setAmount(value)}
            autoFocus={true}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddExpenseModal;
