import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  getReadableDateString,
  getTime,
  numFormatter,
} from '../../utils/helpers';
import {
  card,
  cardVeryLight,
  green,
  normal,
  offWhite,
  red,
  text,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';

const TipItem = ({ tip, user }) => {
  const isTablet = useMediaQuery('(max-width: 768px)');

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: '100%',
      backgroundColor: card,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
      >
        {tip?.user_tipped === user?.username && (
          <>
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Amount</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 0.5 }}
                  >
                    <RivoxCoin size={18} />
                    <Grid item>
                      <Typography sx={{ ...styles.value, color: green }}>
                        +{numFormatter().format(tip?.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!isTablet && (
              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.label}>Received from</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {tip?.user_tipping}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {tip?.user_tipping === user?.username && (
          <>
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Amount</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 0.5 }}
                  >
                    <RivoxCoin size={18} />
                    <Grid item>
                      <Typography sx={{ ...styles.value, color: red }}>
                        -{numFormatter().format(tip?.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!isTablet && (
              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.label}>Sent to</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {tip?.user_tipped}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='end'
            justifyContent='center'
          >
            <Grid item alignSelf='end'>
              <Typography sx={{ ...styles.label, textAlign: 'right' }}>
                Date
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {!isTablet ? (
                  <>
                    {getReadableDateString(new Date(tip?.timestamp)) +
                      ' at ' +
                      getTime(new Date(tip?.timestamp))}
                  </>
                ) : (
                  <>{getReadableDateString(new Date(tip?.timestamp))}</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TipItem;
