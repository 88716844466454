import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { MdCheck, MdStar } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getStaffStatsListUsers, setSalary } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import Avatar from '../../avatar/Avatar';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import { getRole } from '../../utils/helpers';
import {
  card,
  cardLight,
  container,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import CustomInput from '../custom/CustomInput';

const CustomSelect = styled(Select)({
  backgroundColor: cardLight,
  height: '32px',
  fontSize: '14px',
  color: 'white',
  border: '1px solid ' + cardLight,
});

const CustomMenuProps = {
  PaperProps: {
    style: {
      backgroundColor: cardLight,
      color: 'white',
    },
  },
};

const StaffStats = () => {
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [chartData, setChartData] = useState({});
  const [globalChartView, setGlobalChartView] = useState('monthly');
  const [salaryInputs, setSalaryInputs] = useState({});
  const [loadingSalaries, setLoadingSalaries] = useState({});
  const [modifiedSalaries, setModifiedSalaries] = useState({});
  const [entireBudget, setEntireBudget] = useState(0);
  const [entireTotalActions, setEntireTotalActions] = useState(0);
  const [sortBy, setSortBy] = useState('totalActions');

  const handleGetStaffStatsListUsers = () => {
    getStaffStatsListUsers(middleware).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setUsers(res?.users.map(user => ({ ...user, chartView: 'monthly' })));
      }
    });
  };

  useEffect(() => {
    if (users == null) {
      handleGetStaffStatsListUsers();
    }
  }, []);

  useEffect(() => {
    if (users) {
      const newChartData = {};
      users.forEach(user => {
        newChartData[user._id] = prepareChartData(
          user.chartView === 'daily'
            ? user.dailyActions
            : user.chartView === 'weekly'
              ? user.weeklyActions
              : user.chartView === 'monthly'
                ? user.monthlyActions
                : [],
          user.chartView
        );
      });
      setChartData(newChartData);
      updateEntireStats(users, globalChartView);
    }
  }, [users, globalChartView]);

  const prepareChartData = (actions, view) => {
    if (!actions || !view) return [];

    const data = [];
    const addDataPoint = (key, type, count) => {
      const existing = data.find(d => d.date === key);
      if (existing) {
        existing[type] = (existing[type] || 0) + count;
      } else {
        const newEntry = { date: key };
        newEntry[type] = count;
        data.push(newEntry);
      }
    };

    const currentDate = moment().format('YYYY-MM-DD');

    if (view === 'daily') {
      actions
        .filter(action => action.day === currentDate)
        .forEach(action => {
          Object.keys(action.actions).forEach(type => {
            action.actions[type].forEach(act => {
              addDataPoint(act.hour, type, act.count);
            });
          });
        });

      // Ensure every hour of the day is represented
      for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        if (!data.find(d => d.date === hour)) {
          data.push({ date: hour });
        }
      }
      data.sort((a, b) => parseInt(a.date) - parseInt(b.date));
    } else if (view === 'weekly') {
      const startOfWeek = moment().startOf('isoWeek');
      const endOfWeek = moment().endOf('isoWeek');
      const weekDates = [];

      while (startOfWeek.isBefore(endOfWeek)) {
        weekDates.push(startOfWeek.format('YYYY-MM-DD'));
        startOfWeek.add(1, 'day');
      }

      actions.forEach(action => {
        if (weekDates.includes(action.day)) {
          Object.keys(action.actions).forEach(type => {
            action.actions[type].forEach(act => {
              addDataPoint(action.day, type, act.count);
            });
          });
        }
      });

      weekDates.forEach(date => {
        if (!data.find(d => d.date === date)) {
          data.push({ date });
        }
      });
      data.sort((a, b) => new Date(a.date) - new Date(b.date));
    } else if (view === 'monthly') {
      const startOfMonth = moment().startOf('month');
      const endOfMonth = moment().endOf('month');
      const monthDates = [];

      while (
        startOfMonth.isBefore(endOfMonth) ||
        startOfMonth.isSame(endOfMonth, 'day')
      ) {
        monthDates.push(startOfMonth.format('YYYY-MM-DD'));
        startOfMonth.add(1, 'day');
      }

      actions.forEach(action => {
        if (monthDates.includes(action.day)) {
          Object.keys(action.actions).forEach(type => {
            action.actions[type].forEach(act => {
              addDataPoint(action.day, type, act.count);
            });
          });
        }
      });

      monthDates.forEach(date => {
        if (!data.find(d => d.date === date)) {
          data.push({ date });
        }
      });
      data.sort((a, b) => new Date(a.date) - new Date(b.date));
    } else {
      console.error('Unknown view:', view);
    }

    return data;
  };

  const calculateTotalActions = (actions, view) => {
    let total = 0;
    const currentDate = moment().format('YYYY-MM-DD');

    actions.forEach(action => {
      if (view === 'daily' && action.day !== currentDate) {
        return; // Skip actions from days other than today
      }

      Object.keys(action.actions).forEach(type => {
        action.actions[type].forEach(act => {
          total += act.count;
        });
      });
    });

    return total;
  };

  const calculateContributionPercentage = (userActions, allUsers, view) => {
    const totalActions = calculateTotalActions(userActions, view);
    const allActions = allUsers.reduce((total, user) => {
      const userActionsCount = calculateTotalActions(
        view === 'daily'
          ? user.dailyActions
          : view === 'weekly'
            ? user.weeklyActions
            : user.monthlyActions,
        view
      );
      return total + userActionsCount;
    }, 0);

    return allActions ? (totalActions / allActions) * 100 : 0;
  };

  const calculateBudgetPercentage = (salary, totalBudget) => {
    return totalBudget ? (salary / totalBudget) * 100 : 0;
  };

  const calculateContributionVsBudgetRatio = (
    userActions,
    allUsers,
    view,
    salary,
    totalBudget
  ) => {
    const contributionPercentage = calculateContributionPercentage(
      userActions,
      allUsers,
      view
    );
    const budgetPercentage = calculateBudgetPercentage(salary, totalBudget);
    return budgetPercentage ? contributionPercentage / budgetPercentage : 0;
  };

  const updateEntireStats = (users, view) => {
    const totalBudget = users.reduce(
      (total, user) => total + (user.salary || 0),
      0
    );
    const totalActions = users.reduce(
      (total, user) =>
        total +
        calculateTotalActions(
          view === 'daily'
            ? user.dailyActions
            : view === 'weekly'
              ? user.weeklyActions
              : user.monthlyActions,
          view
        ),
      0
    );

    setEntireBudget(totalBudget);
    setEntireTotalActions(totalActions);
  };

  const sortUsersByCriteria = (users, view, criteria) => {
    return users.sort((a, b) => {
      let valueA, valueB;
      switch (criteria) {
        case 'contribution':
          valueA = calculateContributionPercentage(
            view === 'daily'
              ? a.dailyActions
              : view === 'weekly'
                ? a.weeklyActions
                : a.monthlyActions,
            users,
            view
          );
          valueB = calculateContributionPercentage(
            view === 'daily'
              ? b.dailyActions
              : view === 'weekly'
                ? b.weeklyActions
                : b.monthlyActions,
            users,
            view
          );
          break;
        case 'budgetVsContribution':
          valueA = calculateContributionVsBudgetRatio(
            view === 'daily'
              ? a.dailyActions
              : view === 'weekly'
                ? a.weeklyActions
                : a.monthlyActions,
            users,
            view,
            a.salary || 0,
            entireBudget
          );
          valueB = calculateContributionVsBudgetRatio(
            view === 'daily'
              ? b.dailyActions
              : view === 'weekly'
                ? b.weeklyActions
                : b.monthlyActions,
            users,
            view,
            b.salary || 0,
            entireBudget
          );
          break;
        case 'totalActions':
        default:
          valueA = calculateTotalActions(
            view === 'daily'
              ? a.dailyActions
              : view === 'weekly'
                ? a.weeklyActions
                : a.monthlyActions,
            view
          );
          valueB = calculateTotalActions(
            view === 'daily'
              ? b.dailyActions
              : view === 'weekly'
                ? b.weeklyActions
                : b.monthlyActions,
            view
          );
      }
      return valueB - valueA;
    });
  };

  const handleGlobalChartViewChange = event => {
    const newView = event.target.value;
    setGlobalChartView(newView);
    setSortBy('totalActions'); // Reset sort by to "Total Actions" when changing the global chart view
    let updatedUsers = users.map(user => ({
      ...user,
      chartView: newView,
    }));
    updatedUsers = sortUsersByCriteria(updatedUsers, newView, 'totalActions');
    setUsers(updatedUsers);
    updateEntireStats(updatedUsers, newView);
  };

  const handleSortByChange = event => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    let sortedUsers = sortUsersByCriteria(users, globalChartView, newSortBy);
    setUsers(sortedUsers);
  };

  const handleIndividualChartViewChange = (event, index) => {
    const newView = event.target.value;
    const newUsers = [...users];
    newUsers[index] = {
      ...newUsers[index],
      chartView: newView,
    };
    setUsers(newUsers);
  };

  const handleSalaryChange = (value, userId) => {
    const newSalaryInputs = { ...salaryInputs, [userId]: value };
    const newModifiedSalaries = { ...modifiedSalaries, [userId]: true };
    setSalaryInputs(newSalaryInputs);
    setModifiedSalaries(newModifiedSalaries);
  };

  const handleSaveSalary = async userId => {
    const amount = salaryInputs[userId];
    if (!amount) {
      updateStore({ type: SET_ERRORS, payload: 'Please set a salary amount.' });
      return;
    }

    const newLoadingSalaries = { ...loadingSalaries, [userId]: true };
    setLoadingSalaries(newLoadingSalaries);

    const res = await setSalary(middleware, userId, amount);
    if (res.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      updateStore({ type: SET_SUCCESSES, payload: res?.message });
    }

    const updatedLoadingSalaries = { ...loadingSalaries, [userId]: false };
    const updatedModifiedSalaries = { ...modifiedSalaries, [userId]: false };
    setLoadingSalaries(updatedLoadingSalaries);
    setModifiedSalaries(updatedModifiedSalaries);
  };

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    userContainer: {
      width: '100%',
      padding: 1,
      borderRadius: 2,
      transition: transition,
      backgroundColor: card,
      // "&:hover": {
      //   cursor: "pointer",
      //   backgroundColor: card,
      // },
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <MdStar style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>STAFF STATS</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          justifyContent='start'
          alignItems='start'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontSize: '18px', color: text, fontWeight: 700 }}>
              Entire Budget: ${entireBudget}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: '18px', color: text, fontWeight: 700 }}>
              Entire Total Actions: {entireTotalActions} ({globalChartView})
            </Typography>
          </Grid>
          <Grid item sx={{ marginRight: 'auto' }}>
            <Typography sx={{ fontSize: '18px', color: text, fontWeight: 700 }}>
              Date Range
            </Typography>
            <CustomSelect
              value={globalChartView}
              onChange={handleGlobalChartViewChange}
              MenuProps={CustomMenuProps}
            >
              <MenuItem value='daily'>Daily</MenuItem>
              <MenuItem value='weekly'>Weekly</MenuItem>
              <MenuItem value='monthly'>Monthly</MenuItem>
            </CustomSelect>
          </Grid>
          <Grid item sx={{ marginRight: 'auto' }}>
            <Typography sx={{ fontSize: '18px', color: text, fontWeight: 700 }}>
              Sort By
            </Typography>
            <CustomSelect
              value={sortBy}
              onChange={handleSortByChange}
              MenuProps={CustomMenuProps}
            >
              <MenuItem value='totalActions'>Contribution</MenuItem>
              {/* <MenuItem value="contribution">Contribution %</MenuItem> */}
              <MenuItem value='budgetVsContribution'>
                Budget vs Contribution Ratio
              </MenuItem>
            </CustomSelect>
          </Grid>
        </Grid>
      </Grid>

      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && users && users.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {users.map((user, i) => (
              <Grid item sx={styles.userContainer} key={i}>
                <Grid item>
                  <Grid container justifyContent='start' alignItems='center'>
                    <Grid item>
                      <Avatar
                        size={75}
                        bgColor={container}
                        avatar={user.new_avatar}
                      />
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: text,
                              fontWeight: 800,
                            }}
                          >
                            {user.username} - {getRole(user.account_type)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: text,
                                fontWeight: 500,
                              }}
                            >
                              Total Actions:{' '}
                              {calculateTotalActions(
                                user.chartView === 'daily'
                                  ? user.dailyActions
                                  : user.chartView === 'weekly'
                                    ? user.weeklyActions
                                    : user.monthlyActions,
                                user.chartView
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: text,
                                fontWeight: 500,
                              }}
                            >
                              Contribution:{' '}
                              {calculateContributionPercentage(
                                user.chartView === 'daily'
                                  ? user.dailyActions
                                  : user.chartView === 'weekly'
                                    ? user.weeklyActions
                                    : user.monthlyActions,
                                users,
                                user.chartView
                              ).toFixed(2)}
                              %
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: text,
                                fontWeight: 500,
                              }}
                            >
                              % of Budget:{' '}
                              {calculateBudgetPercentage(
                                user.salary || 0,
                                entireBudget
                              ).toFixed(2)}
                              %
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: text,
                                fontWeight: 500,
                              }}
                            >
                              Contribution vs Budget Ratio:{' '}
                              {calculateContributionVsBudgetRatio(
                                user.chartView === 'daily'
                                  ? user.dailyActions
                                  : user.chartView === 'weekly'
                                    ? user.weeklyActions
                                    : user.monthlyActions,
                                users,
                                user.chartView,
                                user.salary || 0,
                                entireBudget
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      {store?.user?.account_type >=
                        AccountTypeEnum.SENIOR_ADMIN && (
                        <>
                          {loadingSalaries[user._id] ? (
                            <CircularProgress
                              size={24}
                              sx={{ color: secondaryText, marginRight: '4px' }}
                            />
                          ) : (
                            <MdCheck
                              style={{
                                fontSize: 24,
                                color: modifiedSalaries[user._id]
                                  ? 'green'
                                  : 'gray',
                                cursor: modifiedSalaries[user._id]
                                  ? 'pointer'
                                  : 'not-allowed',
                                marginRight: '4px',
                              }}
                              onClick={() => handleSaveSalary(user._id)}
                              disabled={!modifiedSalaries[user._id]}
                            />
                          )}
                          <CustomInput
                            value={salaryInputs[user._id] || ''}
                            placeholder={user.salary || 'Unpaid Discord Mod'}
                            onChange={value =>
                              handleSalaryChange(value, user._id)
                            }
                            type='number'
                            width={10}
                            height={35}
                          />
                        </>
                      )}
                      <CustomSelect
                        value={user.chartView}
                        onChange={e => handleIndividualChartViewChange(e, i)}
                        MenuProps={CustomMenuProps}
                      >
                        <MenuItem value='daily'>Daily</MenuItem>
                        <MenuItem value='weekly'>Weekly</MenuItem>
                        <MenuItem value='monthly'>Monthly</MenuItem>
                      </CustomSelect>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={chartData[user._id] || []}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <Tooltip />
                      <Legend
                        layout='horizontal'
                        align='right'
                        verticalAlign='top'
                      />
                      <Bar dataKey='punish' fill='#ff9999' barSize={20} />
                      <Bar dataKey='reset' fill='#99ccff' barSize={20} />
                      <Bar dataKey='forcewin' fill='#99ff99' barSize={20} />
                      <Bar dataKey='cancel' fill='#ffcc99' barSize={20} />
                      <XAxis
                        dataKey='date'
                        tickFormatter={tick =>
                          user.chartView === 'daily'
                            ? tick
                            : moment(tick).format(
                                user.chartView === 'weekly' ? 'ddd' : 'MMM D'
                              )
                        }
                      />
                      <YAxis
                        tickFormatter={tick =>
                          Number.isInteger(tick) ? tick : ''
                        }
                        allowDecimals={false}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StaffStats;
