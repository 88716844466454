import { Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import {
  cardVeryLight,
  doubtedOrange,
  normal,
  offWhite,
  small,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';

const SubmitLossModal = ({ open, onClose, submitLoss, submitLoading }) => {
  const [canSubmit, setCanSubmit] = useState(false);

  const handleClose = () => {
    setCanSubmit(false);
    onClose();
  };

  const handleSubmit = () => {
    submitLoss();
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title='Submit Loss'
      description='Submit loss and give opponent the win?'
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label='My Team Lost'
          loading={submitLoading}
          onClick={handleSubmit}
          bg={doubtedOrange}
          disabled={!canSubmit}
        />
      }
      secondaryButton={<SecondaryButton label='Cancel' onClick={onClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item>
          <Typography sx={styles.description}>
            You are about to submit your loss and give your opponent the win
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={canSubmit}
                onChange={value => setCanSubmit(value)}
                color={doubtedOrange}
                disabled={submitLoading}
                checkColor={text}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: offWhite,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                By checking this box you understand that you are about to give
                your opponent the win
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default SubmitLossModal;
