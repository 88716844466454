import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { getUserPurchasesAdmin } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { getReadableDateString, getReadableTime } from '../../utils/helpers';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import {
  card,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import NotSelected from '../custom/NotSelected';
import LogGlimmer from '../glimmers/LogGlimmer';

const UserPurchases = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetPurchases = () => {
    getUserPurchasesAdmin(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.purchases?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setPurchases(
          purchases ? [...purchases, ...res?.purchases] : res?.purchases
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(purchases?.length));

  useEffect(() => {
    if (purchases == null) {
      handleGetPurchases();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetPurchases();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!purchases || purchases?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO purchases' />
        </Grid>
      )}

      {!loading && purchases && purchases?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 0.5 }}
          >
            {purchases?.map((purchase, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: '100%',
                  backgroundColor: card,
                  boxShadow: modalShadow,
                  transition: transition,
                  '&:hover': {
                    cursor: 'pointer',
                    transform: 'translateY(-5px)',
                  },
                }}
                key={i}
              >
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  sx={{
                    width: '100%',
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  {/* <Grid item>
                    <Typography sx={styles.label}>
                      {getNotiTimestamp(new Date(tip?.timestamp))}
                    </Typography>
                  </Grid> */}
                  <Typography sx={styles.label}>
                    {getReadableDateString(new Date(purchase?.timestamp)) +
                      ' at ' +
                      getReadableTime(new Date(purchase?.timestamp))}
                  </Typography>
                  {purchase?.type && (
                    <Typography
                      sx={{ fontSize: normal, color: text, fontWeight: 600 }}
                    >
                      {`${user?.username} bought ${purchase?.type} for $${purchase?.price} `}
                    </Typography>
                  )}
                  {purchase?.piece_name && (
                    <Typography
                      sx={{ fontSize: normal, color: text, fontWeight: 600 }}
                    >
                      {`${user?.username} bought ${purchase?.piece_name} for $${purchase?.price} `}
                    </Typography>
                  )}

                  <Grid item></Grid>
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserPurchases;
