import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  FaGamepad,
  FaMapMarkerAlt,
  FaRegClock,
  FaUserFriends,
} from 'react-icons/fa';
import { GiPodium } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../avatar/Avatar';
import { CDN_URL } from '../../utils/constants';
import { getMatchTypeImage } from '../../utils/helpers';
import { gameModeOptions } from '../../utils/options';
import {
  arcteryx,
  backgroundOpacity,
  cardVeryLight,
  doubtedBlue,
  green,
  linearGradientToTop,
  offWhite,
  red,
  secondaryText,
  text,
  yellow,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';

const TournamentCountdown = ({ startTime }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const start = new Date(startTime);
      const diffInSeconds = Math.floor((start - now) / 1000);

      if (diffInSeconds <= 0) {
        setHasStarted(true);
        return;
      }

      if (diffInSeconds < 60) {
        // Less than a minute, show seconds
        setTimeLeft(`Tournament Starts in ${diffInSeconds}s`);
      } else if (diffInSeconds < 3600) {
        // Less than an hour, show minutes
        const minutes = Math.floor(diffInSeconds / 60);
        setTimeLeft(`Tournament Starts in ${minutes}m`);
      } else if (diffInSeconds < 86400) {
        // Less than a day, show hours
        const hours = Math.floor(diffInSeconds / 3600);
        setTimeLeft(`Tournament Starts in ${hours}h`);
      } else {
        // More than a day, show days
        const days = Math.floor(diffInSeconds / 86400);
        setTimeLeft(`Tournament Starts in ${days}d`);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  if (hasStarted) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        backgroundColor: 'rgba(26, 44, 57, 0.9)',
        padding: '6px 12px',
        borderRadius: '12px',
        border: `1px solid ${doubtedBlue}50`,
        color: arcteryx,
        fontSize: 13,
        fontWeight: 600,
        width: 'fit-content',
        boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
        backdropFilter: 'blur(5px)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      <FaRegClock size={13} />
      {timeLeft}
    </Box>
  );
};

const RegistrationCountdown = ({ registrationTime }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const registration = new Date(registrationTime);
      const diffInSeconds = Math.floor((registration - now) / 1000);

      if (diffInSeconds <= 0) {
        setHasStarted(true);
        return;
      }

      if (diffInSeconds < 60) {
        setTimeLeft(`Registration Opens in ${diffInSeconds}s`);
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        setTimeLeft(`Registration Opens in ${minutes}m`);
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        setTimeLeft(`Registration Opens in ${hours}h`);
      } else {
        const days = Math.floor(diffInSeconds / 86400);
        setTimeLeft(`Registration Opens in ${days}d`);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [registrationTime]);

  if (hasStarted) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        backgroundColor: 'rgba(26, 44, 57, 0.9)',
        padding: '6px 12px',
        borderRadius: '12px',
        border: `1px solid ${doubtedBlue}50`,
        color: arcteryx,
        fontSize: 13,
        fontWeight: 600,
        width: 'fit-content',
        boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
        backdropFilter: 'blur(5px)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      <FaRegClock size={13} />
      {timeLeft}
    </Box>
  );
};

const TournamentCard = ({ tournament }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const isTablet = useMediaQuery('(max-width:768px)');

  const rivoxLogo = `${CDN_URL}rivox-logo.svg`;
  const faxutyLogo = `${CDN_URL}faxuty.png`;

  const getStatusColor = status => {
    switch (status) {
      case 'REGISTRATION':
      case 'PENDING':
        return yellow;
      case 'IN_PROGRESS':
        return green;
      case 'COMPLETED':
        return red;
      default:
        return offWhite;
    }
  };

  const getStatusLabel = status => {
    switch (status) {
      case 'PENDING':
      case 'REGISTRATION':
        if (tournament.registration_opens_at) {
          return `Registration Opens ${format(new Date(tournament.registration_opens_at), "MMM d 'at' h:mma")}`;
        }
        if (tournament.is_locked) {
          return 'Qualified Players Only';
        }
        return 'Registration Open';
      case 'IN_PROGRESS':
        return 'Tournament Live';
      case 'COMPLETED':
        return 'Tournament Ended';
      default:
        return status;
    }
  };

  const styles = {
    container: {
      width: '100%',
      maxWidth: '720px',
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
      borderRadius: 2.5,
      padding: isTablet ? 1 : 1.5,
      cursor: 'pointer',
      transition: 'all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1)',
      transform: isHovered ? 'translateY(-4px)' : 'none',
      boxShadow: isHovered
        ? `0 12px 28px rgba(0,0,0,0.4), 0 2px 8px rgba(102, 52, 214, 0.2)`
        : '0 4px 12px rgba(0,0,0,0.15)',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '5px',
        background: tournament.name.toLowerCase().includes('finals')
          ? `linear-gradient(90deg, ${red}cc, ${red})`
          : tournament.name.toLowerCase().includes('opens')
            ? `linear-gradient(90deg, #3498dbcc, #3498db)`
            : `linear-gradient(90deg, ${doubtedBlue}cc, ${doubtedBlue})`,
        transition: 'all 0.3s ease',
        opacity: isHovered ? 1 : 0.8,
      },
    },
    card: {
      background: linearGradientToTop(
        backgroundOpacity(0.98),
        backgroundOpacity(0.94)
      ),
      padding: isTablet ? '18px' : '24px',
      borderRadius: 1.5,
      height: isTablet
        ? tournament.status === 'PENDING'
          ? '660px'
          : '590px'
        : tournament.status === 'PENDING'
          ? '540px'
          : '500px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1,
      backdropFilter: 'blur(12px)',
      border: '1px solid rgba(255,255,255,0.04)',
      overflow: 'hidden',
    },
    title: {
      fontSize: isTablet ? 20 : 22,
      fontWeight: 800,
      color: '#FFFFFF',
      marginBottom: 1,
      zIndex: 2,
      letterSpacing: '0.02em',
      transition: 'color 0.3s ease',
      textShadow: isHovered ? '0 0 15px rgba(255,255,255,0.3)' : 'none',
    },
    meta: {
      fontSize: 13,
      fontWeight: 500,
      color: secondaryText,
    },
    prizePool: {
      fontSize: isTablet ? 24 : 28,
      fontWeight: 'bold',
      color: yellow,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'left',
      marginTop: isTablet ? 1.5 : 0.5,
      zIndex: 2,
      textShadow: '0 2px 8px rgba(252, 231, 28, 0.3)',
    },
    entryFee: {
      fontSize: isTablet ? 16 : 18,
      fontWeight: 'bold',
      color: offWhite,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'left',
      marginTop: 1,
      zIndex: 2,
    },
    infoGrid: {
      display: 'grid',
      gridTemplateColumns: isTablet ? '1fr' : 'repeat(2, 1fr)',
      gap: 1.5,
      zIndex: 2,
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1.2,
      color: secondaryText,
      fontSize: isTablet ? 13 : 14,
      padding: '14px 16px',
      borderRadius: 1.5,
      backgroundColor: 'rgba(255,255,255,0.03)',
      border: '1px solid rgba(255,255,255,0.05)',
      transition: 'all 0.25s ease',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.06)',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        borderColor: 'rgba(255,255,255,0.1)',
      },
    },
    infoText: {
      color: text,
      fontWeight: 600,
      fontSize: isTablet ? 13 : 14,
    },
    avatarWrapper: {
      width: isTablet ? 30 : 34,
      height: isTablet ? 30 : 34,
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: cardVeryLight,
      border: `2px solid ${doubtedBlue}60`,
      transition: 'all 0.2s ease',
      marginLeft: '-6px',
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 10,
        boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
        border: `2px solid ${doubtedBlue}`,
      },
    },
    playerCount: {
      fontSize: isTablet ? 12 : 13,
      fontWeight: 600,
      color: text,
      marginLeft: '4px',
      backgroundColor: 'rgba(255,255,255,0.07)',
      padding: '6px 10px',
      borderRadius: '16px',
      border: `1px solid ${doubtedBlue}50`,
      boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
    },
    statusIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: getStatusColor(tournament.status),
      fontSize: 13,
      fontWeight: 600,
      background: `rgba(${tournament.status === 'IN_PROGRESS' ? '114, 242, 56, 0.1' : tournament.status === 'COMPLETED' ? '245, 74, 74, 0.1' : '252, 231, 28, 0.1'})`,
      padding: '6px 12px',
      borderRadius: '12px',
      width: 'fit-content',
      border: `1px solid ${getStatusColor(tournament.status)}40`,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      gap: 1,
      marginTop: 'auto',
      paddingTop: 2,
    },
  };

  // Calculate if we need to show countdown elements
  const hasCountdowns =
    (tournament.registration_opens_at &&
      tournament.status === 'PENDING' &&
      !tournament.is_locked) ||
    (tournament.start_time && tournament.status === 'PENDING');

  // Calculate countdown section height based on tournament state
  const countdownHeight = hasCountdowns
    ? tournament.registration_opens_at && tournament.start_time
      ? '80px'
      : '40px'
    : '0px';

  return (
    <Grid
      item
      sx={styles.container}
      onClick={() => navigate(`/tournaments/${tournament._id}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Paper sx={styles.card} elevation={0}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 1,
            minHeight: isTablet ? '80px' : '100px',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography sx={styles.title}>{tournament.name}</Typography>
              {tournament.name.toLowerCase().includes('qualifiers') ||
                (tournament.name.toLowerCase().includes('finals') && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      ml: 2,
                      background: 'rgba(0,0,0,0.2)',
                      padding: '6px 10px',
                      borderRadius: '12px',
                      border: '1px solid rgba(255,255,255,0.05)',
                      alignContent: 'right',
                    }}
                  >
                    <img
                      src={rivoxLogo}
                      style={{
                        width: '58px',
                        height: '58px',
                        objectFit: 'contain',
                      }}
                      alt='Rivox'
                    />
                    {/* <Typography
                    sx={{
                      color: secondaryText,
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                  >
                    X
                  </Typography>
                  <img
                    src={faxutyLogo}
                    style={{
                      width: '66px',
                      height: '66px',
                      objectFit: 'contain',
                    }}
                    alt='Faxuty'
                  /> */}
                  </Box>
                ))}
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}
            >
              <Box sx={styles.statusIndicator}>
                {tournament.status === 'IN_PROGRESS' ? (
                  <div
                    className='live-green'
                    style={{ transform: 'scale(1.2)' }}
                  />
                ) : (
                  <GiPodium size={isTablet ? 14 : 16} />
                )}
                {getStatusLabel(tournament.status)}
              </Box>
            </Box>
          </Box>
        </Box>

        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(26, 44, 57, 0.2) 0%, rgba(26, 44, 57, 0.5) 50%, rgb(26, 44, 57) 100%), url(${getMatchTypeImage(
              tournament?.game_match_type,
              tournament?.game,
              tournament?.game_mode_options
            )})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            width: '100%',
            borderRadius: '8px 8px 8px 8px',
            top: 0,
            left: 0,
            right: 0,
            height: isTablet ? '150px' : '180px',
            zIndex: 0,
            opacity: isHovered ? 0.5 : 0.35,
            transition: 'opacity 0.3s ease',
            pointerEvents: 'none',
            filter: 'blur(1px)',
          }}
        />

        {/* Upper section with fixed height to ensure consistent positioning */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: isTablet ? '160px' : '180px',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Box sx={styles.prizePool}>
            <RivoxCoin size={isTablet ? 28 : 32} />
            {tournament.prize_pool.toFixed(2)}
          </Box>

          {tournament.entry_fee > 0 && (
            <Box sx={styles.entryFee}>
              <RivoxCoin size={isTablet ? 18 : 22} />
              <Typography
                component='span'
                sx={{
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  color: secondaryText,
                }}
              >
                Entry Fee:{' '}
                <span
                  style={{
                    color: offWhite,
                    marginLeft: '8px',
                    fontWeight: 700,
                  }}
                >
                  {tournament.entry_fee.toFixed(2)}
                </span>
              </Typography>
            </Box>
          )}

          {/* Countdowns in the fixed-height container */}
          {hasCountdowns && (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              {tournament.registration_opens_at &&
                tournament.status === 'PENDING' &&
                !tournament.is_locked && (
                  <RegistrationCountdown
                    registrationTime={tournament.registration_opens_at}
                  />
                )}
              {tournament.start_time && tournament.status === 'PENDING' && (
                <TournamentCountdown startTime={tournament.start_time} />
              )}
            </Box>
          )}
        </Box>

        {/* Info grid section now positioned at consistent height */}
        <Box sx={{ ...styles.infoGrid, marginTop: 2 }}>
          <Box sx={styles.infoContainer}>
            <FaUserFriends size={18} color={arcteryx} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.registered_teams.length}/{tournament.max_players}
              </Typography>
              <Typography sx={styles.meta}>Teams</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaGamepad size={18} color={arcteryx} />
            <Box>
              <Typography sx={styles.infoText}>
                {
                  gameModeOptions.find(
                    option => option.value === tournament.game_match_type
                  )?.title
                }
              </Typography>
              <Typography sx={styles.meta}>Mode</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaMapMarkerAlt size={18} color={arcteryx} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.region.toUpperCase()}
              </Typography>
              <Typography sx={styles.meta}>Region</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaUserFriends size={18} color={arcteryx} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.team_size}v{tournament.team_size}
              </Typography>
              <Typography sx={styles.meta}>Team Size</Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          sx={{ my: 2, borderColor: `${doubtedBlue}30`, opacity: 0.7 }}
        />

        {/* Flexible spacer */}
        <Box sx={{ flex: 1 }} />

        {/* Avatar container always at the bottom of the card */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: 0,
            pl: 1,
            position: 'relative',
            paddingBottom: 1,
            height: '40px',
            marginTop: 'auto',
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: -10,
              left: 0,
              right: 0,
              height: '1px',
              background: `linear-gradient(to right, transparent, ${doubtedBlue}40, transparent)`,
              opacity: 0.5,
            },
          }}
        >
          {tournament.registered_players
            .slice(0, isTablet ? 3 : 4) // Reduce number of avatars shown
            .map((player, index) => (
              <Box
                key={index}
                sx={{
                  ...styles.avatarWrapper,
                  width: isTablet ? 28 : 32, // Slightly smaller avatars
                  height: isTablet ? 28 : 32,
                  zIndex: 20 - index,
                }}
              >
                <Avatar
                  size={isTablet ? 28 : 32}
                  avatar={player.new_avatar}
                  bgColor={cardVeryLight}
                />
              </Box>
            ))}
          {tournament.registered_teams.length * tournament.team_size >
            (isTablet ? 3 : 4) && (
            <Typography
              sx={{
                ...styles.playerCount,
                fontSize: isTablet ? 11 : 12,
                padding: '3px 7px',
                marginLeft: '4px',
              }}
            >
              +
              {tournament.registered_teams.length * tournament.team_size -
                (isTablet ? 3 : 4)}
            </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default TournamentCard;
