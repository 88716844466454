import { Grid, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { StoreContext } from '../store/Store';

const SpacingWrapper = ({ children, maxWidth = 1200 }) => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  const getPadding = () => {
    if (store.leftRailOpen) {
      if (isDesktop) {
        return 4;
      }
      return 2;
    } else {
      return isDesktop ? 4 : 2;
    }
  };

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      id='spacing-wrapper'
      sx={{
        paddingLeft: getPadding(),
        paddingRight: getPadding(),
        paddingTop: 4,
        width: '100%',
        maxWidth,
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Grid>
  );
};

export default SpacingWrapper;
