import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { punishUser } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import {
  cardLight,
  cardVeryLight,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
  yellow,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';

const PunishModal = ({ open, onClose, user }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [canPunish, setCanPunish] = useState(false);
  const [punishment, setPunishment] = useState({ type: null, points: null });
  const [punishLoading, setPunishLoading] = useState(false);

  const handleClose = () => {
    setCanPunish(false);
    setPunishLoading(false);
    setPunishment(null);
    onClose();
  };

  const getPunPointOptions = () => {
    const seniorOptions = [
      {
        id: 'toxicity_25',
        title: 'Toxicity  ( -25 )',
        type: 'toxicity',
        points: -25,
      },
      {
        id: 'toxicity_50',
        title: 'Toxicity  ( -50 )',
        type: 'toxicity',
        points: -50,
      },
      {
        id: 'false_mark_25',
        title: 'False Mark / Stalling ( -25 )',
        type: 'false_mark',
        points: -25,
      },
      {
        id: 'false_mark_75',
        title: 'False Mark / Stalling ( -75 )',
        type: 'false_mark',
        points: -75,
      },
    ];
    const adminOptions = [
      {
        id: 'toxicity_25',
        title: 'Toxicity  ( -25 )',
        type: 'toxicity',
        points: -25,
      },
      {
        id: 'toxicity_50',
        title: 'Toxicity  ( -50 )',
        type: 'toxicity',
        points: -50,
      },
      {
        id: 'toxicity_150',
        title: 'Toxicity  ( -150 )',
        type: 'toxicity',
        points: -150,
      },
      {
        id: 'false_mark_25',
        title: 'False Mark / Stalling ( -25 )',
        type: 'false_mark',
        points: -25,
      },
      {
        id: 'false_mark_75',
        title: 'False Mark / Stalling ( -75 )',
        type: 'false_mark',
        points: -75,
      },
      {
        id: 'false_mark_150',
        title: 'False Mark / Stalling ( -150 )',
        type: 'false_mark',
        points: -150,
      },
    ];

    const regularOptions = [
      {
        id: 'toxicity_25',
        title: 'Toxicity  ( -25 )',
        type: 'toxicity',
        points: -25,
      },
      {
        id: 'toxicity_50',
        title: 'Toxicity  ( -50 )',
        type: 'toxicity',
        points: -50,
      },
      {
        id: 'false_mark_25',
        title: 'False Mark / Stalling ( -25 )',
        type: 'false_mark',
        points: -25,
      },
      {
        id: 'false_mark_75',
        title: 'False Mark / Stalling ( -75 )',
        type: 'false_mark',
        points: -75,
      },
    ];

    if (store?.user?.account_type == AccountTypeEnum.SENIOR_MODERATOR) {
      return seniorOptions;
    }
    if (store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN) {
      return adminOptions;
    }
    return regularOptions;
  };

  const handlePunishmentChange = id => {
    const options = getPunPointOptions();
    const selectedOption = options.find(option => option.id === id);
    if (selectedOption) {
      setPunishment({ type: selectedOption.id, points: selectedOption.points });
    }
  };

  const handlePunish = () => {
    setPunishLoading(true);
    punishUser(middleware, punishment.type, punishment.points, user?._id).then(
      res => {
        setPunishLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          updateStore({ type: SET_SUCCESSES, payload: res?.message });
          handleClose();
        }
      }
    );
  };

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Punish ${user?.username}`}
      primaryButton={
        <PrimaryButton
          label='Punish'
          loading={punishLoading}
          disabled={!canPunish || !punishment}
          onClick={handlePunish}
        />
      }
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>PUNISHMENT*</Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomDropdown
                options={getPunPointOptions().map(option => ({
                  ...option,
                  value: option.id,
                }))}
                placeholder='Punishment'
                onChange={value => handlePunishmentChange(value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={canPunish}
                onChange={value => setCanPunish(value)}
                color={yellow}
                disabled={punishLoading}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: secondaryText,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                I understand that falsely punishing someone can result in the
                loss of my staff permissions.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default PunishModal;
