import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { renewTokens } from '../api/auth';
import {
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '../api/useMiddleware';
import { SERVER_URL } from '../utils/constants';
import {
  NEW_ADD_MATCH_EVENT,
  NEW_MATCH_CHAT_MESSAGE_EVENT,
  NEW_MATCH_UPDATE_EVENT,
  NEW_ONGOING_MATCH_EVENT,
  NEW_REMOVE_MATCH_EVENT,
  NEW_REMOVE_ONGOING_MATCH_EVENT,
} from './socketConstants';

const useMatch = (matchId, token) => {
  const socketRef = useRef();

  const [matchData, setMatchData] = useState(null);
  const [matchMessage, setMatchMessage] = useState(null);
  const [matchToAdd, setMatchToAdd] = useState(null);
  const [matchToRemove, setMatchToRemove] = useState(null);
  const [ongoingToAdd, setOngoingToAdd] = useState(null);
  const [ongoingToRemove, setOngoingToRemove] = useState(null);

  useEffect(() => {
    if (!matchId) return;

    if (!socketRef?.current?.connected) {
      socketRef.current = socketIOClient(SERVER_URL, {
        query: { matchId },
        auth: { token },
      });
    }

    socketRef.current.on('connect_error', async err => {
      if (err?.data?.needs_renew) {
        // renew tokens
        await renewTokens(getRefreshToken()).then(res => {
          if (!res?.error) {
            saveAccessToken(res?.accessToken);
            saveRefreshToken(res?.refreshToken);
          }
        });
      }

      if (err?.data?.needs_login) {
        // force new login
      }
    });

    socketRef.current.on(NEW_MATCH_UPDATE_EVENT, matchdata => {
      if (matchdata) {
        setMatchData(matchdata);
      }
    });

    socketRef.current.on(NEW_MATCH_CHAT_MESSAGE_EVENT, messagedata => {
      if (messagedata) {
        setMatchMessage(messagedata);
      }
    });

    socketRef.current.on(NEW_ADD_MATCH_EVENT, matchdata => {
      if (matchdata) {
        if (matchdata?._id === matchToAdd?._id) {
          return;
        }

        setMatchToAdd(matchdata);
      }
    });

    socketRef.current.on(NEW_REMOVE_MATCH_EVENT, matchId => {
      if (matchId) {
        setMatchToRemove(matchId);
      }
    });

    socketRef.current.on(NEW_ONGOING_MATCH_EVENT, matchdata => {
      if (matchdata) {
        setOngoingToAdd(matchdata);
      }
    });

    socketRef.current.on(NEW_REMOVE_ONGOING_MATCH_EVENT, ongoingRemoveData => {
      if (ongoingRemoveData) {
        setOngoingToRemove(ongoingRemoveData);
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [matchId, token]);

  return {
    matchData,
    matchMessage,
    matchToAdd,
    matchToRemove,
    ongoingToAdd,
    ongoingToRemove,
  };
};

export default useMatch;
