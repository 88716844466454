import React, { Suspense } from 'react';
import { getColor } from '../../utils/getColor';

const necklaceComponents = {
  choker_necklace: React.lazy(() => import('./types/Choker')),
  cuban_chain_necklace: React.lazy(() => import('./types/Chain')),
  cuban_chain_silver_necklace: React.lazy(() => import('./types/SilverChain')),
  eighty_eight_chain_necklace: React.lazy(
    () => import('./types/EightyEightChain')
  ),
  jordan_necklace: React.lazy(() => import('./types/Jordan')),
  yeezy_necklace: React.lazy(() => import('./types/Yeezy')),
  dog_tag_necklace: React.lazy(() => import('./types/DogTag')),
  on_pause: React.lazy(() => import('./types/OnPause')),
  blinding: React.lazy(() => import('./types/Blinding')),
  wc_cleats: React.lazy(() => import('../clothes/types/world_cup/Cleats')),
  bandana: React.lazy(() => import('./types/Bandana')),
  scarf: React.lazy(() => import('./types/Scarf')),
  cross: React.lazy(() => import('./types/Cross')),
};

const Necklace = ({ type, skinColor }) => {
  const NecklaceComponent = necklaceComponents[type];

  if (!NecklaceComponent) return null;

  const props = {};

  if (type === 'scarf') {
    props.skinColor = getColor(skinColor);
  }

  return (
    <Suspense fallback={null}>
      <NecklaceComponent {...props} />
    </Suspense>
  );
};

export default Necklace;
