import { Grid, Typography } from '@mui/material';
import { CDN_URL } from '../../utils/constants';
import {
  cardVeryLight,
  normal,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';

const MainMenuListItem = ({ title, description, type, onClick }) => {
  const games = `${CDN_URL}3d_icons/games.png`;
  const leaderboard = `${CDN_URL}3d_icons/leaderboard.png`;
  const store = `${CDN_URL}3d_icons/store.png`;
  const snipes = `${CDN_URL}3d_icons/snipes.png`;
  const reset = `${CDN_URL}3d_icons/reset.png`;
  const premium = `${CDN_URL}3d_icons/premium.png`;
  const privacy = `${CDN_URL}3d_icons/privacy.png`;
  const terms = `${CDN_URL}3d_icons/terms.png`;
  const faq = `${CDN_URL}3d_icons/faq.png`;
  const ac = `${CDN_URL}3d_icons/ac.png`;
  const withdrawals = `${CDN_URL}3d_icons/withdrawals.png`;
  const profit = `${CDN_URL}3d_icons/profit.png`;
  const expense = `${CDN_URL}3d_icons/expense.png`;
  const disputes = `${CDN_URL}3d_icons/disputes.png`;
  const search = `${CDN_URL}3d_icons/search.png`;
  const unban = `${CDN_URL}3d_icons/unban.png`;
  const home = `${CDN_URL}3d_icons/home.png`;
  const security = `${CDN_URL}3d_icons/security.png`;
  const chargeback = `${CDN_URL}3d_icons/charge-back.png`;
  const chatUnban = `${CDN_URL}3d_icons/chat-unban.png`;
  const maps = `${CDN_URL}3d_icons/maps.png`;
  const earnings = `${CDN_URL}3d_icons/earnings-reset.png`;
  const referrals = `${CDN_URL}3d_icons/referrals.png`;
  const tournaments = `${CDN_URL}3d_icons/tournaments.png`;

  const getIcon = () => {
    switch (type) {
      case 'games':
        return games;
      case 'leaderboard':
        return leaderboard;
      case 'store':
        return store;
      case 'snipes':
        return snipes;
      case 'reset':
        return reset;
      case 'reset_earnings':
        return earnings;
      case 'staff':
        return;
      case 'premium':
        return premium;
      case 'terms':
        return terms;
      case 'faq':
        return faq;
      case 'privacy':
        return privacy;
      case 'AC':
        return ac;
      case 'withdrawals':
        return withdrawals;
      case 'profit':
        return profit;
      case 'expense':
        return expense;
      case 'disputes':
        return disputes;
      case 'search':
        return search;
      case 'unban':
        return unban;
      case 'chat-unban':
        return chatUnban;
      case 'home':
        return home;
      case 'chargeback':
        return chargeback;
      case 'security':
        return security;
      case 'maps':
        return maps;
      case 'tournaments':
        return tournaments;
      case 'referrals':
        return referrals;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      width: '100%',
      borderRadius: 2,
      padding: 1,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardVeryLight,
      },
      maxWidth: 360,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    description: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 400,
      maxWidth: 280,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 2 }}
        wrap='nowrap'
      >
        <Grid item sx={styles.icon}>
          <img src={getIcon()} draggable={false} height={36} width={36} />
        </Grid>

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={styles.title}>{title}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.description}>{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainMenuListItem;
