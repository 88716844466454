import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { getNews } from '../../api/news';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { getReadableDateString } from '../../utils/helpers';
import {
  doubtedBlue,
  large,
  normal,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';
import SocialBadge from '../leaderboard/SocialBadge';

const NewsModal = ({ open, onClose }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setNews(null);
    setLoading(true);
    onClose();
  };

  useEffect(() => {
    if (open && loading) {
      if (news == null) {
        getNews(middleware).then(res => {
          setLoading(false);
          if (res?.error) {
            updateStore({ type: SET_ERRORS, payload: res?.message });
            handleClose();
          } else {
            setNews(res?.news);
          }
        });
      }
    }
  }, [open]);

  const styles = {
    width: {
      width: '100%',
    },
    socialText: {
      fontSize: small,
      fontWeight: 500,
      color: text,
    },
    header: {
      fontSize: large,
      fontWeight: 800,
      color: doubtedBlue,
    },
    item: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 500,
      maxWidth: 500,
    },
  };

  return (
    <CustomModal
      title="What's New"
      open={open}
      description={news ? getReadableDateString(new Date(news?.date)) : null}
      loading={loading}
      onClose={handleClose}
      bottomContent={
        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            <SocialBadge
              type='twitter'
              onClick={() =>
                window.open('https://twitter.com/rivoxgg', '_blank')
              }
            />
            <Grid item>
              <Typography sx={styles.socialText}>
                Follow us for more updates!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        {news?.sections &&
          news?.sections?.map(section => (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 2 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>
                        {section?.header}
                      </Typography>
                    </Grid>

                    <Grid item sx={{ flexGrow: 1 }}>
                      <Divider
                        sx={{ width: '100%', backgroundColor: doubtedBlue }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    {section?.news_items?.map(item => (
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.width}>
                          <Typography sx={styles.item}>
                            {item?.title === 'Twitter Announcement' ? (
                              <TwitterTweetEmbed
                                tweetId={item?.body}
                                options={{ theme: 'dark' }}
                              />
                            ) : (
                              <span>
                                •{' '}
                                <span
                                  style={{ fontWeight: 800, color: 'text' }}
                                >
                                  {item?.title}
                                </span>{' '}
                                {item?.body}
                              </span>
                            )}
                          </Typography>
                        </Grid>

                        {item?.link && (
                          <SecondaryButton
                            label={item?.link_body}
                            onClick={() => {
                              navigate(item?.link);
                              handleClose();
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </CustomModal>
  );
};

export default NewsModal;
