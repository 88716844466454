import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import useMiddleware from '../../api/useMiddleware';
import { createPayPalOrder } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import PayPalCheckoutButton from '../wallet/PayPalCheckoutButton';

const StaffPayPalTest = () => {
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);

  const handlePayPal = () => {
    setLoading(true);
    createPayPalOrder(middleware, 0.25).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        window.open(res?.link?.href, '_self');
      }
    });
  };

  return (
    <Grid item sx={{ width: '100%' }}>
      <PayPalCheckoutButton loading={loading} onClick={handlePayPal} />
    </Grid>
  );
};

export default StaffPayPalTest;
