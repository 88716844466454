import { Divider, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { setUnbanDate } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import { formatDateToCustomString } from '../../utils/helpers';
import {
  cardVeryLight,
  doubtedOrange,
  normal,
  text,
} from '../../utils/themeContstants';
import CustomDateTimeSelect from '../custom/CustomDateTimeSelect';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';

const BanLengthModal = ({
  open,
  onClose,
  user,
  unban_timestamp,
  setUnbanTimestamp,
}) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(unban_timestamp);

  const handleDateChange = newValue => {
    setSelectedDate(newValue);
  };

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  const handleSetNewDate = () => {
    setLoading(true);
    setUnbanDate(middleware, user?._id, selectedDate.toISOString()).then(
      res => {
        setLoading(false);

        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          updateStore({ type: SET_SUCCESSES, payload: res?.message });
          setUnbanTimestamp(selectedDate);
          handleClose();
        }
      }
    );
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  useEffect(() => {
    try {
      setSelectedDate(unban_timestamp);
    } catch (err) {
      console.error(err);
    }
  }, [unban_timestamp]);

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Set New Unban Date`}
      description={`You are about to set ${
        user?.username
      } unban date to be at ${formatDateToCustomString(selectedDate)}`}
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label='Set'
          loading={loading}
          bg={doubtedOrange}
          onClick={handleSetNewDate}
        />
      }
      secondaryButton={<SecondaryButton label='Cancel' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>

        {store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN && (
          <Grid item sx={styles.width}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item>
                <CustomDateTimeSelect
                  value={selectedDate}
                  onChange={handleDateChange}
                  size={35}
                  inputColor={text}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default BanLengthModal;
