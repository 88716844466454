import { CircularProgress, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ImStatsDots } from 'react-icons/im';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getProfitStats } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import {
  currencyFormatter,
  getFirstDayOfMonth,
  getReadableDateString,
  getTime,
} from '../../utils/helpers';
import {
  card,
  green,
  modalShadow,
  normal,
  offWhite,
  red,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import NotSelected from '../custom/NotSelected';

const ProfitStats = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [date, _] = useState(new Date());
  const [stats, setStats] = useState(null);
  const [dateRange, setDateRange] = useState('all');

  const handleGetStats = () => {
    setLoading(true);
    getProfitStats(middleware, dateRange).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setStats(res);
      }
    });
  };

  useEffect(() => {
    if (stats == null) {
      handleGetStats();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (dateRange) {
        handleGetStats();
      }
    }
  }, [dateRange]);
  function msToTime(duration) {
    let minutes = Math.floor(duration / 60000);
    let seconds = ((duration % 60000) / 1000).toFixed(0);
    return minutes > 0
      ? `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      : `${seconds} second(s)`;
  }

  let formattedTime = 'Loading...';
  if (stats?.query_speed) {
    let ms = stats?.query_speed;
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    formattedTime = `${minutes} minutes and ${seconds} seconds`;
  }

  const total = Object.values(
    stats?.total_avatars?.rare_crates_obj || {}
  ).reduce((a, b) => a + b, 0);

  const getRangeOptions = range => {
    switch (range) {
      case 'all':
        return [{ title: 'This Month', value: 'monthly' }];
      case 'monthly':
        return [{ title: 'All Time', value: 'all' }];
      default:
        return getRangeOptions('all');
    }
  };

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    container: {
      borderRadius: 1,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
    },
    smallLabel: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 600,
    },
    totalLabel: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    totalValue: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    label: {
      fontSize: 15,
      color: offWhite,
      fontWeight: 600,
    },
    value: {
      fontSize: 15,
      color: text,
      fontWeight: 800,
    },
    containerHeader: {
      fontSize: 18,
      color: text,
      fontWeight: 800,
    },
    percentValue: {
      fontSize: 12,
      color: green,
      fontWeight: 600,
    },
  };

  return (
    <>
      {/* {!loading && (
        <Grid item>
          <CustomDropdown
            options={getRangeOptions(dateRange)}
            placeholder="Date Range"
            onChange={(value) => {
              setStats(null);
              setDateRange(value);
            }}
          />
        </Grid>
      )} */}
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.icon}>
                <ImStatsDots style={{ fontSize: 22, color: text }} />
              </Grid>

              <Grid item>
                <Typography sx={styles.title}>PROFIT STATS</Typography>
              </Grid>
            </Grid>
          </Grid>
          {dateRange == 'all' && (
            <Grid item>
              <Typography sx={styles.meta}>
                Breakdown of profit stats on Rivox since launch, as of:{' '}
                <span style={{ fontWeight: 800 }}>
                  {getReadableDateString(new Date(stats?.last_updated_time))}
                </span>
                ,{' '}
                <span style={{ fontWeight: 800 }}>
                  {getTime(new Date(stats?.last_updated_time))}
                </span>
              </Typography>
            </Grid>
          )}

          {dateRange == 'monthly' && dateRange && (
            <Grid item>
              <Typography sx={styles.meta}>
                Breakdown of profit stats on Rivox since{' '}
                <span style={{ fontWeight: 800 }}>
                  {getReadableDateString(getFirstDayOfMonth(date))}
                </span>{' '}
              </Typography>
              {stats?.last_updated_time && (
                <Grid item>
                  <Typography sx={styles.meta}>
                    Last updated:{' '}
                    <span style={{ fontWeight: 800 }}>
                      {getReadableDateString(
                        new Date(stats?.last_updated_time)
                      )}{' '}
                      {getTime(new Date(stats?.last_updated_time))}
                    </span>
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {stats?.query_speed && (
            <Grid item>
              <Typography sx={styles.meta}>
                Data updated in{' '}
                <span style={{ fontWeight: 800 }}>
                  {msToTime(stats?.query_speed)}
                </span>{' '}
                on the back-end.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}
      {!loading && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {!stats && (
              <Grid item alignSelf='center'>
                <NotSelected label='NO STATS' />
              </Grid>
            )}

            {!loading && stats && (
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 4 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Match Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.match_profit?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_matches?.total} Matches
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Withdraw Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_withdrawal_profit?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {' '}
                          {
                            stats?.total_withdrawal_profit
                              .withdrawal_player_count
                          }{' '}
                          Withdrawals
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Banned Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_pool?.banned_profit
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_pool.banned_player_count} Players
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Banned Purchase profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_pool?.purchase_profit
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Unlink Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_pool?.unlink_profit
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Tip Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_withdrawal_profit?.tips
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_withdrawal_profit.tip_player_count} Tips
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Avatar Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_avatars?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_avatars.player_count} Pcs Sold
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Crate Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_avatars?.crates
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_avatars.crates_count} Crates Sold
                        </Typography>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_avatars.rare_crates_count} Rare Crates
                          Sold
                        </Typography>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_avatars.emoji_crates_count} Emoji Crates
                          Sold
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Sub Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_subscriptions?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_subscriptions.player_count}{' '}
                          Subscriptions
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            BP Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(stats?.total_bp?.total)}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_bp.player_count} Passes Bought
                        </Typography>
                        <Typography sx={styles.smallLabel}>
                          Given Away:{' '}
                          {currencyFormatter().format(
                            stats?.total_bp?.expenses
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Stat Reset Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_stat_resets?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_stat_resets.player_count} Purchases
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Snipes Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_snipes?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_snipes.player_count} Purchases
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Manual Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_manual_profit?.total
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Deposits (Gross)
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_deposits?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_deposits.player_count} Deposits
                        </Typography>
                        <Typography sx={styles.smallLabel}>
                          {currencyFormatter().format(
                            stats?.total_deposits?.total -
                              stats?.total_deposits?.total_fees
                          )}{' '}
                          After Fees
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            Deposits
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_deposits?.total -
                                stats?.total_deposits?.total_fees
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          {stats?.total_deposits.player_count} Deposits
                        </Typography>
                      </Grid>
                    </Grid> */}
                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            PayPal Deposits
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_deposits?.paypal
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            sx={(styles.totalLabel, { color: green })}
                          >
                            CashApp Deposits
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_deposits?.cashapp
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}

                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={styles.totalLabel}>
                            Revenue
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(stats?.total_revenue)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={styles.totalLabel}>
                            Total Profit
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(stats?.total_profit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {dateRange === 'all' && (
                      <Grid item sx={styles.container}>
                        <Grid
                          container
                          direction='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Grid item>
                            <Typography sx={styles.totalLabel}>
                              Remaining Profit
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography sx={styles.totalValue}>
                              {currencyFormatter().format(
                                stats?.running_profit
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.title}>EXPENSE STATS</Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Paypal Fees
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_bonus_given?.total
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Tournaments Prize Pool
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.tournaments_prize_pool?.total
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Given by Admin/Giveaway
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_given_away?.total
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Withdrawals Sent
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_withdrawals_sent?.total
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Expenses Paid
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_expenses_payouts?.expenses
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Typography sx={(styles.totalLabel, { color: red })}>
                            Payouts Paid
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_expenses_payouts?.payouts
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {dateRange === 'all' && (
                      <>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography sx={styles.title}>
                              MONEY POOL STATS
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Total Money In Pool
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {currencyFormatter().format(
                                  stats?.total_pool?.total
                                )}
                              </Typography>
                            </Grid>
                            <Typography sx={styles.smallLabel}>
                              Across {stats?.total_pool.player_count} Players
                            </Typography>
                            <Typography sx={styles.smallLabel}>
                              Avg Max Withdrawal:{' '}
                              {currencyFormatter().format(
                                stats?.total_pool.avg_max_withdraw
                              )}
                            </Typography>
                            <Typography sx={styles.smallLabel}>
                              Withdrawals Sent: {''}
                              {stats?.total_withdrawals_sent?.number_sent}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography sx={styles.totalLabel}>
                            Projected Money In Pool
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {currencyFormatter().format(
                              stats?.total_pool_estimate?.total
                            )}
                          </Typography>
                        </Grid>
                        <Typography sx={styles.smallLabel}>
                          Compared To Real:
                        </Typography>
                        <Typography sx={styles.smallLabel}>
                          {" "}
                          {currencyFormatter().format(
                            stats?.total_pool_estimate.difference
                          )}
                        </Typography>{" "}
                      </Grid>
                    </Grid> */}
                    {/* <Grid item sx={styles.container}>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography sx={styles.totalLabel}>
                            # Of Withdrawals Sent
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.totalValue}>
                            {stats?.total_withdrawals_sent?.number_sent}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {dateRange == 'all' && (
                      <>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography sx={styles.title}>
                              ACTIVITY STATS
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Registered Users
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {stats?.total_users?.total}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Recurring / Current Subs
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {
                                  stats?.total_subscriptions
                                    ?.recurring_player_count
                                }{' '}
                                /{' '}
                                {
                                  stats?.total_subscriptions
                                    ?.current_player_count
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Matches Played
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {stats?.total_matches?.total}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Average Deposit
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {currencyFormatter().format(
                                  stats?.total_deposits.avg_deposit
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Grid item>
                              <Typography sx={styles.totalLabel}>
                                Average Withdrawal
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={styles.totalValue}>
                                {currencyFormatter().format(
                                  stats?.total_withdrawals_sent.avg_withdrawal
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.title}>TOKEN STATS</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='start'
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.containerHeader}>
                                Profit By Match Type
                              </Typography>
                            </Grid>

                            {stats?.match_profit?.by_game_match_type?.map(
                              (category, i) => (
                                <Grid item key={i}>
                                  <Grid
                                    container
                                    justifyContent='start'
                                    alignItems='center'
                                    gap={{ xs: 1 }}
                                  >
                                    <Grid item>
                                      <Typography sx={styles.label}>
                                        {category?._id}:
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.value}>
                                        {currencyFormatter().format(
                                          category?.sum
                                        )}{' '}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography sx={styles.percentValue}>
                                        {(
                                          (category?.sum /
                                            stats?.match_profit?.total) *
                                          100
                                        ).toFixed(2)}
                                        {'%'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                        <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.containerHeader}>
                                Profit By Region
                              </Typography>
                            </Grid>

                            {stats?.match_profit?.by_region?.map(
                              (category, i) => (
                                <Grid item key={i}>
                                  <Grid
                                    container
                                    justifyContent='start'
                                    alignItems='center'
                                    gap={{ xs: 1 }}
                                  >
                                    <Grid item>
                                      <Typography sx={styles.label}>
                                        {category?._id}:
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.value}>
                                        {currencyFormatter().format(
                                          category?.sum
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography sx={styles.percentValue}>
                                        {(
                                          (category?.sum /
                                            stats?.match_profit?.total) *
                                          100
                                        ).toFixed(2)}
                                        {'%'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.title}>AVATAR STATS</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='start'
                        gap={{ xs: 1 }}
                      >
                        {/* <Grid item sx={styles.container}> */}
                        {/* <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.containerHeader}>
                                Profit By Category
                              </Typography>
                            </Grid> */}
                        {/* 
                            {stats?.total_avatars?.by_category?.map(
                              (category, i) => (
                                <Grid item key={i}>
                                  <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    gap={{ xs: 1 }}
                                  >
                                    <Grid item>
                                      <Typography sx={styles.label}>
                                        {category[0]?._id}:
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.value}>
                                        {currencyFormatter().format(
                                          category[0]?.sum
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )} */}
                        {/* </Grid> */}
                        {/* </Grid> */}

                        {/* <Grid item sx={styles.container}>
                          <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.containerHeader}>
                                Rare Crate Stats
                              </Typography>
                            </Grid>
                            {Object.entries(
                              stats?.total_avatars?.rare_crates_obj || {}
                            ).map(([key, value], i) => {
                              const percentage = (
                                (value / total) *
                                100
                              ).toFixed(2);
                              return (
                                <Grid item key={i}>
                                  <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    gap={{ xs: 1 }}
                                  >
                                    <Grid item>
                                      <Typography sx={styles.label}>
                                        {isNaN(Number(key))
                                          ? key
                                          : `${key} snipes`}
                                        :
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.value}>
                                        {value}
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.percentValue}>
                                        {percentage}%
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid> */}
                        <Grid
                          item
                          sx={{
                            ...styles.container,
                            maxHeight: 400,
                            overflow: 'auto',
                          }}
                        >
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.containerHeader}>
                                Profit By Piece (Recent Drop)
                              </Typography>
                            </Grid>

                            {stats?.total_avatars?.by_name?.map(
                              (category, i) => (
                                <Grid item key={i}>
                                  <Grid
                                    container
                                    justifyContent='start'
                                    alignItems='center'
                                    gap={{ xs: 1 }}
                                  >
                                    <Grid item>
                                      <Typography sx={styles.label}>
                                        {category[0]?._id}:
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Typography sx={styles.value}>
                                        {currencyFormatter().format(
                                          category[0]?.sum
                                        )}{' '}
                                        ({category[0]?.player_count})
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.title}>SIGNUPS STATS</Typography>
                    </Grid>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                      sx={{ ...styles.container }}
                    >
                      <Grid item sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <BarChart
                            data={stats?.signups || []}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <Tooltip
                              formatter={(value, name) => [
                                value,
                                name === 'signups' ? 'Signups' : name,
                              ]}
                            />
                            <Bar
                              dataKey='signups'
                              fill='#82ca9d'
                              barSize={20}
                            />

                            <XAxis
                              dataKey='date'
                              tickFormatter={tick =>
                                moment(tick).format('MMM D')
                              }
                            />
                            <YAxis
                              tickFormatter={tick =>
                                Number.isInteger(tick) ? tick : ''
                              }
                              allowDecimals={false}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.title}>MATCHES STATS</Typography>
                    </Grid>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                      sx={{ ...styles.container }}
                    >
                      <Grid item sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <BarChart
                            data={stats?.matches || []}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <Tooltip
                              formatter={(value, name) => [
                                value,
                                name === 'matches' ? 'Matches' : name,
                              ]}
                            />
                            <Bar
                              dataKey='matches'
                              fill='#82ca9d'
                              barSize={20}
                            />

                            <XAxis
                              dataKey='date'
                              tickFormatter={tick =>
                                moment(tick).format('MMM D')
                              }
                            />
                            <YAxis
                              tickFormatter={tick =>
                                Number.isInteger(tick) ? tick : ''
                              }
                              allowDecimals={false}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.title}>DEPOSITS STATS</Typography>
                    </Grid>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                      sx={{ ...styles.container }}
                    >
                      <Grid item sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <BarChart
                            data={stats?.deposits || []}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <Tooltip
                              formatter={(value, name) => [
                                `${currencyFormatter().format(value)}`,
                                name === 'deposits' ? 'Deposits' : name,
                              ]}
                            />
                            <Bar
                              dataKey='deposits'
                              fill='#82ca9d'
                              barSize={20}
                            />

                            <XAxis
                              dataKey='date'
                              tickFormatter={tick =>
                                moment(tick).format('MMM D')
                              }
                            />
                            <YAxis
                              tickFormatter={tick =>
                                Number.isInteger(tick) ? tick : ''
                              }
                              allowDecimals={false}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProfitStats;
