import { Button, Grid } from '@mui/material';
import { CDN_URL } from '../../utils/constants';
import { small, text, transition } from '../../utils/themeContstants';

const ConnectionButton = ({
  type,
  onClick,
  fullWidth,
  login = false,
  auth = false,
}) => {
  const paypal = `${CDN_URL}connections/paypal.svg`;
  const discord = `${CDN_URL}connections/discord.svg`;
  const discordAuth = `${CDN_URL}connections/discord-auth.svg`;
  const twitch = `${CDN_URL}connections/twitch.svg`;
  const twitchAuth = `${CDN_URL}connections/twitch-auth.svg`;
  const twitter = `${CDN_URL}connections/twitter.svg`;
  const epic = `${CDN_URL}connections/epic.png`;
  const google = `${CDN_URL}connections/google.svg`;
  const googleAuth = `${CDN_URL}connections/google-auth.svg`;
  const clash = `${CDN_URL}connections/clash.svg`;
  const chess = `${CDN_URL}connections/chess.svg`;
  const game = `${CDN_URL}connections/game.png`;

  const getIcon = () => {
    switch (type) {
      case 'twitch':
        return auth ? twitchAuth : twitch;
      case 'discord':
        return auth ? discordAuth : discord;
      case 'epic':
        return epic;
      case 'twitter':
        return twitter;
      case 'paypal':
        return paypal;
      case 'google':
        return auth ? googleAuth : google;
      case 'clashroyale':
        return clash;
      case 'chess':
        return chess;
      case 'game':
        return game;
    }
  };

  const getLabel = () => {
    switch (type) {
      case 'twitch':
        return login ? 'Play with Twitch' : 'Twitch';
      case 'discord':
        return login ? 'Play with Discord' : 'Discord';
      case 'epic':
        return 'Fortnite';
      case 'twitter':
        return 'Twitter';
      case 'paypal':
        return 'PayPal';
      case 'google':
        return login ? 'Play with Google' : 'Google';
      case 'clashroyale':
        return 'Clash Royale';
      case 'chess':
        return 'Chess.com';
      case 'game':
        return 'Game Account';
      default:
        return null;
    }
  };

  const styles = {
    container: {
      padding: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      transition: transition,
      border: 'none',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
      height: 40,
      borderRadius: 2,
      fontWeight: 800,
      minWidth: fullWidth ? '100%' : 0,
      fontSize: small,
      color: text,
      backgroundColor: 'rgba(203, 215, 255, 0.075)',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(203, 215, 255, 0.125)',
        boxShadow: 'none',
      },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Button
      variant='contained'
      size='large'
      sx={styles.container}
      onClick={onClick}
    >
      <Grid
        container
        justifyContent={fullWidth ? 'center' : 'start'}
        alignItems='center'
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.iconContainer}>
          <img
            src={getIcon()}
            alt={`${type}-icon`}
            draggable={false}
            height={35}
          />
        </Grid>

        <Grid item>{getLabel()}</Grid>
      </Grid>
    </Button>
  );
};

export default ConnectionButton;
