export const MatchStateEnum = {
  WAITING: 'waiting',
  CANCEL: 'cancel',
  PLAYING: 'playing',
  COMPLETED: 'completed',
  DISPUTE: 'dispute',
  READY: 'ready',
  SUBMIT: 'submit',
};

export const AccountTypeEnum = {
  PLAYER: 1,
  MODERATOR: 100,
  SENIOR_MODERATOR: 200,
  JUNIOR_ADMIN: 250,
  ADMIN: 300,
  LEAD_ADMIN: 350,
  SENIOR_ADMIN: 400,
  GOD: 10000,
  ANTICHEAT: 251,
};

export const TransactionStatusTypeEnum = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
};

export const NotificationTypeEnum = {
  BP_MONEY: 'bp_money',
  TOURNAMENT_EARNED: 'tournament_earned',
  TOURNAMENT_WIN: 'tournament_win',
  TOURNAMENT_START: 'tournament_start',
  NEW_MATCH: 'new_match',
  TEAM_INVITE: 'team_invite',
  NEW_BADGE: 'new_badge',
  PUNISHED: 'punished',
  EARNED: 'earned',
  KICKED: 'kicked',
  AVATAR: 'avatar',
  GIVEAWAY: 'giveaway',
  REMATCH: 'rematch',
  PRIVATE_INVITE: 'private_match',
  RARE_ITEMS_CRATE: 'rare_items_crate',
  SNIPES: 'snipes',
  RARE_CRATE: 'starter',
  EMOJI_CRATE: 'emoji',
  EXOTIC_CRATE: 'drip',
  BATTLE_PASS: 'battle_pass',
};

export const AlertTypeEnum = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const AvatarRarityEnum = {
  COMMON: 'common',
  UNCOMMON: 'uncommon',
  RARE: 'rare',
  LEGENDARY: 'legendary',
  EXOTIC: 'exotic',
  GODLIKE: 'godlike',
  VIP: 'vip',
  EMOJI: 'emoji',
  EMOTE: 'emote',
};

export const AvatarPiecePriceEnum = {
  COMMON: 0.5,
  UNCOMMON: 1,
  RARE: 1.5,
  EMOJI: 1.5,
  LEGENDARY: 2.5,
  EXOTIC: 3.5,
  GODLIKE: 5,
  VIP: 0,
};
