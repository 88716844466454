import { Grid, Skeleton } from '@mui/material';
import { cardVeryLight } from '../../utils/themeContstants';

const MatchGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  return (
    <Grid item sx={{ width: '100%' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 4fr))',
          gap: 12,
        }}
      >
        {array.map((_, i) => (
          <Skeleton
            key={i}
            variant='rectangular'
            animation='pulse'
            sx={{
              bgcolor: cardVeryLight,
              minWidth: 250,
              height: 300,
              borderRadius: 1,
            }}
          />
        ))}
      </div>
    </Grid>
  );
};

export default MatchGlimmer;
