import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip as ChartTooltip,
} from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  FaChartLine,
  FaDiscord,
  FaLink,
  FaTimes,
  FaUsers,
} from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminUserSearch } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  addReferralAdmin,
  getReferralCodes,
  getReferralStats,
  removeReferralAdmin,
} from '../../api/user';
import Avatar from '../../avatar/Avatar';
import { StoreContext } from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import { numFormatter } from '../../utils/helpers';
import {
  card,
  cardDark,
  cardLight,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import StaffSearchResultItem from '../staff/StaffSearchResultItem';
import SearchBar from '../tip/SearchBar';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);
// Import statements remain the same...

const ReferralStats = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Split states
  const [loading, setLoading] = useState(true);
  const [referralCodes, setReferralCodes] = useState([]);
  const [currentReferral, setCurrentReferral] = useState(null);
  const [newAdminId, setNewAdminId] = useState('');
  const [currentTab, setCurrentTab] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const isSeniorAdmin =
    store?.user?.account_type === AccountTypeEnum.SENIOR_ADMIN ||
    store?.user?.account_type === AccountTypeEnum.GOD;

  // Get current referral code from URL
  const currentReferralCode =
    location.pathname.split('/').pop() === 'referrals'
      ? null
      : location.pathname.split('/').pop();

  // Process monthly stats (doesn't need useCallback as it doesn't depend on state/props)
  const processMonthlyStats = users => {
    const today = new Date();
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    const labels = Array.from({ length: daysInMonth }, (_, i) =>
      (i + 1).toString()
    );
    const signUps = Array(daysInMonth).fill(0);

    users?.forEach(user => {
      const date = new Date(user.created_at);
      if (
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        signUps[date.getDate() - 1]++;
      }
    });

    return { labels, signUps };
  };

  // Update chart data with both signups and deposits
  const updateChartData = monthlyStats => {
    if (!monthlyStats) return;

    setChartData({
      labels: monthlyStats.labels,
      datasets: [
        {
          label: 'Sign Ups',
          data: monthlyStats.signUps,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          tension: 0.4,
          borderWidth: isMobile ? 2 : 3,
          yAxisID: 'y',
        },
        {
          label: 'Deposits',
          data: monthlyStats.depositsCount,
          borderColor: theme.palette.success.main,
          backgroundColor: theme.palette.success.main,
          tension: 0.4,
          borderWidth: isMobile ? 2 : 3,
          yAxisID: 'y',
        },
      ],
    });
  };

  // Navigate to referral code
  const handleNavigate = code => {
    if (code !== currentTab) {
      setCurrentTab(code);
      navigate(`/profile/referrals/${code}`);
    }
  };

  // Handle admin actions
  const handleAdmin = async (action, userId = null) => {
    if (!currentReferral) return;

    setLoading(true);
    try {
      const adminFunction =
        action === 'add' ? addReferralAdmin : removeReferralAdmin;
      const response = await adminFunction(
        middleware,
        currentReferral.code,
        userId || newAdminId
      );

      if (!response.error) {
        // Fetch updated stats
        const statsResponse = await getReferralStats(
          middleware,
          currentReferral.code
        );
        if (!statsResponse.error) {
          setCurrentReferral(statsResponse);
          if (action === 'add') {
            setNewAdminId('');
          }
          updateChartData(statsResponse.monthly_stats);
        }
      }
    } catch (error) {
      console.error(`Error ${action}ing admin:`, error);
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch effect
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const codesResponse = await getReferralCodes(middleware);
        if (!codesResponse.error) {
          const codes = codesResponse.referralCodes || [];
          setReferralCodes(codes);

          // Handle initial navigation
          if (location.pathname === '/profile/referrals' && codes.length > 0) {
            handleNavigate(codes[0].code);
            return;
          }
        }
      } catch (error) {
        console.error('Error fetching referral codes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Fetch stats when referral code changes
  useEffect(() => {
    const fetchStats = async () => {
      if (!currentReferralCode) return;

      setLoading(true);
      try {
        const statsResponse = await getReferralStats(
          middleware,
          currentReferralCode
        );
        if (!statsResponse.error) {
          setCurrentReferral(statsResponse);
          updateChartData(statsResponse.monthly_stats);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [currentReferralCode]);

  // Chart options configuration
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: isMobile ? 'bottom' : 'top',
        labels: {
          color: text,
          boxWidth: isMobile ? 12 : isTablet ? 30 : 40,
          padding: isMobile ? 8 : isTablet ? 10 : 12,
          font: { size: isMobile ? 10 : isTablet ? 11 : 12 },
        },
      },
      tooltip: {
        callbacks: {
          title: context => `Day ${context[0].label}`,
          label: context => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Sign Ups',
          color: theme.palette.primary.main,
        },
        beginAtZero: true,
        ticks: {
          color: secondaryText,
          font: { size: isMobile ? 10 : isTablet ? 11 : 12 },
          maxTicksLimit: isMobile ? 6 : 8,
        },
        grid: {
          color: card,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          color: secondaryText,
          font: { size: isMobile ? 10 : isTablet ? 11 : 12 },
          maxTicksLimit: isMobile ? 10 : 15,
        },
        grid: {
          color: card,
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Day of Month',
          color: secondaryText,
        },
      },
    },
  };

  const handleSearch = async query => {
    if (!query || query.length >= 21) {
      setSearchResults(null);
      return;
    }

    setSearchLoading(true);
    try {
      const res = await adminUserSearch(middleware, query);
      if (!res?.error) {
        setSearchResults(res?.userArray);
      }
    } catch (error) {
      console.error('Error searching for users:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleUserSelect = user => {
    handleAdmin('add', user.user_id);
    setNewAdminId('');
    setSearchResults(null);
  };

  return (
    <Box sx={{ width: '100%', p: { xs: 0.5, sm: 1, md: 2 } }}>
      <Grid container direction='column' spacing={{ xs: 1, sm: 1.5, md: 2 }}>
        {/* Header - Always visible */}
        <Grid item>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            gap={{ xs: 0.5, sm: 1 }}
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              <FaChartLine
                style={{ fontSize: isMobile ? 18 : 22, color: text }}
              />
              <Typography
                sx={{
                  fontSize: isMobile ? 18 : 22,
                  color: text,
                  fontWeight: 800,
                }}
              >
                Referral Statistics
              </Typography>
            </Grid>

            {!loading && referralCodes.length > 0 && (
              <Grid item sx={{ width: { xs: '100%', sm: 'auto' } }}>
                <Grid
                  container
                  spacing={0.5}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                >
                  {referralCodes.map(ref => (
                    <Grid item key={ref.code}>
                      <Paper
                        elevation={0}
                        onClick={() => handleNavigate(ref.code)}
                        sx={{
                          p: { xs: 0.75, sm: 1, md: 1.5 },
                          backgroundColor:
                            ref.code === currentReferral?.code
                              ? theme.palette.primary.main
                              : card,
                          borderRadius: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          cursor: 'pointer',
                          '&:hover': { opacity: 0.8 },
                        }}
                      >
                        <FaLink
                          size={isMobile ? 14 : 16}
                          color={
                            ref.code === currentReferral?.code
                              ? '#fff'
                              : theme.palette.primary.main
                          }
                        />
                        <Typography
                          sx={{
                            fontSize: isMobile ? 12 : 14,
                            fontWeight: 600,
                            color:
                              ref.code === currentReferral?.code
                                ? '#fff'
                                : text,
                          }}
                        >
                          {ref.code}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* No Referrals Message */}
        {!loading && referralCodes.length === 0 && !isSeniorAdmin && (
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                p: { xs: 2, sm: 3 },
                backgroundColor: card,
                borderRadius: { xs: 1, sm: 2 },
                textAlign: 'center',
              }}
            >
              <Grid
                container
                direction='column'
                spacing={2}
                alignItems='center'
              >
                <Grid item>
                  <Box
                    component='a'
                    href='https://discord.gg/rivox'
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      transition:
                        'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        opacity: 0.8,
                      },
                    }}
                  >
                    <FaDiscord
                      style={{
                        fontSize: isMobile ? 48 : 64,
                        color: theme.palette.primary.main,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: isMobile ? 16 : 20,
                      color: text,
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    No Referral Codes Found
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isMobile ? 14 : 16,
                      color: secondaryText,
                      maxWidth: 400,
                      mx: 'auto',
                    }}
                  >
                    To receive a referral code, please apply through our Discord
                    server. Join our community to get started!
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {/* Loading Indicator */}
        {loading ? (
          <Grid
            item
            alignSelf='center'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: { xs: 2, sm: 3, md: 4 },
              width: '100%',
            }}
          >
            <CircularProgress
              size={isMobile ? 24 : 30}
              sx={{ color: secondaryText }}
            />
          </Grid>
        ) : (
          <>
            {(referralCodes.length > 0 || isSeniorAdmin) && (
              <>
                {/* Stats Cards */}
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 1, sm: 1.5, md: 2 }}>
                    <Grid item xs={12} sm={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: { xs: 1.5, sm: 2, md: 3 },
                          backgroundColor: card,
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <FaUsers
                          size={isMobile ? 18 : isTablet ? 20 : 24}
                          color={theme.palette.primary.main}
                        />
                        <Box sx={{ ml: { xs: 1.5, sm: 2 } }}>
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            color={secondaryText}
                          >
                            Total Sign Ups
                          </Typography>
                          <Typography
                            variant={isMobile ? 'h6' : isTablet ? 'h6' : 'h5'}
                            color={text}
                          >
                            {numFormatter(0).format(
                              currentReferral?.total_stats?.total_signups || 0
                            )}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: { xs: 1.5, sm: 2, md: 3 },
                          backgroundColor: card,
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <FaChartLine
                          size={isMobile ? 18 : isTablet ? 20 : 24}
                          color={theme.palette.success.main}
                        />
                        <Box sx={{ ml: { xs: 1.5, sm: 2 } }}>
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            color={secondaryText}
                          >
                            Total Earned
                          </Typography>
                          <Typography
                            variant={isMobile ? 'h6' : isTablet ? 'h6' : 'h5'}
                            color={text}
                          >
                            $
                            {currentReferral?.total_stats?.total_deposits
                              ? Number(
                                  currentReferral?.total_stats
                                    ?.total_deposit_count * 0.1
                                ).toFixed(2)
                              : '0.00'}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: { xs: 1.5, sm: 2, md: 3 },
                          backgroundColor: card,
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <FaChartLine
                          size={isMobile ? 18 : isTablet ? 20 : 24}
                          color={theme.palette.warning.main}
                        />
                        <Box sx={{ ml: { xs: 1.5, sm: 2 } }}>
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            color={secondaryText}
                          >
                            Total Deposit Count
                          </Typography>
                          <Typography
                            variant={isMobile ? 'h6' : isTablet ? 'h6' : 'h5'}
                            color={text}
                          >
                            {currentReferral?.total_stats
                              ?.total_deposit_count || 0}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Chart */}
                <Grid item xs={12} sx={{ maxWidth: '100%' }}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: { xs: 1, sm: 1.5, md: 2 },
                      backgroundColor: card,
                      borderRadius: { xs: 1, sm: 2 },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: isMobile ? 16 : isTablet ? 18 : 20,
                        color: text,
                        fontWeight: 600,
                        mb: { xs: 1, sm: 1.5, md: 2 },
                      }}
                    >
                      Monthly Statistics
                    </Typography>
                    <Box
                      sx={{
                        height: {
                          xs: 200,
                          sm: 250,
                          md: 300,
                        },
                      }}
                    >
                      <Line options={options} data={chartData} />
                    </Box>
                  </Paper>
                </Grid>

                {/* Admin Controls */}
                {isSeniorAdmin && (
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 1, sm: 1.5, md: 2 },
                        backgroundColor: card,
                        borderRadius: { xs: 1, sm: 2 },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: isMobile ? 16 : isTablet ? 18 : 20,
                          color: text,
                          fontWeight: 600,
                          mb: { xs: 1, sm: 1.5 },
                        }}
                      >
                        Access Control
                      </Typography>

                      {/* Search Section */}
                      <Grid
                        container
                        spacing={1}
                        alignItems='center'
                        sx={{ mb: { xs: 1, sm: 1.5 } }}
                      >
                        <Grid item xs>
                          <Box>
                            <SearchBar
                              placeholder='Search users to add as admin...'
                              value={newAdminId}
                              onChange={val => {
                                setNewAdminId(val);
                                handleSearch(val);
                              }}
                              onKeyPress={() => handleSearch(newAdminId)}
                              onPaste={val => {
                                setNewAdminId(val);
                                handleSearch(val);
                              }}
                              size='small'
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: isMobile ? 32 : 36,
                                  fontSize: isMobile ? '0.75rem' : '0.813rem',
                                  padding: '0 8px',
                                },
                                '& .MuiInputBase-input': {
                                  padding: isMobile ? '2px 4px' : '4px 8px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderRadius: 1,
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Search Results */}
                      {searchLoading && (
                        <Grid
                          container
                          justifyContent='center'
                          sx={{ py: { xs: 1, sm: 1.5 } }}
                        >
                          <CircularProgress
                            size={isMobile ? 20 : 24}
                            sx={{ color: secondaryText }}
                          />
                        </Grid>
                      )}

                      {!searchLoading &&
                        newAdminId &&
                        searchResults &&
                        searchResults.length > 0 && (
                          <Grid
                            container
                            direction='column'
                            spacing={0.5}
                            sx={{ mb: { xs: 1, sm: 1.5 } }}
                          >
                            {searchResults.map((user, i) => (
                              <Grid item key={i}>
                                <StaffSearchResultItem
                                  user={user}
                                  onClick={() => handleUserSelect(user)}
                                  size={isMobile ? 'small' : 'medium'}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}

                      {!searchLoading &&
                        newAdminId &&
                        (!searchResults || searchResults.length === 0) && (
                          <Typography
                            sx={{
                              fontSize: isMobile ? 14 : 16,
                              color: text,
                              fontWeight: 600,
                              mb: { xs: 1, sm: 1.5 },
                            }}
                          >
                            No results for{' '}
                            <span style={{ fontWeight: 800 }}>
                              {newAdminId}
                            </span>
                          </Typography>
                        )}

                      <Divider sx={{ my: { xs: 1, sm: 1.5 } }} />

                      {/* Current Admins List */}
                      <Grid container direction='column' spacing={0.5}>
                        {currentReferral?.admin_ids?.map(admin => (
                          <Grid item key={admin._id}>
                            <Paper
                              elevation={0}
                              sx={{
                                p: { xs: 0.75, sm: 1 },
                                backgroundColor: cardLight,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.5,
                                }}
                              >
                                <Avatar
                                  avatar={admin.avatar}
                                  bgColor={cardDark}
                                  size={isMobile ? 80 : 100}
                                />
                                <Typography
                                  sx={{
                                    fontSize: isMobile ? 20 : 22,
                                    color: text,
                                    fontWeight: 600,
                                  }}
                                >
                                  {admin.username}
                                </Typography>
                              </Box>
                              <IconButton
                                size={isMobile ? 'small' : 'medium'}
                                onClick={() => handleAdmin('remove', admin._id)}
                                sx={{
                                  color: theme.palette.error.main,
                                  padding: isMobile ? 0.25 : 0.5,
                                }}
                              >
                                <FaTimes size={isMobile ? 14 : 16} />
                              </IconButton>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ReferralStats;
