import { Grid, useMediaQuery } from '@mui/material';
import { winnerOptions } from '../../../utils/options';
import { cardDark, cardDarker } from '../../../utils/themeContstants';
import CustomDropdown from '../../custom/CustomDropdown';
import ScrollableContainer from '../../custom/ScrollableContainer';

const MatchHistoryFilters = ({ setWinners, setLosers, setState }) => {
  const isTablet = useMediaQuery('(max-width: 768px)');

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.width}>
      {isTablet && (
        <ScrollableContainer gap={1}>
          <Grid item>
            <CustomDropdown
              options={winnerOptions}
              placeholder='Outcome'
              onChange={value => setWinners(value)}
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={'transparent'}
            />
          </Grid>
        </ScrollableContainer>
      )}

      {!isTablet && (
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <CustomDropdown
              options={winnerOptions}
              placeholder='Outcome'
              onChange={value => setWinners(value)} // <-- Corrected here
              backgroundColor={cardDark}
              backgroundHover={cardDarker}
              border={'transparent'}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MatchHistoryFilters;
