import { Grid, Typography } from '@mui/material';
import { AiFillInfoCircle } from 'react-icons/ai';
import {
  bestOfOptions,
  buildHealthOptions,
  buildLootOptions,
  firstToOptions,
  gameMapTypeOptions,
  killLeadOptions,
  newGameModeOptions,
  realsLootOptions,
  regionOptions,
} from '../../../utils/options';
import {
  badgeIconSizeSmall,
  doubtedBlue,
  normal,
  text,
} from '../../../utils/themeContstants';
import Badge from '../../custom/Badge';
import CustomDropdown from '../../custom/CustomDropdown';
import CustomRadio from '../../custom/CustomRadio';

const FortniteCreate = ({
  game,
  setGameMode,
  gameMode,
  setBestOf,
  setFirstTo,
  setRegion,
  region,
  platform,
  setPlatform,
  setMap,
  setLoot,
  setHealth,
  firstTo,
  setKillLead,
  loot,
  health,
  map,
  bestOf,
  killLead,
}) => {
  const showFirstTo = () => {
    if (
      gameMode?.includes('zw') ||
      gameMode?.includes('bf') ||
      gameMode?.includes('real') ||
      gameMode?.includes('zbm_real') ||
      gameMode?.includes('zbm_bf') ||
      gameMode?.includes('build')
    )
      return true;

    return false;
  };

  const styles = {
    width: {
      width: '100%',
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: '#d7e4f1',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Platform</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform(null)}
                    selected={platform == null}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      All Platforms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform('console')}
                    selected={platform === 'console'}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      Console Only
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>
                  Game mode{' '}
                  <span style={{ fontWeight: 400 }}>
                    (Can select multiple game modes)
                  </span>
                </Typography>
              </Grid>

              <Badge
                label='You can choose multiple game mode options and your opponent will pick one of those game modes when they join.'
                size='small'
                maxWidth={200}
                textTransform={'none'}
              >
                <AiFillInfoCircle
                  style={{ color: text, fontSize: badgeIconSizeSmall }}
                />
              </Badge>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder='Game mode'
              onChange={value => {
                let newModes = gameMode == null ? [] : gameMode;
                newModes.push(value);
                setGameMode([...newModes]);
              }}
              removeSelected={value => {
                const index = gameMode?.indexOf(value);

                if (index !== -1) {
                  let newModes = gameMode;
                  newModes?.splice(index, 1);
                  setGameMode([...newModes]);
                }
              }}
              clearSelected={() => setGameMode(null)}
              selected={gameMode ?? []}
              select
              selectType='gameMode'
            />
          </Grid>
        </Grid>
      </Grid>

      {(gameMode === 'build' || gameMode?.includes('build')) && (
        <>
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>Loot type</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <CustomDropdown
                  options={buildLootOptions}
                  placeholder='Loot type'
                  onChange={value => setLoot(value)}
                  value={loot}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>Health</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <CustomDropdown
                  options={buildHealthOptions}
                  placeholder='Health'
                  onChange={value => setHealth(value)}
                  value={health}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {(gameMode === 'real' || gameMode?.includes('real')) && (
        <>
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>Loot type</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <CustomDropdown
                  options={realsLootOptions}
                  placeholder='Loot type'
                  onChange={value => setLoot(value)}
                  value={loot}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {gameMode === 'real' ||
        (gameMode?.includes('real') && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>Realistic map</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <CustomDropdown
                  options={gameMapTypeOptions(game)}
                  placeholder='Map'
                  onChange={value => setMap(value)}
                  value={map}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.subLabel}>
                  Region{' '}
                  <span style={{ fontWeight: 400 }}>
                    (Can select multiple regions)
                  </span>
                </Typography>
              </Grid>

              <Badge
                label='You can choose multiple region options and your opponent will pick one of those regions when they join.'
                size='small'
                maxWidth={200}
                textTransform={'none'}
              >
                <AiFillInfoCircle
                  style={{ color: text, fontSize: badgeIconSizeSmall }}
                />
              </Badge>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={regionOptions(gameMode)}
              placeholder='Region'
              onChange={value => {
                let newRegions = region === null ? [] : region;
                newRegions.push(value);
                setRegion([...newRegions]);
              }}
              removeSelected={value => {
                const index = region?.indexOf(value);
                if (index !== -1) {
                  let newRegions = region;
                  newRegions?.splice(index, 1);
                  setRegion([...newRegions]);
                }
              }}
              clearSelected={() => setRegion(null)}
              disabled={!gameMode}
              selected={region ?? []}
              select
              selectType={'region'}
            />
          </Grid>
        </Grid>
      </Grid>

      {gameMode && (
        <>
          {showFirstTo() && (
            <>
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography sx={styles.subLabel}>First to</Typography>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <CustomDropdown
                      options={firstToOptions}
                      placeholder='First to'
                      onChange={value => setFirstTo(value)}
                      value={firstTo}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {firstTo > 1 && (
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent='start'
                        alignItems='center'
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.subLabel}>
                            Give round lead{' '}
                            <span style={{ fontWeight: 400 }}>
                              (not required)
                            </span>
                          </Typography>
                        </Grid>

                        <Badge
                          label='You can choose a lead to give your opponent before the match even starts.'
                          size='small'
                          maxWidth={200}
                          textTransform={'none'}
                        >
                          <AiFillInfoCircle
                            style={{
                              color: text,
                              fontSize: badgeIconSizeSmall,
                            }}
                          />
                        </Badge>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <CustomDropdown
                        options={killLeadOptions(firstTo)}
                        placeholder='Round lead'
                        onChange={value => setKillLead(value)}
                        value={killLead}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {(gameMode === 'zbm' || gameMode?.includes('zbm')) && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.subLabel}>Best Of</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <CustomDropdown
                    options={bestOfOptions}
                    placeholder='Best Of'
                    onChange={value => setBestOf(value)}
                    value={bestOf}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default FortniteCreate;
