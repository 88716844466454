import { Grid, Typography, useMediaQuery } from '@mui/material';
import Emote from '../../avatar/pieces/emote/Emote';
import { CDN_URL } from '../../utils/constants';
import { hexToRgbA } from '../../utils/helpers';
import { darkGrey } from '../../utils/themeContstants';

const NonAvatarSimple = ({ snipeAmount, type, monetaryAmount, avatarType }) => {
  const snipes = `${CDN_URL}3d_icons/snipes.png`;
  const exoticCrate = `${CDN_URL}exotic-crate.svg`;
  const emojiCrate = `${CDN_URL}emoji-crate.svg`;
  const godlikeCrate = `${CDN_URL}godlike-crate.svg`;
  const rareCrate = `${CDN_URL}rare-crate.svg`;
  const rivoxCoin = `${CDN_URL}coin.svg`;

  const isDesktop = useMediaQuery('(min-width:1025px)');

  const getBorderColor = () => {
    if (snipeAmount === 5000) {
      return '#ffd700'; // Gold border for amount 5000
    }

    switch (type) {
      case 'exotic':
        return '#fb8b24';
      case 'rare':
        return '#1575e2';
      case 'godlike':
        return '#ff006e';
      case 'emoji':
        return '#ffd700'; // Assuming emojiYellow
      case 'common':
        return '#D7D9E7';
      case 'uncommon':
        return '#15E2A4';
      case 'legendary':
        return '#7052DD';
      case 'money':
        return '#0d4500';
      default:
        return '#1575e2'; // Default to rare for snipes
    }
  };

  const getBackgroundColor = () => {
    if (snipeAmount === 5000) {
      return 'rgb(255, 255, 193)'; // Gold background for amount 5000
    }

    switch (type) {
      case 'exotic':
        return `rgba(251, 139, 36, 0.3)`;
      case 'rare':
        return `rgba(21, 117, 226, 0.3)`;
      case 'godlike':
        return `rgba(255, 0, 110, 0.3)`;
      case 'emoji':
        return hexToRgbA('#ffd700', 0.3); // Assuming emojiYellow
      case 'common':
        return `rgba(215, 217, 231, 0.3)`;
      case 'uncommon':
        return `rgba(21, 226, 164, 0.3)`;
      case 'legendary':
        return `rgba(112, 82, 221, 0.3)`;
      case 'money':
        return 'rgba(119, 255, 87, 0.3)';
      default:
        return `rgba(21, 117, 226, 0.3)`; // Default to rare for snipes
    }
  };

  const getImage = () => {
    if (snipeAmount > 0) return snipes;
    if (monetaryAmount > 0) return rivoxCoin;
    switch (type) {
      case 'exotic':
        return exoticCrate;
      case 'emoji':
        return emojiCrate;
      case 'godlike':
        return godlikeCrate;
      case 'rare':
        return rareCrate;
      default:
        return snipes;
    }
  };

  const getAnimation = () => {
    if (avatarType) return <Emote type={avatarType} />;
  };

  const styles = {
    width: {
      width: '100%',
    },
    pieceContainer: {
      overflow: 'hidden',
      borderRadius: 1.5,
      border: `3px solid ${getBorderColor()}`,
      boxSizing: 'border-box',
      background: getBackgroundColor(),
      position: 'relative',
      width: isDesktop ? null : '100%',
      height: 105,
      width: !isDesktop ? '100%' : 105,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Grid item sx={styles.pieceContainer}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent={isDesktop ? 'start' : 'center'}
      >
        <Grid
          item
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {snipeAmount > 0 && (
            <Grid
              item
              sx={{
                position: 'absolute',
                bottom: -4,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 800,
                  color: '#fff',
                  textShadow: `
                  -1px -1px 0 ${darkGrey},
                  1px -1px 0 ${darkGrey},
                  -1px 1px 0 ${darkGrey},
                  1px 1px 0 ${darkGrey}
                `, // Creates a border-like effect
                }}
              >
                x{snipeAmount}
              </Typography>
            </Grid>
          )}
          {avatarType == null && (
            <img src={getImage()} draggable={false} height={75} width={75} />
          )}

          {avatarType && getAnimation()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NonAvatarSimple;
