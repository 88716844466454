import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { BiCheck, BiRefresh } from 'react-icons/bi';
import { FaGamepad } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';
import { MdLeaderboard } from 'react-icons/md';
import useMiddleware from '../../api/useMiddleware';
import { refreshEpic, removeConnection } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { CDN_URL } from '../../utils/constants';
import {
  badgeIconSize,
  card,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import Badge from '../custom/Badge';
import CustomIconButton from '../custom/CustomIconButton';

const ConnectionItem = ({ connection, type }) => {
  const paypal = `${CDN_URL}connections/paypal.svg`;
  const discordAuth = `${CDN_URL}connections/discord-auth.svg`;
  const twitchAuth = `${CDN_URL}connections/twitch-auth.svg`;
  const twitterAuth = `${CDN_URL}connections/twitter-auth.svg`;
  const googleAuth = `${CDN_URL}connections/google-auth.svg`;
  const chess = `${CDN_URL}connections/chess.svg`;
  const clash = `${CDN_URL}games/clash-transparent.png`;
  const fifa = `${CDN_URL}games/fifa-logo.jpeg`;
  const activision = `${CDN_URL}connections/activision-logo.png`;
  const rivoxLogo = `${CDN_URL}rivox-logo.svg`;

  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const handleRemove = () => {
    setLoading(true);
    removeConnection(middleware, type.toLowerCase()).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Successfully removed connection.',
        });
        updateStore({ type: SET_USER, payload: res?.user });
      }
    });
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    refreshEpic(middleware).then(res => {
      setRefreshLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        updateStore({ type: SET_USER, payload: res?.user });
      }
    });
  };

  const getIcon = () => {
    switch (type) {
      case 'twitch':
        return twitchAuth;
      case 'discord':
        return discordAuth;
      case 'epic':
        return rivoxLogo;
      case 'twitter':
        return twitterAuth;
      case 'paypal':
        return paypal;
      case 'google':
        return googleAuth;
      case 'chess':
        return chess;
      case 'clashroyale':
        return clash;
      case 'fifa':
        return fifa;
      case 'activision':
        return activision;
      default:
        return null;
    }
  };

  const canLogin = () => {
    switch (type) {
      case 'twitch':
      case 'discord':
      case 'google':
        return true;
      default:
        return false;
    }
  };

  const shownOnLeaderboard = () => {
    switch (type) {
      case 'twitch':
      case 'twitter':
        return true;
      default:
        return false;
    }
  };

  const showGameAccount = () => {
    switch (type) {
      case 'clashroyale':
      case 'epic':
      case 'chess':
      case 'fifa':
        return true;
      default:
        return false;
    }
  };

  const shownOnProfile = () => {
    switch (type) {
      case 'twitch':
      case 'twitter':
        return true;
      default:
        return false;
    }
  };

  const getGameType = () => {
    switch (type) {
      case 'chess':
        return 'Chess.com';
      case 'epic':
        return 'Tokens';
      case 'clashroyale':
        return 'Clash Royale';
      case 'fifa':
        return 'FIFA 23';
      default:
        return type.toUpperCase();
    }
  };

  const styles = {
    container: {
      width: '100%',
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      backgroundColor: card,
    },
  };

  return connection ? (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <img
            src={getIcon()}
            alt={`${type}-icon`}
            draggable={false}
            height={48}
          />
        </Grid>

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography
                sx={{ fontSize: normal, color: text, fontWeight: 800 }}
              >
                {getGameType()}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 500,
                }}
              >
                {connection?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            {canLogin() && (
              <Badge label='Login Method'>
                <BiCheck
                  style={{ fontSize: badgeIconSize, color: doubtedBlue }}
                />
              </Badge>
            )}

            {shownOnProfile() && (
              <Badge label='Shown on Public Profile'>
                <AiFillEye
                  style={{ fontSize: badgeIconSize, color: doubtedBlue }}
                />
              </Badge>
            )}

            {shownOnLeaderboard() && (
              <Badge label='Shown on Leaderboard'>
                <MdLeaderboard
                  style={{ fontSize: badgeIconSize, color: doubtedBlue }}
                />
              </Badge>
            )}

            {/* {type === "paypal" && (
              <Badge label="Deposit/Withraw Method">
                <RivoxCoin size={16} />
              </Badge>
            )} */}

            {showGameAccount() && (
              <Badge label='GAME ACCOUNT'>
                <FaGamepad
                  style={{ fontSize: badgeIconSize, color: doubtedBlue }}
                />
              </Badge>
            )}
          </Grid>
        </Grid>

        {type !== 'epic' && type !== 'chess' && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            <CustomIconButton
              label='Remove'
              loading={loading}
              onClick={handleRemove}
              icon={<FiTrash2 style={{ fontSize: 20, color: text }} />}
            />
          </Grid>
          // <Grid item sx={{ marginLeft: "auto" }}>
          //   <SecondaryButton
          //     label="Remove"
          //     type="secondary"
          //     onClick={handleRemove}
          //     loading={loading}
          //   />
          // </Grid>
        )}

        {type === 'epic' && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            <CustomIconButton
              label='Refresh'
              loading={refreshLoading}
              onClick={handleRefresh}
              icon={<BiRefresh style={{ fontSize: 24, color: text }} />}
            />
          </Grid>
          // <Grid item sx={{ marginLeft: "auto" }}>
          //   <SecondaryButton
          //     type="secondary"
          //     label="Refresh"
          //     onClick={handleRefresh}
          //     loading={refreshLoading}
          //   />
          // </Grid>
        )}
      </Grid>
    </Grid>
  ) : null;
};

export default ConnectionItem;
