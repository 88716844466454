import { CircularProgress, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { searchForUser } from '../../api/search';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { normal, offWhite, secondaryText } from '../../utils/themeContstants';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import NotSelected from '../custom/NotSelected';
import SecondaryButton from '../custom/SecondaryButton';
import UserInviteResultItem from './UserInviteResultItem';

const TeamInviteModal = ({ open, onClose, team }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invited, setInvited] = useState([]);
  const [inviteHovered, setInviteHovered] = useState(false);
  const middleware = useMiddleware();

  const handleClose = () => {
    setQuery('');
    setResults(null);
    setLoading(false);
    setInvited([]);
    setInviteHovered(false);
    onClose();
  };

  const handleSearch = searchQuery => {
    if (
      searchQuery === '' ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    searchForUser(middleware, searchQuery).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: offWhite,
    },
  };

  return (
    <CustomModal
      title='Invite Players'
      titleSize='large'
      description='Search for friends to compete with'
      onClose={handleClose}
      open={open}
      primaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <CustomInput
                value={query}
                placeholder='Search users...'
                onChange={val => {
                  setQuery(val);
                  handleSearch(val);
                }}
                autoFocus={true}
                onPaste={val => {
                  setQuery(val);
                  handleSearch(val);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {loading && (
          <Grid item>
            <CircularProgress size={30} sx={{ color: secondaryText }} />
          </Grid>
        )}

        {!loading &&
          query !== '' &&
          query != null &&
          (results == null || results?.length < 1) && (
            <NotSelected label='No results' />
          )}

        {loading ||
        results?.length < 1 ||
        results == null ||
        query === '' ||
        query == null ? null : (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              {results?.map((user, i) => (
                <UserInviteResultItem
                  team={team}
                  user={user}
                  invitedUsers={invited}
                  setInvitedUsers={setInvited}
                  key={i}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default TeamInviteModal;
