import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPotentialCratePieces } from '../../api/avatar';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { AvatarRarityEnum } from '../../utils/enums';
import {
  getAvatarBackgroundColor,
  getAvatarBorder,
  getAvatarBorderColor,
  getAvatarCrateImage,
  numFormatter,
} from '../../utils/helpers';
import {
  card,
  cardVeryLight,
  emojiYellow,
  large,
  normal,
  offWhite,
  red,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import TextButton from '../custom/TextButton';
import ShopItemSimpleGlimmer from '../glimmers/ShopItemSimpleGlimmer';
import NonAvatarSimple from './NonAvatarSimple';
import PurchaseCrateModal from './PurchaseCrateModal';
import ShopItemSimple from './ShopItemSimple';

const CrateItemDetail = () => {
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const location = useLocation();
  const navigate = useNavigate();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);

  const [type, setType] = useState(null);
  const [potentialLoading, setPotentialLoading] = useState(true);
  const [potentialItems, setPotentialItems] = useState(null);
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  const getCrateName = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 'Exotic Crate';
      case AvatarRarityEnum.RARE:
        return 'Rare Crate';
      case AvatarRarityEnum.GODLIKE:
        return 'Godlike Crate';
      case AvatarRarityEnum.EMOJI:
        return 'Emoji Crate';
      case AvatarRarityEnum.EMOTE:
        return 'Emote Crate';
      default:
        return null;
    }
  };

  const getCratePrice = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 4;
      case AvatarRarityEnum.RARE:
        return 2;
      case AvatarRarityEnum.GODLIKE:
        return 1.99;
      case AvatarRarityEnum.EMOJI:
        return 1.49;
      case AvatarRarityEnum.EMOTE:
        return 2.99;
      default:
        return null;
    }
  };

  const getTypeString = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 'Exotic';
      case AvatarRarityEnum.RARE:
        return 'Rare';
      case AvatarRarityEnum.GODLIKE:
        return 'Godlike';
      case AvatarRarityEnum.EMOJI:
        return 'Emoji';
      case AvatarRarityEnum.EMOTE:
        return 'Emote';
      default:
        return null;
    }
  };

  const getSecondaryTypeString = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 'Legendary';
      case AvatarRarityEnum.RARE:
        return 'Uncommon';
      case AvatarRarityEnum.GODLIKE:
        return 'Godlike';
      case AvatarRarityEnum.EMOTE:
        return 'Emote';
      default:
        return null;
    }
  };

  useEffect(() => {
    const path = location?.pathname;

    if (path?.split('/')[3] === 'exotic') {
      setType(AvatarRarityEnum.EXOTIC);
      return;
    }

    if (path?.split('/')[3] === 'rare') {
      setType(AvatarRarityEnum.RARE);
      return;
    }

    if (path?.split('/')[3] === 'godlike') {
      setType(AvatarRarityEnum.GODLIKE);
      return;
    }

    if (path?.split('/')[3] === 'emoji') {
      setType(AvatarRarityEnum.EMOJI);
      return;
    }
    if (path?.split('/')[3] === 'emote') {
      setType(AvatarRarityEnum.EMOTE);
      return;
    }

    setType(null);
  }, [location?.pathname]);

  useEffect(() => {
    if (type && potentialItems == null) {
      getPotentialCratePieces(type).then(res => {
        setPotentialLoading(false);

        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setPotentialItems(res?.pieces);
        }
      });
    }
  }, [type, potentialItems]);

  const styles = {
    width: {
      width: '100%',
    },
    itemContainer: {
      borderRadius: 2,
      border: `3px solid ${getAvatarBorderColor(type)}`,
      boxSizing: 'border-box',
      backgroundColor: getAvatarBackgroundColor(type),
      transition: transition,
      overflow: 'hidden',
      minWidth: isDesktop ? 0 : '100%',
    },
    titleContainer: {
      width: '100%',
      padding: 1,
      backgroundColor: getAvatarBackgroundColor(type),
    },
    name: {
      fontSize: 18,
      color: '#fff',
      fontWeight: 800,
      textAlign: 'center',
    },
    description: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
    },
    header: {
      fontSize: 22,
      color: text,
      fontWeight: 600,
    },
    descriptionContainer: {
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: '100%',
    },
    buttonContainer: {
      padding: 1,
      width: '100%',
      backgroundColor: getAvatarBackgroundColor(type),
    },
    top: {
      width: '100%',
      padding: 2,
      borderBottom: `1px solid ${cardVeryLight}`,
      backgroundColor: card,
    },
    bottom: {
      width: '100%',
      padding: 2,
    },
    descriptionTitle: {
      fontSize: large,
      fontWeight: 700,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <>
      <PurchaseCrateModal
        type={type}
        open={purchaseOpen}
        onClose={() => setPurchaseOpen(false)}
      />

      <Grid item alignSelf='start'>
        <TextButton
          label='Back to Crates'
          color={offWhite}
          icon={
            <BiArrowBack
              style={{
                color: offWhite,
                fontSize: 22,
              }}
            />
          }
          onClick={() => navigate(-1)}
        />
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction={isDesktop ? 'row' : 'column'}
          justifyContent={isDesktop ? 'start' : 'center'}
          alignItems={isDesktop ? 'start' : 'center'}
          gap={{ xs: 2 }}
          wrap={isDesktop ? 'nowrap' : null}
        >
          <Grid item sx={styles.itemContainer}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent={isDesktop ? 'start' : 'center'}
            >
              <Grid item sx={styles.titleContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.name}>{getCrateName()}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  draggable={false}
                  src={getAvatarCrateImage(type)}
                  height={300}
                />
              </Grid>

              <Grid item sx={styles.buttonContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <RivoxCoin size={30} />
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 22,
                            color: text,
                            fontWeight: 800,
                          }}
                        >
                          {numFormatter().format(getCratePrice(type))}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 22,
                            color: red,
                            fontWeight: 600,
                            textDecoration: 'line-through',
                          }}
                        >
                          <span style={{ color: secondaryText }}>
                            {numFormatter().format(getCratePrice(type) * 1.5)}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <SecondaryButton
                      bg={getAvatarBorder(type)}
                      label='Purchase Crate'
                      fullWidth
                      disabled={
                        !store?.user ||
                        !type ||
                        !potentialItems ||
                        potentialLoading
                      }
                      onClick={() => setPurchaseOpen(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              flexGrow: isDesktop ? 1 : null,
              minWidth: isDesktop ? null : '100%',
            }}
          >
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 2 }}
            >
              {/* <Grid item>
                      <Typography sx={styles.header}>
                        {getCrateName()}
                      </Typography>
                    </Grid> */}

              <Grid item sx={styles.descriptionContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item sx={styles.top}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.descriptionTitle}>
                          {getCrateName(type)} Details
                        </Typography>
                      </Grid>

                      {/* <SecondaryButton label="" */}
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.bottom}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                        >
                          <Grid item>
                            <Typography sx={styles.label}>
                              How it works
                            </Typography>
                          </Grid>

                          <Grid item>
                            {type === AvatarRarityEnum.GODLIKE && (
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                The {getCrateName()} drops you ONE item. The
                                rarer the item, the less chance it drops.
                              </Typography>
                            )}
                            {type === AvatarRarityEnum.EMOTE && (
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                The {getCrateName()} drops you ONE item. Each
                                item has an equal chance of being pulled. You
                                cannot receive duplicates.
                              </Typography>
                            )}

                            {type === AvatarRarityEnum.EMOJI && (
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                The {getCrateName()} drops you two{' '}
                                <span
                                  style={{
                                    fontWeight: 800,
                                    color: emojiYellow,
                                  }}
                                >
                                  Emoji
                                </span>{' '}
                                rarity avatars that you do not already own at
                                random.
                              </Typography>
                            )}

                            {type !== AvatarRarityEnum.GODLIKE &&
                              type !== AvatarRarityEnum.EMOJI &&
                              type !== AvatarRarityEnum.EMOTE && (
                                <Typography
                                  sx={{
                                    fontSize: normal,
                                    fontWeight: 500,
                                    color: text,
                                  }}
                                >
                                  The {getCrateName()} drops you 5 pieces in
                                  total. 3 common pieces and 2 of either (
                                  <span
                                    style={{
                                      color: getAvatarBorder(
                                        AvatarRarityEnum[`${type}`]
                                      ),
                                      fontWeight: 800,
                                    }}
                                  >
                                    {getTypeString()}
                                  </span>
                                  /
                                  <span
                                    style={{
                                      color: getAvatarBorder(
                                        type === AvatarRarityEnum.EXOTIC
                                          ? AvatarRarityEnum.LEGENDARY
                                          : AvatarRarityEnum.UNCOMMON
                                      ),
                                      fontWeight: 800,
                                    }}
                                  >
                                    {getSecondaryTypeString()}
                                  </span>
                                  ).
                                </Typography>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                        >
                          <Grid item>
                            <Typography sx={styles.label}>
                              {type === AvatarRarityEnum.GODLIKE
                                ? 'Drop Chances'
                                : "What you'll receive"}
                            </Typography>
                          </Grid>

                          {type === AvatarRarityEnum.GODLIKE && (
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                <li
                                  style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    color: 'rgb(255, 255, 193)',
                                    textShadow: 'rgb(255 93 0) 0px 0px 10px',
                                  }}
                                >
                                  5000 SNIPES (0.1%)
                                </li>
                                <li>250 Snipes (0.2%)</li>
                                <li>100 Snipes (2%)</li>
                                <li>Godlike Avatar (3.5%)</li>
                                <li>50 Snipes (4.4%)</li>
                                <li>25 Snipes (10%)</li>
                                <li>10 Snipes (25.5%)</li>
                                <li>5 Snipes (54.3%)</li>
                              </Typography>
                            </Grid>
                          )}

                          {type === AvatarRarityEnum.EMOJI && (
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                <li>2 pieces in total</li>
                                <li>
                                  Both pieces are of the{' '}
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      color: emojiYellow,
                                    }}
                                  >
                                    Emoji
                                  </span>{' '}
                                  rarity
                                </li>
                              </Typography>
                            </Grid>
                          )}

                          {type !== AvatarRarityEnum.GODLIKE &&
                            type !== AvatarRarityEnum.EMOJI &&
                            type !== AvatarRarityEnum.EMOTE && (
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontSize: normal,
                                    fontWeight: 500,
                                    color: text,
                                  }}
                                >
                                  <li>5 avatar pieces in total</li>

                                  <li>
                                    2 of either{' '}
                                    <span
                                      style={{
                                        color: getAvatarBorder(
                                          type === AvatarRarityEnum.EXOTIC
                                            ? AvatarRarityEnum.EXOTIC
                                            : AvatarRarityEnum.RARE
                                        ),
                                        fontWeight: 800,
                                      }}
                                    >
                                      {type === AvatarRarityEnum.EXOTIC
                                        ? 'Exotic'
                                        : 'Rare'}
                                    </span>{' '}
                                    or{' '}
                                    <span
                                      style={{
                                        color: getAvatarBorder(
                                          type === AvatarRarityEnum.EXOTIC
                                            ? AvatarRarityEnum.LEGENDARY
                                            : AvatarRarityEnum.UNCOMMON
                                        ),
                                        fontWeight: 800,
                                      }}
                                    >
                                      {type === AvatarRarityEnum.EXOTIC
                                        ? 'Legendary'
                                        : 'Uncommon'}
                                    </span>{' '}
                                    rarity avatar pieces (2 of one rarity, or
                                    one of each)
                                  </li>

                                  <li>
                                    3{' '}
                                    <span
                                      style={{
                                        color: getAvatarBorder(
                                          AvatarRarityEnum.COMMON
                                        ),
                                        fontWeight: 800,
                                      }}
                                    >
                                      Common
                                    </span>{' '}
                                    avatar pieces
                                  </li>
                                </Typography>
                              </Grid>
                            )}
                          {type == AvatarRarityEnum.EMOTE && (
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: normal,
                                  fontWeight: 500,
                                  color: text,
                                }}
                              >
                                <li>
                                  1{' '}
                                  <span
                                    style={{
                                      color: getAvatarBorder(
                                        AvatarRarityEnum.EMOTE
                                      ),
                                      fontWeight: 800,
                                    }}
                                  >
                                    GODLIKE
                                  </span>{' '}
                                  Emote
                                </li>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.header}>Potential Drops</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.description}>
                      All the items that can appear in the {getCrateName(type)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ flexGrow: 1 }}>
                <Divider
                  sx={{
                    width: '100%',
                    backgroundColor: cardVeryLight,
                    height: 2,
                    borderRadius: 1,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {potentialLoading && !potentialItems && (
            <Grid item sx={styles.width}>
              <ShopItemSimpleGlimmer size={100} numItems={30} />
            </Grid>
          )}

          {!potentialLoading && potentialItems && (
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 1 }}
              >
                {potentialItems?.map((item, i) => (
                  <ShopItemSimple key={i} item={item} />
                ))}
                {type === AvatarRarityEnum.GODLIKE && (
                  <>
                    <NonAvatarSimple snipeAmount={5} />
                    <NonAvatarSimple snipeAmount={10} />
                    <NonAvatarSimple snipeAmount={25} />
                    <NonAvatarSimple snipeAmount={50} />
                    <NonAvatarSimple snipeAmount={100} />
                    <NonAvatarSimple snipeAmount={250} />
                    <NonAvatarSimple snipeAmount={5000} />
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CrateItemDetail;
