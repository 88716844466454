import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import { getWordLogo, hexToRgbA } from '../../utils/helpers';
import {
  card,
  cardVeryLight,
  linearGradientToTop,
  text,
} from '../../utils/themeContstants';
import DialogGlimmer from '../glimmers/DialogGlimmer';
import CustomIconButton from './CustomIconButton';

const StepperDialog = ({
  open,
  onClose,
  title,
  stepperItems,
  children,
  primaryButton,
  secondaryButton,
  loading,
  currentState,
  game,
  minWidth = 450,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const ref = useRef(null);

  useEffect(() => {
    if ((currentState || currentState === 0) && ref?.current) {
      ref.current.scrollTo({
        top: 0,
        left: currentState * 100,
        behavior: 'smooth',
      });
    }
  }, [currentState]);

  const styles = {
    width: {
      width: '100%',
    },
    card: {
      backgroundColor: card,
      minWidth: isDesktop ? minWidth : isTablet ? '100%' : '97%',
      maxWidth: 800,
      borderRadius: 12,
      boxShadow: '0 1px 5px 1px rgb(0 0 0 / 40%)',
      height: isTablet ? '100%' : null,
    },
    top: {
      width: '100%',
      backgroundColor: card,
      position: 'relative',
      // backgroundImage: `url(${getGameHeader(game)})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 10,
      top: 10,
      zIndex: 10,
    },
    title: {
      color: text,
      textAlign: 'left',
      fontSize: 34,
      fontWeight: 800,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: styles.card }}
      BackdropProps={{ style: { background: 'rgba(16, 18, 27, 0.8)' } }}
    >
      <DialogTitle sx={styles.top}>
        {onClose && (
          <Grid item sx={styles.closeButton}>
            <CustomIconButton
              onClick={onClose}
              icon={<IoClose style={{ color: text, fontSize: 20 }} />}
              opacity
            />
          </Grid>
        )}

        <div
          style={{
            backgroundColor: hexToRgbA(card, 0.6),
            position: 'absolute',
            width: '100%',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 0,
          }}
        />

        <div
          style={{
            background: linearGradientToTop(
              hexToRgbA(card, 1),
              hexToRgbA(card, 0)
            ),
            position: 'absolute',
            width: '100%',
            top: 0,
            bottom: -1,
            right: 0,
            left: 0,
            zIndex: 0,
          }}
        />

        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={{ xs: 1 }}
          sx={{ zIndex: 2 }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              draggable={false}
              height={game === 'clashroyale' ? 60 : 50}
              src={getWordLogo(game)}
            />
          </Grid>
          <Grid item>
            <Typography sx={styles.title}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {loading && <DialogGlimmer />}

        {!loading && (
          <>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 2 }}
            >
              <Grid item sx={{ width: '100%', paddingTop: 1 }}>
                <Grid container justifyContent='center' alignItems='center'>
                  {stepperItems?.map(item => item)}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Divider
                  sx={{ width: '100%', backgroundColor: cardVeryLight }}
                />
              </Grid>

              {children}
            </Grid>
          </>
        )}
      </DialogContent>

      {!loading && (
        <DialogActions sx={{ padding: 2 }}>
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='end'
              alignItems='center'
              gap={{ xs: 2 }}
            >
              <Grid item>{secondaryButton}</Grid>

              <Grid item>{primaryButton}</Grid>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default StepperDialog;
