import {
  Divider,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineShopping } from 'react-icons/ai';
import { BiArrowToLeft, BiArrowToRight, BiPlus, BiX } from 'react-icons/bi';
import { FaHistory, FaUsers } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { RiSwordLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSensitiveMatch } from '../../api/matches';
import useMiddleware, { getAccessToken } from '../../api/useMiddleware';
import {
  getRecentlyPlayed,
  getRecentPurchase,
  getUserTeamsDetailed,
} from '../../api/user';
import {
  SET_ERRORS,
  SET_LEFT_RAIL_OPEN,
  SET_TEAMS,
  SET_WALLET_OPEN,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { getGameName, getMatchTitle, numFormatter } from '../../utils/helpers';
import {
  card,
  cardDark,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  emptyIconSmall,
  offWhite,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import Empty from '../custom/Empty';
import Hover from '../custom/Hover';
import NewListItem from '../custom/NewListItem';
import SecondaryButton from '../custom/SecondaryButton';
import NewsModal from '../news/NewsModal';
import PurchaseChatUnbanModal from '../public_profile/PurchaseChatUnbanModal';
import PurchaseUnbanModal from '../public_profile/PurchaseUnbanModal';
import ResetStatsModal from '../public_profile/ResetStatsModal';
import BuySnipesModal from '../subscriptions/BuySnipesModal';
import CreateTeamModal from '../teams/CreateTeamModal';
import Wallet from '../wallet/Wallet';

import { TbFlame } from 'react-icons/tb';
import useNotifications from '../../sockets/useNotifications';
import { TEAMS_LIMIT } from '../../utils/limits';
import usePagination from '../../utils/usePagination';
import PurchaseListItem from '../custom/PurchaseListItem';
import '../custom/styles/ScrollBar.css';
import RecreateMatchModal from '../match/RecreateMatchModal';
import CreateMatch from '../matches/CreateMatch';
import UpdateTOSModal from '../support/UpdateTOSModal';
import TeamProfileModal from '../teams/TeamProfileModal';
import PurchaseMenu from './PurchaseMenu';

const ListItemGlimmer = () => {
  const styles = {
    width: {
      width: '100%',
    },
  };
  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        <Grid item>
          <Skeleton
            variant='circular'
            width={36}
            height={36}
            sx={{
              bgcolor: cardVeryLight,
            }}
            animation='pulse'
          />
        </Grid>

        <Grid item sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Skeleton
                variant='text'
                width={150}
                height={15}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>

            <Grid item>
              <Skeleton
                variant='text'
                width={150}
                height={15}
                sx={{
                  bgcolor: cardVeryLight,
                }}
                animation='pulse'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LeftRail = () => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const middleware = useMiddleware();
  const { newPurchase, newHistory } = useNotifications(
    store?.user?._id,
    getAccessToken()
  );
  const teamsBottom = useRef(null);

  const [open, setOpen] = useState(true);
  const [toggleHovered, setToggleHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [selected, setSelected] = useState('home');
  const [walletOpen, setWalletOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [unbanOpen, setUnbanOpen] = useState(false);
  const [chatUnbanOpen, setChatUnbanOpen] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);

  const [matchLoading, setMatchLoading] = useState(false);
  const [match, setMatch] = useState(null);
  const [recentLoading, setRecentLoading] = useState(true);
  const [recentMatch, setRecentMatch] = useState(null);
  const [teamsLoading, setTeamsLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [purchase, setPurchase] = useState(null);
  const [purchaseMenuAnchor, setPurchaseMenuAnchor] = useState(null);
  const [recreateOpen, setRecreateOpen] = useState(false);

  const [createMatchOpen, setCreateMatchOpen] = useState(false);
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const [gameOpen, setGameOpen] = useState(false);
  const [game, setGame] = useState(null);
  const [tosOpen, setTosOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamSkip, setTeamSkip] = useState(0);
  const [hasMoreTeams, setHasMoreTeams] = useState(false);

  const handlePurchaseMenuOpen = e => {
    e.stopPropagation();
    setPurchaseMenuAnchor(e.currentTarget);
  };

  const handlePurchaseMenuClose = e => {
    e?.stopPropagation();
    setPurchaseMenuAnchor(null);
  };

  const handleGetMatch = () => {
    setMatchLoading(true);
    getSensitiveMatch(middleware, store?.user?.match_info?.match_id).then(
      res => {
        setMatchLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setMatch(res?.match);
        }
      }
    );
  };

  const handleGetRecentlyPlayed = () => {
    getRecentlyPlayed(middleware).then(res => {
      setRecentLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setRecentMatch(res?.recently_played);
      }
    });
  };

  const handleGetRecentPurchase = () => {
    getRecentPurchase(middleware).then(res => {
      setPurchaseLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setPurchase(res?.recent_purchase);
      }
    });
  };

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware, null, teamSkip).then(res => {
      setTeamsLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.teams?.length >= TEAMS_LIMIT) {
          setHasMoreTeams(true);
        } else {
          setHasMoreTeams(false);
        }

        updateStore({
          type: SET_TEAMS,
          payload: store?.teams ? [...store?.teams, ...res?.teams] : res?.teams,
        });
      }
    });
  };

  const toggleOpen = () => {
    updateStore({ type: SET_LEFT_RAIL_OPEN, payload: open ? false : true });
  };

  const getWidth = () => {
    if (isDesktop) {
      return open ? 240 : 40;
    } else {
      if (open) {
        return '100%';
      } else {
        return 0;
      }
    }
  };

  usePagination(teamsBottom, () => setTeamSkip(teams?.length));

  useEffect(() => {
    if (teamSkip !== 0) {
      handleGetTeams();
    }
  }, [teamSkip]);

  useEffect(() => {
    if (newPurchase) {
      if (newPurchase?._id !== purchase?._id) {
        setPurchase(newPurchase);
      }
    }
  }, [newPurchase]);

  useEffect(() => {
    if (newHistory) {
      if (newHistory?._id !== recentMatch?._id) {
        setRecentMatch(newHistory);
      }
    }
  }, [newHistory]);

  useEffect(() => {
    if (store?.user) {
      if (!teams && teamsLoading) {
        return handleGetTeams();
      }
    }
  }, [store?.user, teams]);

  useEffect(() => {
    if (store?.user) {
      if (store?.teams && store?.teams?.length !== teams?.length) {
        setTeams(store?.teams);
        setTeamsLoading(false);
      }
    }
  }, [store?.user, store?.teams]);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.match_info?.match_id && !match && !matchLoading) {
        return handleGetMatch();
      }

      if (!store?.user?.match_info?.match_id) {
        return setMatch(null);
      }
    }
  }, [store?.user, match]);

  useEffect(() => {
    if (store?.user) {
      if (!recentMatch) {
        handleGetRecentlyPlayed();
      }
    } else {
      setRecentLoading(false);
    }
  }, [store?.user, recentMatch]);

  useEffect(() => {
    if (store?.user) {
      if (!purchase) {
        handleGetRecentPurchase();
      } else {
        setPurchaseLoading(false);
      }
    }
  }, [store?.user, purchase]);

  useEffect(() => {
    if (store.leftRailOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [store.leftRailOpen]);

  useEffect(() => {
    const path = location?.pathname;

    if (path === '/') {
      setSelected('home');
      return;
    }

    if (path === '/leaderboard') {
      setSelected('leaderboard');
      return;
    }

    if (path === '/locker') {
      setSelected('locker');
      return;
    }

    if (path === '/shop') {
      setSelected('shop');
      return;
    }

    if (path === '/games') {
      setSelected('games');
      return;
    }

    if (path?.split('/')[2] === 'history') {
      setSelected('history');
      return;
    }

    if (path?.split('/')[2] === 'accounts') {
      setSelected('accounts');
      return;
    }

    if (path?.split('/')[2] === 'withdrawals') {
      setSelected('withdrawals');
      return;
    }

    if (path?.split('/')[2] === 'deposits') {
      setSelected('deposits');
      return;
    }

    if (path?.split('/')[2] === 'purchases') {
      setSelected('purchases');
      return;
    }

    if (path?.split('/')[1] === 'profile') {
      setSelected('profile');
      return;
    }

    if (path?.split('/')[2] === 'terms') {
      setSelected('terms');
      return;
    }

    if (path?.split('/')[2] === 'privacy') {
      setSelected('privacy');
      return;
    }

    if (path?.split('/')[2] === 'faq') {
      setSelected('faq');
      return;
    }

    if (path?.split('/')[1] === 'staff') {
      setSelected('staff');
      return;
    }

    setSelected(null);
  }, [location]);

  const styles = {
    container: {
      position: 'fixed',
      left: 0,
      top: 102,
      width: getWidth(),
      minWidth: getWidth(),
      minHeight: 'calc(100vh - 174px)',
      height: 'calc(100vh - 174px)',
      backgroundColor: open ? cardDark : 'transparent',
      // borderRight: !isDesktop && !open ? null : `1px solid ${cardVeryLight}`,
      zIndex: 98,
      maxHeight: '100vh',
      boxSizing: 'border-box',
      overflowY: open ? 'auto' : null,
    },
    width: {
      width: '100%',
    },
    listContainers: {
      width: '100%',
      paddingLeft: open ? 1 : 0,
      paddingRight: open ? 1 : 0,
    },
    bottomContainer: {
      width: !isDesktop ? '100%' : 240,
      backgroundColor: open ? card : 'transparent',
      padding: 2,
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 10,
    },
    label: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    icon: {
      fontSize: 22,
      color: toggleHovered ? text : offWhite,
      transition: transition,
    },
    iconSelected: {
      fontSize: 20,
      color: doubtedBlue,
    },
    iconNotSelected: {
      fontSize: 20,
      color: offWhite,
    },
    dividerContainer: {
      width: '100%',
      paddingLeft: 2,
      paddingRight: 1,
    },
    divider: { width: '100%', backgroundColor: text, opacity: 0.1 },
    header: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1,
      position: 'relative',
      backgroundColor: open
        ? toggleHovered
          ? cardVeryLight
          : cardLight
        : toggleHovered
          ? cardVeryLight
          : cardLight,
      borderRadius: 1.5,
      transition: transition,
      cursor: toggleHovered ? 'pointer' : 'default',
      boxShadow: !open
        ? '0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f'
        : 'none',
      marginTop: isTablet ? (!open ? 6 : 0) : 0,
      marginBottom: !open ? null : 0.5,
    },
  };

  return (
    <>
      <Wallet
        open={walletOpen}
        onClose={() => setWalletOpen(false)}
        selectedTab='deposit'
      />
      <ResetStatsModal open={resetOpen} onClose={() => setResetOpen(false)} />
      <BuySnipesModal open={snipesOpen} onClose={() => setSnipesOpen(false)} />
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PurchaseChatUnbanModal
        open={chatUnbanOpen}
        onClose={() => setChatUnbanOpen(false)}
      />
      <NewsModal open={newsOpen} onClose={() => setNewsOpen(false)} />
      <CreateTeamModal
        open={createTeamOpen}
        onClose={() => setCreateTeamOpen(false)}
      />
      <PurchaseMenu
        anchor={purchaseMenuAnchor}
        handleClose={handlePurchaseMenuClose}
      />
      <RecreateMatchModal
        currentMatch={recentMatch}
        open={recreateOpen}
        onClose={() => setRecreateOpen(false)}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />
      {/* <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      /> */}
      <CreateMatch
        open={createMatchOpen}
        onClose={() => setCreateMatchOpen(false)}
        game={'fortnite'}
      />

      <TeamProfileModal
        open={teamOpen}
        onClose={() => {
          setTeamOpen(false);
          setSelectedTeam(null);
        }}
        team={selectedTeam}
      />

      <Grid item sx={styles.container} className='scroll'>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='start'
          gap={{ xs: open ? 1 : 0 }}
          sx={{
            paddingBottom: 1,
          }}
          wrap='nowrap'
        >
          {!open && (
            <Grid
              item
              sx={{ ...styles.toggleContainer, marginTop: 1, marginLeft: 1 }}
              ref={setRefEl}
              onClick={() => {
                toggleOpen();
                setToggleHovered(false);
              }}
              onMouseEnter={() => setToggleHovered(true)}
              onMouseLeave={() => setToggleHovered(false)}
            >
              {toggleHovered && (
                <Hover
                  label={'Open Activity Menu'}
                  placement='right'
                  refEl={refEl}
                />
              )}

              {store?.user?.match_info?.match_id && (
                <Grid
                  item
                  sx={{
                    position: 'absolute',
                    top: -3,
                    right: -3,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className='smallLive' />
                </Grid>
              )}

              <BiArrowToRight style={styles.icon} />
            </Grid>
          )}

          {open && (
            <Grid item sx={{ ...styles.listContainers, marginTop: 1 }}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={{ ...styles.width, marginBottom: -1 }}>
                  <Grid
                    container
                    justifyContent={open ? 'space-between' : 'center'}
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.header}>ONGOING MATCH</Typography>
                    </Grid>

                    <Grid
                      item
                      sx={styles.toggleContainer}
                      ref={setRefEl}
                      onClick={() => {
                        toggleOpen();
                        setToggleHovered(false);
                      }}
                      onMouseEnter={() => setToggleHovered(true)}
                      onMouseLeave={() => setToggleHovered(false)}
                    >
                      {toggleHovered && (
                        <Hover
                          label={'Collapse'}
                          placement='right'
                          refEl={refEl}
                        />
                      )}

                      <BiArrowToLeft style={styles.icon} />
                    </Grid>
                  </Grid>
                </Grid>

                {!store?.user?.match_info?.match_id && (
                  <>
                    <Empty
                      title='No ongoing match'
                      label='You are not in a match.'
                      icon={<RiSwordLine style={emptyIconSmall} />}
                      size='small'
                      buttonLabel='Create Match'
                      buttonOnClick={() => setCreateMatchOpen(true)}
                    />
                  </>
                )}

                {store?.user?.match_info?.match_id && (
                  <>
                    {matchLoading && <ListItemGlimmer />}

                    {!matchLoading && match && (
                      <NewListItem
                        title={getMatchTitle(
                          match?.game_match_type,
                          match?.team_size,
                          match?.game_map_type,
                          match?.extra_options,
                          match?.game_mode_options
                        )}
                        description={`${getGameName(
                          match?.game
                        )} • ${numFormatter().format(match?.entry_fee)} Entry`}
                        live={true}
                        backgroundHover={cardLight}
                        onClick={() =>
                          navigate(
                            `/token/${store?.user?.match_info?.match_id}`
                          )
                        }
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {open && store?.user && (
            <Grid item sx={styles.dividerContainer}>
              <Divider sx={styles.divider} />
            </Grid>
          )}

          {/* {open && store?.user && (
            <Grid item sx={styles.listContainers}>
              <Typography sx={styles.label}>For You</Typography>
            </Grid>
          )} */}

          {open && (
            <Grid item sx={styles.listContainers}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.header}>
                        RECENTLY PLAYED
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {!recentLoading && !recentMatch && (
                  <Empty
                    size='small'
                    title='No recent matches'
                    label='Try playing a match.'
                    icon={<TbFlame style={emptyIconSmall} />}
                  />
                )}

                {recentLoading && !recentMatch && <ListItemGlimmer />}

                {!recentLoading && recentMatch && (
                  <>
                    <NewListItem
                      title={getMatchTitle(
                        recentMatch?.game_match_type,
                        recentMatch?.team_size,
                        recentMatch?.game_map_type,
                        recentMatch?.extra_options,
                        recentMatch?.game_mode_options
                      )}
                      description={`${getGameName(recentMatch?.game)} • ${numFormatter().format(
                        recentMatch?.entry_fee
                      )} Entry`}
                      icon={<FaHistory style={styles.icon} />}
                      backgroundHover={cardLight}
                      onClick={() => navigate(`/token/${recentMatch?._id}`)}
                    />

                    {!store?.user?.match_info?.match_id && (
                      <SecondaryButton
                        label='Recreate Match'
                        fullWidth
                        size='small'
                        onClick={() => setRecreateOpen(true)}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {open && (
            <Grid item sx={styles.dividerContainer}>
              <Divider sx={styles.divider} />
            </Grid>
          )}

          {open && store?.user && (
            <Grid item sx={styles.listContainers}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.header}>
                        RECENTLY PURCHASED
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {!purchaseLoading && !purchase && (
                  <Empty
                    size='small'
                    title='No recent purchases'
                    label='Visit our shop.'
                    icon={<AiOutlineShopping style={emptyIconSmall} />}
                    buttonLabel='Shop Now'
                    buttonOnClick={handlePurchaseMenuOpen}
                  />
                )}

                {purchaseLoading && <ListItemGlimmer />}

                {!purchaseLoading && purchase && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                    >
                      <PurchaseListItem
                        purchase={purchase}
                        onClick={() => navigate('/profile/purchases')}
                      />
                      <NewListItem
                        title={'New Purchase'}
                        icon={
                          purchaseMenuAnchor ? (
                            <BiX style={styles.icon} />
                          ) : (
                            <BiPlus style={styles.icon} />
                          )
                        }
                        backgroundHover={cardLight}
                        onClick={
                          purchaseMenuAnchor
                            ? handlePurchaseMenuClose
                            : handlePurchaseMenuOpen
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {open && (
            <Grid item sx={styles.dividerContainer}>
              <Divider sx={styles.divider} />
            </Grid>
          )}

          {open && store?.user && (
            <Grid item sx={styles.listContainers}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.header}>YOUR TEAMS</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {teamsLoading && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      <ListItemGlimmer />
                      <ListItemGlimmer />
                      <ListItemGlimmer />
                      <ListItemGlimmer />
                      <ListItemGlimmer />
                    </Grid>
                  </Grid>
                )}

                {!teamsLoading && !teams && (
                  <Empty
                    size='small'
                    title='No teams'
                    label='Try creating a team.'
                    icon={<FiUsers style={emptyIconSmall} />}
                    buttonLabel='Create Team'
                    buttonOnClick={() => setCreateTeamOpen(true)}
                  />
                )}

                {!teamsLoading && teams && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                    >
                      <NewListItem
                        title={'Create Team'}
                        icon={<BiPlus style={styles.icon} />}
                        backgroundHover={cardLight}
                        onClick={() => setCreateTeamOpen(true)}
                      />
                      {teams?.map((team, i) => (
                        <NewListItem
                          key={i}
                          title={team?.name}
                          description={`${team?.users?.length} players`}
                          icon={<FaUsers style={styles.icon} />}
                          backgroundHover={cardLight}
                          onClick={() => {
                            setSelectedTeam(team);
                            setTeamOpen(true);
                          }}
                        />
                      ))}
                      {hasMoreTeams && (
                        <div style={{ width: '100%' }} ref={teamsBottom}>
                          <ListItemGlimmer />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {open && (
        <Grid item sx={styles.bottomContainer}>
          {store?.user && (
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              gap={{ xs: 2 }}
            >
              <Grid item sx={styles.width}>
                <SecondaryButton
                  bg='rgba(32, 108, 84, 1)'
                  label='Add Funds'
                  fullWidth
                  onClick={() =>
                    updateStore({ type: SET_WALLET_OPEN, payload: true })
                  }
                  icon={
                    <MdAccountBalanceWallet
                      style={{ fontSize: 18, color: text }}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}

          {!store?.user && (
            <SecondaryButton
              label='Get Started'
              fullWidth
              onClick={() => navigate('/login')}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default LeftRail;
