import { Grid, Typography } from '@mui/material';
import { bestOfOptions, newGameModeOptions } from '../../../utils/options';
import { normal } from '../../../utils/themeContstants';
import CustomDropdown from '../../custom/CustomDropdown';

const ChessCreate = ({ game, setGameMode, gameMode, setBestOf, bestOf }) => {
  const styles = {
    width: {
      width: '100%',
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: '#d7e4f1',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Game mode</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder='Game mode'
              onChange={value => setGameMode(value)}
              value={gameMode}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Best of</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={bestOfOptions}
              placeholder='Best of'
              onChange={value => setBestOf(value)}
              value={bestOf}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChessCreate;
