import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../store/Store';
import {
  card,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import UpdateTOSModal from './UpdateTOSModal';

const Terms = () => {
  const store = useContext(StoreContext);

  const [tosOpen, setTosOpen] = useState(false);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
        setTosOpen(true);
        return;
      }
    }
  }, [store?.user]);

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    container: {
      width: '100%',
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
    },
    modified: {
      fontSize: normal,
      fontWeight: 700,
      color: secondaryText,
    },
    forward: {
      fontWeight: 500,
      color: text,
      fontSize: 16,
    },
    subHeader: {
      fontSize: 22,
      fontWeight: 800,
      color: text,
    },
    text: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <>
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.header}>Terms of Service</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.modified}>
              Last modified: May 23, 2024
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 4 }}
          sx={{ backgroundColor: cardLight, padding: 2, borderRadius: 1 }}
        >
          <Grid item>
            <Typography sx={styles.text}>
              1. ACCEPTING THESE TERMS
              <br />
              1.1. This document, our rules, policies, and the other documents
              referenced make up our Terms of Service (“Terms”). The Terms are a
              legally binding contract between you and Rivox Entertainment. This
              contract sets out your rights and responsibilities when you use
              our website. Please read them carefully.
              <br />
              <br />
              2. Changes
              <br />
              2.1. Rivox Entertainment may amend the Terms at any time by
              posting a revised version on our website without prior notice. The
              revised version will be effective at the time we post it.
              Following any change to the Terms a notification will be provided
              as notice upon visitation to the website.
              <br />
              <br />
              3. ACCESS
              <br />
              3.1 You are granted a non-exclusive, limited, and revocable
              license to access the website and use its functionality on the
              condition that: You are over the age of 18; You are able to give
              consent to use of digital data in your jurisdiction; You only use
              the website for lawful purposes; You can agree to these terms at
              all times; You do not engage in any improper, indecent or
              offensive behavior while using the website; and You are not
              breaking any law in your relevant jurisdiction by accessing this
              website.
              <br />
              3.2 Rivox Entertainment has developed a additional software called
              the "Rivox Anti-Cheat" in order to ensure the integrity of all
              matches and competitions. This software is not requested to be
              installed on any user by default. However, if a user is suspected
              of cheating, exploiting, or otherwise violating the integrity of
              the platform as defined in these terms, Rivox Entertainment
              reserves the right to enforce a installation of the Rivox
              anti-cheat. In the event of a refusal of the installation, Rivox
              Entertainment can and will immediately suspend and terminate the
              account of the refuser. Disclosure of the Anti-cheat's operations
              is confidential to prevent bad actors from negating it's purpose.
              Rivox Entertainment holds itself to a high standard when it comes
              to user security and will exclusively use findings and
              declarations made by the anti-cheat to ensure the integrity of the
              platform.
              <br />
              <br />
              4. YOUR DETAILS AND VISITS TO THIS WEBSITE
              <br />
              4.1 When you use this website, you agree to the processing of the
              information and details and you state that all information and
              details provided are true and correspond to reality.
              <br />
              <br />
              5. PROHIBITED USES
              <br />
              You may not use, or encourage, promote, facilitate, instruct, or
              induce others to use, the website or website services for any
              activities that violate any law, statute, ordinance, or
              regulation; For any other illegal or fraudulent purpose or any
              purpose that is harmful to others; or to transmit, store, display,
              distribute or otherwise make available content that is illegal,
              fraudulent, or harmful to others.
              <br />
              5.1 Selling or trading virtual coins or any digital assets for
              real-world currency or any form of compensation is strictly
              prohibited. Any violation of this rule will result in immediate
              account suspension or termination without notice.
              <br />
              <br />
              6. SECURITY
              <br />
              Rivox Entertainment ensures the protection and honesty of the data
              it gathers by:
              <br />
              - Utilizing fitting authoritative conventions
              <br />
              - Specialized shields
              <br />
              - Actual security controls intended to restrict access
              <br />
              - Identify and forestall the unapproved access
              <br />
              - Preventing inappropriate divulgence, adjustments, and
              obliteration of the data under its influence
              <br />
              <br />
              7. Conditions of Participation
              <br />
              7.1 The prerequisite for using the gaming platform is registered
              with the organizer. The registration is free of charge. Multiple
              registrations by one game participant are not permitted.
              <br />
              7.2 In order to register, the player must complete a registration
              form. The player chooses a method promoted by the website to sign
              up with. The player must provide the data requested in the
              registration form completely and correctly. It is prohibited to
              register using extraneous or otherwise inaccurate information. It
              is prohibited to register on behalf of someone else. The organizer
              shall confirm receipt of the details submitted to the organizer
              with the registration by sending an e-mail to the e-mail address
              provided by the player during registration.
              <br />
              7.3 If the player subsequently wishes to participate in games with
              monetary stakes, he must deposit means of payment and
              corresponding payment data in the further course.
              <br />
              7.4 There is no entitlement to the conclusion of a contract of
              use. The organizer can reject a registration without giving
              reasons. In this case, the organizer will delete the transmitted
              data immediately.
              <br />
              7.5 If the player subsequently wishes to participate in games with
              monetary stakes, they must be over the age of 18 and they must
              ensure that this is allowed and not prohibited under their
              jurisdiction. In the event of a violation of this, the organizer
              reserves the right to terminate the player's account/data.
              <br />
              7.6 Any player may be blocked from the gaming platform by the
              organizer for good cause or no cause. The organizer reserves the
              right to; effective immediately, terminate any one account at any
              time without prior notice. No refunds for purchases of virtual
              currency or other digital goods will be granted under any
              circumstances. Under this pretence any restricted account will
              have their funds withheld.
              <br />
              7.7 If the player is required by their jurisdiction to provide
              additional information for the purposes of participation, claiming
              prizes, or competing in games of skill, they must inform the
              organizer via reasonable channels. Appropriate channels can be
              deemed, in particular, to be the submission of a "ticket" or
              "request" in the support server{' '}
              <a
                style={{ fontWeight: 800, color: text }}
                href={'https://discord.gg/rivox'}
              >
                Discord
              </a>{' '}
              or via email support@rivox.gg. Once the organizer has been
              informed and verified the information, the player may now use the
              platform under the regulations and conditions provided by the
              player's jurisdiction, Ontario province laws and Canadian laws.
              Should the player not communicate with the organizer or provide
              the necessary information, the organizer is not responsible for
              the player's actions on the platform. The Organizer, will at the
              knowledge of such violation, banish the player's access to the
              platform. It is the responsibility of the player to ensure that
              partaking in any activities on the platform are lawful in their
              jurisdiction and the Organiser shall not be held responsible for a
              user's unlawful access to the website under this pretence.
              <br />
              <br />
              8. Performance of Games of Skill
              <br />
              8.1 The skill level of a player can be determined on a
              game-by-game basis. It increases and decreases depending on the
              success of the participant in previous participations in the
              specific game. Players can gauge other players' skill by looking
              at their public profile and check who is competing at the moment
              by visiting the "active matches" page.
              <br />
              8.2 In real-time games, a maximum of the number of participants
              specified in the game announcement will participate at the same
              time. In the case of certain real-time games, the game will not
              start until a sufficient number of players have registered for the
              game. The required number of players is specified in the game
              announcement. This is indicated to the player by the number of
              players required and the number of players already registered. Up
              to the start of the game, the player can revoke his participation
              in the real-time game at any time. Once the required number of
              players has been found, the game starts automatically, which is
              indicated by information on the screen and the transfer of the
              stake required for money games. The winner of the game is the
              player who has won the game according to the given game rules.
              After the end of the game, the winner is notified directly in the
              game about the outcome of the game and a possible prize.
              <br />
              8.3 In the case of time-shifted games, each game participant who
              registers for a game round after the game has been announced will
              participate. Each individual game participant starts his personal
              game round immediately after his registration, so that all game
              participants of the gameplay at different times. Depending on the
              game type, the player is entitled to repeat or only one game
              participation. This distinction is tied to whether repeated
              participation in a game round provides a specific advantage. The
              number of players specified in the match announcement must
              participate in the staggered matches. In order to find the
              necessary players, a validity window is defined for each game
              offer, in which players can find each other. Once this number of
              participants has been reached and all players have completed their
              game round, the game is over and participation is no longer
              possible. The organizer then determines which game participant has
              achieved the best result, taking into account the rules of the
              game. However, the player is able to unsubscribe from this
              confirmation mail and only view the information online in the
              "game account".
              <br />
              8.4 By participating in a game for which a fee is charged, the
              participants in the game make mutual declarations of intent by
              which a gaming contract is concluded. The organizer is the
              receiving agent for these declarations of intent of the respective
              other players. When the number of participants required by the
              game announcement has been reached, a game contract has been
              concluded between all participants. According to the concluded
              game contract, the winner of the game is the one who has achieved
              the best result according to the game rules specified by the
              organizer. In the case of a tie in points, the winner is the one
              who has presented the corresponding score or has been better in
              other provable game contents. This is described as the "challenge
              principle". In case of doubt, the organizer has the right to act
              as referee to determine the winner.
              <br />
              8.5 If - for example, due to a malfunction - the match is not
              completed in accordance with the rules, the match shall be deemed
              not to have taken place. The organizer reserves the right to
              suspend, cancel or postpone the game in whole or in part without
              prior notice. This applies if the game cannot be played as
              scheduled for reasons unforeseeable by the organizer and beyond
              the organizer's control. Such circumstances include, but are not
              limited to, hardware or software failure, the occurrence of a
              computer virus or program error, unauthorized third-party
              intervention, and mechanical, technical, or legal problems beyond
              the control and influence of the Promoter. In such cases, players
              will be refunded any stakes paid. If the cancellation of the game
              is due to the culpable violation of rules by a game participant,
              the organizer expressly reserves the right to assert claims, in
              particular claims for damages. We cannot accept any responsibility
              for the processing of such personal data by third parties.
              <br />
              <br />
              9. Participation Fees and Prizes
              <br />
              9.1 If a participation fee is stated for a match in the invitation
              to tender, this will be debited from the virtual stake account of
              the match participant after the player has registered.
              <br />
              9.2 Unless otherwise stated in the game announcement, the winner
              of a fee-based game receives 90% of the entry fees paid by the
              entirety of the participating game participants as winnings. 10%
              of the entry fees shall be paid to the organizer as a handling fee
              for the organization and processing of the game.
              <br />
              9.3 An attempt will be made to credit the winnings to the player's
              virtual wallet account held with Rivox immediately after the
              result has been determined. If this is not possible directly for
              technical reasons, the credit will be carried out again and the
              winnings will be credited within 24 hours at the latest.
              <br />
              <br />
              10. General Obligations of the Players
              <br />
              10.1 Multiple registrations are not permitted.
              <br />
              10.2 The password for the access-protected area of the organizer’s
              website must be kept strictly secret by the player and changed at
              regular intervals (every four weeks at the latest). If the player
              violates these duties of care and an unauthorized third party
              makes dispositions based on knowledge of the required password,
              the player shall be liable for any unauthorized use of his/her
              password made possible by his/her conduct and the associated use
              of the gaming platform. As soon as the player becomes aware that
              his password has become accessible to third parties, he is obliged
              to change his password immediately. If this is not possible, the
              organizer must be informed immediately.
              <br />
              10.3 The aforementioned also applies to the transfer of access
              data to a third party. Passing on access data is generally not
              permitted and can lead to the blocking of the entire player
              account.
              <br />
              10.4 Any change in the personal data of the player (in particular
              the account details, address, and e-mail address) must be reported
              immediately on the personal settings page.
              <br />
              <br />
              11. ENTIRE AGREEMENT
              <br />
              This Agreement contains the entire agreement and understanding
              among the parties hereto with respect to the subject matter
              hereof, and supersedes all prior and contemporaneous agreements,
              understandings, inducements and conditions, express or implied,
              oral or written, of any nature whatsoever with respect to the
              subject matter hereof. 12. PayDo ID 931398
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Terms;
