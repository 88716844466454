import { useMediaQuery } from '@mui/material';
import { Howl } from 'howler';
import { useEffect, useRef, useState } from 'react';
import { confetti } from 'tsparticles-confetti';
import ShopItemSimpleGlimmer from '../glimmers/ShopItemSimpleGlimmer';
import NonAvatarSimple from './NonAvatarSimple';
import ShopItemSimple from './ShopItemSimple';

const CrateOpeningAnimationModal = ({
  open,
  onClose,
  potentialItems,
  receivedItems,
}) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const [shuffledItems, setShuffledItems] = useState([]);
  const [animationDone, setAnimationDone] = useState(false);
  const containerRef = useRef(null);
  const animationTriggered = useRef(false);

  // Initialize the tick sound
  const tickSound = new Howl({
    src: [require('./ticker.mp3')], // Ensure the file path is correct
    volume: 0.5,
  });

  const itemWidth = 106; // Width of each item

  const shuffleArray = array => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const generatePotentialItems = () => {
    //godlike crate
    if (potentialItems.length === 5) {
      const snipePrizes = [
        { isSnipe: true, amount: 5 },
        { isSnipe: true, amount: 10 },
        { isSnipe: true, amount: 25 },
        { isSnipe: true, amount: 50 },
        { isSnipe: true, amount: 100 },
        { isSnipe: true, amount: 250 },
        // Do not include 5000 in this array
      ];
      const snipe5000Prizes = Array(1).fill({ isSnipe: true, amount: 5000 });
      const repeatedSnipePrizes = Array(5).fill(snipePrizes).flat();
      const combinedItems = [
        ...potentialItems,
        ...repeatedSnipePrizes,
        ...snipe5000Prizes,
      ];
      const duplicatedItems = duplicateArray(combinedItems, 10);
      return shuffleArray(duplicatedItems);
    }
    //emoji crate and emoji
    if (potentialItems.length === 22 || potentialItems.length === 12) {
      const duplicatedItems = duplicateArray(potentialItems, 3);
      return shuffleArray(duplicatedItems);
    }

    return shuffleArray(potentialItems);
  };

  const duplicateArray = (array, times) => {
    let result = [];
    for (let i = 0; i < times; i++) {
      result = result.concat(array);
    }
    return result;
  };

  const findAllIndices = (array, target) => {
    let indices = [];
    array.forEach((item, index) => {
      if (
        item._id === target ||
        (item.isSnipe && target === `snipe_${item.amount}`)
      ) {
        indices.push(index);
      }
    });
    return indices;
  };

  const triggerConfetti = () => {
    const confettiOptions = {
      angle: 90,
      count: 500,
      position: { x: 50, y: 65 },
      spread: 45,
      startVelocity: 45,
      decay: 0.95,
      gravity: 1,
      drift: 0,
      ticks: 200,
      colors: ['#ffffff', '#ff0000'],
      shapes: ['square', 'circle', 'star', 'heart', 'spades', 'diamonds'],
      scalar: 1,
      zIndex: 100,
      disableForReducedMotion: true,
    };

    confetti('tsparticles', confettiOptions);
  };

  useEffect(() => {
    if (open && potentialItems.length > 0) {
      setShuffledItems(generatePotentialItems());
      animationTriggered.current = false;
    }
  }, [open, potentialItems]);

  useEffect(() => {
    if (
      open &&
      shuffledItems.length > 0 &&
      receivedItems.length > 0 &&
      !animationTriggered.current
    ) {
      const containerWidth = containerRef.current
        ? containerRef.current.offsetWidth
        : 0;

      const runAnimation = () => {
        tickSound.play(); // Play the tick sound once at the start
        receivedItems.forEach((receivedItem, itemIndex) => {
          const targetIndices = findAllIndices(shuffledItems, receivedItem);

          let randomTargetIndex;
          if (
            potentialItems.length === 5 ||
            potentialItems.length === 22 ||
            potentialItems.length === 12
          ) {
            const adjustedIndices = targetIndices.slice(2); // Ignore the first two indices
            randomTargetIndex =
              adjustedIndices[
                Math.floor(Math.random() * adjustedIndices.length)
              ];
          } else {
            randomTargetIndex =
              targetIndices[Math.floor(Math.random() * targetIndices.length)];
          }
          // Add randomness to the final position
          const randomOffset = (Math.random() - 0.5) * 90; // Random value between -15 and +15
          const translateX =
            -randomTargetIndex * itemWidth +
            (containerWidth / 2 - itemWidth / 2) +
            randomOffset;

          const animationElement = document.getElementById(
            `animation-${itemIndex}`
          );
          if (animationElement) {
            animationElement.style.transition =
              'transform 12s cubic-bezier(0.25, 1, 0.5, 1)';
            animationElement.style.transform = `translateX(${translateX}px)`;
          }
        });

        setTimeout(() => {
          setAnimationDone(true);
          triggerConfetti(); // Trigger confetti animation
        }, 12000); // Total animation time
      };

      const startAnimation = () => {
        if (containerWidth > 0) {
          // Set the initial translateX for all rows to ensure the first item is visible
          for (let i = 0; i < receivedItems.length; i++) {
            const animationElement = document.getElementById(`animation-${i}`);
            if (animationElement) {
              animationElement.style.transform = `translateX(${0}px)`;
            }
          }

          runAnimation();
          animationTriggered.current = true; // Mark animation as triggered
        } else {
          setTimeout(startAnimation, 100);
        }
      };

      setTimeout(startAnimation, 500); // Delay the start of the animation by 0.5 seconds
    }
  }, [open, shuffledItems, receivedItems]);

  const renderReceivedItems = receivedItem => {
    if (typeof receivedItem === 'string' && receivedItem.startsWith('snipe_')) {
      const amount = parseInt(receivedItem.split('_')[1], 10);
      return <NonAvatarSimple snipeAmount={amount} />;
    }
    const item = potentialItems.find(p => p._id === receivedItem);
    return item ? <ShopItemSimple item={item} isCrateObject={true} /> : null;
  };

  return (
    <div
      style={{
        ...styles.modal(isDesktop, isTablet),
        display: open ? 'block' : 'none',
      }}
      id='crateModal'
    >
      <div style={styles.modalContent(isDesktop, isTablet)}>
        {animationDone && (
          <div style={styles.closeButtonContainer}>
            <span style={styles.close} onClick={onClose}>
              &times;
            </span>
          </div>
        )}

        <div style={styles.modalBody}>
          <div style={styles.container} ref={containerRef}>
            {receivedItems.map((receivedItem, itemIndex) => (
              <div
                key={itemIndex}
                style={{
                  display: 'flex',
                  position: 'relative',
                  marginBottom: '16px',
                }}
              >
                <div
                  id={`animation-${itemIndex}`}
                  style={{
                    display: 'flex',
                    transition: 'none',
                    transform: 'translateX(0)',
                  }}
                >
                  {shuffledItems.map((item, i) => (
                    <div key={i} style={{ minWidth: itemWidth }}>
                      {item.isSnipe ? (
                        <NonAvatarSimple snipeAmount={item.amount} />
                      ) : (
                        <ShopItemSimple
                          item={item}
                          isCrateObject={true}
                          isGodlike={potentialItems.length === 5}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: '50%',
                    width: 2,
                    backgroundColor: 'red',
                    transform: 'translateX(-50%)',
                    boxShadow: '0 0 10px red',
                  }}
                />
              </div>
            ))}
          </div>
          {!animationDone && (
            <div style={{ marginTop: '16px' }}>
              <h2
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '20px',
                }}
              >
                Prizes
              </h2>
              <div style={styles.gridContainer}>
                {receivedItems.map((receivedItem, i) => (
                  <div key={i}>
                    <ShopItemSimpleGlimmer
                      size={isDesktop ? 100 : 50}
                      numItems={1}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {animationDone && (
            <div style={{ marginTop: '16px' }}>
              <h2
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '20px',
                }}
              >
                Prizes
              </h2>
              <div style={styles.gridContainer}>
                {/* <div style={styles.backgroundWrapper}> */}
                {receivedItems.map((receivedItem, i) => (
                  <div key={i}>{renderReceivedItems(receivedItem)}</div>
                ))}
              </div>
            </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  modal: (isDesktop, isTablet) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: isDesktop ? 50 : 20,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.9)',
  }),
  modalContent: (isDesktop, isTablet) => ({
    height: isTablet ? '70%' : null,
    overflow: 'auto',
    borderRadius: '8px',
    backgroundColor: '#213744',
    padding: '20px',
    border: '1px solid #325468',
    width: isDesktop ? '60%' : '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
  // backgroundWrapper: {
  //   gap :'2px',
  //   display:"flex",
  //   // boxShadow: '0 0 0 4px gold, 0 0 0 5px darkgoldenrod',
  //   backgroundColor: 'rgba(255, 255, 0, 0.2)',
  //   padding: '4px',
  //   borderRadius: '8px',
  // },
  closeButtonContainer: {
    position: 'absolute',
    top: '0px',
    right: '10px',
  },
  close: {
    color: '#aaa',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  modalBody: {
    marginTop: '16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
  gridContainer: {
    gap: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  closeButton: {
    marginTop: '16px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default CrateOpeningAnimationModal;
