import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { BiArrowToRight } from 'react-icons/bi';
import { BsArrowDownShort, BsFillChatSquareFill } from 'react-icons/bs';
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import { IoIosPause } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import { getMessages, sendMessage } from '../../api/matches';
import useMiddleware, { getAccessToken } from '../../api/useMiddleware';
import useMatch from '../../sockets/useMatch';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { AccountTypeEnum, MatchStateEnum } from '../../utils/enums';
import { MATCH_MESSAGE_LIMIT } from '../../utils/limits';
import {
  card,
  cardLight,
  cardVeryLight,
  container,
  doubtedBlue,
  emptyIcon,
  menuShadow,
  normal,
  red,
  secondaryButton,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import ChatInput from '../custom/ChatInput';
import CustomMenuButton from '../custom/CustomMenuButton';
import Empty from '../custom/Empty';
import Hover from '../custom/Hover';
import SecondaryButton from '../custom/SecondaryButton';
import ChatRulesMenu from '../menu/ChatRulesMenu';
import Evidence from './Evidence';
import { isCreatingTeam, isInMatch, isStaff } from './matchHelpers';
import MatchMessage from './MatchMessage';
import MatchSystemMessage from './MatchSystemMessage';

// Add slowmode constant
const SLOWMODE_COOLDOWN_SECONDS = 5; // 5 seconds cooldown after sending 3 messages
const SLOWMODE_MESSAGE_LIMIT = 3; // Number of messages allowed before cooldown

const MatchChat = ({ open, token, toggleChat }) => {
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const top = useRef(null);
  const messagesEnd = useRef(null);
  const { matchMessage } = useMatch(token?._id, getAccessToken());

  const [selected, setSelected] = useState('chat');
  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [bannedFromChat, setBannedFromChat] = useState(false);
  const [message, setMessage] = useState(null);
  const [charactersRemaining, setCharactersRemaining] = useState(250);
  const [chatPaused, setChatPaused] = useState(false);
  const [pauseHovered, setPauseHovered] = useState(0);
  const [messages, setMessages] = useState(null);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [rulesHovered, setRulesHovered] = useState(false);
  const [rulesAnchor, setRulesAnchor] = useState(null);
  const [newMessageCount, setNewMessageCount] = useState(0);
  // Add slowmode state variables
  const [inSlowmode, setInSlowmode] = useState(false);
  const [slowmodeTimer, setSlowmodeTimer] = useState(0);
  const slowmodeInterval = useRef(null);
  const [messageCount, setMessageCount] = useState(0); // Track number of messages sent

  const handleRulesOpen = e => {
    e.stopPropagation();
    setRulesAnchor(e.currentTarget);
  };

  const handleRulesClose = e => {
    e?.stopPropagation();
    setRulesAnchor(null);
  };

  const fetchMatchMessages = (pagination = false) => {
    getMessages(middleware, token?._id, skip).then(res => {
      setFetchLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.messages?.length >= MATCH_MESSAGE_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        if (pagination) {
          setMessages(
            messages ? [...messages, ...res?.messages] : res?.messages
          );
          return;
        } else {
          setMessages(
            messages ? [...messages, ...res?.messages] : res?.messages
          );
        }
      }
    });
  };

  const handleSendMessage = () => {
    setLoading(true);

    const chatInput = document.getElementById('chat-input');
    if (!chatInput?.innerText || chatInput.innerText.trim() === '') {
      setLoading(false);
      return;
    }

    if (chatInput.innerText.length > 250) {
      setLoading(false);
      updateStore({
        type: SET_ERRORS,
        payload: 'Message cannot be longer than 250 characters.',
      });
      return;
    }

    // Check if user is in slowmode
    if (inSlowmode) {
      setLoading(false);
      updateStore({
        type: SET_ERRORS,
        payload: `You're in slowmode. Please wait ${slowmodeTimer} seconds before sending another message.`,
      });

      return;
    }
    const teamId = isCreatingTeam(token, store?.user?._id)
      ? token?.creating_team?._id
      : token?.joining_team?._id;

    const currentMessage = message; // Store the current message

    // Don't clear the input yet
    setCharactersRemaining(250 - (chatInput?.innerText?.length || 0));

    sendMessage(middleware, token?._id, teamId, currentMessage).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        // Clear the input only after successful send
        chatInput.innerText = '';
        setMessage('');
        setCharactersRemaining(250);

        // Increment message count
        const newCount = messageCount + 1;
        setMessageCount(newCount);

        // Start slowmode if message limit reached
        if (
          newCount >= SLOWMODE_MESSAGE_LIMIT &&
          store?.user?.account_type < AccountTypeEnum.MODERATOR
        ) {
          startSlowmode();
        }
      }
    });
  };

  // Add slowmode functions
  const startSlowmode = () => {
    setInSlowmode(true);
    setSlowmodeTimer(SLOWMODE_COOLDOWN_SECONDS);
    setMessageCount(0); // Reset message count

    // Clear any existing interval
    if (slowmodeInterval.current) {
      clearInterval(slowmodeInterval.current);
    }

    // Start countdown
    slowmodeInterval.current = setInterval(() => {
      setSlowmodeTimer(prev => {
        if (prev <= 1) {
          clearInterval(slowmodeInterval.current);
          setInSlowmode(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Clean up interval on unmount
  useEffect(() => {
    return () => {
      if (slowmodeInterval.current) {
        clearInterval(slowmodeInterval.current);
      }
    };
  }, []);

  const onScroll = () => {
    const matchChat = document.getElementById('match-chat');
    if (matchChat.scrollTop > -50) {
      setChatPaused(false);
      setPauseHovered(false);
      setUnreadCount(0);
    } else {
      setChatPaused(true);
    }
  };

  const handlePauseClick = () => {
    messagesEnd?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    });
    setChatPaused(false);
    setPauseHovered(false);
    setUnreadCount(0);
  };

  // effects
  usePagination(top, () => setSkip(messages?.length));

  useEffect(() => {
    if (matchMessage) {
      if (chatPaused) {
        setUnreadCount(unreadCount + 1);
      }
      setMessages(messages ? [matchMessage, ...messages] : [matchMessage]);
    }
  }, [matchMessage]);

  useEffect(() => {
    if (
      !open &&
      matchMessage &&
      newMessageCount === 0 &&
      messages?.findIndex(message => message?._id === matchMessage?._id) === -1
    ) {
      setNewMessageCount(newMessageCount + 1);
    }

    if (open && newMessageCount > 0) {
      setNewMessageCount(0);
    }
  }, [matchMessage, open]);

  useEffect(() => {
    if (messages) {
      if (!chatPaused) {
        messagesEnd?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'start',
        });
      }
    }
  }, [messages]);

  useEffect(() => {
    if (token) {
      if (messages == null) {
        setFetchLoading(true);
        fetchMatchMessages();
      }
    }
  }, [token]);

  useEffect(() => {
    if (skip !== 0) {
      fetchMatchMessages(true);
    }
  }, [skip]);

  useEffect(() => {
    if (
      new Date(store?.user?.behavior_info?.match_chat_unban_timestamp) >
      new Date()
    ) {
      setDisabled(true);
      setBannedFromChat(true);
      return;
    }

    const canChat = isInMatch(token, store?.user?._id);

    if (!isStaff(store?.user?.account_type) && !canChat) {
      setDisabled(true);
      return;
    }

    if (
      token?.state === MatchStateEnum.READY ||
      token?.state === MatchStateEnum.WAITING
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [token]);

  const styles = {
    container: {
      position: 'fixed',
      right: 0,
      top: isTablet ? 0 : 50,
      width: open ? (!isDesktop ? '100%' : 340) : 40,
      minHeight: '100vh',
      height: '100%',
      backgroundColor: open ? card : 'transparent',
      zIndex: isTablet ? 100 : 10,
      boxSizing: 'border-box',
      paddingBottom: 8,
      borderLeft: open ? `1px solid ${cardVeryLight}` : null,
      paddingTop: '50px',
    },
    topContainer: {
      width: '100%',
      minHeight: 50,
      padding: open ? 2 : 0,
      paddingRight: 2,
      paddingTop: 1,
      paddingBottom: 0,
      borderBottom: `1px solid ${open ? cardVeryLight : 'transparent'}`,
    },
    width: {
      width: '100%',
    },
    title: {
      textTransform: 'uppercase',
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
      fontWeight: 800,
      fontSize: small,
    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1,
      position: 'relative',
      backgroundColor: open
        ? hovered
          ? cardVeryLight
          : cardLight
        : hovered
          ? cardLight
          : card,
      borderRadius: 1,
      transition: transition,
      cursor: hovered ? 'pointer' : 'default',
      boxShadow: !open ? menuShadow : 'none',
      marginTop: isTablet ? (!open ? 7 : 0) : 0,
    },
    icon: {
      fontSize: 20,
      color: hovered ? text : secondaryText,
      transition: transition,
    },
    chatBoxContainer: {
      flex: 1,
      width: '100%',
      maxWidth: '100%',
      overflowX: 'hidden',
      maxHeight: '100%',
      position: 'relative',
      paddingLeft: 1,
      paddingRight: 1,
    },
    bottomContainer: {
      width: '100%',
      paddingTop: 1,
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 2,
      borderTop: `1px solid ${cardVeryLight}`,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: transition,
      cursor: hovered ? 'pointer' : 'default',
      position: 'relative',
      height: 35,
      width: 35,
      backgroundColor: hovered
        ? open
          ? secondaryButton
          : container
        : 'transparent',
      borderRadius: 1.5,
    },
    characterCount: {
      fontSize: small,
      fontWeight: 800,
      color: secondaryText,
    },
    disabledContainer: {
      padding: 0.5,
      paddingTop: 0,
      width: '100%',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      paddingBottom: 1.5,
      zIndex: -1,
      marginBottom: -1,
    },
    disabledText: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    unreadBadge: {
      position: 'absolute',
      top: -3,
      right: -3,
      height: 12,
      width: 12,
      backgroundColor: red,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      boxShadow: menuShadow,
    },
  };

  return (
    <>
      <ChatRulesMenu anchor={rulesAnchor} handleClose={handleRulesClose} />

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent={open ? 'center' : 'start'}
          sx={{ height: '100%' }}
        >
          <Grid item sx={styles.topContainer}>
            <Grid
              container
              justifyContent={open ? 'space-between' : 'center'}
              alignItems='center'
              sx={{ height: '100%' }}
            >
              {open && (
                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <CustomMenuButton
                      label='Chat'
                      selected={selected === 'chat'}
                      onClick={() => setSelected('chat')}
                    />
                    {(token?.state === MatchStateEnum.DISPUTE ||
                      token?.evidence?.creating_team_links?.length > 0 ||
                      token?.evidence?.joining_team_links?.length > 0 ||
                      token?.evidence?.staff_links?.length > 0) && (
                      <CustomMenuButton
                        label='Evidence'
                        selected={selected === 'evidence'}
                        onClick={() => setSelected('evidence')}
                        size='small'
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                sx={styles.toggleContainer}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => {
                  toggleChat();
                  setHovered(false);
                }}
                ref={setRefEl}
              >
                {hovered && (
                  <Hover
                    label={open ? 'Collapse' : 'Open Chat'}
                    placement='left'
                    refEl={refEl}
                  />
                )}

                {newMessageCount > 0 && !open && (
                  <Grid item sx={styles.unreadBadge} />
                )}

                {open && <BiArrowToRight style={styles.icon} />}

                {!open && <BsFillChatSquareFill style={styles.icon} />}
              </Grid>
            </Grid>
          </Grid>

          {open && selected === 'evidence' && <Evidence token={token} />}

          {open && selected === 'chat' && (
            <>
              <Grid item sx={styles.chatBoxContainer}>
                {chatPaused && (
                  <Grid
                    item
                    sx={{
                      position: 'absolute',
                      bottom: 4,
                      left: isTablet ? 0 : 70,
                      right: isTablet ? 0 : null,
                      borderRadius: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(26, 29, 41, 0.7)',
                      border: `1px solid ${cardVeryLight}`,
                      padding: 1,
                      cursor: pauseHovered ? 'pointer' : 'default',
                      minWidth: 202,
                      zIndex: 4,
                    }}
                    onMouseEnter={() => setPauseHovered(true)}
                    onMouseLeave={() => setPauseHovered(false)}
                    onClick={handlePauseClick}
                  >
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      gap={{ xs: 0.5 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {pauseHovered ? (
                          <BsArrowDownShort
                            style={{ color: text, fontSize: 22 }}
                          />
                        ) : (
                          <IoIosPause style={{ color: text, fontSize: 22 }} />
                        )}
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 800,
                            color: text,
                          }}
                        >
                          {pauseHovered
                            ? `${unreadCount} ${
                                unreadCount === 1
                                  ? 'unread message'
                                  : 'unread messages'
                              }`
                            : 'Chat paused due to scroll'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {fetchLoading && (
                  <Grid
                    item
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={30} sx={{ color: secondaryText }} />
                  </Grid>
                )}

                {messages?.length > 0 && messages != null && !fetchLoading && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      height: '100%',
                      overflowY: 'auto',
                    }}
                    id='match-chat'
                    onScroll={onScroll}
                  >
                    <Grid
                      container
                      direction='column-reverse'
                      alignItems='start'
                      justifyContent='end'
                    >
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction='column-reverse'
                          alignItems='center'
                          justifyContent='center'
                          gap={{ xs: 1 }}
                        >
                          <div
                            ref={messagesEnd}
                            style={{
                              height: 1,
                              width: '100%',
                            }}
                          />

                          {messages?.map((message, i) => {
                            if (message?.user?.username == null) {
                              return (
                                <MatchSystemMessage message={message} key={i} />
                              );
                            } else {
                              return (
                                <MatchMessage
                                  message={message}
                                  token={token}
                                  key={i}
                                />
                              );
                            }
                          })}
                        </Grid>
                      </Grid>

                      {hasMore && (
                        <div
                          ref={top}
                          style={{ alignSelf: 'center', marginTop: 8 }}
                        >
                          <CircularProgress
                            size={15}
                            sx={{ color: secondaryText }}
                          />
                        </div>
                      )}
                    </Grid>
                  </div>
                )}

                {messages?.length < 1 && (
                  <Grid
                    item
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Empty
                      title='No messages'
                      label='Try sending a message'
                      icon={<HiOutlineChatBubbleLeftRight style={emptyIcon} />}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item sx={styles.bottomContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {disabled && (
                        <Grid item sx={styles.disabledContainer}>
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                          >
                            <Grid item sx={styles.iconContainer}>
                              <RiErrorWarningFill
                                style={{
                                  fontSize: 20,
                                  color: secondaryText,
                                }}
                              />
                            </Grid>

                            <Grid item>
                              <Typography sx={styles.disabledText}>
                                {bannedFromChat
                                  ? 'You are banned from chat'
                                  : (isInMatch(token, store?.user?._id) &&
                                        !isStaff(store?.user?.account_type)) ||
                                      (isInMatch(token, store?.user?._id) &&
                                        isStaff(store?.user?.account_type))
                                    ? 'Chat is currently disabled.'
                                    : 'You are not in this match.'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item sx={styles.width}>
                        <ChatInput
                          placeholder='Say Something...'
                          onChange={value => {
                            setMessage(value);
                            setCharactersRemaining(250 - value?.length);
                          }}
                          onKeyDown={() => handleSendMessage()}
                          disabled={disabled}
                          onPaste={value => {
                            setMessage(value);
                            setCharactersRemaining(250 - value?.length);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        onMouseEnter={() => setRulesHovered(true)}
                        onMouseLeave={() => setRulesHovered(false)}
                        onClick={handleRulesOpen}
                      >
                        <Typography
                          sx={{
                            ...styles.characterCount,
                            textDecoration: rulesHovered ? 'underline' : 'none',
                            cursor: rulesHovered ? 'pointer' : 'default',
                          }}
                        >
                          RULES
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 2 }}
                        >
                          <Grid item>
                            <Typography sx={styles.characterCount}>
                              {charactersRemaining}
                            </Typography>
                          </Grid>

                          {inSlowmode && (
                            <Grid item>
                              <Typography
                                sx={{
                                  ...styles.characterCount,
                                  color: red,
                                }}
                              >
                                {slowmodeTimer}s
                              </Typography>
                            </Grid>
                          )}

                          <Grid item>
                            <SecondaryButton
                              bg={doubtedBlue}
                              label='Chat'
                              onClick={handleSendMessage}
                              disabled={disabled || inSlowmode}
                              loading={loading}
                              size={isTablet ? null : 'small'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MatchChat;
