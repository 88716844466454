import { API_ROUTE, errMessage } from '../utils/constants';

export const searchForUser = async (useMiddleware, username) => {
  const params = { username };
  return await useMiddleware
    .get(`${API_ROUTE}/search/user`, { params })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};
