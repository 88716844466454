import { Grid, Typography } from '@mui/material';
import {
  cardExtraLight,
  large,
  normal,
  text,
  transition,
} from '../../utils/themeContstants';
import BorderOutlineButton from './BorderOutlineButton';

const Empty = ({
  icon,
  title,
  label,
  size = null,
  buttonLabel,
  buttonOnClick,
}) => {
  const styles = {
    title: {
      fontSize: size === 'small' ? normal : large,
      fontWeight: 700,
      color: text,
    },
    label: {
      fontSize: size === 'small' ? 12 : normal,
      color: '#d7e4f1',
      fontWeight: 500,
      textAlign: 'center',
      maxWidth: 300,
    },
    button: {
      fontFamily: 'Nunito Sans',
      padding: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      transition: transition,
      border: `2px solid ${cardExtraLight}`,
      textTransform: 'none',
      boxShadow: 'none',
      height: 32,
      borderRadius: 100,
      fontWeight: 800,
      minWidth: 192,
      fontSize: 12,
      color: text,
      backgroundColor: 'transparent',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardExtraLight,
        boxShadow: 'none',
      },
    },
  };

  return (
    <Grid item alignSelf='center' sx={{ padding: 2 }}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
        </Grid>

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={styles.title}>{title}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.label}>{label}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {buttonLabel && buttonOnClick && (
          <BorderOutlineButton onClick={buttonOnClick} label={buttonLabel} />
        )}
      </Grid>
    </Grid>
  );
};

export default Empty;
