import { Box, Typography, useMediaQuery } from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  arcteryx,
  backgroundDark,
  cardExtraLight,
  cardVeryLight,
  linearGradientToTop,
  offWhite,
  yellow,
} from '../../utils/themeContstants';
import MatchBox from './MatchBox';

const TournamentBracket = ({ tournament }) => {
  const isTablet = useMediaQuery('(max-width:1024px)');
  const MATCH_WIDTH = isTablet ? 200 : 250;
  const ROUND_GAP = 5.2;
  const MATCH_HEIGHT = 80;
  const MATCH_GAP = 40;
  const DRAG_THRESHOLD = 5; // Pixels to move before initiating drag
  const navigate = useNavigate();
  // For drag scrolling
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const dragRef = useRef({
    isDragging: false,
    lastPageX: 0,
    scrollLeft: 0,
    startPos: null,
  });

  const handleMouseDown = e => {
    dragRef.current.startPos = { x: e.pageX, y: e.pageY };
    dragRef.current.lastPageX = e.pageX;
    dragRef.current.scrollLeft = containerRef.current.scrollLeft;
  };

  const handleMouseUp = e => {
    // If we haven't moved much, treat it as a click
    if (dragRef.current.startPos) {
      const deltaX = Math.abs(e.pageX - dragRef.current.startPos.x);
      const deltaY = Math.abs(e.pageY - dragRef.current.startPos.y);

      if (deltaX < DRAG_THRESHOLD && deltaY < DRAG_THRESHOLD) {
        const matchBox = e.target.closest('[data-match-id]');
        if (matchBox) {
          const matchId = matchBox.dataset.matchId;
          if (matchId) {
            window.open(`/token/${matchId}`, '_blank');
          }
        }
      }
    }

    dragRef.current.isDragging = false;
    dragRef.current.startPos = null;
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!dragRef.current.startPos) return;

    const deltaX = Math.abs(e.pageX - dragRef.current.startPos.x);
    const deltaY = Math.abs(e.pageY - dragRef.current.startPos.y);

    // Only start dragging if we've moved past the threshold
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (!dragRef.current.isDragging) {
        dragRef.current.isDragging = true;
        setIsDragging(true);
      }

      // Use requestAnimationFrame for smoother scrolling
      requestAnimationFrame(() => {
        if (containerRef.current) {
          const delta = e.pageX - dragRef.current.lastPageX;
          dragRef.current.lastPageX = e.pageX;
          containerRef.current.scrollLeft -= delta;
        }
      });
    }
  };

  // Group matches by round
  const matchesByRound = tournament.matches.reduce((acc, match) => {
    if (!acc[match.round]) {
      acc[match.round] = [];
    }
    acc[match.round].push(match);
    return acc;
  }, {});

  const rounds = Object.keys(matchesByRound).sort((a, b) => a - b);
  const maxRounds = Math.ceil(Math.log2(tournament.registered_teams.length));

  // Fill in empty rounds
  for (let i = 1; i <= maxRounds; i++) {
    if (!matchesByRound[i]) {
      matchesByRound[i] = [];
    }
  }

  // Calculate total height based on the first round matches
  const numTeams = tournament.registered_teams.length;
  const firstRoundMatches = Math.ceil(numTeams / 2);
  const totalHeight = firstRoundMatches * (MATCH_HEIGHT + MATCH_GAP + 12); // Subtract last gap

  // Get a round-specific background color
  const getRoundGradient = roundIndex => {
    const gradientOpacity = 0.05;

    // Championship round gets a special gold gradient
    if (roundIndex === maxRounds - 1) {
      return linearGradientToTop(
        `rgba(252, 231, 28, ${gradientOpacity + 0.05})`,
        `rgba(252, 231, 28, ${gradientOpacity / 2})`
      );
    }
    // Semi-finals get a silver-ish gradient
    else if (roundIndex === maxRounds - 2) {
      return linearGradientToTop(
        `rgba(215, 228, 241, ${gradientOpacity + 0.05})`,
        `rgba(215, 228, 241, ${gradientOpacity / 2})`
      );
    }
    // Quarter-finals get a bronze-ish gradient
    else if (roundIndex === maxRounds - 3) {
      return linearGradientToTop(
        `rgba(239, 142, 51, ${gradientOpacity + 0.02})`,
        `rgba(239, 142, 51, ${gradientOpacity / 3})`
      );
    }
    // Earlier rounds get a purple gradient
    else {
      return linearGradientToTop(
        `rgba(140, 111, 247, ${gradientOpacity})`,
        `rgba(102, 52, 214, ${gradientOpacity / 2})`
      );
    }
  };

  const getRoundTitleColor = roundIndex => {
    if (roundIndex === maxRounds - 1) return yellow; // Championship
    if (roundIndex === maxRounds - 2) return offWhite; // Semi-finals
    if (roundIndex === maxRounds - 3) return '#ef8e33'; // Quarter-finals
    return arcteryx; // Earlier rounds
  };

  const styles = {
    bracketContainer: {
      display: 'flex',
      overflowX: 'auto',
      padding: '40px 20px',
      gap: ROUND_GAP,
      height: `${totalHeight + 80}px`, // Reduced padding to 80px for titles
      position: 'relative',
      cursor: isDragging ? 'grabbing' : 'grab',
      userSelect: 'none',
      willChange: 'transform',
      transform: 'translateZ(0)',
      // borderRadius: '12px',
      // border: `1px solid ${cardLight}`,
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: backgroundDark,
        borderRadius: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: cardVeryLight,
        borderRadius: '5px',
        border: `2px solid ${backgroundDark}`,
        '&:hover': {
          backgroundColor: cardExtraLight,
        },
      },
    },
    round: roundIndex => ({
      display: 'flex',
      flexDirection: 'column',
      minWidth: MATCH_WIDTH,
      height: totalHeight,
      position: 'relative',
      // borderRadius: '8px',
      padding: '0 8px',
      '&:not(:last-child)': {
        marginRight: ROUND_GAP,
      },
    }),
    roundTitle: roundIndex => ({
      color: getRoundTitleColor(roundIndex),
      fontWeight: 700,
      marginBottom: '20px',
      textAlign: 'center',
      fontSize: '18px',
      textTransform: 'uppercase',
      letterSpacing: '1.5px',
      padding: '10px 0',
      textShadow:
        roundIndex === maxRounds - 1
          ? '0 0 10px rgba(252, 231, 28, 0.5)'
          : 'none',
      borderBottom: `1px solid ${getRoundTitleColor(roundIndex)}22`,
      transition: 'all 0.3s ease',
    }),
    matchesContainer: {
      position: 'relative',
      height: totalHeight,
      flex: 1,
    },
  };

  return (
    <Box
      ref={containerRef}
      sx={styles.bracketContainer}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {rounds.map((round, roundIndex) => (
        <Box key={round} sx={styles.round(roundIndex)}>
          <Typography sx={styles.roundTitle(roundIndex)}>
            {roundIndex === maxRounds - 1
              ? 'Finals'
              : roundIndex === maxRounds - 2
                ? 'Semi Finals'
                : roundIndex === maxRounds - 3
                  ? 'Quarter Finals'
                  : `Round ${round}`}
          </Typography>
          <Box sx={styles.matchesContainer}>
            {matchesByRound[round].map((match, matchIndex) => (
              <MatchBox
                key={match.match_id || matchIndex}
                match={match}
                roundIndex={roundIndex}
                matchIndex={matchIndex}
                maxRounds={maxRounds}
                tournament={tournament}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TournamentBracket;
