import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getAvatarOptions } from '../../api/avatar';
import useMiddleware from '../../api/useMiddleware';
import Piece from '../../avatar/Piece';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import LockerGlimmer from '../glimmers/LockerGlimmer';
import PiecePreview from './PiecePreview';

const PieceOptions = ({ avatar, onChange, setSelected, type }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const theme = useTheme();
  const isMobile = window.innerWidth <= 768;

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleGetOptions = () => {
    getAvatarOptions(middleware, type).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setOptions(res?.options);
        setFilteredOptions(res?.options);
      }
    });
  };

  useEffect(() => {
    if (options == null) {
      handleGetOptions();
    }
  });

  useEffect(() => {
    if (!loading) {
      setSelected(avatar?.options[`${type}`]);
    }
  }, [loading]);

  useEffect(() => {
    if (avatar?.options) {
      setSelected(avatar?.options[`${type}`]);
    }
  }, [avatar]);

  // Filter options when search query changes
  useEffect(() => {
    if (options) {
      if (searchQuery.trim() === '') {
        setFilteredOptions(options);
      } else {
        const filtered = options.filter(
          option =>
            option?.name &&
            option?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredOptions(filtered);
      }
    }
  }, [searchQuery, options]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  // Format the type for display (e.g. "hairStyle" -> "Hair Style")
  const formatTypeLabel = type => {
    if (!type) return '';
    // Add space before capital letters and capitalize the first letter
    return type
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  };

  const styles = {
    container: {
      width: '100%',
      overflow: 'hidden',
      paddingTop: 2,
    },
    searchContainer: {
      marginBottom: 3,
      width: '100%',
      padding: theme.spacing(1, 0),
    },
    searchField: {
      '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius * 2,
        transition: 'all 0.3s ease-in-out',
        backgroundColor: 'rgba(30, 30, 35, 0.6)',
        '&:hover': {
          backgroundColor: 'rgba(40, 40, 50, 0.8)',
        },
        '&.Mui-focused': {
          boxShadow: `0 0 0 2px ${theme.palette.primary.dark}`,
          backgroundColor: 'rgba(45, 45, 55, 0.9)',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.1)',
      },
      '& .MuiInputBase-input': {
        color: 'rgba(255, 255, 255, 0.9)',
      },
      '& .MuiInputAdornment-root': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
      width: isMobile ? '100%' : '30vw',
    },
  };

  return (
    <Grid item sx={styles.container}>
      {!loading && options?.length > 0 && (
        <Box sx={styles.searchContainer}>
          <TextField
            fullWidth
            variant='outlined'
            placeholder={`Search ${formatTypeLabel(type)}...`}
            value={searchQuery}
            onChange={handleSearchChange}
            sx={styles.searchField}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.6)' }} />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    onClick={clearSearch}
                    aria-label='clear search'
                    edge='end'
                    sx={{
                      color: 'rgba(255, 255, 255, 0.6)',
                      '&:hover': {
                        color: 'rgba(255, 255, 255, 0.9)',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size='small'
            InputLabelProps={{
              style: { color: 'rgba(255, 255, 255, 0.6)' },
            }}
          />
        </Box>
      )}

      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        {loading && <LockerGlimmer numItems={20} size={100} />}

        {!loading && filteredOptions?.length > 0 && (
          <>
            {filteredOptions?.map((option, i) => (
              <PiecePreview
                key={i}
                option={option}
                value={option?.value}
                selected={option?.value === avatar?.options[`${type}`]?.value}
                onClick={() => onChange(type, option)}
                owned={store?.user?.new_locker?.options[`${type}`]?.includes(
                  option?._id
                )}
                size={100}
              >
                <Piece
                  type={type}
                  color={avatar?.options[`${type}Color`]}
                  option={option?.value}
                  skinColor={avatar?.options?.skinColor}
                  size={100}
                />
              </PiecePreview>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PieceOptions;
