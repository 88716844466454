import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AvatarRarityEnum } from '../../utils/enums';
import CrateItem from './CrateItem';

const Crates = () => {
  const navigate = useNavigate();

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill,minmax(194px, 6fr))',
            gap: 12,
          }}
        >
          <CrateItem
            type={AvatarRarityEnum.EXOTIC}
            onClick={() => navigate('/shop/crates/exotic')}
          />
          <CrateItem
            type={AvatarRarityEnum.RARE}
            onClick={() => navigate('/shop/crates/rare')}
          />
          <CrateItem
            type={AvatarRarityEnum.GODLIKE}
            onClick={() => navigate('/shop/crates/godlike')}
          />
          <CrateItem
            type={AvatarRarityEnum.EMOTE}
            onClick={() => navigate('/shop/crates/emote')}
          />
          <CrateItem
            type={AvatarRarityEnum.EMOJI}
            onClick={() => navigate('/shop/crates/emoji')}
          />
        </div>
      </Grid>
    </>
  );
};

export default Crates;
