import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { getPotentialCratePieces } from '../../api/avatar';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { CDN_URL } from '../../utils/constants';
import {
  getAvatarBorderColor,
  getAvatarCratePrice,
  numFormatter,
} from '../../utils/helpers';
import {
  card,
  cardVeryLight,
  normal,
  offWhite,
  red,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import TextButton from '../custom/TextButton';
import { battlePassRewards } from './battlePassData/battlePassRewards'; // Import Rivox pass rewards data
import { xp_gain_data } from './battlePassData/XpGainData'; // Import XP gain data
import NonAvatarSimple from './NonAvatarSimple';
import PurchaseBattlePassModal from './PurchaseBattlePassModal';
import XpPerWin from './XpPerWin';

const BattlePassItemDetail = () => {
  const PlaceholderImage = `${CDN_URL}bp-icon.svg`; // Placeholder image from CDN

  const isDesktop = useMediaQuery('(min-width:1025px)');
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const [potentialLoading, setPotentialLoading] = useState(true);
  const [potentialItems, setPotentialItems] = useState(null);

  useEffect(() => {
    if (potentialItems == null) {
      getPotentialCratePieces('godlike').then(res => {
        // Assuming "godlike" for example
        setPotentialLoading(false);

        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setPotentialItems(res?.pieces);
        }
      });
    }
  }, [potentialItems]);

  const styles = {
    width: {
      width: '100%',
    },
    xpPerWinStyle: {
      transform: isDesktop ? 'none' : 'scale(0.65)',
      transformOrigin: 'top left',
    },
    subtitle: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
      textShadow: `1px 1px 12px 0px`,
    },
    itemContainer: {
      borderRadius: 2,
      border: `3px solid ${cardVeryLight}`,
      boxSizing: 'border-box',
      backgroundColor: card,
      transition: transition,
      overflow: 'hidden',
      minWidth: isDesktop ? 0 : '100%',
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      padding: 1,
      backgroundColor: card,
    },
    name: {
      fontSize: 18,
      color: '#fff',
      fontWeight: 800,
      textAlign: 'center',
    },
    description: {
      fontSize: normal,
      fontWeight: 400,
      color: offWhite,
      marginTop: '3px',
      marginBottom: '3px',
    },
    header: {
      fontSize: 22,
      color: text,
      fontWeight: 600,
    },
    descriptionContainer: {
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: '100%',
    },
    buttonContainer: {
      padding: 1,
      width: '100%',
      backgroundColor: card,
    },
    top: {
      width: '100%',
      padding: 2,
      borderBottom: `1px solid ${cardVeryLight}`,
      backgroundColor: card,
    },
    bottom: {
      width: '100%',
      padding: 2,
    },
    descriptionTitle: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  const totalValue = battlePassRewards
    .reduce((acc, reward) => {
      const value = parseFloat(reward.worth.replace('$', ''));
      return acc + value;
    }, 0)
    .toFixed(2);

  return (
    <>
      <PurchaseBattlePassModal
        open={purchaseOpen}
        onClose={() => setPurchaseOpen(false)}
      />

      <Grid item alignSelf='start'>
        <TextButton
          label='Back to Shop'
          color={offWhite}
          icon={<BiArrowBack style={{ color: offWhite, fontSize: 22 }} />}
          onClick={() => navigate(-1)}
        />
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction={isDesktop ? 'row' : 'row'}
          justifyContent={isDesktop ? 'start' : 'center'}
          alignItems={isDesktop ? 'start' : 'center'}
          gap={{ xs: 2 }}
          wrap={isDesktop ? 'nowrap' : 'wrap'}
        >
          <Grid item sx={styles.itemContainer}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent={isDesktop ? 'start' : 'center'}
            >
              <Grid item sx={styles.titleContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={styles.name}>Rivox Pass</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  draggable={false}
                  src={PlaceholderImage}
                  height={300}
                  alt='Rivox Pass'
                />
              </Grid>

              <Grid item sx={styles.buttonContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <RivoxCoin size={30} />
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 22,
                            color: text,
                            fontWeight: 800,
                          }}
                        >
                          {numFormatter().format(getAvatarCratePrice('bp'))}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 22,
                            color: red,
                            fontWeight: 600,
                            textDecoration: 'line-through',
                          }}
                        >
                          <span style={{ color: secondaryText }}>
                            {numFormatter().format(
                              getAvatarCratePrice('bp') * 1.5
                            )}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <SecondaryButton
                      bg={getAvatarBorderColor('bp')}
                      label='Purchase Rivox Pass'
                      fullWidth
                      disabled={!store?.user}
                      onClick={() => setPurchaseOpen(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              flexGrow: isDesktop ? 1 : null,
              minWidth: isDesktop ? null : '100%',
            }}
          >
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 2 }}
            >
              <Grid item sx={styles.descriptionContainer}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item sx={styles.top}>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.descriptionTitle}>
                          Rivox Pass Details
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={styles.bottom}>
                    <Grid
                      container
                      direction='row'
                      alignItems='start'
                      justifyContent='left'
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <Typography sx={styles.label} gutterBottom>
                          Rewards
                        </Typography>
                        <Grid container direction='row' spacing={2}>
                          {battlePassRewards.map((reward, index) => (
                            <Grid item key={index}>
                              <NonAvatarSimple
                                type={reward.type}
                                monetaryAmount={reward.monetaryAmount}
                                avatarType={reward.avatar_type}
                              />
                              <Typography
                                sx={{ textAlign: 'center', color: text }}
                              >
                                <strong>{reward.reward}</strong>
                                <br />
                                <Typography sx={styles.subtitle}>
                                  {reward.xpRequired}
                                </Typography>
                                <Typography sx={styles.subtitle}>
                                  {' '}
                                  Value: {reward.worth}
                                </Typography>
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.label}>How it works</Typography>
                        <Typography sx={styles.description}>
                          Earn XP by playing matches and progress through the
                          tiers of the Rivox Pass to unlock rewards. Each tier
                          requires a certain amount of XP to unlock. The Rivox
                          Pass offers a total value of ${totalValue}, giving you
                          a great return on your investment.
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.label}>
                          How Much XP Do I Gain Per Match?
                        </Typography>
                        <Typography sx={styles.description}>
                          Higher entry fees give higher XP per win.
                        </Typography>
                        <div style={styles.xpPerWinStyle}>
                          <XpPerWin data={xp_gain_data} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BattlePassItemDetail;
