import axios from 'axios';
import { API_ROUTE, errMessage } from '../utils/constants';

export const getAvatarOptions = async (useMiddleware, option) => {
  const params = { option };
  return await useMiddleware
    .get(`${API_ROUTE}/avatar/options`, { params })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const getAvatarVariants = async (useMiddleware, option) => {
  const params = { option };
  return await useMiddleware
    .get(`${API_ROUTE}/avatar/variants`, { params })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const setAvatar = async (useMiddleware, options) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/avatar/edit`, { options })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const getStore = async () => {
  return await axios
    .get(`${API_ROUTE}/avatar/store`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const getPotentialCratePieces = async type => {
  const params = { type };
  return await axios
    .get(`${API_ROUTE}/avatar/store/crates/potential`, { params })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchasePiece = async (useMiddleware, piece_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/purchase`, { piece_id })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchaseCrate = async (useMiddleware, tier) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/crate/purchase`, { tier })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchaseGodlikeCrate = async useMiddleware => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/crate/rare/purchase`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchaseEmoteCrate = async useMiddleware => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/crate/emote/purchase`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchaseEmojiCrate = async useMiddleware => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/crate/emoji/purchase`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const purchaseBattlePass = async useMiddleware => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/battlepass/purchase`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const giftPiece = async (useMiddleware, piece_id, user_to_gift) => {
  return await useMiddleware
    .post(`${API_ROUTE}/user/gift`, { piece_id, user_to_gift })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const getPieceValue = async piece_id => {
  const params = { piece_id };
  return await axios
    .get(`${API_ROUTE}/avatar/value`, { params })
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};
