import { Button, Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { currencyFormatter, getReadableDateString } from '../../utils/helpers';
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import CryptoCheckout from './CryptoCheckout';

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import WertWidget from '@wert-io/widget-initializer';
import CardCheckout from './CardCheckout';

import {
  createPayPalOrder,
  getWertSignedData,
  kinguinCheckout,
  paydoCheckout,
  poofCheckout,
  sendFingerprint,
  shopifyCheckout,
} from '../../api/user';
import { CDN_URL } from '../../utils/constants';
import CashAppCheckout from './CashAppCheckout';
import IframeModal from './iFrameModal';

const WERT_PARTNER_ID = '01JKBE3D46C5RT6DYQR5X0ESVN'; //"01JJY6QDVQ4PWBGX68BRBSADPQ";

const ModalContent = ({
  open,
  onClose,
  amount,
  closeWallet,
  description = null,
}) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const navigate = useNavigate();

  const { data: visitorData, error: visitorError } = useVisitorData({
    immediate: true,
    extendedResult: true,
  });

  const [loading, setLoading] = useState(false);
  const [cashAppLoading, setCashAppLoading] = useState(false);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const [kinguinPaypalLoading, setKinguinPaypalLoading] = useState(false);
  const [kinguinProductUrl, setKinguinProductUrl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [termsHovered, setTermsHovered] = useState(false);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [isNA, setIsNA] = useState(false);
  const cardLogo = `${CDN_URL}shopify-checkout-button.png`;
  const paypalLogo = `${CDN_URL}paypal-button.svg`;

  // Reference for the SDK iframe wrapper
  const wrapperRef = useRef(null);

  // Send fingerprint when it's available
  useEffect(() => {
    if (open && visitorData?.visitorId) {
      sendFingerprint(middleware, visitorData.visitorId).then(res => {
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        }
      });
    }
  }, [open, visitorData, middleware, updateStore]);

  // Check user's region using ipapi.co
  useEffect(() => {
    if (open) {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const country = data.country;
          setIsNA(country === 'US' || country === 'CA');
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          setIsNA(false);
        });
    }
  }, [open]);

  const handleClose = e => {
    setLoading(false);
    setCashAppLoading(false);
    setConfirmOpen(false);
    setTermsHovered(false);
    closeWallet(e);
    onClose();
  };

  // Fetch the product URL when the modal opens
  useEffect(() => {
    if (open) {
      const fetchProductUrl = async () => {
        try {
          const response = await kinguinCheckout(
            middleware,
            parseFloat(amount)
          );
          if (response?.error) {
            updateStore({ type: SET_ERRORS, payload: response?.message });
          } else {
            setKinguinProductUrl(response?.checkout_url);
          }
        } catch (error) {
          console.error('Error fetching product URL:', error);
          updateStore({
            type: SET_ERRORS,
            payload: 'Failed to load the checkout. Please try again.',
          });
        }
      };

      fetchProductUrl();
    } else {
      setKinguinProductUrl(null);
    }
  }, [open, amount, middleware, updateStore]);

  const handlePaymentClick = isPayPalButton => {
    if (isPayPalButton) {
      // Existing PayPal logic
      handleKinguinIframe();
    } else {
      // New card payment logic using Wert
      handleWertWidget();
    }
  };

  // Initialize the Kinguin SDK after the product URL is fetched and button is rendered
  useEffect(() => {
    if (kinguinProductUrl) {
      const initializeSDK = () => {
        const productElement = document.querySelector('.productElement');
        if (wrapperRef.current && productElement && window.kinguinCheckoutSDK) {
          console.log('Initializing Kinguin SDK...');
          window.kinguinCheckoutSDK.init({
            wrapperSelector: '#kinguinIframeWrapper',
            productsSelector: '.productElement',
            productUrlAttribute: 'data-product',
            iframeId: 'kinguinCheckoutIframe',
            iframeWidth: '100%',
            iframeHeight: '900px',
            language: 'en',
            currency: 'USD',
            discount: 'MAJOR24',
            email: store?.user?.email,
          });
        } else {
          console.error(
            'Kinguin Checkout SDK is not loaded or elements are not available.'
          );
        }
      };

      // Delay initialization to ensure elements are rendered
      const timer = setTimeout(initializeSDK, 0);

      return () => clearTimeout(timer);
    }
  }, [kinguinProductUrl, store?.user?.email]);

  // Existing functions
  const handlePayPal = () => {
    setLoading(true);
    createPayPalOrder(middleware, parseFloat(amount))
      .then(res => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.link?.href, '_self');
        }
      })
      .catch(() => {
        setLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to initiate PayPal checkout.',
        });
      });
  };

  const handlePoof = () => {
    setCashAppLoading(true);
    poofCheckout(middleware, parseFloat(amount))
      .then(res => {
        setCashAppLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.url, '_self');
        }
      })
      .catch(() => {
        setCashAppLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to initiate Poof checkout.',
        });
      });
  };

  const handleSellsn = () => {
    setLoading(true);
    const button = document.createElement('button');

    button.setAttribute(
      'data-sellsn-sid',
      '000bcc5a-f0e9-467c-93eb-7a9694972872'
    );
    button.setAttribute(
      'data-sellsn-pid',
      '2e4d65bd-104c-4afa-b289-9018ff7d020c'
    );
    button.style.display = 'none';
    document.body.appendChild(button);

    // Initialize Sellsn with quantity
    window.openSellSnModal(
      '000bcc5a-f0e9-467c-93eb-7a9694972872',
      '2e4d65bd-104c-4afa-b289-9018ff7d020c',
      null,
      store?.user?.info?.email,
      amount,
      {
        userId: store?.user?._id,
      }
    );

    button.remove();
    setLoading(false);
  };

  const handlePaydo = () => {
    setShopifyLoading(true);
    paydoCheckout(middleware, parseFloat(amount))
      .then(res => {
        setShopifyLoading(false);
        if (res?.error) {
          console.log('error', res?.error);
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, '_self');
        }
      })
      .catch(e => {
        console.log('error', e);
        setShopifyLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to initiate Paydo checkout.',
        });
      });
  };

  const handleKinguinIframe = () => {
    if (kinguinProductUrl) {
      setIframeOpen(true);
    }
  };

  const handleKinguinPaypal = () => {
    setKinguinPaypalLoading(true);
    kinguinCheckout(middleware, parseFloat(amount))
      .then(res => {
        setKinguinPaypalLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, '_self');
        }
      })
      .catch(() => {
        setKinguinPaypalLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to initiate Kinguin PayPal checkout.',
        });
      });
  };

  const handleShopify = () => {
    setShopifyLoading(true);
    shopifyCheckout(middleware, parseFloat(amount))
      .then(res => {
        console.log('amount', amount);
        setShopifyLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, '_self');
        }
      })
      .catch(() => {
        setShopifyLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: 'Failed to initiate Shopify checkout.',
        });
      });
  };

  const handleWertWidget = async () => {
    try {
      setShopifyLoading(true);

      const response = await getWertSignedData(middleware, parseFloat(amount));
      if (response?.error) {
        updateStore({ type: SET_ERRORS, payload: response?.message });
        return;
      }

      const wertWidget = new WertWidget({
        ...response,
        partner_id: WERT_PARTNER_ID,
        origin: 'https://widget.wert.io',
        commodities: JSON.stringify([
          {
            commodity: 'USDC',
            network: 'base',
          },
        ]),
        commodity: 'USDC',
        network: 'base',
        extra: {
          wallets: [
            {
              address: '0x724BE4312e04Df2BE1d165417f5E287950901366',
              network: 'base',
              name: 'USDC',
            },
          ],
        },
        currency_amount: amount,
        listeners: {
          'payment-status': status => {
            if (status === 'success') {
              updateStore({
                type: SET_ERRORS,
                payload:
                  'Payment successful! Your coins will be credited shortly.',
              });
              handleClose();
            } else if (status === 'failed') {
              updateStore({
                type: SET_ERRORS,
                payload: 'Payment failed. Please try again.',
              });
            }
          },
        },
        click_id: store?.user?._id,
        theme: 'dark',
        color_background: 'rgb(33, 55, 68)',
        color_buttons: '#6634D6',
      });

      wertWidget.open();
    } catch (error) {
      console.error('Error initializing Wert widget:', error);
      updateStore({
        type: SET_ERRORS,
        payload: 'Failed to initialize payment system. Please try again.',
      });
    } finally {
      setShopifyLoading(false);
    }
  };

  const styles = {
    width: {
      width: '100%',
    },
    container: {
      width: '100%',
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: 'center',
      maxWidth: 500,
    },
    warning: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
      maxWidth: 400,
    },
    paymentContainer: {
      width: '100%',
      borderRadius: 1,
      border: `1px solid ${cardVeryLight}`,
      padding: 2,
      backgroundColor: cardLight,
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 500,
    },
  };

  return (
    <>
      {/* Existing Modals and Components */}
      {/* <ConfirmCashAppModal ... /> */}

      <CustomModal
        open={open}
        onClose={onClose}
        title='Complete Purchase'
        titleSize='large'
        bottomContent={
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <CryptoCheckout
                  loading={cashAppLoading}
                  onClick={handleSellsn}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <CardCheckout
                  loading={shopifyLoading}
                  onClick={() => handlePaymentClick(false)}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <CashAppCheckout
                  loading={cashAppLoading}
                  onClick={handleSellsn}
                />
              </Grid>
              {kinguinProductUrl ? (
                <Grid item sx={styles.width}>
                  <Button
                    variant='contained'
                    fullWidth
                    className='productElement'
                    data-product={kinguinProductUrl}
                    onClick={() => handlePaymentClick(true)}
                  >
                    Pay Now With
                    <Grid item sx={styles.icon}>
                      <img
                        src={paypalLogo}
                        draggable={false}
                        alt='paypal-logo'
                        width={80}
                        style={{ marginLeft: '10px' }}
                      />
                    </Grid>
                  </Button>
                </Grid>
              ) : (
                <Grid item sx={styles.width}>
                  <Button variant='contained' fullWidth disabled>
                    Loading Checkout
                  </Button>
                </Grid>
              )}

              {/* Terms and Conditions */}
              <Grid item sx={{ width: '100%', marginTop: 1 }}>
                <Typography sx={styles.meta}>
                  By purchasing these coins you agree to our{' '}
                  <span
                    style={{
                      fontWeight: 700,
                      color: termsHovered ? doubtedBlue : text,
                      cursor: termsHovered ? 'pointer' : 'default',
                    }}
                    onMouseEnter={() => setTermsHovered(true)}
                    onMouseLeave={() => setTermsHovered(false)}
                    onClick={e => {
                      navigate('/support/terms');
                      handleClose(e);
                    }}
                  >
                    Terms of Service
                  </span>{' '}
                  and you are aware that when you purchase these coins, you
                  cannot cancel or refund the order once they are received.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        {/* Promotional Message */}
        {/* <Grid
          item
          sx={{
            width: '100%',
            marginBottom: 1,
            border: '1px solid white',
            borderRadius: '8px',
            padding: '5px',
          }}
        >
          <Typography sx={styles.warning}>
            Enjoy 8% Off PayPal Deposits! Get an 8% discount on your deposits
            when using the code{' '}
            <span
              style={{
                fontWeight: 700,
                color: yellow,
                cursor: 'default',
              }}
            >
              XMASRIVOX
            </span>{' '}
            at checkout. Don't miss out on this limited-time offer!
          </Typography>
        </Grid> */}

        {/* Purchase Summary */}
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              {description && (
                <>
                  <Grid item>
                    <Typography sx={styles.description}>
                      {description}
                    </Typography>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Divider
                      sx={{
                        width: '100%',
                        backgroundColor: text,
                        opacity: 0.15,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item>
                <Typography
                  sx={{ fontSize: normal, fontWeight: 700, color: text }}
                >
                  Purchase Summary
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RivoxCoin size={40} />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 700,
                          }}
                        >
                          {amount} Coins
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: secondaryText,
                            fontWeight: 500,
                          }}
                        >
                          One-time charge on {getReadableDateString(new Date())}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <Grid item sx={styles.container}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography sx={styles.label}>Tax</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.label}>
                      {currencyFormatter().format(0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography sx={{ ...styles.value, fontWeight: 600 }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {currencyFormatter().format(amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <IframeModal
            open={iframeOpen}
            onClose={() => setIframeOpen(false)}
            title='Checkout'
            iframeUrl={kinguinProductUrl}
            height='800px'
          />
          {/* Wrapper for the SDK iframe */}
          {/* <div id="kinguinIframeWrapper" ref={wrapperRef}></div> */}
        </Grid>
      </CustomModal>
    </>
  );
};

const PayPalDepositModal = props => {
  return <ModalContent {...props} />;
};

export default PayPalDepositModal;
