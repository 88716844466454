import { getGameModeString, getRegionString } from './helpers';

export const regionOptions = (gameMode = null) => {
  switch (gameMode) {
    case 'zbm':
    case 'zbm_real':
    case 'zbm_bf':
      return [
        { title: 'None', value: null },
        { title: 'Any Region', value: 'all' },
        { title: 'NA East', value: 'nae' },
        { title: 'NA West', value: 'naw' },
        { title: 'NA Central', value: 'nac' },
        { title: 'Europe', value: 'eu' },
        { title: 'OCE', value: 'oce' },
        { title: 'Brazil', value: 'br' },
        { title: 'Asia', value: 'asia' },
      ];
    default:
      return [
        { title: 'None', value: null },
        { title: 'NA East', value: 'nae' },
        { title: 'NA West', value: 'naw' },
        { title: 'NA Central', value: 'nac' },
        { title: 'Europe', value: 'eu' },
        { title: 'OCE', value: 'oce' },
        { title: 'Brazil', value: 'br' },
        { title: 'Asia', value: 'asia' },
      ];
  }
};

export const multiRegionOptions = regions => {
  if (regions?.length < 2) return;

  const newRegions = [{ title: 'None', value: null }];

  regions?.forEach(region => {
    let obj = { title: '', value: '' };

    obj.title = getRegionString(region);
    obj.value = region;

    newRegions.push(obj);
  });

  return newRegions;
};

export const multiGameModeOptions = gameModes => {
  if (gameModes?.length < 2) return;

  const newModes = [{ title: 'None', value: null }];

  gameModes?.forEach(mode => {
    let obj = { title: '', value: '' };

    obj.title = getGameModeString(mode);
    obj.value = mode;

    newModes.push(obj);
  });

  return newModes;
};

export const fifaRegionOptions = [
  { title: 'None', value: null },
  { title: 'North America', value: 'na' },
  { title: 'Europe', value: 'eu' },
];

export const warzoneRegionOptions = [
  { title: 'None', value: null },
  { title: 'Any Region', value: 'all' },
  { title: 'North America', value: 'na' },
  { title: 'Europe', value: 'eu' },
];

export const gameModeOptions = [
  { title: 'None', value: null },
  { title: 'Zone Wars', value: 'zw' },
  { title: 'Boxfights', value: 'bf' },
  { title: 'Realistics', value: 'real' },
  // { title: "Build Fights", value: "build" },
  { title: 'ZBM Realistics', value: 'zbm_real' },
  { title: 'ZBM Boxfights', value: 'zbm_bf' },
  { title: 'ZBM Kill Race', value: 'zbm' },
  { title: 'Build Fights', value: 'build' },
  { title: 'ESL - CTF', value: 'esl_ctf' },
  { title: 'ESL - Hardpoint', value: 'esl_hp' },
];

export const newGameModeOptions = game => {
  switch (game) {
    case 'fortnite':
      return [
        { title: 'None', value: null },
        { title: 'Zone Wars', value: 'zw' },
        { title: 'Boxfights', value: 'bf' },
        { title: 'Realistics', value: 'real' },
        { title: 'Build Fights', value: 'build' },
        { title: 'ZBM Realistics', value: 'zbm_real' },
        { title: 'ZBM Boxfights', value: 'zbm_bf' },
        { title: 'ZBM Kill Race', value: 'zbm' },
        { title: 'ESL - CTF', value: 'esl_ctf' },
        { title: 'ESL - Hardpoint', value: 'esl_hp' },
      ];
    case 'clashroyale':
      return [
        { title: 'None', value: null },
        { title: 'Battle', value: 'clash_battle' },
        { title: 'Mega Draft', value: 'clash_draft' },
      ];
    case 'chess':
      return [
        { title: 'None', value: null },
        { title: 'Bullet 1 min', value: 'chess_bullet_1' },
        { title: 'Blitz 3 min', value: 'chess_blitz_3' },
        { title: 'Any', value: 'chess_any' },
      ];
    case 'fifa':
      return [
        { title: 'None', value: null },
        { title: 'FIFA Ultimate Team', value: 'fifa_ultimate' },
        { title: 'Online Friendlies', value: 'fifa_online_friendlies' },
      ];
    case 'warzone':
      return [
        { title: 'None', value: null },
        { title: 'Kill Race', value: 'wz_race' },
        { title: 'Kill Race Resurgence', value: 'wz_resurgence' },
        { title: 'Survival', value: 'wz_survival' },
      ];
  }
};

export const gameMapTypeOptions = game => {
  switch (game) {
    case 'fortnite':
      return [
        { title: 'None', value: null },
        // { title: "Pro Realistics", value: "prorealistics" },
        { title: 'Finest', value: 'finest' },
        { title: 'Rivox', value: 'rivoxrealistics' },
      ];
    default:
      return null;
  }
};

export const buildLootOptions = [
  { title: 'None', value: null },
  { title: 'Mythic Havoc', value: 'mythic' },
  { title: 'Gold Pump', value: 'pump' },
];

export const realsLootOptions = [
  { title: 'Default', value: null },
  { title: 'Mythic Havoc', value: 'mythic' },
  { title: 'Gold Pump', value: 'pump' },
];

export const buildHealthOptions = [
  { title: 'None', value: null },
  { title: '185', value: 185 },
  { title: '200', value: 200 },
];

export const bestOfOptions = [
  { title: 'None', value: null },
  { title: '1', value: 1 },
  { title: '3', value: 3 },
];

export const firstToOptions = [
  { title: 'None', value: null },
  { title: '1', value: 1 },
  { title: '3', value: 3 },
  { title: '5', value: 5 },
];

export const killLeadOptions = (firstTo = null) => {
  switch (firstTo) {
    case 5:
      return [
        { title: 'None', value: null },
        { title: '1', value: 1 },
        { title: '2', value: 2 },
        { title: '3', value: 3 },
        { title: '4', value: 4 },
      ];
    case 3:
      return [
        { title: 'None', value: null },
        { title: '1', value: 1 },
        { title: '2', value: 2 },
      ];
    default:
      return null;
  }
};

export const killLeadOptionsFilter = () => {
  return [
    { title: 'None', value: null },
    { title: 'Lead Offered', value: true },
  ];
};

export const teamSizeOptions = (gameMode = null) => {
  switch (gameMode) {
    case 'zw':
    case 'bf':
    case 'real':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
    case 'build':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
      ];
    case 'zbm':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
      ];
    case 'zbm_real':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
    case 'zbm_bf':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
      ];
    case 'esl_ctf':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
    case 'esl_hp':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
    default:
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
  }
};

export const newTeamSizeOptions = (game, gameMode) => {
  switch (game) {
    case 'fortnite':
      return teamSizeOptions(gameMode);
    case 'clashroyale':
      switch (gameMode) {
        case 'clash_battle':
          return [
            { title: 'None', value: null },
            { title: '1v1', value: 1 },
            { title: '2v2', value: 2 },
          ];
        case 'clash_draft':
          return [
            { title: 'None', value: null },
            { title: '1v1', value: 1 },
          ];
        default:
          return null;
      }
    case 'warzone':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
      ];
    case 'chess':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
      ];
    case 'fifa':
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
      ];
    default:
      return null;
  }
};

export const snipeOptions = [
  { title: 'None', value: null },
  { title: '10', value: 10 },
  { title: '25', value: 25 },
  { title: '50', value: 50 },
];

export const snipePrices = amount => {
  switch (amount) {
    case 10:
      return 1.99;
    case 25:
      return 3.99;
    case 50:
      return 7.99;
    default:
      return 0;
  }
};

export const clashTeamSizeOptions = [
  { title: 'None', value: null },
  { title: '1v1', value: 1 },
  { title: '2v2', value: 2 },
];

export const winnerOptions = [
  { title: 'None', value: null },
  { title: 'Won', value: 'win' },
  { title: 'Lost', value: 'loss' },
];
export const platformOptions = [
  { title: 'None', value: null },
  { title: 'Console', value: 'console' },
  { title: 'PC', value: 'pc' },
];

export const getTeamOptions = teams => {
  const teamOptions = [{ title: 'None', value: null }];

  for (let i = 0; i < teams?.length; i++) {
    teamOptions?.push({ title: teams[i]?.name, value: teams[i] });
  }

  return teamOptions;
};

export const banLengthOptions = [
  { title: 'None', value: null },
  { title: '1 day', value: 1 },
  { title: '3 days', value: 3 },
  { title: '1 week', value: 7 },
  { title: '1 month', value: 30 },
  { title: 'Permanently', value: 10000 },
  { title: 'Permanently + Reset Balance', value: 15000 },
];

export const gameModeValues = gameMode => {
  switch (gameMode) {
    case 'zbm':
      return 2;
    case 'zbm_real':
      return 4;
    case 'zbm_bf':
      return 3;
    case 'zw':
      return 4;
    case 'bf':
      return 4;
    case 'real':
      return 4;
    case 'build':
      return 1;
    case 'esl_hp':
      return 5;
    case 'esl_ctf':
      return 5;
    default:
      return 0;
  }
};

export const checkLowestGameMode = gameModes => {
  if (gameModes?.length < 1) return null;

  let lowest = 4;

  gameModes?.forEach(gameMode => {
    let currentValue = gameModeValues(gameMode);

    if (currentValue < lowest) {
      lowest = currentValue;
    }
  });

  return lowest;
};

export const getTeamSizeOptions = teamSize => {
  switch (teamSize) {
    case 1:
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
      ];
    case 2:
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
      ];
    case 3:
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
      ];
    case 4:
      return [
        { title: 'None', value: null },
        { title: '1v1', value: 1 },
        { title: '2v2', value: 2 },
        { title: '3v3', value: 3 },
        { title: '4v4', value: 4 },
      ];
    default:
      return [{ title: 'None', value: null }];
  }
};
