import { CDN_URL } from './constants';
import { AvatarPiecePriceEnum, AvatarRarityEnum } from './enums';
import {
  blue,
  doubtedBlue,
  emojiYellow,
  green,
  purple,
  red,
} from './themeContstants';

const fortniteHeader = `${CDN_URL}games/headers/fortnite-header.jpeg`;
const clashHeader = `${CDN_URL}games/headers/clash-header.jpeg`;
const chessHeader = `${CDN_URL}games/headers/skinny-chess-header.png`;
const fifaHeader = `${CDN_URL}games/headers/fifa-header.jpeg`;
const warzoneHeader = `${CDN_URL}games/headers/warzone-header.jpeg`;

const fortniteWord = `${CDN_URL}games/word_logos/fortnite-word-logo.png`;
const fifaWord = `${CDN_URL}games/word_logos/fifa-word-logo.png`;
const clashWord = `${CDN_URL}games/word_logos/clash-word-logo.png`;
const chessWord = `${CDN_URL}games/word_logos/chess-word-logo.png`;
const warzoneWord = `${CDN_URL}games/word_logos/warzone-word-logo.png`;

const exoticCrate = `${CDN_URL}exotic-crate.svg`;
const rareCrate = `${CDN_URL}rare-crate.svg`;
const godlikeCrate = `${CDN_URL}godlike-crate.svg`;
const emojiCrate = `${CDN_URL}emoji-crate.svg`;
const emoteCrate = `${CDN_URL}emotes-crate.svg`;

const zw = `${CDN_URL}match/zw.svg`;
const bf = `${CDN_URL}match/bf.svg`;
const build = `${CDN_URL}match/build.svg`;
const zbm = `${CDN_URL}match/zbm.svg`;
const real = `${CDN_URL}match/real.svg`;
const zbmReal = `${CDN_URL}match/zbmReal.svg`;

export const currencyFormatter = (decimalAmount = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: decimalAmount,
  });
};

export const numFormatter = (decimalAmount = 2) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalAmount,
    maximumFractionDigits: decimalAmount,
  });
};

export const isUserAuthorized = () => {
  const token = localStorage.getItem('accessToken');
  return token ? true : false;
};

export const getGameModeString = gameMode => {
  switch (gameMode) {
    case 'zw':
      return 'Zone Wars';
    case 'bf':
      return 'Box Fight';
    case 'real':
      return 'Realistic';
    case 'build':
      return 'Build Fight';
    case 'zbm':
      return 'ZBM Kill Race';
    case 'zbm_real':
      return 'ZBM Realistic';
    case 'zbm_bf':
      return 'ZBM Boxfights';
    case 'clash_battle':
      return 'Battle';
    case 'clash_draft':
      return 'Mega Draft';
    case 'chess_bullet_1':
      return 'Bullet 1 min';
    case 'chess_blitz_3':
      return 'Blitz 3 min';
    case 'chess_rapid_10':
      return 'Rapid 10 min';
    case 'chess_any':
      return 'Any';
    case 'fifa_ultimate':
      return 'FIFA Ultimate Team';
    case 'fifa_online_friendlies':
      return 'Online Friendlies';
    case 'fifa_pro_clubs':
      return 'FIFA Pro Clubs';
    case 'fifa_any':
      return 'Any Game Mode';
    case 'wz_race':
      return 'Kill Race';
    case 'wz_resurgence':
      return 'Resurgence Kill Race';
    case 'wz_survival':
      return 'Survival';
    case 'esl_ctf':
      return 'ESL - Capture The Flag';
    case 'esl_hp':
      return 'ESL - Hardpoint';
    default:
      return null;
  }
};

export const getGameModeStringFromArray = (
  gameModeArray,
  selectedGameMode,
  gameMatchType
) => {
  if (gameMatchType) return getGameModeString(gameMatchType);

  if (selectedGameMode) return getGameModeString(selectedGameMode);

  if (gameModeArray?.length > 0) {
    if (gameModeArray?.length < 2) return getGameModeString(gameModeArray[0]);

    return 'Match';
  }

  return '';
};

export const getRegionStringFromArray = (regionArr, selectedRegion) => {
  if (regionArr?.length < 1) return;

  if (
    regionArr?.includes('nae') &&
    regionArr?.includes('naw') &&
    regionArr?.includes('nac') &&
    regionArr?.includes('eu') &&
    regionArr?.includes('oce') &&
    regionArr?.includes('br') &&
    regionArr?.includes('asia')
  )
    return 'Any Region';

  if (selectedRegion) return getRegionString(selectedRegion);

  const arr = [];

  regionArr.forEach(region => {
    arr.push(getRegionString(region));
  });

  return arr.join(', ');
};

export const getRegionString = region => {
  switch (region) {
    case 'naw':
      return 'NA West';
    case 'nae':
      return 'NA East';
    case 'eu':
      return 'EU';
    case 'oce':
      return 'OCE';
    case 'br':
      return 'Brazil';
    case 'asia':
      return 'Asia';
    case 'nac':
      return 'NA Central';
    case 'na':
      return 'NA';
    case 'all':
      return 'Any Region';
    default:
      return 'Any Region';
  }
};

export const getWithdrawMethodString = type => {
  switch (type) {
    case 'paypal':
      return 'PayPal';
    case 'cashapp':
      return 'Cash App';
    default:
      return null;
  }
};

export const getPlatformString = platform => {
  switch (platform) {
    case 'console':
      return 'Console';
    case 'last_gen':
      return 'Last Gen';
    case 'current_gen':
      return 'Current Gen';
    default:
      return 'All';
  }
};

export const getStateString = state => {
  switch (state) {
    case 'playing':
      return 'Playing';
    case 'dispute':
      return 'Disputed';
    case 'submit':
      return 'Submitting';
    default:
      return null;
  }
};

export const getTeamSizeString = teamSize => {
  switch (teamSize) {
    case 1:
      return '1v1';
    case 2:
      return '2v2';
    case 3:
      return '3v3';
    case 4:
      return '4v4';
    default:
      return 'Team Size';
  }
};

export const getChessTime = gameMode => {
  switch (gameMode) {
    case 'chess_bullet_1':
      return '1 min';
    case 'chess_blitz_3':
      return '3 min';
    case 'chess_rapid_10':
      return '10 min';
    case 'chess_any':
      return 'Any';
    default:
      return null;
  }
};

export const getGameMapType = gameMap => {
  switch (gameMap) {
    case 'finest':
      return 'Finest';
    case 'rivoxrealistics':
      return 'Rivox';
    case 'prorealistics':
      return 'Rivox';
    default:
      return null;
  }
};

export const getMatchTitle = (
  gameMode,
  teamSize,
  gameMap,
  extraOptions,
  gameModeOptions,
  recreate = false
) => {
  // game mode has already been selected by joining team
  if (gameModeOptions?.selected_option && !recreate) {
    return getTokenTitle(
      gameModeOptions?.selected_option,
      teamSize,
      gameMap,
      extraOptions
    );
  }

  // old style match (have not refreshed)
  if (gameMode && gameModeOptions?.options?.length < 1) {
    return getTokenTitle(gameMode, teamSize, gameMap, extraOptions);
  }

  // new style match with no selected mode
  if (gameModeOptions?.options?.length > 0) {
    // only one option
    if (gameModeOptions?.options?.length === 1) {
      return getTokenTitle(
        gameModeOptions?.options[0],
        teamSize,
        gameMap,
        extraOptions
      );
    }

    // mulitple options
    if (gameModeOptions?.options?.length > 1) {
      const modeArr = [];

      gameModeOptions?.options?.forEach(option => {
        modeArr.push(getGameModeString(option));
      });

      return `${teamSize}v${teamSize} ${modeArr.join(', ')}`;
    }
  }
};

export const getTokenTitle = (gameMode, teamSize, gameMap, extraOptions) => {
  const gameModeString = getGameModeString(gameMode);

  if (gameMode === 'real' && gameMap != null) {
    return `${teamSize}v${teamSize} ${getGameMapType(gameMap)} ${gameModeString}`;
  }

  if (gameMode === 'build') {
    return `${getLootType(extraOptions?.loot_type)} ${gameModeString}`;
  }

  return `${teamSize}v${teamSize} ${gameModeString}`;
};

export const timeRemaining = start_date => {
  const remaining = new Date(start_date) - new Date();
  return remaining / 1000;
};

export const addMinutes = (minutes, date) => {
  const initialDate = new Date(date);
  const expire_time = new Date(initialDate.getTime() + minutes * 60000);
  return expire_time;
};

export const getDateFromId = id => {
  const timestamp = id?.toString()?.substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000);
};

export const getReadableDateFromId = id => {
  const timestamp = id?.toString().substring(0, 8);
  const date = new Date(parseInt(timestamp, 16) * 1000);
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const getReadableTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const generateRandomAvatar = () => {
  try {
    const randomSeed = getRandomInt(1, 10000000);
    return `https://avatars.dicebear.com/api/bottts/${randomSeed}.svg`;
  } catch (err) {
    return null;
  }
};

export const getReadableDateString = date => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleString('en-US', options);
};

export const formatDateToCustomString = date => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = date.getDate();
  let suffix = 'th';

  // Determine the suffix for the day
  if (day % 10 === 1 && day !== 11) {
    suffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd';
  }

  return `${monthNames[date.getMonth()]} ${day}${suffix}, ${date.getFullYear()}`;
};

export const getFirstDayOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getRole = accountType => {
  const roles = {
    1: 'Player',
    100: 'Moderator',
    200: 'Senior Moderator',
    250: 'Junior Admin',
    251: 'Anticheat',
    300: 'Admin',
    350: 'Lead Admin',
    400: 'Head Admin',
    10000: 'God',
  };
  return roles[accountType] || 'Unknown';
};

export const getTime = date => {
  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const today = date => {
  const todayDate = new Date();
  if (
    date.getDate() === todayDate.getDate() &&
    date.getMonth() === todayDate.getMonth() &&
    date.getYear() === todayDate.getYear()
  ) {
    return true;
  } else {
    return false;
  }
};

export const yesterday = date => {
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getYear() === yesterday.getYear()
  ) {
    return true;
  } else {
    return false;
  }
};

export const thisWeek = date => {
  const now = new Date();

  if (now.getDate() - date.getDate() > -7) {
    return true;
  }

  return false;
};

export const getDays = date => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[date.getDay()];
};

export const formatWithdrawalDate = date => {
  if (!date) return '';
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(date).toLocaleString('en-GB', options);
};

export const getNotiTimestamp = date => {
  if (date === null) {
    return null;
  }

  const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (today(date)) {
    return `Today at ${getTime(date)}`;
  }

  if (yesterday(date)) {
    return `Yesterday at ${getTime(date)}`;
  }

  if (thisWeek(date)) {
    return `${date.toLocaleDateString('en-US', {
      weekday: 'long',
    })} at ${getTime(date)}`;
  }

  return `${longEnUSFormatter.format(date)} at ${getTime(date)}`;
};

export const canChangePieceColor = (category, value) => {
  if (value === null) {
    return false;
  }

  if (category === 'hair') {
    switch (value) {
      case 'clown':
      case 'reyna':
      case 'jett':
      case 'chamber':
      case 'naruto':
      case 'anime_kid':
      case 'carti':
      case 'keef':
      case 'goku':
      case 'spice':
      case 'kodak':
      case 'six_nine':
        return false;
      default:
        return true;
    }
  }

  if (category === 'facialHair') {
    switch (value) {
      case 'leftovers':
        return false;
      default:
        return true;
    }
  }

  if (category === 'clothes') {
    switch (value) {
      case 'ugly_sweater':
      case 'louis':
      case 'bathing_ape':
      case 'college_hoodie':
      case 'dawn':
      case 'wc_usa':
      case 'wc_brazil':
      case 'wc_england':
      case 'wc_france':
      case 'wc_germany':
      case 'wc_mancity':
      case 'wc_manu':
      case 'wc_mexico':
      case 'wc_messi':
      case 'wc_ronaldo':
      case 'canadian_goose':
      case 'minion':
      case 'incognito':
      case 'kobe':
      case 'mj_jersey':
      case 'pinhead':
      case 'gym_rat':
      case 'travis_scott':
      case 'creeper':
      case 'ape_shit':
      case 'rex':
      case 'bullet_vest':
      case 'kirito_coat':
      case 'black_bull':
      case 'mummy':
      case 'doge':
      case 'drool_emoji':
      case 'nerd_emoji':
      case 'alien_emoji':
      case 'laugh_emoji':
      case 'hearts_emoji':
      case 'angry_emoji':
      case 'crying_emoji':
      case 'demon_emoji':
      case 'half-skull_emoji':
      case 'eyes_emoji':
      case 'heart_eyes_emoji':
      case 'singer_emoji':
      case 'skull_emoji':
      case 'crossbones_emoji':
      case 'monkey_emoji':
      case 'interesting_emoji':
      case 'disguise_emoji':
      case 'sunglasses_emoji':
      case 'cowboy_emoji':
      case 'blown_emoji':
      case 'zipper_emoji':
      case 'swirl_emoji':
      case 'confused_emoji':
        return false;
      default:
        return true;
    }
  }

  if (category === 'hat') {
    switch (value) {
      case 'turban':
      case 'ski_mask_hat':
      case 'bandana_hat':
      case 'durag_flat_bill':
      case 'flat_bill':
      case 'yeat_fuzzy':
        return true;
      default:
        return false;
    }
  }

  return false;
};

export const getAvatarBackground = (rarity, owned) => {
  switch (rarity) {
    case AvatarRarityEnum.COMMON:
      return `rgba(215, 217, 231, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.UNCOMMON:
      return `rgba(21, 226, 164, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.RARE:
      return `rgba(21, 117, 226, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.LEGENDARY:
      return `rgba(112, 82, 221, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.EXOTIC:
      return `rgba(251, 139, 36, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.VIP:
      return `rgba(255, 84, 101, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.GODLIKE:
      return `rgba(255, 0, 110, ${owned ? '0.15' : '0.3'})`;
    case AvatarRarityEnum.EMOJI:
      return hexToRgbA(emojiYellow, owned ? 0.15 : 0.3);
  }
};

export const getAvatarBorder = (rarity, owned) => {
  if (owned) {
    return getAvatarBackground(rarity, owned);
  }

  switch (rarity) {
    case AvatarRarityEnum.COMMON:
      return '#D7D9E7';
    case AvatarRarityEnum.UNCOMMON:
      return green;
    case AvatarRarityEnum.RARE:
      return blue;
    case AvatarRarityEnum.LEGENDARY:
      return purple;
    case AvatarRarityEnum.EXOTIC:
      return '#fb8b24';
    case AvatarRarityEnum.VIP:
      return red;
    case AvatarRarityEnum.GODLIKE:
      return red;
    case AvatarRarityEnum.EMOTE:
      return green;
    case AvatarRarityEnum.EMOJI:
      return emojiYellow;
  }
};

export const getCoinAmount = rarity => {
  switch (rarity) {
    case AvatarRarityEnum.COMMON:
      return AvatarPiecePriceEnum.COMMON;
    case AvatarRarityEnum.UNCOMMON:
      return AvatarPiecePriceEnum.UNCOMMON;
    case AvatarRarityEnum.RARE:
      return AvatarPiecePriceEnum.RARE;
    case AvatarRarityEnum.LEGENDARY:
      return AvatarPiecePriceEnum.LEGENDARY;
    case AvatarRarityEnum.EXOTIC:
      return AvatarPiecePriceEnum.EXOTIC;
    case AvatarRarityEnum.GODLIKE:
      return AvatarPiecePriceEnum.GODLIKE;
    case AvatarRarityEnum.EMOJI:
      return AvatarPiecePriceEnum.EMOJI;
    default:
      return null;
  }
};
export const getCoinAmountSale = rarity => {
  switch (rarity) {
    case AvatarRarityEnum.COMMON:
      return AvatarPiecePriceEnum.COMMON;
    case AvatarRarityEnum.UNCOMMON:
      return AvatarPiecePriceEnum.UNCOMMON;
    case AvatarRarityEnum.RARE:
      return AvatarPiecePriceEnum.RARE;
    case AvatarRarityEnum.LEGENDARY:
      return AvatarPiecePriceEnum.LEGENDARY;
    case AvatarRarityEnum.EXOTIC:
      return AvatarPiecePriceEnum.EXOTIC;
    case AvatarRarityEnum.GODLIKE:
      return AvatarPiecePriceEnum.GODLIKE;
    case AvatarRarityEnum.EMOJI:
      return AvatarPiecePriceEnum.EMOJI;
    default:
      return null;
  }
};

export const getAvatarRarity = rarity => {
  switch (rarity) {
    case AvatarRarityEnum.COMMON:
      return 'DEFAULT';
    case AvatarRarityEnum.UNCOMMON:
      return 'UNCOMMON';
    case AvatarRarityEnum.RARE:
      return 'RARE';
    case AvatarRarityEnum.LEGENDARY:
      return 'LEGENDARY';
    case AvatarRarityEnum.EXOTIC:
      return 'EXOTIC';
    case AvatarRarityEnum.VIP:
      return 'VIP';
    case AvatarRarityEnum.GODLIKE:
      return 'GODLIKE';
    case AvatarRarityEnum.EMOJI:
      return 'EMOJI';
    default:
      return null;
  }
};

export const getAvatarCrateImage = type => {
  switch (type) {
    case AvatarRarityEnum.EXOTIC:
      return exoticCrate;
    case AvatarRarityEnum.RARE:
      return rareCrate;
    case AvatarRarityEnum.GODLIKE:
      return godlikeCrate;
    case AvatarRarityEnum.EMOJI:
      return emojiCrate;
    case AvatarRarityEnum.EMOTE:
      return emoteCrate;
    default:
      return null;
  }
};

export const getAvatarBorderColor = type => {
  switch (type) {
    case AvatarRarityEnum.EXOTIC:
      return '#fb8b24';
    case AvatarRarityEnum.RARE:
      return blue;
    case AvatarRarityEnum.GODLIKE:
      return red;
    case AvatarRarityEnum.EMOJI:
      return emojiYellow;
    case AvatarRarityEnum.EMOTE:
      return green;
    case 'bp':
      return purple;
    default:
      return null;
  }
};

export const getAvatarBackgroundColor = type => {
  switch (type) {
    case AvatarRarityEnum.EXOTIC:
      return `rgba(251, 139, 36, 0.3)`;
    case AvatarRarityEnum.RARE:
      return `rgba(21, 117, 226, 0.3)`;
    case AvatarRarityEnum.GODLIKE:
      return `rgba(255, 84, 101, 0.3)`;
    case AvatarRarityEnum.EMOJI:
      return hexToRgbA(emojiYellow, 0.3);
    case AvatarRarityEnum.EMOTE:
      return `rgba(0,255,127, 0.3)`;
    default:
      return null;
  }
};

export const getAvatarCratePrice = type => {
  switch (type) {
    case AvatarRarityEnum.EXOTIC:
      return 4;
    case AvatarRarityEnum.RARE:
      return 2;
    case AvatarRarityEnum.GODLIKE:
      return 1.99;
    case AvatarRarityEnum.EMOJI:
      return 1.49;
    case AvatarRarityEnum.EMOTE:
      return 2.99;
    case 'bp':
      return 4.49;
    default:
      return null;
  }
};

export const getGameName = game => {
  switch (game) {
    case 'fortnite':
      return 'Find Matches';
    case 'clashroyale':
      return 'Clash Royale';
    case 'chess':
      return 'Chess.com';
    case 'fifa':
      return 'FIFA 23';
    case 'warzone':
      return 'Call of Duty: Warzone';
    default:
      return 'All Games';
  }
};

export const getGameHeader = game => {
  switch (game) {
    case 'fortnite':
      return fortniteHeader;
    case 'clashroyale':
      return clashHeader;
    case 'chess':
      return chessHeader;
    case 'fifa':
      return fifaHeader;
    case 'warzone':
      return warzoneHeader;
    default:
      return null;
  }
};

// export const getGameModeHeader = (game, gameMode) => {
//   switch (game) {
//     case "fortnite":
//       switch (gameMode) {
//         case "zw":
//           return zoneWars;
//         case "bf":
//           return box;
//         case "zbm":
//           return zbm;
//         case "real":
//           return reals;
//         case "build":
//           return buildFight;
//         default:
//           return null;
//       }
//     case "clashroyale":
//       return clashHeader;
//     case "chess":
//       return chessHeader;
//     default:
//       return null;
//   }
// };

export const getWordLogo = game => {
  switch (game) {
    // case "fortnite":
    //   return fortniteWord;
    case 'clashroyale':
      return clashWord;
    case 'chess':
      return chessWord;
    case 'fifa':
      return fifaWord;
    case 'warzone':
      return warzoneWord;
    default:
      return null;
  }
};

export const isWithinOneMinute = time => {
  // Get the current time in milliseconds
  const now = Date.now();

  // Convert the provided time to milliseconds
  const providedTime = new Date(time).getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = now - providedTime;

  // Check if the time difference is less than or equal to 1 minute (60,000 milliseconds)
  return timeDifference <= 180000;
};

export const isDateGreaterThanCurrent = date => {
  var currentDate = new Date();
  return date > currentDate;
};

export const convertToROT7 = value => {
  var result = '';

  for (var i = 0; i < value.length; i++) {
    var charCode = value.charCodeAt(i);

    // Check if the character is a lowercase letter
    if (charCode >= 97 && charCode <= 122) {
      charCode = ((charCode - 97 + 7) % 26) + 97; // Apply ROT7 transformation
    }
    // Check if the character is an uppercase letter
    else if (charCode >= 65 && charCode <= 90) {
      charCode = ((charCode - 65 + 7) % 26) + 65; // Apply ROT7 transformation
    }

    result += String.fromCharCode(charCode);
  }

  return result;
};

export const getUserAvatarBackgroundColor = (user, hovered = false) => {
  if (user?.premium_info?.active) {
    return 'rgb(255, 255, 193)';
  } else {
    return hovered ? '#7A3EFF' : doubtedBlue;
  }
};

export const getLootType = loot => {
  switch (loot) {
    case 'mythic':
      return 'Mythic Havoc';
    case 'pump':
      return 'Gold Pump';
    default:
      return 'Default';
  }
};

export const getPurchaseTitle = purchase => {
  switch (purchase?.type) {
    case 'crate':
      return 'Avatar Crate';
    case 'rare_items_crate':
      return 'Godlike Crate';
    case 'emote_crate':
      return 'Emote Crate';
    case 'emoji_crate':
      return 'Emoji Crate';
    case 'VIP':
      return 'VIP Subscription';
    case 'UNBAN':
      return 'Unban';
    case 'CHAT_UNBAN':
      return 'Chat Unban';
    case 'STATS':
      return 'Stat Reset';
    case 'EARNINGS_RESET':
      return 'Earnings Reset';
    case 'SNIPES':
      return 'Match Snipes';
    case 'UNLINK':
      return 'Account Unlink';
    case 'BP':
      return 'Rivox Pass';
    default:
      return purchase?.piece_name;
  }
};

export const hexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  throw new Error('Bad Hex');
};

export const getMatchTypeImage = (matchType, game, gameModeOptions) => {
  if (game !== 'fortnite') {
    switch (game) {
      case 'clashroyale':
        return clashHeader;
      case 'fifa':
        return fifaHeader;
      case 'warzone':
        return warzoneHeader;
      default:
        return null;
    }
  } else {
    if (gameModeOptions?.options?.length > 0) {
      switch (gameModeOptions?.options[0]) {
        case 'zw':
          return zw;
        case 'bf':
        case 'zbm_bf':
          return bf;
        case 'real':
          return real;
        case 'build':
          return build;
        case 'zbm':
          return zbm;
        case 'zbm_real':
          return zbmReal;
        case 'esl_hp':
          return zbmReal;
        case 'esl_ctf':
          return zbmReal;
        default:
          return null;
      }
    }
    switch (matchType) {
      case 'zw':
        return zw;
      case 'bf':
      case 'zbm_bf':
        return bf;
      case 'real':
        return real;
      case 'build':
        return build;
      case 'zbm':
        return zbm;
      case 'zbm_real':
        return zbmReal;
      default:
        return null;
    }
  }
};

export const shouldShowFirstTo = (selectedOption, gameModeOptions, game) => {
  if (game !== 'fortnite') return false;

  if (
    gameModeOptions?.includes('esl_ctf') ||
    gameModeOptions?.includes('esl_hp')
  ) {
    return false;
  }
  if (selectedOption) {
    if (selectedOption !== 'zbm') return true;

    return false;
  }

  if (gameModeOptions?.length === 1) {
    if (gameModeOptions[0] !== 'zbm') {
      return true;
    }

    return false;
  }

  return true;
};

export const shouldShowBestOf = (game, selectedOption, gameModeOptions) => {
  if (game === 'warzone') return true;

  if (selectedOption) {
    if (selectedOption === 'zbm') return true;

    return false;
  }

  if (gameModeOptions?.length === 1) {
    if (gameModeOptions[0] === 'zbm') return true;

    return false;
  }

  if (gameModeOptions?.includes('zbm')) return true;

  return false;
};

export const getFee = game => {
  switch (game) {
    case 'warzone':
      return 0.95;
    default:
      return 0.9;
  }
};
