import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { cardVeryLight, doubtedBlue } from '../../utils/themeContstants';

const OutlineButton = ({
  label,
  onClick,
  selected,
  size = 'normal',
  color = doubtedBlue,
  loading = false,
}) => {
  const [hovered, setHovered] = useState(false);

  const styles = {
    selected: {
      transition: 'all 0.1s ease 0s',
      height: size === 'small' ? 30 : 40,
      minHeight: size === 'small' ? 30 : 40,
      padding: 2,
      borderRadius: 1,
      fontSize: size === 'small' ? 11 : 14,
      fontWeight: 600,
      cursor: hovered ? 'pointer' : 'default',
      color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: cardVeryLight,
    },
    unselected: {
      transition: 'all 0.1s ease 0s',
      height: size === 'small' ? 30 : 40,
      minHeight: size === 'small' ? 30 : 40,
      padding: 2,
      borderRadius: 1,
      fontSize: size === 'small' ? 11 : 14,
      fontWeight: 600,
      cursor: loading ? 'not-allowed' : hovered ? 'pointer' : 'default',
      color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: loading
        ? 'transparent'
        : hovered
          ? cardVeryLight
          : 'transparent',
    },
  };

  return (
    <Grid
      item
      sx={selected ? styles.selected : styles.unselected}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={loading ? null : onClick}
    >
      {loading ? (
        <CircularProgress size={30} sx={{ color: color }} />
      ) : (
        <>{label}</>
      )}
    </Grid>
  );
};

export default OutlineButton;
