import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { HiOutlineMap } from 'react-icons/hi2';
import { getMaps } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { DISPUTES_LIMIT } from '../../utils/limits';
import { text } from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import MapItem from './MapItem';

const MapsDashboard = () => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [maps, setMaps] = useState(null);
  const [rank, setRank] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetMaps = () => {
    getMaps(middleware, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.maps?.length >= DISPUTES_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setRank(res?.rank);
        setMaps(maps ? [...maps, ...res?.maps] : res?.maps);
      }
    });
  };

  usePagination(bottom, () => setSkip(maps?.length));

  useEffect(() => {
    if (maps == null) {
      handleGetMaps();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetMaps();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <HiOutlineMap style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Maps</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          {/* {(!maps || maps?.length < 1) && (
              <Grid item alignSelf="center">
                <NotSelected label="NO Maps" />
              </Grid>
            )} */}

          <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            {' '}
            <Typography variant='h6' component='div' color='white'>
              Current Worldwide Rank:
            </Typography>
            <Typography variant='h3' component='div' color='green'>
              {rank?.rank}
            </Typography>
            <Typography
              variant='p'
              component='div'
              style={{ marginTop: '0.2rem' }}
              color='secondaryText'
            ></Typography>
            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              {maps?.map((map, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <MapItem map={map} />
                </Grid>
              ))}
            </Grid>
            {/* <Box sx={{ width: "100%", height: "500px" }}>
            <MapFrame />
          </Box> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MapsDashboard;
