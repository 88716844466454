import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import useMiddleware from '../../api/useMiddleware';
import { buySnipes } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { numFormatter } from '../../utils/helpers';
import { snipeOptions, snipePrices } from '../../utils/options';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import CustomModal from '../custom/CustomModal';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';

const BuySnipesModal = ({ open, onClose, closeProfile }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);

  const [understands, setUnderstands] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snipeAmount, setSnipeAmount] = useState(25);

  const handleClose = () => {
    setUnderstands(false);
    setLoading(false);
    setSnipeAmount(25);
    onClose();
  };

  const handleBuySnipes = () => {
    setLoading(true);
    buySnipes(middleware, snipeAmount).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
        closeProfile();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 500,
    },
    width: {
      width: '100%',
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 500,
    },
  };

  return (
    <CustomModal
      title='Purchase Snipes'
      primaryButton={
        <SecondaryButton
          label='Buy now'
          bg={doubtedBlue}
          disabled={
            loading ||
            !understands ||
            !snipeAmount ||
            store?.user?.balance < snipePrices(snipeAmount)
          }
          onClick={handleBuySnipes}
        />
      }
      secondaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
      open={open}
      onClose={handleClose}
      titleSize='large'
      description='View your opponent before playing them.'
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.description}>
            You are about to purchase{' '}
            <span style={{ fontWeight: 800 }}>{snipeAmount ?? 0} snipes</span>,
            which allow you to see who your opponents are prior to joining. This
            does not give you any benefits from the VIP subscription.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider
            sx={{ height: 2, width: '100%', backgroundColor: cardVeryLight }}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography sx={styles.value}>Purchasing</Typography>
                </Grid>

                {/* <Grid item>
                  <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                    10 Snipes
                  </Typography>
                </Grid> */}
                <Grid item>
                  <CustomDropdown
                    options={snipeOptions}
                    placeholder='Amount of Snipes'
                    onChange={value => setSnipeAmount(value)}
                    value={
                      snipeAmount === '' || snipeAmount == null
                        ? 'Amount of Snipes'
                        : snipeAmount
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography sx={styles.value}>Total</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='center'
                    gap={{ xs: 0.5 }}
                  >
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <RivoxCoin size={20} />
                    </Grid>

                    <Grid item>
                      <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                        {numFormatter().format(snipePrices(snipeAmount))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {snipeAmount != null && snipeAmount !== '' && (
          <>
            <Grid item sx={styles.width}>
              <Divider
                sx={{
                  height: 2,
                  width: '100%',
                  backgroundColor: cardVeryLight,
                }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 2 }}
              >
                <Grid>
                  <CustomCheckbox
                    checked={understands}
                    onChange={value => setUnderstands(value)}
                    color={doubtedBlue}
                    disabled={loading}
                    checkColor={text}
                  />
                </Grid>

                <Grid>
                  <Typography
                    sx={{
                      fontSize: small,
                      color: secondaryText,
                      fontWeight: 600,
                      maxWidth: 400,
                    }}
                  >
                    By checking this box you understand that you are about to
                    purchase {snipeAmount} snipes and NOT receive a VIP
                    subscription.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </CustomModal>
  );
};

export default BuySnipesModal;
