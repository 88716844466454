import { CircularProgress, Grid, Typography } from '@mui/material';
import { CDN_URL } from '../../utils/constants';
import { paypal, text, transition } from '../../utils/themeContstants';

const PayPalCheckoutButton = ({ onClick, loading, disabled }) => {
  const paypalLogo = `${CDN_URL}paypal-button.svg`;

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: 1,
      backgroundColor: paypal,
      opacity: disabled || loading ? 0.6 : 1,
      transition: transition,
      padding: 2,
      height: 42,
      '&:hover': {
        cursor: disabled || loading ? 'not-allowed' : 'pointer',
        backgroundColor: paypal,
        filter: disabled || loading ? null : 'brightness(85%)',
      },
    },
    text: {
      fontSize: 16,
      fontWeight: 600,
      color: text,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={e => {
        if (disabled || loading) {
          return;
        }

        onClick(e);
      }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        {loading && (
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={20} sx={{ color: text }} />
          </Grid>
        )}

        {!loading && (
          <>
            <Grid item>
              <Typography sx={styles.text}>Pay now with</Typography>
            </Grid>

            <Grid item sx={styles.icon}>
              <img
                src={paypalLogo}
                draggable={false}
                alt='paypal-logo'
                width={70}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PayPalCheckoutButton;
