import { Grid } from '@mui/material';
import BackBling from './pieces/backbling/BackBling';
import Clothes from './pieces/clothes/Clothes';
import Earrings from './pieces/earrings/Earrings';
import Emote from './pieces/emote/Emote';
import Eyebrows from './pieces/eyebrows/Eyebrows';
import Eyes from './pieces/eyes/Eyes';
import FacialHair from './pieces/facial_hair/FacialHair';
import Glasses from './pieces/glasses/Glasses';
import Hair from './pieces/hair/Hair';
import Hat from './pieces/hat/Hat';
import Mouth from './pieces/mouth/Mouth';
import Necklace from './pieces/necklace/Necklace';
import Nose from './pieces/nose/Nose';
import Tattoo from './pieces/tattoos/Tattoo';
import { getColor } from './utils/getColor';

const Wrapper = ({ children, size, type, option, owned }) => {
  const getViewBox = () => {
    if (type === 'hair') {
      return `200 50 600 600`;
    }

    if (type === 'hat') {
      switch (option) {
        case 'cowboy_hat':
        case 'nice':
        case 'party_hat':
          return `200 0 600 600`;
        case 'turban':
          return `200 150 600 600`;
        case 'doctor':
          return `250 100 600 600`;
        default:
          return `200 100 600 600`;
      }
    }

    if (type === 'facialHair') {
      return `275 200 500 500`;
    }

    if (type === 'earrings') {
      switch (option) {
        case 'airpod':
          return `175 350 150 150`;
        default:
          return `275 350 150 150`;
      }
    }

    if (type === 'eyes') {
      return `380 225 300 300`;
    }

    if (type === 'eyebrows') {
      return `385 200 300 300`;
    }

    if (type === 'nose') {
      return `435 310 200 200`;
    }

    if (type === 'mouth') {
      switch (option) {
        case 'ghost':
        case 'wrap_it_up':
        case 'wrap_it_up_2':
        case 'wrap_it_up_3':
          return `320 250 400 400`;
        case 'kunai':
          return `400 250 400 400`;
        default:
          return `450 375 200 200`;
      }
    }

    if (type === 'glasses') {
      return `315 175 400 400`;
    }

    if (type === 'clothes') {
      switch (option) {
        case 'dawn':
          return `200 275 600 600`;
        case 'brrrrrr':
        case 'canadian_goose':
          return `150 175 675 675`;
        case 'college_hoodie':
        case 'college_hoodie_michigan':
        case 'college_hoodie_duke':
        case 'college_hoodie_ucla':
        case 'college_hoodie_msu':
        case 'bathing_ape':
        case 'creeper':
        case 'minion':
        case 'tate':
        case 'hoodie_eyes':
        case 'incognito':
        case 'cleezy':
        case 'pinhead':
        case 'scream':
        case 'skelly_zip':
        case 'y2k_hoodie':
        case 'travis_scott':
        case 'lil_uzi':
        case 'alien':
        case 'juice_wrld':
        case 'drizzy_drake':
        case 'drool_emoji':
        case 'ape_shit':
        case 'zombie':
        case 'mummy':
        case 'rex':
        case 'yeat_cat_hoodie':
        case 'doge':
          return `165 175 675 715`;
        case 'drool_emoji':
        case 'nerd_emoji':
        case 'alien_emoji':
        case 'laugh_emoji':
        case 'hearts_emoji':
        case 'angry_emoji':
        case 'crying_emoji':
        case 'demon_emoji':
        case 'eyes_emoji':
        case 'heart_eyes_emoji':
        case 'singer_emoji':
        case 'skull_emoji':
        case 'crossbones_emoji':
        case 'monkey_emoji':
        case 'interesting_emoji':
        case 'disguise_emoji':
        case 'sunglasses_emoji':
        case 'cowboy_emoji':
        case 'blown_emoji':
        case 'zipper_emoji':
        case 'half-skull_emoji':
        case 'swirl_emoji':
        case 'confused_emoji':
          return `100 225 800 715`;
        default:
          return `230 425 500 500`;
      }
    }

    if (type === 'necklace') {
      switch (option) {
        case 'yeezy_necklace':
        case 'jordan_necklace':
          return `325 535 300 300`;
        case 'choker_necklace':
        case 'bandana':
          return `405 500 150 150`;
        case 'on_pause':
        case 'scarf':
          return `365 525 225 225`;
        case 'dog_tag_necklace':
        case 'cross':
          return `390 550 175 175`;
        default:
          return `375 560 225 225`;
      }
    }

    if (type === 'backBling') {
      switch (option) {
        case 'sword_backBling':
        case 'guitar_backBling':
          return `115 250 400 400`;
        case 'axe_backBling':
          return `115 315 400 400`;
        case 'sword_katana_backBling':
          return `115 200 450 450`;
        case 'vandal_backBling':
        case 'phantom_backBling':
        case 'spaz':
        case 'scar':
          return `140 250 415 415`;
        case 'cat_backBling':
          return `175 400 300 300`;
        case 'dog_backBling':
        case 'cat_2_backBling':
          return `245 450 250 250`;
        default:
          return `230 425 500 500`;
      }
    }

    if (type === 'tattoos') {
      if (option === 'revenge_face') {
        return '385 250 175 175';
      }
      if (option === 'uzi_diamond') {
        return '450 250 175 175';
      }
      if (option === 'anime_scar') {
        return '525 215 175 175';
      }
      if (option === 'tear_drop') {
        return '375 325 125 125';
      }
      if (option === 'savage_sword') {
        return '450 250 175 175';
      }
      if (option === 'yuji_tattoo') {
        return '325 200 400 400';
      }
      return '425 515 100 100';
    }

    return `125 100 750 750`;
  };
  return (
    <svg
      viewBox={getViewBox()}
      height={size}
      width={size}
      style={{ opacity: owned ? 0.4 : 1 }}
    >
      {children}
    </svg>
  );
};

const EmoteWrapper = ({ children, size, owned }) => {
  return (
    <div
      style={{
        position: 'relative',
        width: size,
        height: size,
        opacity: owned ? 0.4 : 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

const AvatarColor = ({ color }) => {
  return (
    <Grid
      item
      sx={{
        minWidth: 50,
        minHeight: 50,
        backgroundColor: getColor(color),
        borderRadius: 50,
      }}
    />
  );
};

const Piece = ({
  type,
  option,
  color,
  size = null,
  skinColor,
  owned = false,
}) => {
  switch (type) {
    case 'hat':
      return (
        <Wrapper size={size} owned={owned} option={option} type='hat'>
          <Hat type={option} color={color} />
        </Wrapper>
      );
    case 'hair':
      return (
        <Wrapper size={size} owned={owned} type='hair'>
          <Hair type={option} color={color} />
        </Wrapper>
      );
    case 'eyebrows':
      return (
        <Wrapper size={size} type='eyebrows' owned={owned}>
          <Eyebrows type={option} />
        </Wrapper>
      );
    case 'eyes':
      return (
        <Wrapper size={size} type={'eyes'} owned={owned}>
          <Eyes type={option} />
        </Wrapper>
      );
    case 'mouth':
      return (
        <Wrapper size={size} type='mouth' owned={owned} option={option}>
          <Mouth type={option} />
        </Wrapper>
      );
    case 'nose':
      return (
        <Wrapper size={size} type='nose' owned={owned}>
          <Nose type={option} />
        </Wrapper>
      );
    case 'clothes':
      return (
        <Wrapper size={size} type='clothes' owned={owned} option={option}>
          <Clothes type={option} color={color} skinColor={skinColor} />
        </Wrapper>
      );
    case 'facialHair':
      return (
        <Wrapper size={size} owned={owned} type='facialHair'>
          <FacialHair type={option} color={color} />
        </Wrapper>
      );
    case 'glasses':
      return (
        <Wrapper size={size} owned={owned} type='glasses'>
          <Glasses type={option} />
        </Wrapper>
      );
    case 'necklace':
      return (
        <Wrapper size={size} type='necklace' owned={owned} option={option}>
          <Necklace type={option} skinColor={skinColor} />
        </Wrapper>
      );
    case 'earrings':
      return (
        <Wrapper size={size} type='earrings' owned={owned}>
          <Earrings type={option} />
        </Wrapper>
      );
    case 'tattoos':
      return (
        <Wrapper size={size} type='tattoos' option={option} owned={owned}>
          <Tattoo type={option} />
        </Wrapper>
      );
    case 'color':
      return <AvatarColor color={color} />;
    case 'backBling':
      return (
        <Wrapper size={size} type='backBling' option={option} owned={owned}>
          <BackBling type={option} skinColor={skinColor} />
        </Wrapper>
      );
    case 'emote':
      return (
        <EmoteWrapper size={size} type='necklace' option={option} owned={owned}>
          <Emote type={option} skinColor={skinColor} />
        </EmoteWrapper>
      );
    default:
      return null;
  }
};

export default Piece;
