import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../store/Store';

export const useAuth = () => {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      if (!store.user) {
        return;
      }
      setLoading(false);
    }
  }, [store.user, loading]);

  return [loading];
};
