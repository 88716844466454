import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import { CDN_URL } from '../../utils/constants';
import { hexToRgbA, numFormatter } from '../../utils/helpers';
import {
  cardVeryLight,
  menuShadow,
  red,
  secondaryText,
  transition,
} from '../../utils/themeContstants';
import UpgradeModal from '../app/UpgradeModal';
import RivoxCoin from '../custom/RivoxCoin';
import PurchaseChatUnbanModal from '../public_profile/PurchaseChatUnbanModal';
import PurchaseUnbanModal from '../public_profile/PurchaseUnbanModal';
import ResetEarningsModal from '../public_profile/ResetEarningsModal';
import ResetStatsModal from '../public_profile/ResetStatsModal';
import BuySnipesModal from '../subscriptions/BuySnipesModal';

const OtherItem = ({ type }) => {
  const snipes = `${CDN_URL}3d_icons/snipes-large.png`;
  const reset = `${CDN_URL}3d_icons/reset-large.png`;
  const premium = `${CDN_URL}3d_icons/premium.png`;
  const unban = `${CDN_URL}3d_icons/unban-large.png`;
  const chatUnban = `${CDN_URL}3d_icons/chat-unban-large.png`;
  const earnings = `${CDN_URL}3d_icons/earnings-reset.png`;
  const battle_pass = `${CDN_URL}bp-icon.svg`;

  const isDesktop = useMediaQuery('(min-width:1025px)');
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);
  const [unbanOpen, setUnbanOpen] = useState(false);
  const [chatUnbanOpen, setChatUnbanOpen] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);

  const handleOpen = () => {
    if (!store?.user) return navigate('/login');

    switch (type) {
      case 'snipes':
        return setSnipesOpen(true);
      case 'reset':
        return setResetOpen(true);
      case 'vip':
        return setPremiumOpen(true);
      case 'unban':
        return setUnbanOpen(true);
      case 'chat_unban':
        return setChatUnbanOpen(true);
      case 'reset_earnings':
        return setResetEarningsOpen(true);
      case 'battle_pass':
        return navigate('/shop/battle_pass');
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'snipes':
        return 'Match Snipes';
      case 'reset':
        return 'Stat Reset';
      case 'reset_earnings':
        return 'Earnings Reset';
      case 'vip':
        return 'VIP Subscription';
      case 'unban':
        return 'Unban';
      case 'chat_unban':
        return 'Chat Unban';
      case 'battle_pass':
        return 'Rivox Pass';
      default:
        return null;
    }
  };

  const getAmount = () => {
    switch (type) {
      case 'snipes':
        return 1.99;
      case 'reset':
        return 1.99;
      case 'reset_earnings':
        return 1.99;
      case 'vip':
        return 2.99;
      case 'unban':
        return store?.user?.behavior_info?.debt_price;
      case 'chat_unban':
        return 1.49;
      case 'battle_pass':
        return 4.49;
      default:
        return null;
    }
  };

  const getImage = () => {
    switch (type) {
      case 'snipes':
        return snipes;
      case 'reset':
        return reset;
      case 'vip':
        return premium;
      case 'unban':
        return unban;
      case 'chat_unban':
        return chatUnban;
      case 'reset_earnings':
        return earnings;
      case 'battle_pass':
        return battle_pass;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      borderRadius: 2,
      border: `3px solid ${cardVeryLight}`,
      boxSizing: 'border-box',
      backgroundColor: hexToRgbA(cardVeryLight, 0.3),
      transition: transition,
      cursor: hovered ? 'pointer' : 'cursor',
      transform: hovered ? 'translateY(-5px)' : null,
      boxShadow: hovered ? menuShadow : null,
      position: 'relative',
    },
    wordContainer: {
      width: '100%',
      padding: 1,
      backgroundColor: hexToRgbA(cardVeryLight, 0.3),
      marginTop: 'auto',
      position: 'relative',
    },
    name: {
      fontSize: 18,
      color: '#fff',
      fontWeight: 800,
      textAlign: 'center',
    },
  };

  return (
    <>
      <UpgradeModal open={premiumOpen} onClose={() => setPremiumOpen(false)} />
      <BuySnipesModal open={snipesOpen} onClose={() => setSnipesOpen(false)} />
      <ResetStatsModal open={resetOpen} onClose={() => setResetOpen(false)} />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PurchaseChatUnbanModal
        open={chatUnbanOpen}
        onClose={() => setChatUnbanOpen(false)}
      />

      <Grid
        item
        sx={styles.container}
        onClick={handleOpen}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Grid
          container
          direction='column'
          alignItems={isDesktop ? 'start' : 'center'}
          justifyContent='center'
          sx={{ height: '100%' }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              padding: 2,
            }}
          >
            <img src={getImage()} draggable={false} height={100} />
          </Grid>

          <Grid item sx={styles.wordContainer}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item>
                <Typography sx={styles.name}>{getTitle()}</Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  gap={{ xs: 0.5 }}
                >
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <RivoxCoin size={22} />
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#fff',
                        fontWeight: 800,
                      }}
                    >
                      {numFormatter().format(getAmount())}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: red,
                        fontWeight: 800,
                        textDecoration: 'line-through',
                      }}
                    >
                      <span style={{ color: secondaryText }}>
                        {numFormatter().format(getAmount() * 1.5)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OtherItem;
