import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { cardLight } from '../../utils/themeContstants';
import CustomMenu from '../custom/CustomMenu';
import ListItem from '../custom/ListItem';

const MoreProfileMenu = ({ anchor, handleClose }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <CustomMenu anchor={anchor} handleClose={handleClose} minWidth={200}>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          {small && (
            <ListItem
              label='Linked Accounts'
              onClick={() => {
                navigate('/profile/accounts');
                handleClose();
              }}
              selected={false}
              selectedBg={cardLight}
              hoveredBg={cardLight}
              open={true}
            />
          )}

          {!isDesktop && (
            <>
              <ListItem
                label='Match History'
                onClick={() => {
                  navigate('/profile/history');
                  handleClose();
                }}
                selected={false}
                selectedBg={cardLight}
                hoveredBg={cardLight}
                open={true}
              />

              <ListItem
                label='Deposits'
                onClick={() => {
                  navigate('/profile/deposits');
                  handleClose();
                }}
                selected={false}
                selectedBg={cardLight}
                hoveredBg={cardLight}
                open={true}
              />

              <ListItem
                label='Withdrawals'
                onClick={() => {
                  navigate('/profile/withdrawals');
                  handleClose();
                }}
                selected={false}
                selectedBg={cardLight}
                hoveredBg={cardLight}
                open={true}
              />
            </>
          )}

          <ListItem
            label='Tips'
            onClick={() => {
              navigate('/profile/tips');
              handleClose();
            }}
            selected={false}
            selectedBg={cardLight}
            hoveredBg={cardLight}
            open={true}
          />

          <ListItem
            label='Purchases'
            onClick={() => {
              navigate('/profile/purchases');
              handleClose();
            }}
            selected={false}
            selectedBg={cardLight}
            hoveredBg={cardLight}
            open={true}
          />

          <ListItem
            label='Subscriptions'
            onClick={() => {
              navigate('/profile/subscriptions');
              handleClose();
            }}
            selected={false}
            selectedBg={cardLight}
            hoveredBg={cardLight}
            open={true}
          />
          <ListItem
            label='Referrals'
            onClick={() => {
              navigate('/profile/referrals');
              handleClose();
            }}
            selected={false}
            selectedBg={cardLight}
            hoveredBg={cardLight}
            open={true}
          />
        </Grid>
      </Grid>
    </CustomMenu>
  );
};

export default MoreProfileMenu;
