import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { RiTeamLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import useMiddleware, { getAccessToken } from '../../api/useMiddleware';
import { getUserTeamsDetailed } from '../../api/user';
import useNotifications from '../../sockets/useNotifications';
import {
  SET_ERRORS,
  SET_TEAMS,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  doubtedBlue,
  emptyIcon,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import Empty from '../custom/Empty';
import SecondaryButton from '../custom/SecondaryButton';
import CreateTeamModal from './CreateTeamModal';
import TeamItem from './TeamItem';

const Teams = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const { teamToRemove } = useNotifications(store?.user?._id, getAccessToken());
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    if (store?.user) {
      if (!teams) {
        getUserTeamsDetailed(middleware).then(res => {
          setLoading(false);
          if (res?.error) {
            updateStore({
              type: SET_ERRORS,
              payload: res?.message,
            });
          } else {
            updateStore({ type: SET_TEAMS, payload: res?.teams });
          }
        });
      }
    }
  }, [store?.user, middleware, teams, updateStore, store?.teams?.length]);

  useEffect(() => {
    if (!teams || teams?.length !== store?.teams?.length) {
      setTeams(store?.teams);
    }
  }, [store, store?.teams, teams?.length, teams]);

  useEffect(() => {
    if (teamToRemove) {
      const indexOfTeam = teams?.findIndex(team => team?._id === teamToRemove);
      let newTeams = teams;
      newTeams.splice(indexOfTeam, 1);
      setTeams([...newTeams]);
      updateStore({ type: SET_TEAMS, payload: [...newTeams] });
    }
  }, [teamToRemove]);

  useEffect(() => {
    if (location?.state?.create) {
      setCreateOpen(true);
    }
  }, [location?.state]);

  const styles = {
    width: {
      width: '100%',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    container: {
      width: '100%',
    },
  };

  return (
    <>
      <CreateTeamModal open={createOpen} onClose={() => setCreateOpen(false)} />

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          gap={{ xs: 2 }}
        >
          <Grid item>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.icon}>
                <RiTeamLine style={{ fontSize: 22, color: text }} />
              </Grid>

              <Grid item>
                <Typography sx={styles.title}>Teams</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <SecondaryButton
              bg={doubtedBlue}
              label='New Team'
              onClick={() => setCreateOpen(true)}
            />
          </Grid>
        </Grid>
      </Grid>

      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (
        <Grid item sx={styles.container}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {teams?.length < 1 && (
              <Empty
                title='No teams'
                label='Try creating a team'
                icon={<RiTeamLine style={emptyIcon} />}
              />
            )}

            {teams?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  {teams?.map((team, i) => (
                    <TeamItem team={team} key={i} />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Teams;
