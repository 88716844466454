import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../store/Store';
import {
  card,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import UpdateTOSModal from './UpdateTOSModal';

const Rules = () => {
  const store = useContext(StoreContext);

  const [tosOpen, setTosOpen] = useState(false);

  useEffect(() => {
    if (store?.user) {
      if (store?.user?.info?.last_agreed_tos_version !== store?.tosVersion) {
        setTosOpen(true);
        return;
      }
    }
  }, [store?.user]);

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    container: {
      width: '100%',
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
    },
    modified: {
      fontSize: normal,
      fontWeight: 700,
      color: secondaryText,
    },
    forward: {
      fontWeight: 500,
      color: text,
      fontSize: 16,
    },
    subHeader: {
      fontSize: 22,
      fontWeight: 800,
      color: text,
    },
    text: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <>
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.header}>Rules</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.modified}>
              Last modified: December 30, 2024
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 4 }}
          sx={{ backgroundColor: cardLight, padding: 2, borderRadius: 1 }}
        >
          <Grid item>
            <Typography sx={styles.text}>
              <p>
                <strong>Platforms & Epic Names</strong>
                <br />
                1. If a player's platform or Epic Name is incorrect, the player
                has 7 minutes from the time a moderator enters the conversation
                to remedy the problem.
                <br />
                2. The game must be restarted if the player resolves the issue
                by switching accounts or platforms.
                <br />
                3. If a player changes their Epic Name on their rivox.cc
                account, the score remains the same and the game must be
                restarted.
                <br />
                4. Moderators have the option of allowing players to modify
                their Epic Name early if they like.
              </p>
              <hr />

              <p>
                <strong>Continue to play if you're unsure.</strong>
                <br />
                1. Even if you think your opponent is breaking the rules, we
                urge finishing the game!
                <br />
                2. Continuing to play a game will never result in a penalty.
                <br />
                3. If your report is correct, the match will either be replayed
                or will end in your favor.
              </p>
              <hr />

              <p>
                <strong>AFK and availability</strong>
                <br />
                1. From the start of a match, all members get 15 minutes to
                prepare in the lobby. During tournaments, this time is reduced
                to 7 minutes.
                <br />
                2. To verify that the opposition team did not join within 15
                minutes, chat and/or join log photo evidence will be necessary.
                <br />
                3. If any teammates are absent, the match may be played with
                fewer players.
                <br />
                4. Cancellation of the match must be agreed upon by both
                parties.
                <br />
                While your opponent is AFK, you can only get one kill against
                them at a time.
              </p>
              <hr />

              <p>
                <strong>Glitches in the game</strong>
                <br />
                1. If a game glitch occurs, the rounds in which the game glitch
                occurs are not counted.
                <br />
                2. Exploits and two-sided game mechanics that are repeatable do
                not count as game glitches.
                <br />
                3. A game crash isn't considered a bug because it's hard to know
                whether it caused intentionally or not.
                <br />
              </p>
              <hr />

              <p>
                <strong>Party rules, Host, Map & Region</strong>
                <br />
                1. The game is reset if the match is played in the wrong region.
                <br />
                2. Host is team 1 in-game while the opponents switch to Team 2.
                All points awarded to the incorrect teams stand.
                <br />
                3. If the host is incorrect, all completed matches count, and
                the non-hosting team has the option to quit and request the host
                after the current round.
                <br />
                4. If the map is incorrect, all completed matches are counted,
                and after the current round, the default Rivox recommended map
                can be picked.
                <br />
                5. It's an immediate round loss if you kick your opponent out of
                the party in the middle of the round.
                <br />
              </p>
              <hr />

              <p>
                <strong>Observing / Spectating</strong>
                <br />
                1. Any rounds with an outside spectator will be reset, and the
                team that did not invite the spectator will receive a point.
                <br />
                2. The lobby should be made as secluded as feasible.
                <br />
                3. It's important to note that other players joining the lobby
                but not spectating will not cause a redo.
                <br />
              </p>
              <hr />

              <p>
                <strong>Toxicity</strong>
                <br />
                Use the !toxic command for more info in the discord server. You
                are not allowed to be overly toxic, this will result in a ban.
                However most forms of toxicity are fine, it's up to mods and
                admins to decide.
                <br />
              </p>
              <hr />

              <p>
                <strong>Disconnections</strong>
                <br />
                1. You cannot respawn in the middle of a round.
                <br />
                This is to avoid players preventing siphon being awarded to
                their opponents.
                <br />
                2. If a team member leaves the game after the round has begun,
                the remaining members must finish the round. That round would be
                declared a loss if all players left.
                <br />
                3. The round does not count if a team member leaves before the
                round begins, and the game is halted.
                <br />
                4. All unavailable players will be unable to play for the
                remainder of the game if the game is paused for more than 10
                minutes.
                <br />
                5. Disconnecting due to lag, a player's game crashing, or any
                other equipment issues, though unpleasant, does not result in a
                redo because it is hard to show whether or not it was
                intentional. <br />
              </p>
              <hr />

              <p>
                <strong>Deception</strong>
                <br />
                1. Lying to your opponents regarding the rules, expectations,
                setup, or anything else that has a direct and clear impact on
                the match is not allowed. Although not advised, deceiving
                someone about your skill level is allowed.
                <br />
                2. The rule is upheld if enforceable and at least one person
                from each team agrees to an alternative or additional game rule
                or modification by voice or chat, with evidence.
                <br />
                3. Attempting to complete a game or report someone while one is
                in progress is considered deception. <br />
                4. You should immediately stop playing if you flag the match as
                complete or report your opponent. If you leave in the middle of
                a round and your report is invalid, the opposing side will
                receive a point, so if you're not sure, finish the match first.{' '}
                <br />
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Rules;
