import React, { Suspense } from 'react';

const hatComponents = {
  beanie_hat: React.lazy(() => import('./types/Beanie')),
  beanie_2_hat: React.lazy(() => import('./types/BeanieTwo')),
  pirate_hat: React.lazy(() => import('./types/Pirate')),
  durag_hat: React.lazy(() => import('./types/Durag')),
  durag_2_hat: React.lazy(() => import('./types/DuragTwo')),
  cowboy_hat: React.lazy(() => import('./types/Cowboy')),
  pilot_hat: React.lazy(() => import('./types/Pilot')),
  baseball_hat: React.lazy(() => import('./types/Baseball')),
  santa_hat: React.lazy(() => import('./types/Santa')),
  ski_mask_hat: React.lazy(() => import('./types/SkiMask')),
  tac_squad: React.lazy(() => import('./types/TacSquad')),
  astronaut: React.lazy(() => import('./types/Astronaut')),
  doctor: React.lazy(() => import('./types/Doctor')),
  turban: React.lazy(() => import('./types/Turban')),
  naughty: React.lazy(() => import('./types/Naughty')),
  nice: React.lazy(() => import('./types/Nice')),
  party_hat: React.lazy(() => import('./types/PartyHat')),
  ichigo: React.lazy(() => import('./types/Ichigo')),
  terminator: React.lazy(() => import('./types/Arnold')),
  chrome_beanie: React.lazy(() => import('./types/ChromeBeanie')),
  york: React.lazy(() => import('./types/York')),
  gucci_band: React.lazy(() => import('./types/Gucci')),
  jason: React.lazy(() => import('./types/Jason')),
  vlone_mask: React.lazy(() => import('./types/VloneMask')),
  bandana_hat: React.lazy(() => import('./types/BandanaHat')),
  beats: React.lazy(() => import('./types/Beats')),
  boar_mask: React.lazy(() => import('./types/BoarMask')),
  burb_bucket: React.lazy(() => import('./types/BurberryBucket')),
  durag_flat_bill: React.lazy(() => import('./types/DuragFlatBill')),
  flat_bill: React.lazy(() => import('./types/FlatBill')),
  anonymous: React.lazy(() => import('./types/Anonymous')),
  headband: React.lazy(() => import('./types/Headband')),
  hokage: React.lazy(() => import('./types/Hokage')),
  the_chef: React.lazy(() => import('./types/TheChef')),
  yeat_fuzzy: React.lazy(() => import('./types/Tonka')),
  crown: React.lazy(() => import('./types/Crown')),
  obito: React.lazy(() => import('./types/Obito')),
  pennywise: React.lazy(() => import('./types/Pennywise')),
  pharaoh: React.lazy(() => import('./types/Pharaoh')),
  joker: React.lazy(() => import('./types/Joker')),
  mime_hat: React.lazy(() => import('./types/MimeHat')),
  one_piece: React.lazy(() => import('./types/OnePiece')),
  sakonji: React.lazy(() => import('./types/Sakonji')),
};

const Hat = ({ type, color }) => {
  const HatComponent = hatComponents[type];

  if (!HatComponent) return null;

  return (
    <Suspense fallback={null}>
      <HatComponent color={color} />
    </Suspense>
  );
};

export default Hat;
