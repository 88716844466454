import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { addNote } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { cardLight, offWhite, small } from '../../utils/themeContstants';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const AddNoteModal = ({ open, onClose, user }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setNote('');
    setLoading(false);
    onClose();
  };

  const handleAddNote = () => {
    setLoading(true);
    addNote(middleware, note, user?._id).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    label: {
      fontSize: small,
      fontWeight: 700,
      color: offWhite,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      title={`Add note for ${user?.username}`}
      open={open}
      onClose={handleClose}
      backgroundColor={cardLight}
      primaryButton={
        <PrimaryButton
          label='Add'
          loading={loading}
          onClick={handleAddNote}
          type='green'
          disabled={note === '' || note == null}
        />
      }
      secondaryButton={<SecondaryButton label='close' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid item>
          <Typography sx={styles.label}>NEW NOTE</Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <CustomInput
            value={note}
            onChange={setNote}
            placeholder={'Enter a note'}
            autoFocus={true}
            onKeyDown={() => {
              if (note !== '' && note != null && note?.length > 2) {
                handleAddNote();
              } else {
                return;
              }
            }}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddNoteModal;
