import { Divider, Grid, Skeleton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { FaCrown } from 'react-icons/fa';
import { MdOutlineTimer } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getStore } from '../../api/avatar';
import { SET_ERRORS, StoreDispatch } from '../../store/Store';
import {
  cardVeryLight,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import StoreItemGlimmer from '../glimmers/StoreItemGlimmer';
import ShopItem from './ShopItem';

const ShopAvatars = () => {
  const navigate = useNavigate();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [shop, setShop] = useState(null);

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span style={{ color: text }}>
        {days < 10 ? `0${days}` : days}:{hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };

  const handleGetStore = () => {
    getStore().then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setShop(res?.store);
      }
    });
  };

  useEffect(() => {
    if (shop == null) {
      handleGetStore();
    }
  }, []);

  const styles = {
    width: {
      width: '100%',
    },
    subHeader: {
      fontSize: 22,
      color: text,
      fontWeight: 700,
    },
    time: {
      fontSize: 18,
      color: text,
      fontWeight: 600,
    },
    meta: {
      fontSize: 15,
      color: secondaryText,
      fontWeight: 400,
      textAlign: 'center',
    },
    vipWithdrawals: {
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
      fontSize: 14,
      fontWeight: 500,
      opacity: 0.8,
    },
  };

  return (
    <>
      <>
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          alignSelf='center'
        >
          <Typography sx={styles.vipWithdrawals}>
            <FaCrown style={{ color: secondaryText, fontSize: 12 }} /> When you
            purchase VIP, you will be given two random pieces from the RARE to
            LEGENDARY rarities.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
          >
            {/* <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.subHeader}>Football Jerseys</Typography>
                </Grid>

                <Grid item sx={{ flexGrow: 1 }}>
                  <Divider
                    sx={{
                      backgroundColor: cardVeryLight,
                      borderRadius: 1,
                      height: 1,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item sx={styles.width}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill,minmax(194px, 6fr))',
                  gap: 12,
                }}
              >
                {loading && (
                  <>
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                    <StoreItemGlimmer />
                  </>
                )}

                {!loading && shop && (
                  <>
                    {shop?.featured_items?.map((item, i) => (
                      <ShopItem
                        key={i}
                        item={item}
                        onClick={() =>
                          navigate('/shop/shop-item', {
                            state: {
                              item,
                            },
                          })
                        }
                      />
                    ))}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.subHeader}>Current Shop</Typography>
            </Grid>

            <Grid item sx={{ flexGrow: 1 }}>
              <Divider
                sx={{
                  backgroundColor: cardVeryLight,
                  borderRadius: 1,
                  height: 1,
                }}
              />
            </Grid>

            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MdOutlineTimer style={{ color: text, fontSize: 26 }} />
            </Grid>

            {loading && (
              <Grid item>
                <Skeleton
                  variant='text'
                  animation='pulse'
                  sx={{
                    bgcolor: cardVeryLight,
                    height: 30,
                    width: 150,
                  }}
                />
              </Grid>
            )}

            {!loading && (
              <Grid item>
                <Typography sx={styles.time} noWrap>
                  <Countdown
                    date={new Date(shop?.reset_timestamp)}
                    renderer={renderer}
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(194px, 6fr))',
              gap: 12,
            }}
          >
            {loading && (
              <>
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
                <StoreItemGlimmer numItems={1} />
              </>
            )}

            {!loading && shop && (
              <>
                {shop?.daily_items?.map((item, i) => (
                  <ShopItem
                    key={i}
                    item={item}
                    onClick={() =>
                      navigate('/shop/shop-item', {
                        state: {
                          item,
                        },
                      })
                    }
                  />
                ))}
              </>
            )}
          </div>
        </Grid>

        <Grid item alignSelf='center' sx={{ marginTop: 4 }}>
          <Typography sx={styles.meta}>
            These items are purely cosmetic and grant no competitive advantage.
          </Typography>
        </Grid>
      </>
    </>
  );
};

export default ShopAvatars;
