import { Grid } from '@mui/material';
import { useContext } from 'react';
import { StoreContext } from '../../store/Store';
import OtherItem from './OtherItem';

const ShopBP = () => {
  const store = useContext(StoreContext);
  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill,minmax(194px, 6fr))',
            gap: 12,
          }}
        >
          <OtherItem type='battle_pass' />
        </div>
      </Grid>
    </>
  );
};

export default ShopBP;
