import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { FaCrown } from 'react-icons/fa';

import { AiOutlineStop } from 'react-icons/ai';
import Avatar from '../../avatar/Avatar';
import {
  SET_SEARCH_USER,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { MatchStateEnum } from '../../utils/enums';
import {
  badgeIconSize,
  badgeIconSizeSmall,
  card,
  cardDark,
  cardVeryLight,
  doubtedBlue,
  doubtedOrange,
  green,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from '../../utils/themeContstants';
import Badge from '../custom/Badge';

import { GiTwoCoins } from 'react-icons/gi';

const MatchTeamItem = ({ team, isCreatingTeam, token }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [hovered, setHovered] = useState(false);
  const [usernameHovered, setUsernameHovered] = useState(false);
  const [gameAccountHovered, setgameAccountHovered] = useState(false);

  const handleCopy = user => {
    navigator.clipboard.writeText(getConnectionName(user));
    updateStore({
      type: SET_SUCCESSES,
      payload: 'Game Account Copied',
    });
  };

  const handleOpenProfile = user_id => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const getIsHost = () => {
    if (isCreatingTeam) {
      if (token?.match_host === token?.creating_team?._id) {
        return true;
      } else {
        return false;
      }
    } else {
      if (token?.match_host === token?.joining_team?._id) {
        return true;
      } else {
        return false;
      }
    }
  };

  const hasSubmitted = () => {
    if (isCreatingTeam) {
      return token?.submits?.creating_submit;
    } else {
      return token?.submits?.joining_submit;
    }
  };

  const getSubmitLabel = () => {
    if (isCreatingTeam) {
      return token?.submits?.creating_submit === 'win'
        ? 'Submitted Win'
        : 'Submitted Loss';
    } else {
      return token?.submits?.joining_submit === 'win'
        ? 'Submitted Win'
        : 'Submitted Loss';
    }
  };

  const getSubmitIcon = () => {
    if (isCreatingTeam) {
      if (token?.submits?.creating_submit) {
        return token?.submits?.creating_submit === 'win' ? (
          <BiCheck style={{ color: green, fontSize: badgeIconSize }} />
        ) : (
          <BiX style={{ color: red, fontSize: badgeIconSize }} />
        );
      }
    } else {
      if (token?.submits?.joining_submit) {
        return token?.submits?.joining_submit === 'win' ? (
          <BiCheck style={{ color: green, fontSize: badgeIconSize }} />
        ) : (
          <BiX style={{ color: red, fontSize: badgeIconSize }} />
        );
      }
    }

    return null;
  };

  const isWinner = () => {
    if (isCreatingTeam) {
      return token?.winning_team_id === token?.creating_team_obj?._id;
    } else {
      return token?.winning_team_id === token?.joining_team_obj?._id;
    }
  };

  const getConnectionName = user => {
    switch (token?.game) {
      case 'fortnite':
        return user?.connections?.epic?.name;
      case 'clashroyale':
        return user?.connections?.clashroyale?.name;
      case 'chess':
        return user?.connections?.chess?.name;
      case 'fifa':
        return user?.connections?.fifa?.name;
      case 'warzone':
        return user?.connections?.activision?.name;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      border: `1px solid ${cardVeryLight}`,
      borderRadius: 2,
      flexGrow: 1,
      overflow: 'hidden',
    },
    width: {
      width: '100%',
    },
    teamName: {
      color: text,
      fontWeight: 800,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      fontSize: normal,
    },
    username: {
      fontSize: normal,
      transition: transition,
      color: text,
      fontWeight: 700,
    },
    epic: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 800,
    },
    badge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1,
      borderRadius: 1,
      backgroundColor: cardDark,
    },
    winner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: green,
    },
    loser: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: red,
    },
    resultText: {
      fontSize: 12,
      color: text,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        <Grid
          item
          sx={{
            width: '100%',
            borderBottom: `1px solid ${cardVeryLight}`,
            padding: 2,
            // backgroundColor: `${
            //   isCreatingTeam
            //     ? "rgba(51, 144, 255, .7)"
            //     : "rgba(239, 142, 51, .7)"
            // }`,
            backgroundColor: card,
          }}
        >
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1 }}
          >
            <Grid
              item
              sx={{
                borderLeft: `4px solid ${isCreatingTeam ? doubtedBlue : doubtedOrange}`,
                paddingLeft: 1,
              }}
            >
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: small, fontWeight: 600, color: offWhite }}
                  >
                    {isCreatingTeam ? 'Creating team' : 'Joining team'}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.teamName}>
                    {' '}
                    {team.name ? team.name : 'DISBANDED Team'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {getIsHost() && (
              <Badge label={'Host'}>
                <FaCrown style={{ color: yellow, fontSize: badgeIconSize }} />
              </Badge>
            )}

            {hasSubmitted() && (
              <Badge label={getSubmitLabel()}>{getSubmitIcon()}</Badge>
            )}

            {token?.state === MatchStateEnum.COMPLETED && (
              <>
                {isWinner() ? (
                  <Grid item sx={styles.winner}>
                    <Typography sx={styles.resultText}>VICTORY</Typography>
                  </Grid>
                ) : (
                  <Grid item sx={styles.loser}>
                    <Typography sx={styles.resultText}>DEFEAT</Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item sx={{ width: '100%', padding: 2, marginTop: -2 }}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            {team?.users?.map((user, i) => {
              return (
                <Grid item key={i}>
                  <Grid container justifyContent='start' alignItems='center'>
                    <Grid
                      item
                      onMouseEnter={() => setHovered(user?._id)}
                      onMouseLeave={() => setHovered(null)}
                      onClick={() => handleOpenProfile(user?._id)}
                      sx={{ marginLeft: -2 }}
                    >
                      <Avatar
                        size={115}
                        avatar={user?.new_avatar}
                        bgColor={hovered === user?._id ? card : cardDark}
                        sx={{
                          cursor: hovered === user?._id ? 'pointer' : 'default',
                          transition: transition,
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                      >
                        <Grid item>
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                            gap={{ xs: 1 }}
                          >
                            <Grid
                              item
                              onMouseEnter={() => setUsernameHovered(user?._id)}
                              onMouseLeave={() => setUsernameHovered(null)}
                              onClick={() => handleOpenProfile(user?._id)}
                            >
                              <Typography
                                sx={{
                                  ...styles.username,
                                  cursor:
                                    usernameHovered === user?._id
                                      ? 'pointer'
                                      : 'default',
                                  color:
                                    usernameHovered === user?._id
                                      ? user?.premium_info?.active
                                        ? yellow
                                        : doubtedBlue
                                      : user?.premium_info?.active
                                        ? 'rgb(255, 255, 193)'
                                        : text,
                                  textShadow: user?.premium_info?.active
                                    ? 'rgb(255 93 0) 0px 0px 10px'
                                    : null,
                                }}
                              >
                                {user?._id === store?.user?._id
                                  ? 'Me'
                                  : user?.username}
                              </Typography>
                            </Grid>

                            {token?.agreed_players?.includes(user?._id) && (
                              <Badge size='small' label='VOTED TO CANCEL'>
                                <AiOutlineStop
                                  style={{
                                    color: red,
                                    fontSize: badgeIconSizeSmall,
                                  }}
                                />
                              </Badge>
                            )}
                            {token?.put_up_info?.joining_user === user?._id && (
                              <Badge size='small' label='PUT UP'>
                                <GiTwoCoins
                                  style={{
                                    color: yellow,
                                    fontSize: badgeIconSizeSmall,
                                  }}
                                />
                              </Badge>
                            )}
                            {token?.put_up_info?.creating_user ===
                              user?._id && (
                              <Badge size='small' label='PUT UP'>
                                <GiTwoCoins
                                  style={{
                                    color: yellow,
                                    fontSize: badgeIconSizeSmall,
                                  }}
                                />
                              </Badge>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Typography
                            onClick={() => handleCopy(user)}
                            onMouseEnter={() =>
                              setgameAccountHovered(user?._id)
                            }
                            onMouseLeave={() => setgameAccountHovered(null)}
                            sx={{
                              ...styles.epic,
                              cursor:
                                gameAccountHovered === user?._id
                                  ? 'pointer'
                                  : 'default',
                              color:
                                gameAccountHovered === user?._id
                                  ? text
                                  : styles.epic,
                            }}
                          >
                            <span
                              style={{ fontWeight: 500, color: secondaryText }}
                            >
                              Playing as{' '}
                            </span>
                            {getConnectionName(user) ?? 'No Account Linked'}
                          </Typography>
                        </Grid>

                        {/* {token?.game === "clashroyale" &&
                          user?._id !== store?.user?._id && (
                            <Grid item sx={{ marginTop: 1 }}>
                              <SecondaryButton
                                label="Add friend link"
                                color={text}
                                onClick={() =>
                                  window.open(
                                    getClashLink(
                                      user?.connections?.clashroyale?.name
                                    ),
                                    "_blank"
                                  )
                                }
                                size="small"
                              />
                            </Grid>
                          )} */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchTeamItem;
