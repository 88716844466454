import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { BiEnvelopeOpen } from 'react-icons/bi';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineNotificationsNone } from 'react-icons/md';
import {
  clearNotifications,
  getNotifications,
  markAllRead,
} from '../../../api/notifications';
import useMiddleware from '../../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../../store/Store';
import { NOTIFICATIONS_LIMIT } from '../../../utils/limits';
import SpacingWrapper from '../../../utils/SpacingWrapper';
import {
  emptyIcon,
  normal,
  secondaryText,
  text,
} from '../../../utils/themeContstants';
import { useAuth } from '../../../utils/useAuth';
import usePagination from '../../../utils/usePagination';
import CustomIconButton from '../../custom/CustomIconButton';
import Empty from '../../custom/Empty';
import NotificationGlimmer from '../../glimmers/NotificationGlimmer';
import NotificationItem from '../../notifications/NotificationItem';

const MobileNotifications = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading] = useAuth();
  const [notisLoading, setNotisLoading] = useState(true);
  const [notis, setNotis] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);

  const handleGetNotis = () => {
    getNotifications(middleware, skip).then(res => {
      setNotisLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.notifications?.length >= NOTIFICATIONS_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setNotis(
          notis ? [...notis, ...res?.notifications] : res?.notifications
        );
      }
    });
  };

  const setNewNotis = newNotis => {
    setNotis([...newNotis]);
  };

  const handleClearNotis = () => {
    setClearLoading(true);
    clearNotifications(middleware).then(res => {
      setClearLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setNotis(null);
        updateStore({
          type: SET_SUCCESSES,
          payload: 'Cleared notifications!',
        });
      }
    });
  };

  const handleMarkNotis = () => {
    setMarkLoading(true);
    markAllRead(middleware).then(res => {
      setMarkLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setNotis(null);
        setNotisLoading(true);
      }
    });
  };

  usePagination(bottom, () => setSkip(notis?.length));

  useEffect(() => {
    if (notis == null && notisLoading) {
      handleGetNotis();
    }
  }, [notis, notisLoading]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetNotis();
    }
  }, [skip]);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
    },
    width: {
      width: '100%',
    },
    label: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 700,
    },
  };

  return loading ? null : (
    <>
      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <SpacingWrapper>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              gap={{ xs: 6 }}
              sx={{ paddingBottom: 8, maxWidth: 1200 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  {!notisLoading && notis?.length > 0 && (
                    <Grid item sx={{ paddingLeft: 1, width: '100%' }}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <Typography sx={styles.label}>MOST RECENT</Typography>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            justifyContent='start'
                            alignItems='center'
                            gap={{ xs: 1 }}
                          >
                            {!notis || notis?.length < 1 ? null : (
                              <CustomIconButton
                                icon={
                                  <FiTrash2
                                    style={{ color: text, fontSize: 20 }}
                                  />
                                }
                                label='Delete All'
                                loading={clearLoading}
                                onClick={handleClearNotis}
                              />
                            )}

                            {notis?.length < 1 ||
                            !notis ||
                            notis?.findIndex(notif => !notif?.read) ===
                              -1 ? null : (
                              <CustomIconButton
                                icon={
                                  <BiEnvelopeOpen
                                    style={{ color: text, fontSize: 20 }}
                                  />
                                }
                                label='Mark All as Read'
                                onClick={handleMarkNotis}
                                loading={markLoading}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {notisLoading && <NotificationGlimmer numItems={3} />}

                  {!notisLoading && (notis?.length < 1 || !notis) && (
                    <Empty
                      icon={<MdOutlineNotificationsNone style={emptyIcon} />}
                      title='No notifications'
                      label='Notifications will appear here.'
                    />
                  )}

                  {!notisLoading && notis && notis?.length > 0 && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                        gap={{ xs: 1 }}
                      >
                        {notis?.map((noti, i) => (
                          <NotificationItem
                            noti={noti}
                            key={i}
                            setNotifications={setNewNotis}
                            notifications={notis}
                            onClose={() => {}}
                          />
                        ))}
                        {hasMore && (
                          <div style={{ width: '100%' }} ref={bottom}>
                            <NotificationGlimmer numItems={1} />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default MobileNotifications;
