import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  doubtedBlue,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';

const ESLRules = ({ token }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const getMapCode = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case 'esl_ctf':
          return '0363-4024-8917';
        case 'esl_hp':
          return '6809-2248-1610';
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case 'esl_ctf':
          return '0363-4024-8917';
        case 'esl_hp':
          return '6809-2248-1610';
        default:
          return;
      }
    }
  };

  const getMapName = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case 'esl_ctf':
          return 'ESL Capture The Flag';
        case 'esl_hp':
          return 'ESL Hardpoint';
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case 'esl_ctf':
          return 'ESL Capture The Flag';
        case 'esl_hp':
          return 'ESL Hardpoint';
        default:
          return;
      }
    }
  };

  const getMapMode = () => {
    if (token?.game_match_type) {
      switch (token?.game_match_type) {
        case 'esl_ctf':
        case 'esl_hp':
          return 'ESL';
        default:
          return;
      }
    }

    if (token?.game_mode_options?.options?.length === 1) {
      switch (token?.game_mode_options?.options[0]) {
        case 'esl_ctf':
        case 'esl_hp':
          return 'ESL';
        default:
          return;
      }
    }
  };

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='start'
      justifyContent='center'
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : '100%' }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MAP</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <span style={{ color: doubtedBlue }}>{getMapCode()}</span>{' '}
              <span style={{ color: text, fontWeight: 500 }}>
                {getMapName()}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MODE</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>{getMapMode()}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>TEAMS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Host is <span style={{ fontWeight: 700 }}>Team 1</span>
              </li>
              <li>
                Non-Host is <span style={{ fontWeight: 700 }}>Team 2</span>
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>RULES</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              {getMapCode() === '0363-4024-8917' && (
                <>
                  <li>The team with the most flags after all rounds wins.</li>
                  <li>
                    If it's 1-1 after overtime, the team that capped the first
                    flag wins.
                  </li>
                  <li>
                    If it's 0-0 after overtime, the team with the better
                    kill-to-death ratio wins.
                  </li>
                </>
              )}
              {getMapCode() === '6809-2248-1610' && (
                <>
                  <li>First to 250 points wins.</li>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>ROUND ELIGIBILITY</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              {getMapCode() === '0363-4024-8917' && (
                <>
                  <li>
                    If damage is dealt and you leave, you forfeit the round.
                  </li>
                </>
              )}
              {getMapCode() === '6809-2248-1610' && (
                <>
                  <li>
                    If damage is dealt and you leave, you forfeit the entire
                    match.
                  </li>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ESLRules;
