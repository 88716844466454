import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMatch } from '../../api/matches';
import useMiddleware, { getAccessToken } from '../../api/useMiddleware';
import useMatch from '../../sockets/useMatch';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  isCreatingTeam,
  isInMatch,
  isJoiningTeam,
  isStaff,
} from './matchHelpers';

import { Grid, Typography, useMediaQuery } from '@mui/material';
import { AiOutlineFlag } from 'react-icons/ai';
import { BiGlobe } from 'react-icons/bi';
import { FiAward, FiChevronRight, FiLock, FiShare } from 'react-icons/fi';
import { HiDesktopComputer } from 'react-icons/hi';
import {
  IoGameControllerOutline,
  IoPodiumOutline,
  IoTimerOutline,
} from 'react-icons/io5';
import { RiMap2Fill, RiSwordLine, RiTeamLine } from 'react-icons/ri';
import { CDN_URL } from '../../utils/constants';
import { AccountTypeEnum, MatchStateEnum } from '../../utils/enums';
import {
  getChessTime,
  getDateFromId,
  getFee,
  getGameMapType,
  getGameName,
  getLootType,
  getMatchTitle,
  getNotiTimestamp,
  getPlatformString,
  getRegionString,
  getRegionStringFromArray,
  getTeamSizeString,
  hexToRgbA,
  numFormatter,
  shouldShowBestOf,
  shouldShowFirstTo,
} from '../../utils/helpers';
import SpacingWrapper from '../../utils/SpacingWrapper';
import {
  background,
  backgroundOpacity,
  cardDark,
  detailIcon,
  doubtedBlue,
  doubtedOrange,
  linearGradientToTop,
  normal,
  offWhite,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import ACCoin from '../custom/ACCoin';
import CoinBadge from '../custom/CoinBadge';
import CustomIconButton from '../custom/CustomIconButton';
import DetailContainer from '../custom/DetailContainer';
import LoadingScreen from '../custom/LoadingScreen';
import ScrollableContainer from '../custom/ScrollableContainer';
import SecondaryButton from '../custom/SecondaryButton';
import MatchChat from './MatchChat';
import MatchPageState from './MatchPageState';
import MatchRules from './MatchRules';
import MatchStaffButtons from './MatchStaffButtons';
import MatchStateButtons from './MatchStateButtons';
import MatchTeams from './MatchTeams';
import MatchTimer from './MatchTimer';
import ReadyUpModal from './ReadyUpModal';
import RecreateMatchModal from './RecreateMatchModal';
import RequestRematchModal from './RequestRematchModal';
import SubmitNoticeModal from './SubmitNoticeModal';
const MatchPage = () => {
  const NewMatchSound = `${CDN_URL}new-match-sound.mp3`;
  const NewSubmitSound = `${CDN_URL}match-submit-sound.mp3`;

  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const params = useParams();
  const { matchData } = useMatch(params?.id, getAccessToken());
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(max-width: 768px)');

  const [loading, setLoading] = useState(true);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [match, setMatch] = useState(null);
  const [chatOpen, setChatOpen] = useState(true);
  const [rematchOpen, setRematchOpen] = useState(false);
  const [readyOpen, setReadyOpen] = useState(false);
  const [hasSeenReady, setHasSeenReady] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [hasSeenSubmit, setHasSeenSubmit] = useState(false);
  const [recreateOpen, setRecreateOpen] = useState(false);

  const toggleChat = () => {
    setChatOpen(chatOpen ? false : true);
  };

  const getShareLink = () => {
    const base = 'https://rivox.gg';
    if (match?.state === MatchStateEnum.WAITING) {
      if (!match?.public) {
        return `${base}?join=${match?._id}&private=true`;
      }

      return `${base}?join=${match?._id}`;
    }

    return `${base}/token/${match?._id}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getShareLink());
    updateStore({
      type: SET_SUCCESSES,
      payload: 'Match Link Copied',
    });
  };

  const shouldShowStaffButtons = () => {
    if (store?.user?.account_type >= AccountTypeEnum.GOD) return true;

    if (
      !isInMatch(match, store?.user?._id) &&
      store?.user?.account_type >= AccountTypeEnum.MODERATOR
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (params?.id === match?._id) {
      setLoading(false);
      return;
    }

    if (!loading && params?.id === match?._id) {
      return;
    }

    if (!fetchLoading) {
      setLoading(true);
      setFetchLoading(true);
      getMatch(middleware, params?.id).then(res => {
        setFetchLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
          navigate('/');
        } else {
          setMatch(res?.match);
        }
      });
    }
  }, [params, match]);

  useEffect(() => {
    if (matchData) {
      setMatch(matchData);
    }
  }, [matchData]);

  useEffect(() => {
    if (match) {
      const inMatch = isInMatch(match, store?.user?._id);
      const isStaffMember = isStaff(store?.user?.account_type);

      if (!match?.public) {
        if (!store?.user) {
          updateStore({
            type: SET_ERRORS,
            payload:
              'This is a private match and you are not a participant in the match.',
          });
          navigate('/');
          return;
        } else {
          if (!match?.public && !inMatch && !isStaffMember) {
            updateStore({
              type: SET_ERRORS,
              payload:
                'This is a private match and you are not a participant in the match.',
            });
            navigate('/');
            return;
          }
          setLoading(false);
        }
      } else {
        if (
          match?.state === MatchStateEnum.WAITING ||
          match?.state === MatchStateEnum.READY
        ) {
          if (!inMatch && !isStaffMember) {
            updateStore({
              type: SET_ERRORS,
              payload: 'You cannot view this match during this state.',
            });
            navigate('/');
            return;
          }
        }
        setLoading(false);
      }
    }
  }, [store?.user, match]);

  useEffect(() => {
    const inMatch = isInMatch(match, store?.user?._id);

    if (
      match?.state === MatchStateEnum.READY &&
      !match?.readied_users?.readied[store?.user?._id] &&
      !hasSeenReady &&
      inMatch
    ) {
      setReadyOpen(true);
    }
  }, [match, hasSeenReady]);

  useEffect(() => {
    if (isDesktop) {
      if (match) {
        if (match?.state === MatchStateEnum.READY) {
          if (!Object.values(match?.readied_users?.readied)?.includes(true)) {
            (async () => {
              const newMatchSoundURL = `${CDN_URL}new-match-sound.mp3`;
              const newMatchSound = new Audio(newMatchSoundURL);
              newMatchSound.volume = 0.5;
              await newMatchSound.play();
            })();
          }
        }

        if (match?.state === MatchStateEnum.SUBMIT) {
          if (isDesktop) {
            if (isInMatch(match, store?.user?._id)) {
              // if your team has not submitted and the other team submitted win
              if (
                isCreatingTeam(match, store?.user?._id) &&
                match?.submits?.creating_submit == null &&
                match?.submits?.joining_submit === 'win'
              ) {
                // load sound and play
                (async () => {
                  const newSubmitSoundURL = `${CDN_URL}match-submit-sound.mp3`;
                  const newSubmitSound = new Audio(newSubmitSoundURL);
                  newSubmitSound.volume = 0.25;
                  await newSubmitSound.play();
                })();

                // open enemy team has submitted win modal
                if (!hasSeenSubmit) {
                  setSubmitOpen(true);
                }
              }

              if (
                isJoiningTeam(match, store?.user?._id) &&
                match?.submits?.joining_submit == null &&
                match?.submits?.creating_submit === 'win'
              ) {
                // load sound and play
                (async () => {
                  const newSubmitSoundURL = `${CDN_URL}match-submit-sound.mp3`;
                  const newSubmitSound = new Audio(newSubmitSoundURL);
                  newSubmitSound.volume = 0.25;
                  await newSubmitSound.play();
                })();

                // open enemy team has submitted win modal
                if (!hasSeenSubmit) {
                  setSubmitOpen(true);
                }
              }
            }
          }
        }
      }
    }
  }, [match]);

  useEffect(() => {
    if (!isDesktop) {
      setChatOpen(false);
    }
  }, [isDesktop]);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
      // backgroundImage: `url(${background})`,
      // backgroundAttachment: "fixed",
      // backgroundSize: "cover",
      overFlowY: isTablet && chatOpen ? 'hidden' : 'auto',
    },
    bottom: {
      width: '100%',
      paddingBottom: 8,
      marginTop: isTablet ? -3 : null,
    },
    width: {
      width: '100%',
    },
    title: {
      fontSize: isTablet ? 32 : 48,
      color: text,
      fontWeight: 800,
      textAlign: 'center',
    },
    subTitle: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    label: {
      fontSize: 22,
      color: text,
      fontWeight: 700,
    },
  };

  return loading ? (
    <LoadingScreen size={150} />
  ) : (
    <>
      <MatchChat open={chatOpen} token={match} toggleChat={toggleChat} />
      <RequestRematchModal
        open={rematchOpen}
        onClose={() => setRematchOpen(false)}
        token={match}
      />
      <ReadyUpModal
        open={readyOpen}
        onClose={() => setReadyOpen(false)}
        setHasSeenReady={setHasSeenReady}
        token={match}
      />
      <SubmitNoticeModal
        open={submitOpen}
        onClose={() => setSubmitOpen(false)}
        match={match}
        setHasSeenSubmit={setHasSeenSubmit}
        hasSeenSubmit={hasSeenSubmit}
      />
      <RecreateMatchModal
        currentMatch={match}
        open={recreateOpen}
        onClose={() => setRecreateOpen(false)}
      />

      <Grid item sx={styles.container}>
        <Grid
          container
          justifyContent='start'
          alignItems='start'
          sx={{ position: 'relative' }}
          wrap='nowrap'
          id='main'
        >
          <Grid item sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <>
              <Grid item sx={{ position: 'relative' }}>
                <div
                  style={{
                    // backgroundImage: `url(${getGameHeader(match?.game)})`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                />

                <div
                  style={{
                    backgroundColor: backgroundOpacity(0.6),
                    zIndex: -1,
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 2,
                  }}
                />

                <div
                  style={{
                    background: linearGradientToTop(
                      hexToRgbA(background, 1),
                      hexToRgbA(background, 0)
                    ),
                    zIndex: -1,
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    bottom: -1,
                    right: 0,
                    left: 0,
                    zIndex: 2,
                  }}
                />

                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='start'
                  sx={{ height: '100%' }}
                >
                  <SpacingWrapper>
                    <Grid
                      container
                      direction='column'
                      alignItems={isDesktop ? 'start' : 'center'}
                      justifyContent='center'
                      gap={{ xs: 2 }}
                      sx={{ paddingBottom: 4 }}
                    >
                      <Grid
                        item
                        sx={{
                          width: '100%',
                          zIndex: 3,
                          height: '100%',
                        }}
                      >
                        <Grid
                          container
                          direction='column'
                          alignItems='center'
                          justifyContent='center'
                          gap={{ xs: 1, md: 2 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction='column'
                              alignItems='center'
                              justifyContent='center'
                              gap={{ xs: 0, md: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.subTitle}>
                                  Match created{' '}
                                  {getNotiTimestamp(getDateFromId(match?._id))}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Grid
                                  container
                                  direction={isDesktop ? 'row' : 'column'}
                                  justifyContent='start'
                                  alignItems='center'
                                  gap={{ xs: isDesktop ? 2 : 0 }}
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent='start'
                                      alignItems='center'
                                      gap={{ xs: 1 }}
                                    >
                                      <Grid item>
                                        <Typography sx={styles.title}>
                                          {getMatchTitle(
                                            match?.game_match_type,
                                            match?.team_size,
                                            match?.game_map_type,
                                            match?.extra_options,
                                            match?.game_mode_options
                                          )}
                                        </Typography>
                                      </Grid>

                                      {isTablet && (
                                        <CustomIconButton
                                          icon={
                                            <FiShare
                                              style={{
                                                color: text,
                                                fontSize: 20,
                                              }}
                                            />
                                          }
                                          onClick={handleCopy}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>

                                  {!isTablet && (
                                    <Grid
                                      item
                                      sx={{ marginBottom: isDesktop ? 0 : 2 }}
                                    >
                                      <SecondaryButton
                                        label='Share Match'
                                        bg={cardDark}
                                        icon={
                                          <FiShare
                                            style={{
                                              fontSize: 18,
                                              color: text,
                                            }}
                                          />
                                        }
                                        onClick={handleCopy}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid item>
                                <Grid
                                  container
                                  justifyContent='start'
                                  alignItems='center'
                                  gap={{ xs: 1 }}
                                >
                                  {match?.match_type.type === 'tournament' ? (
                                    <>
                                      {match?.next_match_id ? (
                                        <Grid container alignItems='center'>
                                          {match?.prize_info?.loser_prize >=
                                            0 && (
                                            <>
                                              <Grid
                                                item
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                }}
                                              ></Grid>

                                              <CoinBadge
                                                amount={numFormatter().format(
                                                  match?.prize_info?.loser_prize
                                                )}
                                                label='Prize for losing this match'
                                                placement='bottom'
                                                insideLabel='Loser'
                                                size={isTablet ? 'small' : null}
                                              />
                                            </>
                                          )}
                                          <FiChevronRight
                                            style={{
                                              fontSize: 25,
                                              color: secondaryText,
                                            }}
                                          />
                                          <Grid item>
                                            <Grid
                                              container
                                              alignItems='center'
                                              gap={1}
                                            >
                                              <FiAward style={detailIcon} />
                                              <Typography
                                                sx={{
                                                  color: text,
                                                  fontSize: 14,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Winner advances to next round
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <>
                                          <CoinBadge
                                            amount={numFormatter().format(
                                              match?.prize_info?.loser_prize
                                            )}
                                            label='Prize for losing this match'
                                            placement='bottom'
                                            insideLabel='Loser'
                                            size={isTablet ? 'small' : null}
                                          />
                                          <FiChevronRight
                                            style={{
                                              fontSize: 25,
                                              color: secondaryText,
                                            }}
                                          />
                                          <CoinBadge
                                            amount={numFormatter().format(
                                              match?.prize_info?.winner_prize
                                            )}
                                            label='Prize for winning this match'
                                            placement='bottom'
                                            insideLabel='Winner'
                                            type='prize'
                                            size={isTablet ? 'small' : null}
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <CoinBadge
                                        amount={numFormatter().format(
                                          match?.entry_fee
                                        )}
                                        label='Fee to compete in this match'
                                        placement='bottom'
                                        insideLabel='Entry'
                                        size={isTablet ? 'small' : null}
                                      />

                                      <Grid
                                        item
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <FiChevronRight
                                          style={{
                                            fontSize: 25,
                                            color: secondaryText,
                                          }}
                                        />
                                      </Grid>

                                      <CoinBadge
                                        amount={numFormatter().format(
                                          parseFloat(
                                            match?.entry_fee *
                                              getFee(match?.game) +
                                              match?.entry_fee
                                          )
                                        )}
                                        label='The total amount you will receive if you win this match'
                                        type='prize'
                                        placement='bottom'
                                        insideLabel='Prize'
                                        size={isTablet ? 'small' : null}
                                      />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item sx={styles.width}>
                            {!isTablet && (
                              <Grid
                                container
                                justifyContent='center'
                                alignItems='stretch'
                                gap={{ xs: 1 }}
                              >
                                <DetailContainer
                                  label='Game'
                                  value={getGameName(match?.game)}
                                  // icon={<GameBadge game={match?.game} />}
                                  bg={'rgba(26, 29, 41, 0.5)'}
                                />

                                {(match?.game === 'fortnite' ||
                                  match?.game === 'fifa') && (
                                  <DetailContainer
                                    label='Platform'
                                    value={getPlatformString(match?.platform)}
                                    icon={
                                      <IoGameControllerOutline
                                        style={detailIcon}
                                      />
                                    }
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {match?.region && (
                                  <>
                                    {match?.region?.length > 0 ? (
                                      <>
                                        <DetailContainer
                                          label='Region'
                                          value={getRegionStringFromArray(
                                            match?.region,
                                            match?.selected_region
                                          )}
                                          icon={<BiGlobe style={detailIcon} />}
                                          bg={'rgba(26, 29, 41, 0.5)'}
                                        />
                                      </>
                                    ) : (
                                      <DetailContainer
                                        label='Region'
                                        value={getRegionString(match?.region)}
                                        icon={<BiGlobe style={detailIcon} />}
                                        bg={'rgba(26, 29, 41, 0.5)'}
                                      />
                                    )}
                                  </>
                                )}

                                <DetailContainer
                                  label='Team Size'
                                  value={getTeamSizeString(match?.team_size)}
                                  icon={<RiTeamLine style={detailIcon} />}
                                  bg={'rgba(26, 29, 41, 0.5)'}
                                />

                                {match?.game === 'chess' && (
                                  <DetailContainer
                                    label='Time limit'
                                    value={getChessTime(match?.game_match_type)}
                                    icon={<IoTimerOutline style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {shouldShowFirstTo(
                                  match?.game_mode_options?.selected_option,
                                  match?.game_mode_options?.options,
                                  match?.game
                                ) && (
                                  <DetailContainer
                                    label='First To'
                                    value={match?.first_to}
                                    firstTo={true}
                                    icon={<AiOutlineFlag style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                    killLead={!!match?.extra_options?.kill_lead}
                                    killLeadLabel={`${match?.extra_options?.kill_lead} round lead`}
                                  />
                                )}

                                {shouldShowBestOf(
                                  match?.game,
                                  match?.game_mode_options?.selected_option,
                                  match?.game_mode_options?.options
                                ) && (
                                  <DetailContainer
                                    label='Best Of'
                                    value={match?.best_of}
                                    icon={
                                      <IoPodiumOutline style={detailIcon} />
                                    }
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {match?.extra_options?.loot_type && (
                                  <DetailContainer
                                    label='Loot Type'
                                    value={getLootType(
                                      match?.extra_options?.loot_type
                                    )}
                                    icon={<RiSwordLine style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}
                                {match?.game_map_type && (
                                  <DetailContainer
                                    label='Map'
                                    value={getGameMapType(match?.game_map_type)}
                                    icon={<RiMap2Fill style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {match?.extra_options?.must_stream &&
                                  match?.platform == null && (
                                    <DetailContainer
                                      label='PC Settings'
                                      value={'Must Stream'}
                                      icon={
                                        <HiDesktopComputer style={detailIcon} />
                                      }
                                      bg={'rgba(26, 29, 41, 0.5)'}
                                    />
                                  )}

                                {match?.ac_verified && (
                                  <DetailContainer
                                    label='Anti-Cheat'
                                    value='Verified'
                                    icon={<ACCoin size={32} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}
                                {!match?.public &&
                                  match?.match_type.type !== 'tournament' && (
                                    <DetailContainer
                                      label='Private'
                                      value='Private Match'
                                      icon={
                                        <FiLock size={32} style={detailIcon} />
                                      }
                                      bg={'rgba(26, 29, 41, 0.5)'}
                                    />
                                  )}
                                {match?.match_type.type === 'tournament' && (
                                  <DetailContainer
                                    label='Tournament'
                                    value='Tournament Match'
                                    icon={<FiAward style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}
                              </Grid>
                            )}

                            {isTablet && (
                              <ScrollableContainer gap={1} stretch>
                                <DetailContainer
                                  label='Game'
                                  value={getGameName(match?.game)}
                                  // icon={<GameBadge game={match?.game} />}
                                  bg={'rgba(26, 29, 41, 0.5)'}
                                />

                                {(match?.game === 'fortnite' ||
                                  match?.game === 'fifa') && (
                                  <DetailContainer
                                    label='Platform'
                                    value={getPlatformString(match?.platform)}
                                    icon={
                                      <IoGameControllerOutline
                                        style={detailIcon}
                                      />
                                    }
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {match?.region && (
                                  <>
                                    {match?.region?.length > 0 ? (
                                      <>
                                        <DetailContainer
                                          label='Region'
                                          value={getRegionStringFromArray(
                                            match?.region,
                                            match?.selected_region
                                          )}
                                          icon={<BiGlobe style={detailIcon} />}
                                          bg={'rgba(26, 29, 41, 0.5)'}
                                        />
                                      </>
                                    ) : (
                                      <DetailContainer
                                        label='Region'
                                        value={getRegionString(match?.region)}
                                        icon={<BiGlobe style={detailIcon} />}
                                        bg={'rgba(26, 29, 41, 0.5)'}
                                      />
                                    )}
                                  </>
                                )}

                                <DetailContainer
                                  label='Team Size'
                                  value={getTeamSizeString(match?.team_size)}
                                  icon={<RiTeamLine style={detailIcon} />}
                                  bg={'rgba(26, 29, 41, 0.5)'}
                                />

                                {match?.game === 'chess' && (
                                  <DetailContainer
                                    label='Time limit'
                                    value={getChessTime(match?.game_match_type)}
                                    icon={<IoTimerOutline style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {!match?.best_of &&
                                  match.game_match_type !== 'esl_ctf' &&
                                  match.game_match_type !== 'esl_hp' && (
                                    <DetailContainer
                                      label='First To'
                                      value={match?.first_to}
                                      firstTo={true}
                                      icon={
                                        <AiOutlineFlag style={detailIcon} />
                                      }
                                      bg={'rgba(26, 29, 41, 0.5)'}
                                    />
                                  )}

                                {match?.best_of &&
                                  match.game_match_type !== 'esl_ctf' &&
                                  match.game_match_type !== 'esl_hp' && (
                                    <DetailContainer
                                      label='Best Of'
                                      value={match?.best_of}
                                      icon={
                                        <IoPodiumOutline style={detailIcon} />
                                      }
                                      bg={'rgba(26, 29, 41, 0.5)'}
                                    />
                                  )}

                                {match?.extra_options?.loot_type && (
                                  <DetailContainer
                                    label='Loot Type'
                                    value={getLootType(
                                      match?.extra_options?.loot_type
                                    )}
                                    icon={<RiSwordLine style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}
                                {match?.game_map_type && (
                                  <DetailContainer
                                    label='Map'
                                    value={getGameMapType(match?.game_map_type)}
                                    icon={<RiMap2Fill style={detailIcon} />}
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}

                                {match?.extra_options?.must_stream &&
                                  match?.platform == null && (
                                    <DetailContainer
                                      label='PC Settings'
                                      value={'Must Stream'}
                                      icon={
                                        <HiDesktopComputer style={detailIcon} />
                                      }
                                      bg={'rgba(26, 29, 41, 0.5)'}
                                    />
                                  )}

                                {!match?.public && (
                                  <DetailContainer
                                    label='Private'
                                    value='Private Match'
                                    icon={
                                      <FiLock size={32} style={detailIcon} />
                                    }
                                    bg={'rgba(26, 29, 41, 0.5)'}
                                  />
                                )}
                              </ScrollableContainer>
                            )}
                          </Grid>

                          <MatchPageState match={match} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </SpacingWrapper>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent='center'
                alignItems='center'
                sx={styles.bottom}
              >
                <SpacingWrapper>
                  <Grid
                    container
                    alignItems='start'
                    justifyContent='start'
                    gap={{ xs: 2 }}
                    sx={{ maxWidth: 1200 }}
                  >
                    {isDesktop && (
                      <>
                        <Grid item sx={{ flex: '3 1 auto' }}>
                          <MatchTeams token={match} />
                        </Grid>

                        <Grid
                          item
                          sx={{
                            minWidth: isDesktop ? 0 : '100%',
                            flex: '1 1 auto',
                          }}
                        >
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='start'
                            gap={{ xs: 2 }}
                          >
                            {((match?.state !== MatchStateEnum.PLAYING &&
                              match?.state !== MatchStateEnum.DISPUTE &&
                              match?.state !== MatchStateEnum.COMPLETED &&
                              match?.state !== MatchStateEnum.CANCEL &&
                              match?.match_type.type !== 'tournament') ||
                              match?.state === MatchStateEnum.SUBMIT) && (
                              <MatchTimer match={match} />
                            )}

                            {isInMatch(match, store?.user?._id) && (
                              <>
                                {match?.state !== MatchStateEnum.CANCEL &&
                                  match?.state !== MatchStateEnum.COMPLETED && (
                                    <MatchStateButtons token={match} />
                                  )}
                              </>
                            )}

                            {match?.state === MatchStateEnum.COMPLETED &&
                              match?.winners?.includes(store?.user?._id) && (
                                <Grid item sx={styles.width}>
                                  <SecondaryButton
                                    bg={doubtedBlue}
                                    label='Share Results'
                                    fullWidth
                                    onClick={() =>
                                      window.open(
                                        `https://twitter.com/intent/tweet?text=Just%20won%20${numFormatter().format(
                                          match?.entry_fee * 0.8
                                        )}%20on%20https://rivox.gg!`,
                                        '_blank'
                                      )
                                    }
                                  />
                                </Grid>
                              )}

                            {isInMatch(match, store?.user?._id) &&
                              match?.state === MatchStateEnum.COMPLETED &&
                              !match?.winners?.includes(store?.user?._id) &&
                              match?.match_type.type !== 'tournament' && (
                                <Grid item sx={styles.width}>
                                  <SecondaryButton
                                    label={
                                      match?.rematch_sent
                                        ? 'Rematch Sent'
                                        : 'Request Rematch'
                                    }
                                    fullWidth
                                    onClick={() => setRematchOpen(true)}
                                    disabled={match?.rematch_sent}
                                    bg={doubtedBlue}
                                  />
                                </Grid>
                              )}

                            {isInMatch(match, store?.user?._id) &&
                              (match?.state === MatchStateEnum.COMPLETED ||
                                match?.state === MatchStateEnum.CANCEL) &&
                              match?.match_type.type !== 'tournament' && (
                                <Grid item sx={styles.width}>
                                  <SecondaryButton
                                    label='Recreate Match'
                                    fullWidth
                                    bg={doubtedOrange}
                                    onClick={() => setRecreateOpen(true)}
                                  />
                                </Grid>
                              )}

                            {match?.match_type.type === 'tournament' && (
                              <Grid item sx={styles.width}>
                                <SecondaryButton
                                  label='Back To Tournament'
                                  fullWidth
                                  bg={doubtedBlue}
                                  onClick={() =>
                                    navigate(
                                      `/tournaments/${match?.tournament_id}`
                                    )
                                  }
                                />
                              </Grid>
                            )}

                            {match?.state === MatchStateEnum.COMPLETED &&
                              match?.match_type.type === 'tournament' &&
                              match?.next_match_id &&
                              match?.winners?.includes(store?.user?._id) &&
                              isInMatch(match, store?.user?._id) && (
                                <Grid item sx={styles.width}>
                                  <SecondaryButton
                                    label='Go to Next Match'
                                    fullWidth
                                    bg={doubtedOrange}
                                    onClick={() =>
                                      navigate(`/token/${match?.next_match_id}`)
                                    }
                                  />
                                </Grid>
                              )}

                            {match?.state !== MatchStateEnum.CANCEL && (
                              <>
                                {shouldShowStaffButtons() && (
                                  <Grid item sx={styles.width}>
                                    <MatchStaffButtons
                                      token={match}
                                      user={store?.user}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}

                            <MatchRules token={match} />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {!isDesktop && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction='column'
                          alignItems='start'
                          justifyContent='center'
                          gap={{ xs: 2 }}
                        >
                          {((match?.state !== MatchStateEnum.PLAYING &&
                            match?.state !== MatchStateEnum.DISPUTE &&
                            match?.state !== MatchStateEnum.COMPLETED &&
                            match?.state !== MatchStateEnum.CANCEL &&
                            match?.match_type.type !== 'tournament') ||
                            match?.state === MatchStateEnum.SUBMIT) && (
                            <MatchTimer match={match} />
                          )}

                          {isInMatch(match, store?.user?._id) && (
                            <>
                              {match?.state !== MatchStateEnum.CANCEL && (
                                <MatchStateButtons token={match} />
                              )}
                            </>
                          )}

                          {match?.state === MatchStateEnum.COMPLETED &&
                            match?.winners?.includes(store?.user?._id) && (
                              <Grid item sx={styles.width}>
                                <SecondaryButton
                                  bg={doubtedBlue}
                                  label='Share Results'
                                  fullWidth
                                  onClick={() =>
                                    window.open(
                                      `https://twitter.com/intent/tweet?text=Just%20won%20${numFormatter().format(
                                        match?.entry_fee * 0.8
                                      )}%20on%20https://rivox.gg!`,
                                      '_blank'
                                    )
                                  }
                                />
                              </Grid>
                            )}

                          {isInMatch(match, store?.user?._id) &&
                            match?.state === MatchStateEnum.COMPLETED &&
                            !match?.winners?.includes(store?.user?._id) &&
                            match?.match_type.type !== 'tournament' && (
                              <Grid item sx={styles.width}>
                                <SecondaryButton
                                  label={
                                    match?.rematch_sent
                                      ? 'Rematch Sent'
                                      : 'Request Rematch'
                                  }
                                  fullWidth
                                  onClick={() => setRematchOpen(true)}
                                  disabled={match?.rematch_sent}
                                  bg={doubtedBlue}
                                />
                              </Grid>
                            )}

                          <MatchTeams token={match} />

                          {match?.state !== MatchStateEnum.CANCEL && (
                            <>
                              {store?.user?.account_type >=
                                AccountTypeEnum.MODERATOR && (
                                <Grid item sx={styles.width}>
                                  <MatchStaffButtons
                                    token={match}
                                    user={store?.user}
                                  />
                                </Grid>
                              )}
                            </>
                          )}

                          <MatchRules token={match} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </SpacingWrapper>
              </Grid>

              {/* <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SpacingWrapper>
                    <Grid
                      container
                      direction="column"
                      alignItems={isDesktop ? "start" : "center"}
                      justifyContent="center"
                      gap={{ xs: 2 }}
                      sx={{ paddingBottom: 4 }}
                    >
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="start"
                          gap={{ xs: 2 }}
                        >
                          <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                              gap={{ xs: 2 }}
                            >
                              <MatchDetails token={match} />

                              <Grid item sx={styles.width}>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  gap={{ xs: 1 }}
                                >
                                  <Grid item sx={styles.width}>
                                    <PrimaryButton
                                      type="purple"
                                      label="Share Token"
                                      fullWidth
                                      onClick={handleCopy}
                                    />
                                  </Grid>

                                  {match?.state === MatchStateEnum.COMPLETED &&
                                    match?.winners?.includes(
                                      store?.user?._id
                                    ) && (
                                      <Grid item sx={styles.width}>
                                        <PrimaryButton
                                          type="green"
                                          label="Share Results"
                                          fullWidth
                                          onClick={() =>
                                            window.open(
                                              `https://twitter.com/intent/tweet?text=Just%20won%20${numFormatter().format(
                                                match?.entry_fee * 0.80
                                              )}%20on%20https://rivox.gg!`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      </Grid>
                                    )}

                                  {isInMatch(match, store?.user?._id) &&
                                    match?.state === MatchStateEnum.COMPLETED &&
                                    !match?.winners?.includes(
                                      store?.user?._id
                                    ) && (
                                      <Grid item sx={styles.width}>
                                        <PrimaryButton
                                          label={
                                            match?.rematch_sent
                                              ? "Rematch Sent"
                                              : "Request Rematch"
                                          }
                                          fullWidth
                                          onClick={() => setRematchOpen(true)}
                                          disabled={match?.rematch_sent}
                                        />
                                      </Grid>
                                    )}

                                  {isInMatch(match, store?.user?._id) && (
                                    <>
                                      {match?.state !== MatchStateEnum.CANCEL &&
                                        match?.state !==
                                          MatchStateEnum.COMPLETED && (
                                          <MatchStateButtons token={match} />
                                        )}
                                    </>
                                  )}
                                </Grid>
                              </Grid>

                              {match?.state !== MatchStateEnum.CANCEL && (
                                <>
                                  {store?.user?.account_type >=
                                    AccountTypeEnum.SENIOR_MODERATOR && (
                                    <MatchStaffButtons token={match} user={store?.user} />
                                  )}
                                </>
                              )}

                              <MatchRules token={match} />
                            </Grid>
                          </Grid>

                          <Grid item sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              direction="column"
                              alignItems="start"
                              justifyContent="center"
                              gap={{ xs: 2 }}
                            >
                              <MatchState token={match} />

                              <MatchTeams token={match} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </SpacingWrapper>
                </Grid>
              </Grid> */}
            </>
          </Grid>

          {isDesktop && (
            <Grid
              item
              sx={{
                minWidth: chatOpen ? 340 : 0,
                maxWidth: chatOpen ? 340 : 0,
                minHeight: '100vh',
                width: '100%',
              }}
              id='match-chat-placeholder'
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MatchPage;
