import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { SERVER_URL } from '../../utils/constants';
import { secondaryText, small } from '../../utils/themeContstants';
import ConnectionButton from '../connections/ConnectionButton';

const AuthButton = ({ type }) => {
  const [referralCode, setReferralCode] = useState(null);
  const getQueryParams = search => {
    return search
      .substring(1)
      .split('&')
      .reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value) : '';
        return params;
      }, {});
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);
    setReferralCode(queryParams.r || null);
  }, []);

  const handleClick = () => {
    return window.open(
      `${SERVER_URL}/api/user/${type}-signup?r=${referralCode}`,
      '_self'
    );
  };

  const styles = {
    container: {
      borderRadius: 1,
      padding: 1,
      width: '100%',
      backgroundColor: '#272B38',
      boxShadow:
        'rgb(29 32 42 / 40%) 0px 0px 10px, rgb(48 52 68) 0px 1px 0px inset, rgb(0 0 0 / 15%) 0px -3px 0px inset, rgb(29 32 42) 0px 0px 12px inset',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#272B38',
        filter: 'brightness(125%)',
      },
    },
    font: {
      fontSize: small,
      fontWeight: 800,
      color: secondaryText,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.width}>
      <ConnectionButton
        type={type}
        fullWidth
        login={true}
        onClick={handleClick}
        auth={true}
      />
    </Grid>
  );
};

export default AuthButton;
