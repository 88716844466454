import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { usePopper } from 'react-popper';
import { StoreContext } from '../../store/Store';
import { numFormatter } from '../../utils/helpers';
import { menuShadow } from '../../utils/themeContstants';
import CustomPortal from './CustomPortal';
import RivoxCoin from './RivoxCoin';

const Hover = ({ label, placement = 'top', refEl, wallet = false }) => {
  const store = useContext(StoreContext);
  const [popperEl, setPopperEl] = useState(null);

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const localStyles = {
    container: {
      backgroundColor: 'rgba(243, 246, 250, .8)',
      borderRadius: 2,
      padding: 1,
      boxShadow: menuShadow,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <CustomPortal>
      <div
        ref={setPopperEl}
        style={{ ...styles.popper, position: 'absolute', zIndex: 999999 }}
        {...attributes.popper}
      >
        <Grid item sx={localStyles.container}>
          {!wallet && label && (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: '#000',
                maxWidth: 200,
              }}
            >
              {label}
            </Typography>
          )}

          {wallet && store?.user && (
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 0.5 }}
            >
              <RivoxCoin size={16} />

              <Grid item>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, color: '#000' }}
                >
                  {numFormatter(store?.user?.funds?.balance < 1 ? 4 : 2).format(
                    store?.user?.funds?.balance
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </CustomPortal>
  );
};

export default Hover;
