import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import {
  offWhite,
  secondaryButton,
  secondaryText,
  transition,
} from '../../utils/themeContstants';

const TextButton = ({
  label,
  onClick = () => {},
  color,
  disabled = false,
  icon = null,
  size,
}) => {
  const [hovered, setHovered] = useState(false);

  const styles = {
    container: {
      transition: transition,
      cursor: disabled ? 'not-allowed' : hovered ? 'pointer' : 'default',
      backgroundColor: disabled
        ? 'transparent'
        : hovered
          ? secondaryButton
          : 'transparent',
      padding: 1,
      borderRadius: 2,
      width: '100%',
    },
    label: {
      fontSize: size === 'small' ? 11 : 14,
      fontWeight: 700,
      color: disabled ? secondaryText : (color ?? offWhite),
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {icon && (
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>{label}</Typography>
          </Grid>
        </Grid>
      )}

      {!icon && <Typography sx={styles.label}>{label}</Typography>}
    </Grid>
  );
};

export default TextButton;
