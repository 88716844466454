import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { banUser } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { banLengthOptions } from '../../utils/options';
import {
  normal,
  offWhite,
  purple,
  secondaryText,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const BanUserModal = ({ open, onClose, user, reason, setBanned }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [banLoading, setBanLoading] = useState(false);
  const [reasoning, setReasoning] = useState('');
  const [banEvidence, setEvidence] = useState('');
  const [loading, setLoading] = useState(false);
  const [banLength, setBanLength] = useState(null);
  const [banProof, setBanProof] = useState(null);

  useEffect(() => {
    if (open) {
      if (user) {
        setBanned(user?.behavior_info?.banned);
      }
    }
  }, [user, open]);

  useEffect(() => {
    if (open) {
      if (!banProof) {
        setEvidence(null);
      }
    }
  }, [banProof, open]);

  const handleClose = () => {
    setReasoning('');
    setLoading(false);
    setBanLength(null);
    setBanProof(null);
    setEvidence(null);
    onClose();
  };

  const handleBanUser = () => {
    setBanLoading(true);
    banUser(middleware, user?._id, reasoning, banLength, banEvidence).then(
      res => {
        setBanLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          handleClose();
          setBanned(true);
        }
      }
    );
  };

  const styles = {
    width: {
      width: '100%',
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: offWhite,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Ban ${user?.username}`}
      primaryButton={
        <PrimaryButton
          label='Ban User'
          disabled={
            reasoning === '' ||
            reasoning == null ||
            banLength == null ||
            !banLength
          }
          loading={banLoading}
          onClick={handleBanUser}
        />
      }
      secondaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
      titleSize='large'
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.subLabel}>Ban length</Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomDropdown
                options={banLengthOptions}
                placeholder='Ban length'
                onChange={value => setBanLength(value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 600,
                }}
              >
                Reason For Banning {user?.username}? <br /> (THIS WILL BE SHOWN
                TO THE USER, USE PROPER PUNCTUATION, WORDING, ETC.)
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <CustomInput
            value={reason}
            onChange={value => setReasoning(value)}
            autoFocus={true}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: normal,
                color: secondaryText,
                fontWeight: 600,
              }}
            >
              Do you have evidence for the ban you are issuing to{' '}
              {user?.username}? <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomCheckbox
          checked={banProof}
          onChange={value => setBanProof(value)}
          color={purple}
        />
      </Grid>

      {banProof && (
        <Grid item sx={styles.width}>
          <CustomInput
            value={banEvidence}
            onChange={value => setEvidence(value)}
            autoFocus={true}
          />
        </Grid>
      )}
    </CustomModal>
  );
};

export default BanUserModal;
