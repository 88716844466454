import { Grid, useMediaQuery } from '@mui/material';
import { getNotiTimestamp } from '../../../utils/helpers';
import CustomModal from '../../custom/CustomModal';

const ActivityItemPreviewModal = ({ open, onClose, activityItem }) => {
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={activityItem?.user_id}
      description={getNotiTimestamp(new Date(activityItem?.timestamp))}
      minWidth={isDesktop ? '80%' : '97%'}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <img
            draggable={false}
            src={`data:image/jpeg;base64,${activityItem?.dataurl}`}
            alt='screenshot'
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default ActivityItemPreviewModal;
