import { Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiFillFire, AiFillTrophy } from 'react-icons/ai';
import { BsPiggyBankFill, BsPlayFill, BsTwitch } from 'react-icons/bs';
import { RiSwordFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { getStats, getStreams } from '../../api/home';
import { getMatches, getOngoingMatches } from '../../api/matches';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { TWITCH_URL } from '../../utils/authURL';
import {
  currencyFormatter,
  hexToRgbA,
  numFormatter,
} from '../../utils/helpers';
import {
  HOME_MATCH_LIMIT,
  HOME_ONGOING_LIMIT,
  STREAMS_LIMIT,
} from '../../utils/limits';
import SpacingWrapper from '../../utils/SpacingWrapper';
import {
  background,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  emptyIcon,
  menuShadow,
  offWhite,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import CustomPill from '../custom/CustomPill';
import Empty from '../custom/Empty';
import NotSelected from '../custom/NotSelected';
import OutlineButton from '../custom/OutlineButton';
import ScrollableContainer from '../custom/ScrollableContainer';
import SecondaryButton from '../custom/SecondaryButton';
import SelectGameModal from '../custom/SelectGameModal';
import MatchGlimmer from '../glimmers/MatchGlimmer';
import MobileStreamItemGlimmer from '../glimmers/MobileStreamItemGlimmer';
import OngoingGlimmer from '../glimmers/OngoingGlimmer';
import StreamItemGlimmer from '../glimmers/StreamItemGlimmer';
import MatchItem from '../matches/MatchItem';
import StreamItem from '../streams/StreamItem';
import OngoingTokenItem from '../tokens/OngoingTokenItem';

const Home = () => {
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery('(max-width: 768px)');
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const ongoingBottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const [ongoingLoading, setOngoingLoading] = useState(true);
  const [ongoingMatches, setOngoingMatches] = useState(null);
  const [ongoingSkip, setOngoingSkip] = useState(0);
  const [ongoingHasMore, setOngoingHasMore] = useState(false);
  const [ongoingFetchLoading, setOngoingFetchLoading] = useState(false);

  const [streams, setStreams] = useState(null);
  const [streamSkip, setStreamSkip] = useState(0);
  const [hasMoreStreams, setHasMoreStreams] = useState(false);
  const [streamsLoading, setStreamsLoading] = useState(true);
  const [fetchStreamsLoading, setFetchStreamsLoading] = useState(false);

  const [statsLoading, setStatsLoading] = useState(true);
  const [stats, setStats] = useState(null);

  const [gamesOpen, setGamesOpen] = useState(false);
  const [gamesTitle, setGamesTitle] = useState(null);

  const handleGetMatches = (filtering = false) => {
    setFetchLoading(true);
    getMatches(skip, null, null, null, null, null, true, null).then(res => {
      setLoading(false);
      setFetchLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.matches?.length >= HOME_MATCH_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setMatches(
          filtering
            ? res?.matches
            : matches
              ? [...matches, ...res?.matches]
              : res?.matches
        );
      }
    });
  };

  const handleGetOngoing = () => {
    setOngoingFetchLoading(true);
    getOngoingMatches(ongoingSkip, true).then(res => {
      setOngoingLoading(false);
      setOngoingFetchLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.matches?.length >= HOME_ONGOING_LIMIT) {
          setOngoingHasMore(true);
        } else {
          setOngoingHasMore(false);
        }

        setOngoingMatches(
          ongoingMatches ? [...ongoingMatches, ...res?.matches] : res?.matches
        );
      }
    });
  };

  const handleGetStreams = () => {
    setFetchStreamsLoading(true);
    getStreams(streamSkip).then(res => {
      setStreamsLoading(false);
      setFetchStreamsLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.streams?.length >= STREAMS_LIMIT) {
          setHasMoreStreams(true);
        } else {
          setHasMoreStreams(false);
        }

        setStreams(streams ? [...streams, ...res?.streams] : res?.streams);
      }
    });
  };

  const handleGetStats = () => {
    getStats().then(res => {
      setStatsLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setStats(res?.stats);
      }
    });
  };

  useEffect(() => {
    if (loading) {
      if (matches == null) {
        handleGetMatches();
        return;
      }
    }
  }, [loading, matches]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetMatches();
    }
  }, [skip]);

  useEffect(() => {
    if (ongoingLoading) {
      if (ongoingMatches == null) {
        handleGetOngoing();
        return;
      }
    }
  }, [ongoingLoading, ongoingMatches]);

  useEffect(() => {
    if (ongoingSkip !== 0) {
      handleGetOngoing();
    }
  }, [ongoingSkip]);

  useEffect(() => {
    if (streamsLoading) {
      if (streams == null) {
        handleGetStreams();
        return;
      }
    }
  }, [streamsLoading, streams]);

  useEffect(() => {
    if (streamSkip !== 0) {
      handleGetStreams();
    }
  }, [streamSkip]);

  useEffect(() => {
    if (statsLoading) {
      if (!stats) {
        handleGetStats();
      }
    }
  }, [stats, statsLoading]);

  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
      position: 'relative',
      background: background,
    },
    heroOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '85vh',
      background: `linear-gradient(180deg, ${hexToRgbA(
        doubtedBlue,
        0.15
      )} 0%, ${hexToRgbA(doubtedBlue, 0.15)} 50%, ${background} 100%)`,
      zIndex: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url('/assets/fortnite-hero.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.2,
        zIndex: -1,
        filter: 'blur(2px)',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -1,
        left: 0,
        right: 0,
        height: '250px',
        background: `linear-gradient(180deg, transparent 0%, ${background} 100%)`,
        zIndex: 1,
      },
    },
    heroContent: {
      position: 'relative',
      zIndex: 2,
      paddingBottom: '110px',
    },
    width: {
      width: '100%',
    },
    headerContainer: {
      zIndex: 1,
      width: '100%',
      position: 'relative',
      padding: '120px 0 60px',
    },
    header: {
      fontSize: isTablet ? 42 : 64,
      color: text,
      fontWeight: 800,
      maxWidth: 800,
      textAlign: 'center',
      lineHeight: 1.1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      textShadow: '0 2px 25px rgba(0,0,0,0.4)',
      background: `linear-gradient(135deg, ${text} 10%, ${doubtedBlue} 90%)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      letterSpacing: '1px',
    },
    description: {
      fontWeight: 400,
      color: secondaryText,
      fontSize: isTablet ? 15 : 18,
      maxWidth: 560,
      textAlign: 'center',
      lineHeight: 1.5,
    },
    meta: {
      fontSize: isTablet ? 14 : 13,
      fontWeight: 500,
      color: offWhite,
    },
    sectionHeader: {
      fontSize: 24,
      fontWeight: 700,
      color: text,
      position: 'relative',
      paddingBottom: '12px',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '40px',
        height: '3px',
        background: doubtedBlue,
        bottom: 0,
        left: 0,
        borderRadius: '4px',
      },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: 16,
      color: doubtedBlue,
    },
    statsCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      background: hexToRgbA(cardLight, 0.5),
      borderRadius: '8px',
      backdropFilter: 'blur(10px)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 25px rgba(0,0,0,0.15)',
      },
    },
    statIcon: {
      fontSize: 28,
      color: doubtedBlue,
      marginBottom: '10px',
    },
    statValue: {
      fontSize: 24,
      fontWeight: 800,
      color: text,
    },
    statLabel: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
      marginTop: '4px',
    },
    ctaButton: {
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'translateY(-3px)',
      },
    },
    sectionContainer: {
      background: hexToRgbA(cardLight, 0.2),
      padding: '24px',
      borderRadius: '12px',
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
      backdropFilter: 'blur(5px)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'translateY(-3px)',
      },
    },
    nextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
      width: 48,
      backgroundColor: cardLight,
      borderRadius: 100,
      boxShadow: menuShadow,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardVeryLight,
      },
    },
  };

  return (
    <>
      <SelectGameModal
        title={gamesTitle}
        open={gamesOpen}
        onClose={selectedGame => {
          setGamesOpen(false);
          setGamesTitle(null);
          if (selectedGame) {
            navigate(`/wagers/matches`);
          }
        }}
      />

      <Grid item sx={styles.container}>
        <div style={styles.heroOverlay} />

        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <SpacingWrapper maxWidth={1400}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              gap={{ xs: 8 }}
              sx={{
                paddingBottom: 10,
                maxWidth: 1400,
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Grid
                item
                sx={styles.headerContainer}
                className={styles.heroContent}
              >
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={{ xs: 3 }}
                >
                  <Grid item>
                    <Typography sx={styles.header}>
                      Dominate The Arena
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.description}>
                      Join{' '}
                      {statsLoading ? (
                        <Skeleton width={50} sx={{ display: 'inline-block' }} />
                      ) : (
                        <span style={{ color: text, fontWeight: 700 }}>
                          {numFormatter(0).format(stats?.player_count)}
                        </span>
                      )}{' '}
                      other players competing in wager matches and start earning
                      today.
                    </Typography>
                  </Grid>

                  <Grid item sx={styles.width} style={{ marginTop: '15px' }}>
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      gap={{ xs: 2 }}
                    >
                      <Grid
                        item
                        sx={{
                          minWidth: isTablet ? '100%' : null,
                          ...styles.ctaButton,
                        }}
                      >
                        <SecondaryButton
                          label='Find Matches'
                          icon={
                            <RiSwordFill
                              style={{ fontSize: 18, color: text }}
                            />
                          }
                          bg={!store?.user ? secondaryButton : doubtedBlue}
                          fullWidth
                          onClick={() => {
                            navigate('/wagers/matches');
                          }}
                        />
                      </Grid>

                      {!store?.user && (
                        <Grid
                          item
                          sx={{
                            minWidth: isTablet ? '100%' : null,
                            ...styles.ctaButton,
                          }}
                        >
                          <SecondaryButton
                            label='Register Now'
                            bg={doubtedBlue}
                            icon={
                              <BsPlayFill
                                style={{ fontSize: 18, color: text }}
                              />
                            }
                            onClick={() => navigate('/login')}
                            fullWidth
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        sx={{
                          minWidth: isTablet ? '100%' : null,
                          position: 'relative',
                          ...styles.ctaButton,
                        }}
                        onClick={() => navigate('/tournaments')}
                      >
                        <SecondaryButton
                          label='Tournaments'
                          icon={
                            <AiFillTrophy
                              style={{ fontSize: 18, color: text }}
                            />
                          }
                          bg={!store?.user ? secondaryButton : doubtedBlue}
                          onClick={() => navigate('/leaderboard')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ marginTop: 4, width: '100%' }}>
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='stretch'
                      spacing={2}
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <RiSwordFill style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Skeleton
                                    variant='text'
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {numFormatter(0).format(
                                    stats?.matches_played
                                  )}
                                </span>
                              )}{' '}
                              Wagers Played
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.meta}>•</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <BsPiggyBankFill style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Skeleton
                                    variant='text'
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {currencyFormatter().format(
                                    stats?.winnings_paid
                                  )}
                                </span>
                              )}{' '}
                              Winnings Paid
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.meta}>•</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='center'
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <AiFillFire style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Skeleton
                                    variant='text'
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {stats?.ongoing_matches}
                                </span>
                              )}{' '}
                              Ongoing Wagers
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ width: '100%', zIndex: 1, marginTop: -4 }}>
                <div style={styles.sectionContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 2.5 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid container alignItems='center' gap={2}>
                        <Grid item>
                          <Typography sx={styles.sectionHeader}>
                            Live Streams
                          </Typography>
                        </Grid>

                        {store?.user && !store?.user?.connections?.twitch && (
                          <Grid item>
                            <SecondaryButton
                              label='Link Twitch'
                              size='small'
                              icon={
                                <BsTwitch
                                  style={{ fontSize: 18, color: text }}
                                />
                              }
                              onClick={() => window.open(TWITCH_URL, '_self')}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {store?.user?.connections?.twitch && (
                      <CustomPill
                        label={'How to stream?'}
                        hoverLabel='Stream with #rivox.gg in your twitch title and start playing!'
                      />
                    )}

                    {streamsLoading && isTablet && (
                      <MobileStreamItemGlimmer numItems={4} />
                    )}

                    {streamsLoading && !isTablet && (
                      <StreamItemGlimmer numItems={4} />
                    )}

                    {!streamsLoading && streams?.length < 1 && (
                      <Empty
                        title='No live streams'
                        label='Try linking your Twitch and streaming'
                        icon={<BsTwitch style={emptyIcon} />}
                      />
                    )}

                    {isTablet && (
                      <Grid item sx={styles.width}>
                        <ScrollableContainer gap={1}>
                          {streams?.map((stream, i) => (
                            <StreamItem
                              stream={stream}
                              key={i}
                              width={273}
                              height={153}
                            />
                          ))}
                        </ScrollableContainer>
                      </Grid>
                    )}

                    {!isTablet && (
                      <>
                        {!streamsLoading && streams?.length > 0 && (
                          <Grid
                            item
                            sx={{
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns:
                                  'repeat(auto-fill,minmax(325px, 2fr))',
                                gap: 16,
                              }}
                            >
                              {streams?.map((stream, i) => (
                                <StreamItem stream={stream} key={i} />
                              ))}
                            </div>

                            {hasMoreStreams && (
                              <Grid item sx={{ width: '100%', marginTop: 2 }}>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                  gap={{ xs: 1 }}
                                >
                                  <Grid item>
                                    <OutlineButton
                                      label='Show more'
                                      onClick={() => {
                                        setStreamSkip(streams?.length);
                                      }}
                                      loading={fetchStreamsLoading}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </div>
              </Grid>

              <Grid item sx={{ width: '100%', zIndex: 1 }}>
                <div style={styles.sectionContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 2.5 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid item>
                        <Typography sx={styles.sectionHeader}>
                          Open Wagers
                        </Typography>
                      </Grid>
                    </Grid>

                    {loading && <MatchGlimmer numItems={4} />}

                    {!loading && matches?.length < 1 && (
                      <Grid item alignSelf='center' sx={{ marginTop: 4 }}>
                        <NotSelected label='no available wagers' />
                      </Grid>
                    )}

                    {!loading && matches?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns:
                              'repeat(auto-fill,minmax(250px, 4fr))',
                            gap: 16,
                            position: 'relative',
                          }}
                        >
                          {matches?.map((match, i) => (
                            <MatchItem home={true} match={match} key={i} />
                          ))}
                        </div>

                        {hasMore && (
                          <Grid item sx={{ width: '100%', marginTop: 2 }}>
                            <Grid
                              container
                              justifyContent='center'
                              alignItems='center'
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <OutlineButton
                                  label='Show more'
                                  onClick={() => {
                                    setSkip(matches?.length);
                                  }}
                                  loading={fetchLoading}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>

              <Grid item sx={{ width: '100%', zIndex: 1 }}>
                <div style={styles.sectionContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 2.5 }}
                  >
                    <Grid item sx={styles.width}>
                      <Grid item>
                        <Typography sx={styles.sectionHeader}>
                          Ongoing Wagers
                        </Typography>
                      </Grid>
                    </Grid>

                    {ongoingLoading && <OngoingGlimmer numItems={6} />}

                    {!ongoingLoading && ongoingMatches?.length < 1 && (
                      <Grid item alignSelf='center' sx={{ marginTop: 4 }}>
                        <NotSelected label='no ongoing wagers' />
                      </Grid>
                    )}

                    {!ongoingLoading &&
                      ongoingMatches &&
                      ongoingMatches?.length > 0 && (
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction='column'
                            alignItems='start'
                            justifyContent='center'
                            gap={{ xs: 1 }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'grid',
                                gap: 16,
                                gridTemplateColumns: `repeat(${isTablet ? '1' : '2'}, minmax(0, 1fr))`,
                              }}
                            >
                              {ongoingMatches?.map((match, i) => (
                                <OngoingTokenItem token={match} key={i} />
                              ))}
                            </div>

                            {ongoingHasMore && (
                              <Grid item sx={{ width: '100%', marginTop: 2 }}>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                  gap={{ xs: 1 }}
                                >
                                  <Grid item>
                                    <OutlineButton
                                      label='Show more'
                                      onClick={() => {
                                        setOngoingSkip(ongoingMatches?.length);
                                      }}
                                      loading={ongoingFetchLoading}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
