import React, { Suspense } from 'react';
import { getColor } from '../../utils/getColor';

// Create a mapping of clothes types to lazy-loaded components
const clothesComponents = {
  't-shirt': React.lazy(() => import('./types/TShirt')),
  lft_shirt: React.lazy(() => import('./types/LFTShirt')),
  jumper: React.lazy(() => import('./types/Jumper')),
  cardigan: React.lazy(() => import('./types/Cardigan')),
  shoulder_with_jacket: React.lazy(() => import('./types/ShoulderJacket')),
  blouse: React.lazy(() => import('./types/Blouse')),
  jean_jacket: React.lazy(() => import('./types/JeanJacket')),
  ugly_sweater: React.lazy(() => import('./types/UglySweater')),
  hoodie: React.lazy(() => import('./types/Hoodie')),
  button_down: React.lazy(() => import('./types/ButtonDown')),
  louis: React.lazy(() => import('./types/Louis')),
  hockey_jersey: React.lazy(() => import('./types/Hockey')),
  lft_hockey_jersey: React.lazy(() => import('./types/LFTHockey')),
  basketball: React.lazy(() => import('./types/Basketball')),
  letterman: React.lazy(() => import('./types/Letterman')),
  bathing_ape: React.lazy(() => import('./types/BapeHoodie')),
  war_torn: React.lazy(() => import('./types/WarTorn')),
  dawn: React.lazy(() => import('./types/Dawn')),
  graphic_blazer: React.lazy(
    () => import('./types/graphic_blazer/GraphicBlazer')
  ),
  graphic_blazer_neutron: React.lazy(
    () => import('./types/graphic_blazer/GraphicBlazerNeutron')
  ),
  graphic_blazer_stark: React.lazy(
    () => import('./types/graphic_blazer/GraphicBlazerStark')
  ),
  graphic_blazer_idea: React.lazy(
    () => import('./types/graphic_blazer/GraphicBlazerIdea')
  ),
  college_hoodie: React.lazy(
    () => import('./types/college_hoodie/CollegeHoodie')
  ),
  college_hoodie_michigan: React.lazy(
    () => import('./types/college_hoodie/CollegeHoodieMichigan')
  ),
  college_hoodie_duke: React.lazy(
    () => import('./types/college_hoodie/CollegeHoodieDuke')
  ),
  college_hoodie_ucla: React.lazy(
    () => import('./types/college_hoodie/CollegeHoodieUCLA')
  ),
  college_hoodie_msu: React.lazy(
    () => import('./types/college_hoodie/CollegeHoodieMSU')
  ),
  brrrrrr: React.lazy(() => import('./types/Brrrrrr')),
  ceedeegee: React.lazy(() => import('./types/CeeDeeGee')),
  wc_usa: React.lazy(() => import('./types/world_cup/USA')),
  wc_brazil: React.lazy(() => import('./types/world_cup/Brazil')),
  wc_england: React.lazy(() => import('./types/world_cup/England')),
  wc_france: React.lazy(() => import('./types/world_cup/France')),
  wc_germany: React.lazy(() => import('./types/world_cup/Germany')),
  wc_mancity: React.lazy(() => import('./types/world_cup/ManCity')),
  wc_manu: React.lazy(() => import('./types/world_cup/ManUnited')),
  wc_messi: React.lazy(() => import('./types/world_cup/Messi')),
  wc_netherlands: React.lazy(() => import('./types/world_cup/Netherlands')),
  wc_spain: React.lazy(() => import('./types/world_cup/Spain')),
  wc_mexico: React.lazy(() => import('./types/world_cup/Mexico')),
  wc_ronaldo: React.lazy(() => import('./types/world_cup/Ronaldo')),
  minion: React.lazy(() => import('./types/Minion')),
  canadian_goose: React.lazy(() => import('./types/Goose')),
  tech: React.lazy(() => import('./types/Tech')),
  chrome: React.lazy(() => import('./types/Chrome')),
  vlone_tee: React.lazy(() => import('./types/Vlone')),
  tate: React.lazy(() => import('./types/Tate')),
  flannel: React.lazy(() => import('./types/Flannel')),
  hoodie_eyes: React.lazy(() => import('./types/HoodieOverEyes')),
  incognito: React.lazy(() => import('./types/Incognito')),
  kobe: React.lazy(() => import('./types/KobeJersey')),
  greaser_jacket: React.lazy(() => import('./types/GreaserJacket')),
  mj_jersey: React.lazy(() => import('./types/JordanJersey')),
  cleezy: React.lazy(() => import('./types/Moncleezy')),
  pinhead: React.lazy(() => import('./types/Pinhead')),
  gym_rat: React.lazy(() => import('./types/GymRat')),
  scream: React.lazy(() => import('./types/Scream')),
  skelly_zip: React.lazy(() => import('./types/SkellyZip')),
  skelly_wool: React.lazy(() => import('./types/SkellyWool')),
  spider_tour_1: React.lazy(() => import('./types/SpiderTourOne')),
  spider_tour_2: React.lazy(() => import('./types/SpiderTourTwo')),
  supreme_crew: React.lazy(() => import('./types/SupremeCrew')),
  gym_rat_tatted: React.lazy(() => import('./types/GymRatTatted')),
  turtle_neck: React.lazy(() => import('./types/TurtleNeck')),
  y2k_hoodie: React.lazy(() => import('./types/Y2KHoodie')),
  tanjiro: React.lazy(() => import('./types/Tanjiro')),
  psg_jacket: React.lazy(() => import('./types/PSGJacket')),
  travis_scott: React.lazy(() => import('./types/TravisScott')),
  lil_uzi: React.lazy(() => import('./types/LilUzi')),
  alien: React.lazy(() => import('./types/Alien')),
  juice_wrld: React.lazy(() => import('./types/JuiceWrld')),
  drizzy_drake: React.lazy(() => import('./types/Drizzy')),
  creeper: React.lazy(() => import('./types/Creeper')),
  ape_shit: React.lazy(() => import('./types/ApeShit')),
  mime_shirt: React.lazy(() => import('./types/MimeShirt')),
  zombie: React.lazy(() => import('./types/Zombie')),
  mummy: React.lazy(() => import('./types/Mummy')),
  rex: React.lazy(() => import('./types/Rex')),
  bullet_vest: React.lazy(() => import('./types/BulletVest')),
  kirito_coat: React.lazy(() => import('./types/KiritoCoat')),
  yeat_cat_hoodie: React.lazy(() => import('./types/YeatCatHoodie')),
  doge: React.lazy(() => import('./types/Doge')),
  black_bull: React.lazy(() => import('./types/BlackBull')),
  drool_emoji: React.lazy(() => import('./types/emoji/DroolEmoji')),
  nerd_emoji: React.lazy(() => import('./types/emoji/NerdEmoji')),
  alien_emoji: React.lazy(() => import('./types/emoji/AlienEmoji')),
  laugh_emoji: React.lazy(() => import('./types/emoji/LaughEmoji')),
  hearts_emoji: React.lazy(() => import('./types/emoji/HeartsEmoji')),
  angry_emoji: React.lazy(() => import('./types/emoji/PressedEmoji')),
  crying_emoji: React.lazy(() => import('./types/emoji/CryEmoji')),
  demon_emoji: React.lazy(() => import('./types/emoji/DemonEmoji')),
  'half-skull_emoji': React.lazy(() => import('./types/emoji/HalfDeadEmoji')),
  eyes_emoji: React.lazy(() => import('./types/emoji/EyesEmoji')),
  heart_eyes_emoji: React.lazy(() => import('./types/emoji/HeartEyesEmoji')),
  singer_emoji: React.lazy(() => import('./types/emoji/SingerEmoji')),
  crossbones_emoji: React.lazy(() => import('./types/emoji/CrossbonesEmoji')),
  monkey_emoji: React.lazy(() => import('./types/emoji/MonkeyEmoji')),
  interesting_emoji: React.lazy(() => import('./types/emoji/InterestingEmoji')),
  disguise_emoji: React.lazy(() => import('./types/emoji/DisguiseEmoji')),
  sunglasses_emoji: React.lazy(() => import('./types/emoji/SunglassesEmoji')),
  cowboy_emoji: React.lazy(() => import('./types/emoji/CowboyEmoji')),
  blown_emoji: React.lazy(() => import('./types/emoji/BlownEmoji')),
  zipper_emoji: React.lazy(() => import('./types/emoji/ZipperEmoji')),
  confused_emoji: React.lazy(() => import('./types/emoji/ConfusedEmoji')),
  swirl_emoji: React.lazy(() => import('./types/emoji/SwirlEmoji')),
};

const Clothes = ({ type, color, skinColor }) => {
  const ClothesComponent = clothesComponents[type];

  if (!ClothesComponent) return null;

  const props = {};

  // Components that require 'color' prop
  const colorComponents = [
    't-shirt',
    'lft_shirt',
    'jumper',
    'cardigan',
    'shoulder_with_jacket',
    'blouse',
    'jean_jacket',
    'hoodie',
    'button_down',
    'graphic_blazer',
    'graphic_blazer_neutron',
    'graphic_blazer_stark',
    'graphic_blazer_idea',
    'tech',
    'vlone_tee',
    'greaser_jacket',
    'skelly_zip',
    'skelly_wool',
    'supreme_crew',
    'turtle_neck',
    'y2k_hoodie',
    'lil_uzi',
    'chrome',
    'scream',
    'flannel',
    'hoodie_eyes',
    'tanjiro',
    'psg_jacket',
    'juice_wrld',
    'tate',
  ];

  // Components that require 'skinColor' prop
  const skinColorComponents = [
    'cardigan',
    'shoulder_with_jacket',
    'blouse',
    'louis',
    'hockey_jersey',
    'lft_hockey_jersey',
    'basketball',
    'war_torn',
    'hoodie_eyes',
    'tanjiro',
    'psg_jacket',
    'drizzy_drake',
    'skelly_wool',
    'spider_tour_1',
    'spider_tour_2',
    'supreme_crew',
    'turtle_neck',
    'y2k_hoodie',
    'lil_uzi',
    'juice_wrld',
    'moncleezy',
    'kobe',
    'mj_jersey',
    'gym_rat',
    'gym_rat_tatted',
    'tate',
    'black_bull',
    'kirito_coat',
    'bullet_vest',
  ];

  // Components that require both 'color' and 'skinColor' props
  const bothColorComponents = [
    'cardigan',
    'shoulder_with_jacket',
    'blouse',
    'war_torn',
    'hockey_jersey',
    'lft_hockey_jersey',
    'basketball',
    'hoodie_eyes',
    'tanjiro',
    'psg_jacket',
    'drizzy_drake',
    'skelly_wool',
    'spider_tour_1',
    'spider_tour_2',
    'supreme_crew',
    'turtle_neck',
    'y2k_hoodie',
    'lil_uzi',
    'juice_wrld',
    'tate',
    'chrome',
    'greaser_jacket',
    'moncleezy',
    'gym_rat',
    'gym_rat_tatted',
    'flannel',
  ];

  // Assign props based on the component's requirements
  if (bothColorComponents.includes(type)) {
    props.color = getColor(color);
    props.skinColor = getColor(skinColor);
  } else {
    if (colorComponents.includes(type)) {
      props.color = getColor(color);
    }
    if (skinColorComponents.includes(type)) {
      props.skinColor = getColor(skinColor);
    }
  }

  // Special cases where 'skinColor' is required without 'color'
  if (
    type === 'travis_scott' ||
    type === 'mummy' ||
    type === 'kobe' ||
    type === 'mj_jersey' ||
    type === 'black_bull' ||
    type === 'kirito_coat' ||
    type === 'bullet_vest'
  ) {
    props.skinColor = getColor(skinColor);
  }

  return (
    <Suspense fallback={null}>
      <ClothesComponent {...props} />
    </Suspense>
  );
};

export default Clothes;
