import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteAutomatedTournament,
  getAutomatedTournaments,
} from '../../api/automatedTournaments';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { newGameModeOptions } from '../../utils/options';
import {
  card,
  doubtedBlue,
  red,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import PrimaryButton from '../custom/PrimaryButton';

const DAYS_OF_WEEK = [
  { title: 'Monday', value: 'monday' },
  { title: 'Tuesday', value: 'tuesday' },
  { title: 'Wednesday', value: 'wednesday' },
  { title: 'Thursday', value: 'thursday' },
  { title: 'Friday', value: 'friday' },
  { title: 'Saturday', value: 'saturday' },
  { title: 'Sunday', value: 'sunday' },
];

const GAME_OPTIONS = [
  { title: 'Fortnite', value: 'fortnite' },
  { title: 'Warzone', value: 'warzone' },
  { title: 'Chess', value: 'chess' },
  { title: 'FIFA', value: 'fifa' },
  { title: 'Clash Royale', value: 'clashroyale' },
];

const TournamentAutomation = () => {
  const [selectedDay, setSelectedDay] = useState('monday');
  const [automatedTournaments, setAutomatedTournaments] = useState({});
  const [loading, setLoading] = useState(true);
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      getAutomatedTournaments(middleware).then(res => {
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          // Group tournaments by day
          const grouped = res.reduce((acc, tournament) => {
            if (!acc[tournament.day_of_week]) {
              acc[tournament.day_of_week] = [];
            }
            acc[tournament.day_of_week].push(tournament);
            return acc;
          }, {});
          setAutomatedTournaments(grouped);
        }
        setLoading(false);
      });
    }
  }, [loading, middleware, updateStore]);

  const handleDayChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  const handleDeleteTournament = async (id, dayOfWeek) => {
    const res = await deleteAutomatedTournament(middleware, id);
    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      const newTournaments = { ...automatedTournaments };
      newTournaments[dayOfWeek] = newTournaments[dayOfWeek].filter(
        t => t._id !== id
      );
      setAutomatedTournaments(newTournaments);
      updateStore({
        type: SET_SUCCESSES,
        payload: 'Tournament deleted successfully',
      });
    }
  };

  const styles = {
    container: {
      width: '100%',
      padding: { xs: 1, sm: 2 },
      backgroundColor: card,
      borderRadius: 1,
    },
    header: {
      fontSize: { xs: 20, sm: 24 },
      color: text,
      fontWeight: 700,
      marginBottom: 2,
    },
    tabPanel: {
      padding: { xs: 1, sm: 2 },
    },
    tournamentCard: {
      backgroundColor: card,
      borderRadius: 1,
      padding: { xs: 1, sm: 2 },
      marginBottom: 2,
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 1,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'space-between',
      alignItems: { xs: 'flex-start', sm: 'center' },
      gap: { xs: 2, sm: 0 },
    },
    buttonContainer: {
      display: 'flex',
      gap: 1,
      flexDirection: { xs: 'column', sm: 'row' },
      width: { xs: '100%', sm: 'auto' },
    },
    tabsContainer: {
      maxWidth: '90vw',
      '& .MuiTabs-root': {
        maxWidth: '100%',
      },
      '& .MuiTabs-scroller': {
        overflow: 'auto !important',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '& .MuiTabs-flexContainer': {
        gap: 0,
      },
      '& .MuiTab-root': {
        minWidth: { xs: '20vw', sm: 'unset' },
        minHeight: 'unset',
        padding: { xs: '8px', sm: '12px 16px' },
        fontSize: { xs: '0.75rem', sm: '0.875rem' },
        textTransform: 'none',
      },
      '& .MuiTabs-scrollButtons': {
        display: { xs: 'none', sm: 'auto' },
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={styles.headerContainer}>
            <Typography sx={styles.header}>Tournament Automation</Typography>
            <PrimaryButton
              label='Create New Tournament'
              onClick={() => navigate('/staff/tournaments/automation/create')}
              color={doubtedBlue}
              fullWidth={window.innerWidth < 600}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Tabs
            value={selectedDay}
            onChange={handleDayChange}
            variant='scrollable'
            scrollButtons={false}
            allowScrollButtonsMobile={false}
            sx={{
              ...styles.tabsContainer,
              '& .MuiTab-root': {
                color: secondaryText,
                whiteSpace: 'nowrap',
                '&.Mui-selected': {
                  color: text,
                },
              },
            }}
          >
            {DAYS_OF_WEEK.map(day => (
              <Tab key={day.value} label={day.title} value={day.value} />
            ))}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Box sx={styles.tabPanel}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {automatedTournaments[selectedDay]?.map(tournament => (
                  <Box key={tournament._id} sx={styles.tournamentCard}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12} md={8}>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography
                            variant='h6'
                            sx={{
                              color: text,
                              fontSize: { xs: '1rem', sm: '1.25rem' },
                            }}
                          >
                            {tournament.name}
                          </Typography>
                          {tournament.is_active ? (
                            <CheckCircleIcon
                              sx={{ color: 'success.main', fontSize: '1.2rem' }}
                            />
                          ) : (
                            <CancelIcon
                              sx={{ color: 'error.main', fontSize: '1.2rem' }}
                            />
                          )}
                        </Box>
                        <Typography
                          sx={{
                            color: secondaryText,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                          }}
                        >
                          Starts at: {tournament.start_time}
                        </Typography>
                        <Typography
                          sx={{
                            color: secondaryText,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                          }}
                        >
                          Team Size: {tournament.team_size}v
                          {tournament.team_size}
                        </Typography>
                        <Typography
                          sx={{
                            color: secondaryText,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            wordBreak: 'break-word',
                          }}
                        >
                          Game Modes:{' '}
                          {tournament.game_modes
                            ?.map(
                              mode =>
                                `${newGameModeOptions(tournament.game).find(m => m.value === mode.type)?.title} (${mode.weight}%)`
                            )
                            .join(', ')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={styles.buttonContainer}>
                          <PrimaryButton
                            label='Edit'
                            onClick={() =>
                              navigate(
                                `/staff/tournaments/automation/edit/${tournament._id}`
                              )
                            }
                            variant='outlined'
                            fullWidth={window.innerWidth < 600}
                          />
                          <PrimaryButton
                            label='Delete'
                            onClick={() =>
                              handleDeleteTournament(
                                tournament._id,
                                tournament.day_of_week
                              )
                            }
                            color={red}
                            fullWidth={window.innerWidth < 600}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {(!automatedTournaments[selectedDay] ||
                  automatedTournaments[selectedDay].length === 0) && (
                  <Typography
                    sx={{
                      color: secondaryText,
                      textAlign: 'center',
                      marginTop: 2,
                    }}
                  >
                    No automated tournaments scheduled for{' '}
                    {DAYS_OF_WEEK.find(day => day.value === selectedDay)?.title}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TournamentAutomation;
