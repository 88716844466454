import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { ImStatsBars } from 'react-icons/im';
import { getExpenseStats } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import {
  currencyFormatter,
  getReadableDateString,
  getTime,
} from '../../utils/helpers';
import { MATCH_HISTORY_LIMIT } from '../../utils/limits';
import {
  card,
  green,
  modalShadow,
  normal,
  offWhite,
  red,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import PrimaryButton from '../custom/PrimaryButton';
import MatchHistoryGlimmer from '../glimmers/MatchHistoryGlimmer';
import ExpenseItem from '../transactions/ExpenseItem';
import AddExpenseModal from './AddExpenseModal';

const ExpenseStats = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [expenseOpen, setExpenseOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const bottom = useRef(null);
  const [date, _] = useState(new Date());
  const [expenses, setExpenses] = useState(null);
  const [expenseStats, setExpenseStats] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetExpenses = () => {
    getExpenseStats(middleware, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.expenses?.length >= MATCH_HISTORY_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setExpenses(expenses ? [...expenses, ...res?.expenses] : res?.expenses);
        setExpenseStats(res?.expense_stats);
      }
    });
  };
  usePagination(bottom, () => setSkip(expenses?.length));

  useEffect(() => {
    if (expenses == null) {
      handleGetExpenses();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetExpenses();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    container: {
      borderRadius: 2,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
    },
    smallLabel: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 600,
    },
    totalLabel: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    totalValue: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    label: {
      fontSize: 15,
      color: offWhite,
      fontWeight: 600,
    },
    value: {
      fontSize: 15,
      color: text,
      fontWeight: 800,
    },
    containerHeader: {
      fontSize: 18,
      color: text,
      fontWeight: 800,
    },
    percentValue: {
      fontSize: 12,
      color: green,
      fontWeight: 600,
    },
  };

  return (
    <>
      <AddExpenseModal
        open={expenseOpen}
        onClose={() => setExpenseOpen(false)}
      />
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.icon}>
                <ImStatsBars style={{ fontSize: 22, color: text }} />
              </Grid>

              <Grid item>
                <Typography sx={styles.title}>EXPENSE STATS</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography sx={styles.meta}>
              Breakdown of expenses on Rivox as of:{' '}
              <span style={{ fontWeight: 800 }}>
                {getReadableDateString(date)}
              </span>
              , <span style={{ fontWeight: 800 }}>{getTime(date)}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PrimaryButton
          label='Add Expense / Payout'
          type='secondary'
          size='small'
          onClick={() => setExpenseOpen(true)}
        />
      </Grid>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}
      {!loading && expenseStats && expenseStats?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent={'start'}
            alignItems={'center'}
            gap={{ xs: 1 }}
          >
            {expenseStats?.map((expense, i) => (
              <Grid item sx={styles.container} key={i}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Grid item>
                    <Typography sx={(styles.totalLabel, { color: red })}>
                      {expense?.type}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.totalValue}>
                      {currencyFormatter().format(expense?.amount)}
                    </Typography>
                  </Grid>
                  <Typography sx={styles.smallLabel}>
                    {/* {stats?.total_matches?.total} Matches */}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!loading && expenses && expenses?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {expenses?.map((expense, i) => (
              <ExpenseItem expense={expense} key={i} />
            ))}
            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <MatchHistoryGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ExpenseStats;
