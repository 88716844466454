import { Grid, Typography } from '@mui/material';
import { container } from '../../utils/themeContstants';

const NotSelected = ({ label, size = 'large' }) => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: container,
      padding: size === 'small' ? 1 : 2,
      borderRadius: 1,
    },
    label: {
      color: 'rgb(104, 109, 123)',
      textTransform: 'uppercase',
      fontSize: size === 'small' ? 11 : 16,
      fontStyle: 'italic',
      fontWeight: 800,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
    </Grid>
  );
};

export default NotSelected;
