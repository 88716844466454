import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { BiCheck, BiPlus, BiX } from 'react-icons/bi';
import { IoLockClosed } from 'react-icons/io5';
import { StoreContext } from '../../store/Store';
import { AvatarRarityEnum } from '../../utils/enums';
import { hexToRgbA } from '../../utils/helpers';
import {
  cardVeryLight,
  emojiYellow,
  green,
  modalShadow,
  normal,
  purple,
  red,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';

const PiecePreview = ({
  children,
  title,
  onClick,
  selected,
  size,
  option,
  value,
  owned = true,
  staff = false,
  loading = false,
  disabled = false,
}) => {
  const store = useContext(StoreContext);

  const [hovered, setHovered] = useState(false);

  const getBackgroundColor = () => {
    if (loading || disabled) {
      return 'rgba(215, 228, 241, 0.3)';
    }

    switch (option?.rarity) {
      case AvatarRarityEnum.COMMON:
        return '#D7D9E7';
      case AvatarRarityEnum.UNCOMMON:
        return 'rgba(21, 226, 164, 0.7)';
      case AvatarRarityEnum.RARE:
        return 'rgba(21, 117, 226, 0.7)';
      case AvatarRarityEnum.LEGENDARY:
        return 'rgba(112, 82, 221, 0.7)';
      case AvatarRarityEnum.EXOTIC:
        return 'rgba(251, 139, 36, 0.7)';
      case AvatarRarityEnum.VIP:
        return 'rgba(255, 84, 101, 0.7)';
      case AvatarRarityEnum.GODLIKE:
        return 'rgba(255, 0, 110, 0.7)';
      case AvatarRarityEnum.EMOJI:
        return hexToRgbA(emojiYellow, 0.7);
      default:
        return cardVeryLight;
    }
  };

  const styles = {
    container: {
      borderRadius: 1,
      backgroundColor: getBackgroundColor(),
      minHeight: size,
      minWidth: size,
      maxHeight: size,
      maxWidth: size,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      transition: transition,
      boxShadow: modalShadow,
      overflow: 'hidden',
      transform: hovered ? 'translateY(-5px)' : null,
      cursor: hovered ? 'pointer' : 'default',
      position: 'relative',
    },
    textContainer: {
      transition: transition,
      opacity: hovered ? 0 : '100%',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    staffContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: owned ? red : green,
      borderRadius: 50,
      height: 30,
      width: 30,
      position: 'absolute',
      top: 4,
      right: 4,
    },
  };

  return (
    <Grid
      item
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={loading ? null : onClick}
    >
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        gap={{ xs: 0.5 }}
      >
        <Grid item sx={styles.textContainer}>
          <Typography
            sx={{
              fontSize: normal,
              fontWeight: 600,
              color: selected ? text : secondaryText,
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item sx={styles.container}>
          {loading && (
            <Grid
              item
              sx={{
                ...styles.iconContainer,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <CircularProgress size={30} sx={{ color: '#fff' }} />
            </Grid>
          )}

          {selected && owned && (
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 4,
                right: 4,
                height: 25,
                width: 25,
                borderRadius: 1,
                boxSizing: 'border-box',
                border: `2px solid ${cardVeryLight}`,
                backgroundColor: purple,
              }}
            >
              <BiCheck style={{ fontSize: 15, color: '#fff' }} />
            </Grid>
          )}

          {!owned && !staff && (
            <Grid item sx={styles.iconContainer}>
              <IoLockClosed
                style={{
                  fontSize: 24,
                  color: '#fff',
                  position: 'absolute',
                  top: 4,
                  right: 4,
                }}
              />
            </Grid>
          )}

          {owned && staff && !loading && !disabled && (
            <Grid item sx={styles.staffContainer}>
              <BiX style={{ fontSize: 22, color: '#fff' }} />
            </Grid>
          )}

          {!owned && staff && !loading && !disabled && (
            <Grid item sx={styles.staffContainer}>
              <BiPlus style={{ fontSize: 22, color: '#fff' }} />
            </Grid>
          )}

          {value == null && (
            <BiX style={{ fontSize: size, color: secondaryText }} />
          )}

          {value && children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PiecePreview;
