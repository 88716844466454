import { Grid } from '@mui/material';
import { CDN_URL } from '../../utils/constants';

const DoubtedCoin = ({ size, type }) => {
  const ggCoin = `${CDN_URL}doubted-coin.svg`;
  const ggCoinBlue = `${CDN_URL}doubted-coin-blue.svg`;
  const ggCoinOrange = `${CDN_URL}doubted-coin-orange.svg`;
  const ggCoinGold = `${CDN_URL}doubted-coin-yellow.svg`;
  const ggCoinPurple = `${CDN_URL}doubted-coin-purple.svg`;
  const dggCoin = `${CDN_URL}gg_coin.svg`;

  const getCoinType = () => {
    switch (type) {
      case 'blue':
        return ggCoinBlue;
      case 'orange':
        return ggCoinOrange;
      case 'gold':
        return ggCoinGold;
      case 'purple':
        return ggCoinPurple;
      default:
        return ggCoin;
    }
  };
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img draggable={false} height={size} width={size} src={dggCoin} />
    </Grid>
  );
};

export default DoubtedCoin;
