import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import Avatar from '../../avatar/Avatar';
import {
  card,
  cardDark,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';

const StaffSearchResultItem = ({ user, onClick }) => {
  const [hovered, setHovered] = useState(false);

  const styles = {
    container: {
      width: '100%',
      borderRadius: 1,
      padding: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        transform: 'translateY(-5px)',
      },
    },
    username: {
      fontSize: normal,
      color: user?.isPremium ? 'rgb(255, 255, 193)' : text,
      textShadow: user?.isPremium ? 'rgb(255 93 0) 0px 0px 10px' : null,
      fontWeight: 800,
    },
    epic: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        sx={{
          paddingLeft: 2,
          paddingBottom: 1,
          borderRadius: 1,
          backgroundColor: cardLight,
        }}
      >
        <Grid item sx={{ marginLeft: -2 }}>
          <Avatar avatar={user?.avatar} size={100} bgColor={cardDark} />
        </Grid>

        <Grid item>
          <Typography sx={styles.username}>{user?.username}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StaffSearchResultItem;
