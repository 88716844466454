import React, { Suspense } from 'react';
import { getColor } from '../../utils/getColor';

const hairComponents = {
  crew_cut: React.lazy(() => import('./types/CrewCut')),
  hair_w_bow: React.lazy(() => import('./types/HairWithBow')),
  hair_w_headband: React.lazy(() => import('./types/HairWithHeadband')),
  bangs: React.lazy(() => import('./types/Bangs')),
  fade_swirl: React.lazy(() => import('./types/Swirl')),
  afro_w_headband: React.lazy(() => import('./types/AfroWithHeadband')),
  karen: React.lazy(() => import('./types/Karen')),
  fade: React.lazy(() => import('./types/Fade')),
  dad_hair: React.lazy(() => import('./types/DadHair')),
  pony_tail: React.lazy(() => import('./types/PonyTail')),
  afro: React.lazy(() => import('./types/Afro')),
  surfer: React.lazy(() => import('./types/Surfer')),
  mohawk: React.lazy(() => import('./types/Mohawk')),
  bald: React.lazy(() => import('./types/Bald')),
  default: React.lazy(() => import('./types/Default')),
  flow: React.lazy(() => import('./types/Flow')),
  braids: React.lazy(() => import('./types/Braids')),
  corn_rows: React.lazy(() => import('./types/CornRows')),
  fade_rows: React.lazy(() => import('./types/FadeRows')),
  tucked: React.lazy(() => import('./types/Tucked')),
  bunny: React.lazy(() => import('./types/Bunny')),
  jett: React.lazy(() => import('./types/Jett')),
  bob: React.lazy(() => import('./types/Bob')),
  clown: React.lazy(() => import('./types/Clown')),
  messy_buns: React.lazy(() => import('./types/MessyBuns')),
  pig_tails: React.lazy(() => import('./types/PigTails')),
  slays_demons: React.lazy(() => import('./types/SlaysDemons')),
  anime_kid: React.lazy(() => import('./types/AnimeKid')),
  riri: React.lazy(() => import('./types/RiRi')),
  wavy: React.lazy(() => import('./types/Wavy')),
  revenge_flow: React.lazy(() => import('./types/RevengeFlow')),
  carti: React.lazy(() => import('./types/Carti')),
  keef: React.lazy(() => import('./types/Keef')),
  goku: React.lazy(() => import('./types/Goku')),
  spice: React.lazy(() => import('./types/Spice')),
  kodak: React.lazy(() => import('./types/Kodak')),
  king_von: React.lazy(() => import('./types/KingVon')),
  pop_smoke: React.lazy(() => import('./types/PopSmoke')),
  morant: React.lazy(() => import('./types/JaMorant')),
  hunter_hunter: React.lazy(() => import('./types/HunterHunter')),
  todoroki: React.lazy(() => import('./types/Todoroki')),
  six_nine: React.lazy(() => import('./types/SixNine')),
};

const Hair = ({ type, color }) => {
  const HairComponent = hairComponents[type];

  if (!HairComponent) return null;

  const noColorComponents = [
    'jett',
    'clown',
    'anime_kid',
    'carti',
    'keef',
    'goku',
    'six_nine',
  ];

  const props = {};

  if (!noColorComponents.includes(type)) {
    props.color = getColor(color);
  }

  return (
    <Suspense fallback={null}>
      <HairComponent {...props} />
    </Suspense>
  );
};

export default Hair;
