import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import {
  getFee,
  getGameModeString,
  getPlatformString,
  getReadableDateString,
  getTime,
  numFormatter,
} from '../../utils/helpers';
import {
  card,
  cardLight,
  cardVeryLight,
  green,
  normal,
  offWhite,
  red,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import CoinBadge from '../custom/CoinBadge';
import RivoxCoin from '../custom/RivoxCoin';

const HistoryItem = ({ match, staffUser = null }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const [hovered, setHovered] = useState(false);

  const getResult = () => {
    if (match?.state === 'cancel') {
      return (
        <Grid item sx={styles.canceled}>
          <Typography sx={styles.cancelText}>CANCEL</Typography>
        </Grid>
      );
    }

    const winnerIndex = match?.winner_ids?.findIndex(
      user =>
        user?.toString() ===
        (staffUser ? staffUser?._id : store?.user?._id?.toString())
    );

    if (winnerIndex === -1) {
      return (
        <Grid item sx={styles.loser}>
          <Typography sx={styles.resultText}>DEFEAT</Typography>
        </Grid>
      );
    }

    return (
      <Grid item sx={styles.winner}>
        <Typography sx={styles.resultText}>VICTORY</Typography>
      </Grid>
    );
  };

  const getCoinType = () => {
    if (match?.state === 'cancel') return null;

    const winnerIndex = match?.winner_ids?.findIndex(
      user =>
        user?.toString() ===
        (staffUser ? staffUser?._id : store?.user?._id?.toString())
    );

    if (winnerIndex === -1) {
      return 'loss';
    }

    return 'prize';
  };

  const getCoinAmount = () => {
    if (match?.state === 'cancel') return 0;

    const winnerIndex = match?.winner_ids?.findIndex(
      user =>
        user?.toString() ===
        (staffUser ? staffUser?._id : store?.user?._id?.toString())
    );

    if (winnerIndex === -1) {
      return match?.entry_fee;
    }

    return match?.entry_fee * getFee(match?.game);
  };

  const getEarnings = () => {
    if (match?.state === 'cancel') {
      return (
        <Grid item>
          <Typography sx={styles.value}>N/A</Typography>
        </Grid>
      );
    }

    const winnerIndex = match?.winner_ids?.findIndex(
      user =>
        user?.toString() ===
        (staffUser ? staffUser?._id : store?.user?._id?.toString())
    );

    if (winnerIndex === -1) {
      return (
        <Grid item>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 0.5 }}
          >
            <RivoxCoin size={18} />
            <Grid item>
              <Typography sx={{ ...styles.value, color: red }}>
                - {numFormatter().format(match?.entry_fee)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 0.5 }}
        >
          <RivoxCoin size={18} />
          <Grid item>
            <Typography sx={{ ...styles.value, color: green }}>
              + {numFormatter().format(match?.entry_fee * getFee(match?.game))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: '100%',
      transition: transition,
      backgroundColor: card,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: cardLight,
      },
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    winner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      backgroundColor: green,
    },
    loser: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      backgroundColor: red,
    },
    canceled: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      backgroundColor: secondaryButton,
    },
    resultText: {
      fontSize: 12,
      color: text,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    cancelText: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={() => window.open(`/token/${match?.match_id}`)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={styles.label}>Game type</Typography>
            </Grid>

            <Grid item sx={{ marginBottom: isDesktop ? null : 1 }}>
              <Typography sx={styles.value}>
                {getGameModeString(match?.game_match_type)}
              </Typography>
            </Grid>

            {!isDesktop && (
              <CoinBadge
                amount={getCoinAmount()}
                label='Earnings'
                placement='top'
                size='small'
                type={getCoinType()}
              />
            )}
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Platform</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {getPlatformString(match?.platform)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Team size</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {`${match?.team_size}v${match?.team_size}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>Result</Typography>
                </Grid>

                {getResult()}
              </Grid>
            </Grid>

            <CoinBadge
              amount={getCoinAmount()}
              label='Earnings'
              placement='top'
              type={getCoinType()}
            />
          </>
        )}

        <Grid item sx={{ marginLeft: isDesktop ? null : 'auto' }}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item alignSelf='end'>
              <Typography sx={{ ...styles.label, textAlign: 'right' }}>
                Date
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {getReadableDateString(new Date(match?.date)) +
                  ' at ' +
                  getTime(new Date(match?.date))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HistoryItem;
