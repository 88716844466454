import { CircularProgress, Grid } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { getUserWithdrawals } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import { secondaryText } from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import NotSelected from '../custom/NotSelected';
import MatchHistoryGlimmer from '../glimmers/MatchHistoryGlimmer';
import PendingWithdrawalItem from './PendingWithdrawalItem';

const UserWithdrawals = ({ user }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [withdraws, setWithdraws] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetWithdraws = () => {
    getUserWithdrawals(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.withdrawals?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setWithdraws(
          withdraws ? [...withdraws, ...res?.withdrawals] : res?.withdrawals
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(withdraws?.length));

  useEffect(() => {
    if (withdraws == null) {
      handleGetWithdraws();
    }
  }, [withdraws]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetWithdraws();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!withdraws || withdraws?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO WITHDRAWALS' />
        </Grid>
      )}

      {!loading && withdraws && withdraws?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {withdraws?.map((transaction, i) => (
              <PendingWithdrawalItem
                withdrawal={transaction}
                key={i}
                setWithdrawals={setWithdraws}
                withdrawals={withdraws}
                showStatus={true} // Pass showStatus prop
                user={true}
              />
            ))}

            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <MatchHistoryGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserWithdrawals;
