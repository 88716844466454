import { API_ROUTE, errMessage } from '../utils/constants';

export const getAutomatedTournaments = async useMiddleware => {
  return await useMiddleware
    .get(`${API_ROUTE}/tournament-automation`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const createAutomatedTournament = async (
  useMiddleware,
  tournamentData
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/tournament-automation/create`, tournamentData)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const updateAutomatedTournament = async (
  useMiddleware,
  id,
  tournamentData
) => {
  return await useMiddleware
    .put(`${API_ROUTE}/tournament-automation/${id}`, tournamentData)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};

export const deleteAutomatedTournament = async (useMiddleware, id) => {
  return await useMiddleware
    .delete(`${API_ROUTE}/tournament-automation/${id}`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};
