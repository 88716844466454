import React, { Suspense } from 'react';

const eyebrowComponents = {
  sad: React.lazy(() => import('./types/Sad')),
  angry: React.lazy(() => import('./types/Angry')),
  default: React.lazy(() => import('./types/Default')),
  worry: React.lazy(() => import('./types/Worry')),
  confused: React.lazy(() => import('./types/Confused')),
};

const Eyebrows = ({ type }) => {
  const EyebrowComponent = eyebrowComponents[type];

  if (!EyebrowComponent) return null;

  return (
    <Suspense fallback={null}>
      <EyebrowComponent />
    </Suspense>
  );
};

export default Eyebrows;
