import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import useMiddleware from '../../api/useMiddleware';
import { addConnection, linkEpic } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreDispatch,
} from '../../store/Store';
import { getGameName, getWordLogo, hexToRgbA } from '../../utils/helpers';
import {
  card,
  doubtedBlue,
  linearGradientToTop,
  normal,
  offWhite,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomIconButton from '../custom/CustomIconButton';
import CustomInput from '../custom/CustomInput';
import SecondaryButton from '../custom/SecondaryButton';

const useYunite = true;

const GameConnectionModal = ({ open, onClose, minWidth = 450, game }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasLinked, setHasLinked] = useState(false);

  const handleClose = () => {
    setName('');
    setLoading(false);
    setHasLinked(false);
    onClose();
  };

  const addGameAccount = () => {
    setLoading(true);
    addConnection(middleware, game, null, null, name).then(res => {
      setLoading(false);
      if (res?.error) {
        setName('');
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        updateStore({ type: SET_USER, payload: res?.user });
        handleClose();
      }
    });
  };

  const handleLinkEpic = () => {
    setLoading(true);
    if (!useYunite) {
      addConnection(middleware, game, null, null, name).then(res => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: res?.message,
          });
          updateStore({ type: SET_USER, payload: res?.user });
          handleClose();
        }
      });
    } else {
      linkEpic(middleware).then(res => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: res?.message,
          });
          updateStore({ type: SET_USER, payload: res?.user });
          handleClose();
        }
      });
    }
  };

  const styles = {
    width: {
      width: '100%',
    },
    card: {
      backgroundColor: card,
      minWidth: isDesktop ? minWidth : small ? '100%' : '97%',
      maxWidth: 800,
      borderRadius: 12,
      boxShadow: '0 1px 5px 1px rgb(0 0 0 / 40%)',
    },
    top: {
      width: '100%',
      backgroundColor: card,
      position: 'relative',
      // backgroundImage: `url(${getGameHeader(game)})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 10,
      top: 10,
      zIndex: 10,
    },
    title: {
      color: text,
      textAlign: 'left',
      fontSize: 30,
      fontWeight: 800,
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: '#d7e4f1',
    },
    steps: {
      fontSize: normal,
      fontWeight: 400,
      color: text,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: styles.card }}
      BackdropProps={{ style: { background: 'rgba(16, 18, 27, 0.6)' } }}
      fullScreen={!small ? false : true}
    >
      <DialogTitle sx={styles.top}>
        {onClose && (
          <Grid item sx={styles.closeButton}>
            <CustomIconButton
              onClick={onClose}
              icon={<IoClose style={{ color: text, fontSize: 20 }} />}
              opacity
            />
          </Grid>
        )}

        <div
          style={{
            backgroundColor: hexToRgbA(card, 0.6),
            position: 'absolute',
            width: '100%',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 0,
          }}
        />

        <div
          style={{
            background: linearGradientToTop(
              hexToRgbA(card, 1),
              hexToRgbA(card, 0)
            ),
            position: 'absolute',
            width: '100%',
            top: 0,
            bottom: -1,
            right: 0,
            left: 0,
            zIndex: 0,
          }}
        />

        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={{ xs: 1 }}
          sx={{ zIndex: 2 }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              draggable={false}
              height={game === 'clashroyale' ? 60 : 50}
              src={getWordLogo(game)}
            />
          </Grid>
          <Grid item>
            <Typography sx={styles.title}>Add game account</Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {game !== 'fortnite' && (
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.subLabel}>
                {game === 'warzone' && 'Enter your Activision ID'}
                {game !== 'warzone' && <>{getGameName(game)} in-game name</>}
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                value={name}
                onChange={val => setName(val)}
                onPaste={val => setName(val)}
                placeholder={
                  game === 'warzone'
                    ? 'Example#1234'
                    : 'Enter your in-game name'
                }
                onKeyDown={() => {
                  if (name !== '' && name != null) {
                    addGameAccount();
                  } else {
                    return;
                  }
                }}
                disabled={loading}
                autoFocus={true}
              />
            </Grid>
          </Grid>
        )}

        {game === 'fortnite' && useYunite && (
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: normal, color: text, fontWeight: 800 }}
              >
                How to link your Game Account
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.steps}>
                    <span style={{ fontWeight: 800 }}>1.</span> Make sure your
                    Discord is linked to your Rivox account
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.steps}>
                    <span style={{ fontWeight: 800 }}>2.</span> Verify your
                    Yunite in the #Yunite-website-unlock channel in our{' '}
                    <a
                      style={{ color: doubtedBlue }}
                      href={'https://discord.gg/rivox'}
                    >
                      Discord Server.
                    </a>{' '}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.steps}>
                    <span style={{ fontWeight: 800 }}>3.</span> Come back to
                    this page and press "LINK"!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='start'
                alignItems='center'
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <CustomCheckbox
                    checked={hasLinked}
                    onChange={value => setHasLinked(value)}
                    color={doubtedBlue}
                    checkColor={text}
                  />
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: offWhite,
                      fontWeight: 600,
                      maxWidth: 400,
                    }}
                  >
                    I have verified my Yunite in the #Welcome channel of the{' '}
                    <span style={{ color: text, fontWeight: 800 }}>Rivox</span>{' '}
                    Discord Server.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {game === 'fortnite' && !useYunite && (
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 2 }}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: normal, color: text, fontWeight: 800 }}
              >
                Link your Epic Games Account
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.subLabel}>
                Enter your Epic Games display name
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                value={name}
                onChange={val => setName(val)}
                onPaste={val => setName(val)}
                placeholder='Enter your Epic Games display name'
                onKeyDown={() => {
                  if (name !== '' && name != null) {
                    addGameAccount();
                  }
                }}
                disabled={loading}
                autoFocus={true}
              />
            </Grid>

            <Grid item>
              <Typography sx={styles.steps}>
                Make sure to enter the display name exactly as it appears in
                your Epic Games account.
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Grid item>
          <SecondaryButton
            label='Add game account'
            disabled={!hasLinked && (name === '' || name == null)}
            loading={loading}
            onClick={game === 'fortnite' ? handleLinkEpic : addGameAccount}
            bg={doubtedBlue}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GameConnectionModal;
