import { Grid } from '@mui/material';
import {
  clashTeamSizeOptions,
  newGameModeOptions,
} from '../../../utils/options';
import { cardDark, cardDarker } from '../../../utils/themeContstants';
import CustomDropdown from '../../custom/CustomDropdown';

const FifaMatchFilters = ({ game, setTeamSize, setGameMode }) => {
  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        justifyContent='start'
        alignItems='center'
        gap={{ xs: 1 }}
      >
        <Grid item>
          <CustomDropdown
            options={newGameModeOptions(game)}
            placeholder='Game Mode'
            onChange={value => setGameMode(value)}
            backgroundColor={cardDark}
            backgroundHover={cardDarker}
            border={'transparent'}
          />
        </Grid>

        <Grid item>
          <CustomDropdown
            options={clashTeamSizeOptions}
            placeholder='Team Size'
            onChange={value => setTeamSize(value)}
            backgroundColor={cardDark}
            backgroundHover={cardDarker}
            border={'transparent'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FifaMatchFilters;
