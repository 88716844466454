import { API_ROUTE, errMessage } from '../utils/constants';

export const getNews = async useMiddleware => {
  return await useMiddleware
    .get(`${API_ROUTE}/news`)
    .then(res => res.data)
    .catch(err => {
      return { error: true, message: errMessage(err) };
    });
};
