import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiWallet } from 'react-icons/bi';
import { BsClockFill } from 'react-icons/bs';
import { FaCrown } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import useMiddleware from '../../api/useMiddleware';
import { cashAppWithdraw, withdraw } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  currencyFormatter,
  getWithdrawMethodString,
} from '../../utils/helpers';
import {
  doubtedBlue,
  normal,
  offWhite,
  secondaryText,
  text,
} from '../../utils/themeContstants';
import CurrencyInput from '../custom/CurrencyInput';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import SecondaryButton from '../custom/SecondaryButton';

const WithdrawWalletMenu = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const MIN_WITHDRAW_AMOUNT = 10;
  const [amount, setAmount] = useState('');
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('cashapp');
  const [cashTag, setCashTag] = useState('');

  const handleWithdrawal = () => {
    setLoading(true);
    withdraw(middleware, store?.user?.connections?.paypal?.email, amount).then(
      res => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: res?.message,
          });
          setAmount('');
          setCanWithdraw(false);
        }
      }
    );
  };

  const handleCashAppWithdraw = () => {
    setLoading(true);
    cashAppWithdraw(middleware, cashTag, amount, type).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        setAmount('');
        setCashTag('');
        setCanWithdraw(false);
      }
    });
  };

  useEffect(() => {
    if (type === 'paypal') {
      if (
        amount == null ||
        amount === '' ||
        amount < MIN_WITHDRAW_AMOUNT ||
        cashTag == null ||
        cashTag === ''
      ) {
        setCanWithdraw(false);
      } else {
        setCanWithdraw(true);
      }
    } else {
      if (
        amount == null ||
        amount === '' ||
        amount < MIN_WITHDRAW_AMOUNT ||
        cashTag == null ||
        cashTag === ''
      ) {
        setCanWithdraw(false);
      } else {
        setCanWithdraw(true);
      }
    }
  }, [amount, store?.user?.connections?.paypal?.email, type, cashTag]);

  useEffect(() => {
    setCashTag('');
  }, [type]);

  const styles = {
    container: {
      width: '100%',
      marginTop: 1,
      marginBottom: 1,
    },
    vipWithdrawals: {
      color: 'rgb(255, 255, 193)',
      textShadow: 'rgb(255 93 0) 0px 0px 10px',
      fontSize: 12,
      fontWeight: 500,
      opacity: 0.8,
    },
    width: {
      width: '100%',
    },
    label: {
      fontSize: 13,
      color: secondaryText,
      fontWeight: 700,
      opacity: 0.8,
    },
    details: {
      fontSize: normal,
      color: offWhite,
      fontWeight: 500,
      opacity: 0.8,
    },
    value: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    warning: {
      fontSize: 12,
      color: text,
      fontWeight: 500,
      opacity: 0.8,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        {store?.user?.funds?.max_withdrawal_amount >= MIN_WITHDRAW_AMOUNT && (
          <>
            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.label}>Amount</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <CurrencyInput
                    value={amount}
                    onChange={value => setAmount(value)}
                    autoFocus={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.label}>Withdraw Type</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <CustomDropdown
                    options={[
                      { title: 'PayPal', value: 'paypal' },
                      { title: 'Cash App', value: 'cashapp' },
                    ]}
                    placeholder='Withdraw Type'
                    onChange={value => setType(value)}
                    value={getWithdrawMethodString(type)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='start'
                alignItems='center'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                {type === 'paypal' && (
                  <>
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.label}>
                            Paypal Email
                          </Typography>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <CustomInput
                            value={cashTag}
                            onChange={value => {
                              setCashTag(value);
                            }}
                            autoFocus={false}
                            cashTag={true}
                            placeholder='Paypal Email'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <Typography sx={styles.details}>
                            Sending To
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                            {cashTag}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {type === 'cashapp' && (
                  <>
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction='column'
                        alignItems='start'
                        justifyContent='center'
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <Typography sx={styles.label}>Cash Tag</Typography>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <CustomInput
                            value={cashTag}
                            onChange={value => {
                              if (value === '$') return;

                              setCashTag(value);
                            }}
                            autoFocus={false}
                            cashTag={true}
                            placeholder='Cash Tag'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <Typography sx={styles.details}>
                            Sending To
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                            ${cashTag}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.details}>Amount</Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.value}>
                        {amount ? currencyFormatter().format(amount) : '$0.00'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Typography sx={styles.details}>Fee</Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.value}>5% + $1.00</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography sx={styles.value}>Total</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                    {amount - amount * 0.05 - 1 > 0
                      ? currencyFormatter().format(amount - amount * 0.05 - 1)
                      : '$0.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <SecondaryButton
                    bg={doubtedBlue}
                    fullWidth
                    label='Withdraw'
                    disabled={!canWithdraw}
                    onClick={handleCashAppWithdraw}
                    loading={loading}
                  />
                </Grid>

                {(amount == null ||
                  amount === '' ||
                  amount < MIN_WITHDRAW_AMOUNT) && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <RiErrorWarningFill
                          style={{ color: secondaryText, fontSize: 14 }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.warning}>
                          Minimum withdraw amount is ${MIN_WITHDRAW_AMOUNT}.00
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <FaCrown style={{ color: secondaryText, fontSize: 14 }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography sx={styles.vipWithdrawals}>
                        VIP Withdrawals: Instant - 1 Day
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <BsClockFill
                        style={{ color: secondaryText, fontSize: 14 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.warning}>
                        Non VIP Withdrawals: 3-5 days
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {store?.user?.funds?.max_withdrawal_amount < MIN_WITHDRAW_AMOUNT && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.icon}>
                <BiWallet style={{ fontSize: 52, color: secondaryText }} />
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 15,
                        color: text,
                        fontWeight: 700,
                      }}
                    >
                      Not Enough Withdrawable Funds
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 400,
                        color: secondaryText,
                        textAlign: 'center',
                      }}
                    >
                      You need at least ${MIN_WITHDRAW_AMOUNT}.00 Coins won
                      through playing matches to withdraw.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WithdrawWalletMenu;
