import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { revertTip } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, SET_SUCCESSES, StoreDispatch } from '../../store/Store';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  small,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';

const RevertTipModal = ({ open, onClose, tip, tips, setTips }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [canRevert, setCanRevert] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setCanRevert(false);
    onClose();
  };

  const handleRevertTip = () => {
    setLoading(true);
    revertTip(middleware, tip?._id).then(res => {
      setLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setCanRevert(false);
      } else {
        const indexOfTip = tips?.findIndex(
          oldTips => tip?._id === oldTips?._id
        );
        let newTips = tips;

        if (newTips?.length > 0 && indexOfTip !== -1) {
          newTips?.splice(indexOfTip, 1);

          setTips(newTips);
        }
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Revert Tip?`}
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label='Revert'
          bg={doubtedBlue}
          loading={loading}
          disabled={!canRevert}
          onClick={handleRevertTip}
        />
      }
      secondaryButton={<SecondaryButton label='Cancel' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item>
          <Typography sx={styles.description}>
            You are about to revert the tip and give the money back to the
            tipper.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={canRevert}
                onChange={value => setCanRevert(value)}
                color={doubtedBlue}
                disabled={loading}
                checkColor={text}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: offWhite,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                By checking this box you understand that you are about to revert
                this tip and abusing this feature will result in the loss of
                your staff permissions
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default RevertTipModal;
