import { CircularProgress, Grid } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { getUserDeposits } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import { secondaryText } from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import NotSelected from '../custom/NotSelected';
import MatchHistoryGlimmer from '../glimmers/MatchHistoryGlimmer';
import TransactionItem from '../transactions/TransactionItem';

const UserDeposits = ({ user }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetDeposits = () => {
    getUserDeposits(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.deposits?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setDeposits(deposits ? [...deposits, ...res?.deposits] : res?.deposits);
      }
    });
  };

  usePagination(bottom, () => setSkip(deposits?.length));

  useEffect(() => {
    if (deposits == null) {
      handleGetDeposits();
    }
  }, [deposits]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetDeposits();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!deposits || deposits?.length < 1) && (
        <Grid item alignSelf='center'>
          <NotSelected label='NO DEPOSITS' />
        </Grid>
      )}

      {!loading && deposits && deposits?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {deposits?.map((transaction, i) => (
              <TransactionItem transaction={transaction} key={i} />
            ))}
            {hasMore && (
              <div style={{ width: '100%' }} ref={bottom}>
                <MatchHistoryGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserDeposits;
