import { Grid, Skeleton } from '@mui/material';
import { cardVeryLight } from '../../utils/themeContstants';

const StreamItemGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  const styles = {
    container: {
      minWidth: 275,
      minHeight: 204,
    },
    width: {
      width: '100%',
    },
  };

  return (
    <Grid item sx={styles.width}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 2fr))',
          gap: 12,
        }}
      >
        {array.map((_, i) => (
          <Grid item key={i} sx={styles.container}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Skeleton
                  variant='rectangular'
                  animation='pulse'
                  sx={{
                    bgcolor: cardVeryLight,
                    borderRadius: 1,
                    minWidth: '100%',
                    minHeight: 155,
                  }}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Skeleton
                      variant='circular'
                      animation='pulse'
                      sx={{ bgcolor: cardVeryLight, height: 45, width: 45 }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction='column'
                      alignItems='start'
                      justifyContent='center'
                    >
                      <Grid item>
                        <Skeleton
                          variant='text'
                          animation='pulse'
                          sx={{
                            borderRadius: 1,
                            bgcolor: cardVeryLight,
                            width: 200,
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Skeleton
                          variant='text'
                          animation='pulse'
                          sx={{
                            borderRadius: 1,
                            bgcolor: cardVeryLight,
                            width: 100,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </div>
    </Grid>
  );
};

export default StreamItemGlimmer;
