import { Grid, Typography } from '@mui/material';
import {
  bestOfOptions,
  fifaRegionOptions,
  newGameModeOptions,
} from '../../../utils/options';
import { cardLight, normal } from '../../../utils/themeContstants';
import CustomDropdown from '../../custom/CustomDropdown';
import RadioListItem from '../../custom/RadioListItem';

const FifaCreate = ({
  game,
  setGameMode,
  gameMode,
  setRegion,
  region,
  platform,
  setPlatform,
  setBestOf,
  bestOf,
}) => {
  const styles = {
    width: {
      width: '100%',
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: '#d7e4f1',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Platform</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <RadioListItem
                title='Current Gen Crossplay'
                description='PS5, Xbox Series X/S, & PC'
                value='current_gen'
                selected={platform === 'current_gen'}
                onClick={() => setPlatform('current_gen')}
                backgroundColorHover={cardLight}
              />
              <RadioListItem
                title='Last Gen Crossplay'
                description='PS4 & Xbox One'
                value='last_gen'
                selected={platform === 'last_gen'}
                onClick={() => setPlatform('last_gen')}
                backgroundColorHover={cardLight}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Game mode</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder='Game mode'
              onChange={value => setGameMode(value)}
              value={gameMode}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Region</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={fifaRegionOptions}
              placeholder='Region'
              onChange={value => setRegion(value)}
              value={region}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Best of</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={bestOfOptions}
              placeholder='Best of'
              onChange={value => setBestOf(value)}
              value={bestOf}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Platform</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform(null)}
                    selected={platform == null}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      All Platforms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform("console")}
                    selected={platform === "console"}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      Console Only
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Game mode</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder="Game mode"
              onChange={(value) => setGameMode(value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Best of</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={bestOfOptions}
              placeholder="Best of"
              onChange={(value) => setBestOf(value)}
            />
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default FifaCreate;
