import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  doubtedOrange,
  normal,
  secondaryText,
  text,
} from '../../utils/themeContstants';

const ZeroBuildRules = ({ token }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='start'
      justifyContent='center'
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : '100%' }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>MODE</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              {token?.team_size === 2
                ? 'Zero Build BR Squads'
                : 'Zero Build BR Duos'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography sx={styles.nonBoldValue}>
          <Grid item>
            <Typography sx={styles.label}>GAME SETTINGS</Typography>
          </Grid>
          <li style={{ color: doubtedOrange }}>
            Host must have at least 50 seconds of "Hidden Matchmaking Delay"
            enabled in the rightmost tab of their game settings.
          </li>
        </Typography>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>TIE BREAKER</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If both teams have the same amount of kills at the end of the
                token, the team with the most time alive wins.
              </li>
              <li>
                If both teams have the same amount of time alive and are tied
                for kills at the end of the token, play the token again.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>ALLOWED</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                You are <b>ALLOWED</b> to indirectly damage your opponents by
                summoning enemies, stealing loot, and destroying vehicles.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={styles.label}>NOT ALLOWED</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If you <b>directly damage</b> your opponents, you forfeit the
                token.
              </li>

              <li>
                If you hold your opponent in zone in <b>ANY WAY</b>, you forfeit
                the token.
              </li>

              <li>
                If you <b>interrupt your opponent healing</b>, you forfeit the
                token.
              </li>

              <li>
                If you destroy your opponents launch pad while they are{' '}
                <b>attempting to use it</b>, you forfeit the token.
              </li>

              <li>
                <b>Excessive intentional disruption</b> to your opponents play
                will result in forfeitting the token and possible ban.
              </li>

              <li>
                If you lift/carry your opponent for <b>more than a second</b>,
                you forfeit the token.
              </li>

              <li>
                If you lift your opponent to stop their teammate from reviving
                them, <b>or</b> for any disruptive reason, you forfeit the
                token.
              </li>
              <li>If you hire an NPC you forfeit the token.</li>
              <li>
                If you have someone who you have played a match or event with
                before, kill or interfere with your opponent(s), you will both
                be banned and you forfeit the token.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZeroBuildRules;
