import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import useMiddleware from '../../api/useMiddleware';
import { linkEpic } from '../../api/user';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  cardLight,
  green,
  normal,
  offWhite,
  purple,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const EpicModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [hasLinked, setHasLinked] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setHasLinked(false);
    onClose();
  };

  const handleLinkEpic = () => {
    setLoading(true);
    linkEpic(middleware).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        updateStore({ type: SET_USER, payload: res?.user });
        handleClose();
      }
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
    steps: {
      fontSize: normal,
      fontWeight: 400,
      color: text,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title='Link Game Account'
      primaryButton={
        <PrimaryButton
          label='Link'
          loading={loading}
          disabled={!hasLinked}
          type='green'
          onClick={handleLinkEpic}
        />
      }
      secondaryButton={<SecondaryButton label='close' onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={{ fontSize: normal, color: text, fontWeight: 800 }}>
            How to link your Game Account
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.steps}>
                <span style={{ fontWeight: 800 }}>1.</span> Make sure your
                Discord is linked to your Rivox account
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.steps}>
                <span style={{ fontWeight: 800 }}>2.</span> Verify your Yunite
                in the #Yunite-website-unlock channel in our{' '}
                {/* <a style={{ color: green }} href={DISCORD_URL}> */}
                <a style={{ color: green }} href={'https://discord.gg/rivox'}>
                  Discord Server.
                </a>{' '}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.steps}>
                <span style={{ fontWeight: 800 }}>3.</span> Come back to this
                page and press "LINK"!
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 2 }}
          >
            <Grid item>
              <CustomCheckbox
                checked={hasLinked}
                onChange={value => setHasLinked(value)}
                color={purple}
              />
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  fontSize: 14,
                  color: offWhite,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                I have verified my Yunite in the #Welcome channel of the{' '}
                <span style={{ color: text, fontWeight: 800 }}>Rivox</span>{' '}
                Discord Server.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default EpicModal;
