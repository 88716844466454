import { Grid } from '@mui/material';
import { useContext } from 'react';
import { StoreContext } from '../../store/Store';
import { isDateGreaterThanCurrent } from '../../utils/helpers';
import OtherItem from './OtherItem';

const ShopOther = () => {
  const store = useContext(StoreContext);
  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill,minmax(194px, 6fr))',
            gap: 12,
          }}
        >
          {store?.user && store?.user?.behavior_info?.has_debt && (
            <OtherItem type='unban' />
          )}

          {store?.user &&
            isDateGreaterThanCurrent(
              new Date(store?.user?.behavior_info?.match_chat_unban_timestamp)
            ) && <OtherItem type='chat_unban' />}

          {store?.user && !store?.user?.premium_info?.active && (
            <OtherItem type='vip' />
          )}
          <OtherItem type='snipes' />
          <OtherItem type='reset' />
          <OtherItem type='reset_earnings' />
        </div>
      </Grid>
    </>
  );
};

export default ShopOther;
