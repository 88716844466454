import { CDN_URL } from '../../../utils/constants';
const Emote = ({ type }) => {
  const getEmoteContent = type => {
    switch (type) {
      case 'fire_flames':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/fire_flames.gif`} //fire-flames.gif
              alt='Fire Flames'
              style={{
                width: '140%',
                height: '130%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-45%',
        };
      case 'fire_eyes':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/fire_eyes.gif`} //fire-eyes.gif
              alt='Fire Eyes'
              style={{
                width: '160%',
                height: '160%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-72%',
          translateY: '-22%',
        };
      case 'alex_made_it_rain':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/alex_made_it_rain.gif`} //alex-made-it-rain.gif
              alt='Alex'
              style={{
                width: '130%',
                height: '130%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-10%',
        };
      case 'money_arm':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/money_arm.gif`}
              alt='Alex'
              style={{
                width: '140%',
                height: '140%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-16%',
          translateY: '-10%',
        };
      case 'da_dogg':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/da_dogg.gif`}
              alt='Alex'
              style={{
                width: '140%',
                height: '140%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-65%',
          translateY: '-15%',
        };
      case 'glytch':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/glytch.gif`}
              alt='Alex'
              style={{
                width: '150%',
                height: '150%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-67%',
          translateY: '-20%',
        };
      case 'cash_make_it_rain':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/cash_make_it_rain.gif`}
              alt='Alex'
              style={{
                width: '130%',
                height: '130%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-10%',
        };
      case 'crown_winner':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/crown_winner.gif`}
              alt='Alex'
              style={{
                width: '200%',
                height: '200%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-90%',
          translateY: '-30%',
        };
      case 'da_snake':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/da_snake.gif`}
              alt='Alex'
              style={{
                width: '172%',
                height: '172%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-79%',
          translateY: '-25%',
        };
      case 'laser_beam':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/laser_beam.gif`}
              alt='Alex'
              style={{
                width: '162%',
                height: '162%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-74%',
          translateY: '-28%',
        };
      case 'lighting_eyes':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/lighting_eyes.gif`}
              alt='Alex'
              style={{
                width: '170%',
                height: '170%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-78%',
          translateY: '-27%',
        };
      case 'upgrade_energy':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/upgrade_energy.gif`}
              alt='Alex'
              style={{
                width: '175%',
                height: '175%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-81%',
          translateY: '-30%',
        };
      case 'fidget_spinner':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/fidget_spinner.gif`}
              alt='Alex'
              style={{
                width: '175%',
                height: '175%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-81%',
          translateY: '-25%',
        };
      case 'raining_snow':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/raining_snow.gif`}
              alt='Alex'
              style={{
                width: '130%',
                height: '150%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-55%',
          translateY: '-15%',
        };
      case 'jingle_bells':
        return {
          content: (
            <img
              src={`${CDN_URL}emotes/jingle_bells.gif`}
              alt='Alex'
              style={{
                width: '160%',
                height: '150%',
                pointerEvents: 'none',
              }}
            />
          ),
          translateX: '-70%',
          translateY: '-30%',
        };
      default:
        return null;
    }
  };

  const emoteData = getEmoteContent(type);

  if (!emoteData) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: '30%',
        left: '45%',
        transform: `translate(${emoteData.translateX}, ${emoteData.translateY})`,
        pointerEvents: 'none', // ensures the emote doesn't interfere with interactions
      }}
    >
      {emoteData.content}
    </div>
  );
};

export default Emote;
