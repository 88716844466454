import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { renewTokens } from '../api/auth';
import {
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '../api/useMiddleware';
import { SERVER_URL } from '../utils/constants';
import { NEW_TEAMMATE_EVENT } from './socketConstants';

const useTeamSocket = (teamId, token) => {
  const socketRef = useRef(null);

  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    if (!teamId) return;

    if (!socketRef?.current?.connected) {
      socketRef.current = socketIOClient(SERVER_URL, {
        query: { teamId },
        auth: { token },
      });
    }

    socketRef.current.on('connect_error', async err => {
      if (err?.data?.needs_renew) {
        // renew tokens
        await renewTokens(getRefreshToken()).then(res => {
          if (!res?.error) {
            saveAccessToken(res?.accessToken);
            saveRefreshToken(res?.refreshToken);
          }
        });
      }

      if (err?.data?.needs_login) {
        // force new login
      }
    });

    socketRef.current.on(NEW_TEAMMATE_EVENT, newTeamData => {
      if (newTeamData) {
        setTeamData(newTeamData);
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [teamId, token]);

  return {
    teamData,
  };
};

export default useTeamSocket;
