import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { StoreContext } from '../../store/Store';
import { numFormatter } from '../../utils/helpers';
import {
  cardVeryLight,
  normal,
  offWhite,
  text,
} from '../../utils/themeContstants';
import CustomMenuButton from '../custom/CustomMenuButton';
import RivoxCoin from '../custom/RivoxCoin';
import SecondaryButton from '../custom/SecondaryButton';
import TipSearchModal from '../tip/TipSearchModal';
import ClaimWalletMenu from './ClaimWalletMenu';
import DepositWalletMenu from './DepositWalletMenu';
import WithdrawWalletMenu from './WithdrawWalletMenu';

const WalletContent = ({ onClose }) => {
  const store = useContext(StoreContext);

  const [selected, setSelected] = useState('deposit');
  const [tipOpen, setTipOpen] = useState(false);

  const styles = {
    container: {
      width: '100%',
      marginTop: 1,
      paddingLeft: 1,
      paddingRight: 1,
    },
    width: {
      width: '100%',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <>
      <TipSearchModal open={tipOpen} onClose={() => setTipOpen(false)} />

      <Grid item sx={styles.container}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.width}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                >
                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: offWhite,
                            fontWeight: 500,
                            opacity: 0.8,
                          }}
                        >
                          Your Balance
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      justifyContent='start'
                      alignItems='center'
                      gap={{ xs: 0.5 }}
                    >
                      <RivoxCoin size={30} />

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 26,
                            color: text,
                            fontWeight: 700,
                          }}
                        >
                          {numFormatter(
                            store?.user?.funds?.balance < 1 ? 4 : 2
                          ).format(store?.user?.funds?.balance)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <SecondaryButton
                label='Tip a User'
                size='small'
                onClick={() => setTipOpen(true)}
              />
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <Grid
            item
            sx={{ width: '100%', borderBottom: `1px solid ${cardVeryLight}` }}
          >
            <Grid container justifyContent='start' alignItems='center'>
              <CustomMenuButton
                label='Deposit'
                onClick={() => setSelected('deposit')}
                selected={selected === 'deposit'}
                stretch={true}
              />
              <CustomMenuButton
                label='Withdraw'
                onClick={() => setSelected('withdrawal')}
                selected={selected === 'withdrawal'}
                stretch={true}
              />
              <CustomMenuButton
                label='Claim'
                onClick={() => setSelected('claim')}
                selected={selected === 'claim'}
                stretch={true}
              />
            </Grid>
          </Grid>

          {selected === 'deposit' && (
            <DepositWalletMenu closeWallet={onClose} />
          )}

          {selected === 'withdrawal' && <WithdrawWalletMenu />}

          {selected === 'claim' && <ClaimWalletMenu />}
        </Grid>
      </Grid>
    </>
  );
};

export default WalletContent;
