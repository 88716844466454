import { Grid, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineStop, AiOutlineYoutube, AiTwotoneBank } from 'react-icons/ai';
import { FaCoins, FaSearch, FaTrophy, FaUserCheck } from 'react-icons/fa';
import { GiPlayerNext } from 'react-icons/gi';
import { ImStatsBars, ImStatsDots } from 'react-icons/im';
import { MdChecklist, MdStar } from 'react-icons/md';
import { RiSwordFill } from 'react-icons/ri';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import SpacingWrapper from '../../utils/SpacingWrapper';
import { card, modalShadow, normal, text } from '../../utils/themeContstants';
import ListItem from '../custom/ListItem';

const StaffDashboard = () => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const [selected, setSelected] = useState('terms');

  const styles = {
    width: {
      width: '100%',
    },
    container: {
      width: '100%',
      minHeight: '100vh',
      // backgroundImage: `url(${background})`,
      // backgroundAttachment: "fixed",
      // backgroundSize: "cover",
    },
    rightContainer: {
      flexGrow: 1,
    },
    leftContainer: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      minWidth: isDesktop ? 250 : '100%',
    },
    icon: {
      fontSize: normal,
      color: text,
    },
  };

  useEffect(() => {
    setSelected(location?.pathname?.split('/')[2]);
  }, [location]);

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <SpacingWrapper>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 4 }}
            sx={{ paddingBottom: 8, maxWidth: 1200 }}
          >
            <Grid item sx={styles.container}>
              <Grid
                container
                justifyContent='start'
                alignItems='start'
                gap={{ xs: 4 }}
                wrap={isDesktop ? 'nowrap' : 'wrap'}
              >
                <Grid item sx={styles.leftContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 1 }}
                  >
                    <ListItem
                      label='Disputes'
                      onClick={() => {
                        setSelected('disputes');
                        navigate('/staff/disputes');
                      }}
                      icon={
                        <RiSwordFill
                          style={{
                            ...styles.icon,
                            color:
                              selected === 'disputes'
                                ? 'rgb(255, 255, 193)'
                                : text,
                          }}
                        />
                      }
                      selected={selected === 'disputes'}
                    />
                    {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                      <ListItem
                        label='Search'
                        onClick={() => {
                          setSelected('search');
                          navigate('/staff/search');
                        }}
                        icon={
                          <FaSearch
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'search'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'search'}
                      />
                    )}
                    {store?.user?.account_type >= AccountTypeEnum.GOD && (
                      <>
                        <ListItem
                          label='Withdrawals'
                          onClick={() => {
                            setSelected('withdrawals');
                            navigate('/staff/withdrawals');
                          }}
                          icon={
                            <FaCoins
                              style={{
                                ...styles.icon,
                                color:
                                  selected === 'withdrawals'
                                    ? 'rgb(255, 255, 193)'
                                    : text,
                              }}
                            />
                          }
                          selected={selected === 'withdrawals'}
                        />
                        <ListItem
                          label='Chargebacks'
                          onClick={() => {
                            setSelected('chargebacks');
                            navigate('/staff/chargebacks');
                          }}
                          icon={
                            <AiTwotoneBank
                              style={{
                                ...styles.icon,
                                color:
                                  selected === 'chargebacks'
                                    ? 'rgb(255, 255, 193)'
                                    : text,
                              }}
                            />
                          }
                          selected={selected === 'chargebacks'}
                        />
                        <ListItem
                          label='Profit Stats'
                          onClick={() => {
                            setSelected('stats');
                            navigate('/staff/stats');
                          }}
                          icon={
                            <ImStatsDots
                              style={{
                                ...styles.icon,
                                color:
                                  selected === 'stats'
                                    ? 'rgb(255, 255, 193)'
                                    : text,
                              }}
                            />
                          }
                          selected={selected === 'stats'}
                        />
                        <ListItem
                          label='Expense Tracking'
                          onClick={() => {
                            setSelected('expenses');
                            navigate('/staff/expenses');
                          }}
                          icon={
                            <ImStatsBars
                              style={{
                                ...styles.icon,
                                color:
                                  selected === 'expenses'
                                    ? 'rgb(255, 255, 193)'
                                    : text,
                              }}
                            />
                          }
                          selected={selected === 'expenses'}
                        />
                      </>
                    )}

                    {(store?.user?.account_type === AccountTypeEnum.GOD ||
                      store?.user?.account_type === AccountTypeEnum.ANTICHEAT ||
                      store?.user?.account_type ===
                        AccountTypeEnum.SENIOR_ADMIN) && (
                      <ListItem
                        label='AC Dashboard'
                        onClick={() => {
                          setSelected('ac');
                          navigate('/staff/ac/users');
                        }}
                        icon={
                          <AiOutlineStop
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'ac' ? 'rgb(255, 255, 193)' : text,
                            }}
                          />
                        }
                        selected={selected === 'ac'}
                      />
                    )}
                    {(store?.user?.account_type === AccountTypeEnum.ANTICHEAT ||
                      store?.user?.account_type >= AccountTypeEnum.ADMIN) && (
                      <ListItem
                        label='FS Dashboard'
                        onClick={() => {
                          setSelected('fs');
                          navigate('/staff/fs/users');
                        }}
                        icon={
                          <AiOutlineYoutube
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'fs' ? 'rgb(255, 255, 193)' : text,
                            }}
                          />
                        }
                        selected={selected === 'fs'}
                      />
                    )}
                    {store?.user?.account_type >=
                      AccountTypeEnum.JUNIOR_ADMIN && (
                      <ListItem
                        label='Security Dashboard'
                        onClick={() => {
                          setSelected('security');
                          navigate('/staff/security/userstocheck');
                        }}
                        icon={
                          <FaUserCheck
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'security'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'security'}
                      />
                    )}
                    {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                      <ListItem
                        label='Staff List'
                        onClick={() => {
                          setSelected('stafflist');
                          navigate('/staff/current/list');
                        }}
                        icon={
                          <MdChecklist
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'stafflist'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'stafflist'}
                      />
                    )}
                    {store?.user?.account_type >=
                      AccountTypeEnum.LEAD_ADMIN && (
                      <ListItem
                        label='Staff Stats'
                        onClick={() => {
                          setSelected('staffstats');
                          navigate('/staff/staffstats');
                        }}
                        icon={
                          <MdStar
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'staffstats'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'staffstats'}
                      />
                    )}
                    {store?.user?.account_type >=
                      AccountTypeEnum.SENIOR_ADMIN && (
                      <ListItem
                        label='Tournament Automation'
                        onClick={() => {
                          setSelected('tournaments');
                          navigate('/staff/tournaments/automation');
                        }}
                        icon={
                          <FaTrophy
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'tournaments'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'tournaments'}
                      />
                    )}
                    {/* {store?.user?.account_type >= AccountTypeEnum.GOD && (
                      <ListItem
                        label="Maps Dashboard"
                        onClick={() => {
                          setSelected("maps");
                          navigate("/staff/maps/dashboard");
                        }}
                        icon={
                          <HiOutlineMap
                            style={{
                              ...styles.icon,
                              color:
                                selected === "maps"
                                  ? "rgb(255, 255, 193)"
                                  : text,
                            }}
                          />
                        }
                        selected={selected === "maps"}
                      />
                    )} */}
                    {store?.user?.account_type >=
                      AccountTypeEnum.SENIOR_ADMIN && (
                      <ListItem
                        label='Referrals'
                        onClick={() => {
                          setSelected('referrals');
                          navigate('/staff/referrals/dashboard');
                        }}
                        icon={
                          <GiPlayerNext
                            style={{
                              ...styles.icon,
                              color:
                                selected === 'maps'
                                  ? 'rgb(255, 255, 193)'
                                  : text,
                            }}
                          />
                        }
                        selected={selected === 'referrals'}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item sx={styles.rightContainer}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                    gap={{ xs: 2 }}
                  >
                    <Outlet />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpacingWrapper>
      </Grid>
    </Grid>
  );
};

export default StaffDashboard;
