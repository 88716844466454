import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import useMiddleware from '../../api/useMiddleware';
import { claim } from '../../api/user';
import { SET_ERRORS, SET_SUCCESSES, StoreDispatch } from '../../store/Store';
import { secondaryText, text } from '../../utils/themeContstants';
import CustomInput from '../custom/CustomInput';
import SecondaryButton from '../custom/SecondaryButton';

const ClaimWalletMenu = () => {
  const [key, setKey] = useState('');
  const [canClaim, setCanClaim] = useState(false);
  const [loading, setLoading] = useState(false);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const handleClaim = () => {
    setLoading(true);
    claim(middleware, key).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setKey('');
        setCanClaim(false);
      }
    });
  };

  const styles = {
    container: {
      width: '100%',
      marginTop: 1,
      marginBottom: 1,
    },
    width: {
      width: '100%',
    },
    label: {
      fontSize: 13,
      color: secondaryText,
      fontWeight: 700,
      opacity: 0.8,
    },
    details: {
      fontSize: 14,
      color: secondaryText,
      fontWeight: 500,
      opacity: 0.8,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>Key</Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                value={key}
                onChange={value => {
                  setKey(value);
                  setCanClaim(value.trim() !== '');
                }}
                autoFocus={true}
                placeholder='Enter your key'
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider
            sx={{ width: '100%', backgroundColor: text, opacity: 0.15 }}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <SecondaryButton
            label='Claim'
            fullWidth
            disabled={!canClaim}
            onClick={handleClaim}
            loading={loading}
          />
        </Grid>

        <Grid item sx={styles.width}>
          <Typography sx={styles.details}>
            Once you've purchased a key from the Deposit menu option, claim it
            here for your coins.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClaimWalletMenu;
