import { Grid } from '@mui/material';
import { useRef } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import './styles/ScrollBar.css';

const ScrollableContainer = ({
  children,
  gap = 0,
  end,
  paddingTop = 0,
  paddingLeft = 0,
  paddingBottom = 2,
  reverse = false,
  stretch = false,
}) => {
  const ref = useRef(null);
  const { events } = useDraggableScroll(ref, {
    direction: 'horizontal',
  });

  return (
    <Grid
      container
      direction={reverse ? 'row-reverse' : 'row'}
      justifyContent={end ? 'end' : reverse ? null : 'start'}
      alignItems={stretch ? 'stretch' : end ? 'end' : 'start'}
      wrap='nowrap'
      overflow='auto'
      sx={{
        width: '100%',
        paddingBottom,
        boxSizing: 'border-box',
        paddingTop,
        paddingLeft,
      }}
      ref={ref}
      {...events}
      className='scroll'
      gap={{ xs: gap }}
    >
      {children}
    </Grid>
  );
};

export default ScrollableContainer;
