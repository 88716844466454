import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  text,
  transition,
} from '../../utils/themeContstants';
import './styles/Live.css';

const NewListItem = ({
  icon,
  title,
  onClick = () => {},
  selected,
  rightAddOn,
  minWidth,
  backgroundHover = '#354457',
  description = null,
  live = false,
}) => {
  const isTablet = useMediaQuery('(max-width: 768px)');

  const styles = {
    container: {
      borderRadius: 2,
      padding: 1,
      maxWidth: '100%',
      minWidth: isTablet ? '100%' : minWidth ? minWidth : null,
      width: '100%',
      minHeight: 52,
      boxSizing: 'border-box',
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: backgroundHover,
      },
    },
    iconContainer: {
      height: 36,
      minHeight: 36,
      width: 36,
      minWidth: 36,
      borderRadius: 100,
      backgroundColor: selected ? doubtedBlue : cardVeryLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    description: {
      fontSize: 12,
      color: secondaryText,
      fontWeight: 400,
      maxWidth: isTablet ? '100%' : 280,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={onClick}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
        wrap='nowrap'
      >
        <Grid item>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 1.5 }}
            wrap='nowrap'
          >
            {!live && icon && (
              <Grid item sx={styles.iconContainer}>
                {icon}
              </Grid>
            )}

            {live && (
              <Grid item>
                <div className='live' />
              </Grid>
            )}

            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.title}>{title}</Typography>
                </Grid>

                {description && (
                  <Grid item>
                    <Typography sx={styles.description}>
                      {description}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {rightAddOn && (
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {rightAddOn}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NewListItem;
