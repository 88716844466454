import { CircularProgress, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { searchForUser } from '../../api/search';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { emptyIcon, secondaryText } from '../../utils/themeContstants';
import UpgradeModal from '../app/UpgradeModal';
import CustomInput from '../custom/CustomInput';
import CustomModal from '../custom/CustomModal';
import Empty from '../custom/Empty';
import SecondaryButton from '../custom/SecondaryButton';
import UserSearchTipItem from './UserSearchTipItem';
import UserTipModal from './UserTipModal';

const TipSearchModal = ({ open, onClose }) => {
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleClose = () => {
    setQuery('');
    setResults(null);
    setLoading(false);
    setTipOpen(false);
    setUpgradeOpen(false);
    setSelectedUser(null);
    onClose();
  };

  const handleSearch = searchQuery => {
    if (
      searchQuery === '' ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    searchForUser(middleware, searchQuery).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const handleOpenTip = user => {
    setTipOpen(true);
    setSelectedUser(user);
  };

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <UpgradeModal
        open={!store?.user?.premium_info?.active && open}
        onClose={handleClose}
      />

      <UserTipModal
        open={tipOpen}
        onClose={() => setTipOpen(false)}
        user={selectedUser}
        closeSearch={handleClose}
      />

      <CustomModal
        title='Tip a User'
        description='Search for users to send funds to.'
        titleSize='large'
        open={store?.user?.premium_info?.active && open}
        onClose={handleClose}
        primaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction='column'
              alignItems='start'
              justifyContent='center'
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <CustomInput
                  value={query}
                  placeholder='Search users...'
                  onChange={val => {
                    setQuery(val);
                    handleSearch(val);
                  }}
                  autoFocus={true}
                  onPaste={val => {
                    setQuery(val);
                    handleSearch(val);
                  }}
                />
              </Grid>

              {loading && (
                <Grid item alignSelf='center'>
                  <CircularProgress size={30} sx={{ color: secondaryText }} />
                </Grid>
              )}

              {!loading &&
                query !== '' &&
                query != null &&
                (results == null || results?.length < 1) && (
                  <Empty
                    title='No results'
                    label='Try searching something else'
                    icon={<AiOutlineSearch style={emptyIcon} />}
                  />
                )}

              {loading ||
              results?.length < 1 ||
              results == null ||
              query === '' ||
              query == null ? null : (
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction='column'
                    alignItems='start'
                    justifyContent='center'
                  >
                    {results?.map((user, i) => (
                      <UserSearchTipItem
                        user={user}
                        onClick={user => handleOpenTip(user)}
                        key={i}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default TipSearchModal;
