import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { unlinkEpic } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import {
  cardLight,
  normal,
  purple,
  secondaryText,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import PrimaryButton from '../custom/PrimaryButton';
import SecondaryButton from '../custom/SecondaryButton';

const UnlinkModal = ({ open, onClose, user, setEpic }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [mustPay, setMustPay] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAmount('');
    setLoading(false);
    setMustPay(false);
    onClose();
  };

  const handleUnlink = () => {
    setLoading(true);
    unlinkEpic(middleware, user?._id, mustPay).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setEpic(false);
      }
      handleClose();
    });
  };

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Unlink ${user?.username}'s account`}
      primaryButton={
        <PrimaryButton
          label='Unlink'
          loading={loading}
          onClick={handleUnlink}
        />
      }
      secondaryButton={<SecondaryButton label='Close' onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          ></Grid>
        </Grid>

        <Grid item>
          <Typography
            sx={{
              fontSize: normal,
              color: secondaryText,
              fontWeight: 600,
            }}
          >
            Are you charging {user?.username} for this unlink? <br />
          </Typography>
        </Grid>
        <Grid item>
          <CustomCheckbox
            checked={mustPay}
            onChange={value => setMustPay(value)}
            color={purple}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default UnlinkModal;
