import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import Countdown from 'react-countdown';
import {
  doubtedOrange,
  secondaryButton,
  secondaryText,
  text,
} from '../../utils/themeContstants';

const CustomCountdown = ({ remaining, duration }) => {
  const [percent, setPercent] = useState(100);
  const [secondsRemaining, setSecondsRemaining] = useState(duration);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return 'Expired';
    } else {
      return (
        <span
          style={{
            color: minutes === 0 && seconds > 0 ? doubtedOrange : text,
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  // useEffect(() => {
  //   const secondsLeft =
  //     Math.abs(new Date(remaining).getTime() - new Date().getTime()) / 1000;

  //   setSecondsRemaining(secondsLeft);
  // }, [duration]);

  // useEffect(() => {
  //   setPercent((secondsRemaining / duration) * 100);
  // }, [secondsRemaining]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (secondsRemaining <= 0) return;

  //     setSecondsRemaining(secondsRemaining - 1);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // });

  const styles = {
    width: {
      width: '100%',
    },
    progressContainer: {
      width: '100%',
      height: 6,
      borderRadius: 40,
      backgroundColor: secondaryButton,
    },
    progress: {
      height: '100%',
      width: `${percent}%`,
      backgroundColor: '#5c6382',
      borderRadius: 40,
    },
    timerLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: secondaryText,
    },
    timerValue: {
      fontSize: 12,
      fontWeight: 600,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 1 }}
      >
        {/* <Grid item sx={styles.progressContainer}>
          <Grid item sx={styles.progress} />
        </Grid> */}

        <Grid item>
          <Grid
            container
            justifyContent='start'
            alignItems='center'
            gap={{ xs: 0.5 }}
            noWrap
          >
            <Grid item>
              <Typography sx={styles.timerLabel}>Expires in:</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.timerValue}>
                <Countdown date={new Date(remaining)} renderer={renderer} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomCountdown;
