import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import useMiddleware from '../../api/useMiddleware';
import { getUserTipsProfile } from '../../api/user';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { TRANSACTION_LIMIT } from '../../utils/limits';
import {
  emptyIcon,
  secondaryText,
  small,
  text,
} from '../../utils/themeContstants';
import usePagination from '../../utils/usePagination';
import Empty from '../custom/Empty';
import MatchHistoryGlimmer from '../glimmers/MatchHistoryGlimmer';
import TipItem from '../transactions/TipItem';

const UserTipsProfile = ({ user }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);
  const [loading, setLoading] = useState(true);
  const [tips, setTips] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetTipsProfile = () => {
    getUserTipsProfile(middleware, user?._id, skip).then(res => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.tips?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setTips(tips ? [...tips, ...res?.tips] : res?.tips);
      }
    });
  };

  usePagination(bottom, () => setSkip(tips?.length));

  useEffect(() => {
    if (tips == null) {
      handleGetTipsProfile();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetTipsProfile();
    }
  }, [skip]);

  const styles = {
    width: {
      width: '100%',
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent='start'
          alignItems='center'
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <FaExchangeAlt style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Tips</Typography>
          </Grid>
        </Grid>
      </Grid>

      {loading && (
        <Grid
          item
          alignSelf='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!tips || tips?.length < 1) && (
        <Empty
          title='No tips'
          label='Try tipping a friend'
          icon={<FaExchangeAlt style={emptyIcon} />}
        />
      )}

      {!loading && tips && tips?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
            gap={{ xs: 1 }}
          >
            {tips && tips?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction='column'
                  alignItems='start'
                  justifyContent='center'
                  gap={{ xs: 1 }}
                >
                  {tips?.map((tip, i) => (
                    <TipItem tip={tip} key={i} user={store?.user} />
                  ))}
                  {hasMore && (
                    <div style={{ width: '100%' }} ref={bottom}>
                      <MatchHistoryGlimmer numItems={1} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserTipsProfile;
