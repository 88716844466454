import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserAuth } from '../../api/auth';
import useMiddleware, {
  saveAccessToken,
  saveRefreshToken,
} from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_NOTI_COUNT,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import LoadingScreen from '../custom/LoadingScreen';

const AuthCallback = () => {
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const middleware = useMiddleware(searchParams.get('token'));

  useEffect(() => {
    if (!loading) return;

    if (store?.user) return;

    if (searchParams.get('token')) {
      getUserAuth(middleware).then(res => {
        setSearchParams({});
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
          navigate('/login?error=true');
        } else {
          saveAccessToken(res?.accessToken);
          saveRefreshToken(res?.refreshToken);
          updateStore({ type: SET_USER, payload: res?.user });
          updateStore({
            type: SET_NOTI_COUNT,
            payload: res?.has_noti ? 1 : 0,
          });
          navigate('/');
        }
      });
    }
  }, [
    middleware,
    updateStore,
    store?.user,
    loading,
    navigate,
    searchParams,
    setSearchParams,
    store?.errors,
  ]);

  return <LoadingScreen size={150} />;
};

export default AuthCallback;
