import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { CDN_URL } from '../../utils/constants';
import {
  card,
  cardDark,
  menuShadow,
  text,
  transition,
} from '../../utils/themeContstants';
import CustomPortal from '../custom/CustomPortal';

const SocialBadge = ({
  type,
  user,
  onClick,
  backgroundColor = cardDark,
  backgroundHover = card,
}) => {
  const twitch = `${CDN_URL}connections/twitch.svg`;
  const twitter = `${CDN_URL}connections/twitter.svg`;
  const discord = `${CDN_URL}connections/discord.svg`;

  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const getIcon = () => {
    switch (type) {
      case 'twitter':
        return twitter;
      case 'twitch':
        return twitch;
      case 'discord':
        return discord;
      default:
        return null;
    }
  };

  const getLabel = () => {
    switch (type) {
      case 'twitter':
        return 'view twitter';
      case 'twitch':
        return 'view twitch';
      case 'discord':
        return user;
      default:
        return null;
    }
  };

  const localStyles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      padding: 1,
      transition: transition,
      backgroundColor,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: backgroundHover,
      },
    },
    hoverContainer: {
      backgroundColor: 'rgb(16, 18, 27, 0.7)',
      borderRadius: 1,
      padding: 1,
      boxShadow: menuShadow,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Grid
      sx={localStyles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      ref={setRefEl}
    >
      <CustomPortal>
        {hovered && (
          <div
            ref={setPopperEl}
            style={{
              ...styles.popper,
              position: 'absolute',
              zIndex: 999999999,
            }}
            {...attributes.popper}
          >
            <Grid item sx={localStyles.hoverContainer}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 800,
                  color: text,
                  textTransform: 'uppercase',
                }}
              >
                {getLabel()}
              </Typography>
            </Grid>
          </div>
        )}
      </CustomPortal>

      <img src={getIcon()} alt={`${type}-icon`} draggable={false} height={16} />
    </Grid>
  );
};

export default SocialBadge;
