import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { cancelMatch, readyUp, voteToCancel } from '../../api/matches';
import useMiddleware from '../../api/useMiddleware';
import { SET_ERRORS, StoreContext, StoreDispatch } from '../../store/Store';
import { MatchStateEnum } from '../../utils/enums';
import {
  black,
  doubtedBlue,
  doubtedGreen,
  doubtedOrange,
} from '../../utils/themeContstants';
import SecondaryButton from '../custom/SecondaryButton';
import { isCreatingTeam } from './matchHelpers';
import PrivateMatchInviteModal from './PrivateMatchInviteModal';
import SubmitForfeitModal from './SubmitForfeitModal';
import SubmitResultsModal from './SubmitResultsModal';
import UploadEvidenceModal from './UploadEvidenceModal';

const MatchStateButtons = ({ token }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [cancelLoading, setCancelLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [readyDisabled, setReadyDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [forfeitOpen, setForfeitOpen] = useState(false);
  const [voteLoading, setVoteLoading] = useState(false);
  const [voted, setVoted] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);

  const handleCancelMatch = () => {
    setCancelLoading(true);
    cancelMatch(middleware, token?._id).then(res => {
      setCancelLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      }
    });
  };

  const handleReadyUp = () => {
    setReadyLoading(true);
    readyUp(middleware, token?._id).then(res => {
      setReadyLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setReadyDisabled(true);
      }
    });
  };

  const handleVoteCancel = () => {
    setVoteLoading(true);
    const choice = voted ? 'remove' : 'add';
    voteToCancel(middleware, choice, token?._id).then(res => {
      setVoteLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setVoted(choice === 'remove' ? false : true);
      }
    });
  };

  useEffect(() => {
    setReadyDisabled(token?.readied_users?.readied[store?.user?._id]);
    setSubmitDisabled(
      isCreatingTeam(token, store?.user?._id)
        ? token?.submits?.creating_submit
        : token?.submits?.joining_submit
    );
    setVoted(token?.agreed_players?.includes(store?.user?._id));
  }, [token]);

  const styles = {
    width: {
      width: '100%',
    },
  };

  return (
    <>
      <SubmitResultsModal
        open={submitOpen}
        onClose={() => setSubmitOpen(false)}
        token={token}
      />
      <SubmitForfeitModal
        open={forfeitOpen}
        onClose={() => setForfeitOpen(false)}
        token={token}
      />
      <UploadEvidenceModal
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        token={token}
      />
      <PrivateMatchInviteModal
        open={inviteOpen}
        onClose={() => setInviteOpen(false)}
        match={token}
      />

      <Grid item sx={styles.width}>
        <Grid
          container
          direction='column'
          alignItems='start'
          justifyContent='center'
          gap={{ xs: 1 }}
        >
          {token?.state === MatchStateEnum.WAITING &&
            !token?.public &&
            token?.match_type.type !== 'tournament' && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  bg={doubtedBlue}
                  label='Invite Opponents'
                  fullWidth
                  onClick={() => setInviteOpen(true)}
                />
              </Grid>
            )}

          {token?.state === MatchStateEnum.WAITING &&
            token?.match_type.type !== 'tournament' && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label='Cancel Match'
                  fullWidth
                  loading={cancelLoading}
                  onClick={handleCancelMatch}
                />
              </Grid>
            )}

          {token?.state === MatchStateEnum.READY && (
            <Grid item sx={styles.width}>
              <SecondaryButton
                label={readyDisabled ? 'Ready' : 'Ready Up'}
                fullWidth
                bg={doubtedGreen}
                loading={readyLoading}
                onClick={handleReadyUp}
                disabled={readyDisabled}
                textColor={black}
              />
            </Grid>
          )}

          {(token?.state === MatchStateEnum.PLAYING ||
            token?.state === MatchStateEnum.SUBMIT) && (
            <Grid item sx={styles.width}>
              <SecondaryButton
                label={submitDisabled ? 'Submitted' : 'Submit Results'}
                fullWidth
                onClick={() => setSubmitOpen(true)}
                disabled={submitDisabled}
                bg={doubtedBlue}
              />
            </Grid>
          )}

          {token?.state === MatchStateEnum.DISPUTE && (
            <>
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label='Upload Evidence'
                  fullWidth
                  onClick={() => setUploadOpen(true)}
                  bg={doubtedBlue}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <SecondaryButton
                  label={'Forfeit'}
                  fullWidth
                  onClick={() => setForfeitOpen(true)}
                  bg={doubtedOrange}
                />
              </Grid>
            </>
          )}

          {(token?.state === MatchStateEnum.PLAYING ||
            token?.state === MatchStateEnum.DISPUTE ||
            token?.state === MatchStateEnum.SUBMIT) &&
            token?.match_type.type !== 'tournament' && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label={voted ? 'Remove Vote' : 'Vote to Cancel'}
                  fullWidth
                  onClick={handleVoteCancel}
                  loading={voteLoading}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default MatchStateButtons;
