import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { CLIENT_URL } from '../../utils/constants';
import {
  getDateFromId,
  getGameModeString,
  getNotiTimestamp,
} from '../../utils/helpers';
import {
  card,
  cardDark,
  cardLight,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from '../../utils/themeContstants';

const DisputeItem = ({ dispute }) => {
  const [hovered, setHovered] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const styles = {
    container: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      width: '100%',
      transition: transition,
      '&:hover': {
        cursor: 'pointer',
        transform: 'translateY(-5px)',
        backgroundColor: cardDark,
      },
    },
    label: {
      fontSize: small,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() =>
        window.open(`${CLIENT_URL}/token/${dispute?._id}`, '_blank')
      }
    >
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        gap={{ xs: 2 }}
        sx={{
          backgroundColor: hovered ? card : cardLight,
          transition: transition,
          padding: 1,
          borderRadius: 1,
        }}
      >
        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item>
              <Typography sx={styles.label}>GAME TYPE</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {getGameModeString(dispute?.game_match_type)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <Grid item>
                  <Typography sx={styles.label}>TEAM SIZE</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {`${dispute?.team_size}v${dispute?.team_size}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item>
          <Grid
            container
            direction='column'
            alignItems='start'
            justifyContent='center'
          >
            <Grid item alignSelf='end'>
              <Typography sx={{ ...styles.label, textAlign: 'right' }}>
                DATE
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                sx={{ ...styles.value, fontWeight: 500, color: secondaryText }}
              >
                {getNotiTimestamp(getDateFromId(dispute?._id))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DisputeItem;
