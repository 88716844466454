import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { unbanUser } from '../../api/staff';
import useMiddleware from '../../api/useMiddleware';
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from '../../store/Store';
import { AccountTypeEnum } from '../../utils/enums';
import {
  cardVeryLight,
  doubtedOrange,
  normal,
  text,
} from '../../utils/themeContstants';
import CustomCheckbox from '../custom/CustomCheckbox';
import CustomModal from '../custom/CustomModal';
import SecondaryButton from '../custom/SecondaryButton';

const UnbanModal = ({ open, onClose, user, setBanned }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [unbanPrice, setUnbanPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [freeUnban, setFreeUnban] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setFreeUnban(false);
    onClose();
  };

  const handleUnban = () => {
    setLoading(true);
    unbanUser(middleware, user?._id, freeUnban).then(res => {
      setLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setFreeUnban(false);
      } else {
        setBanned(false);
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: '100%',
    },
  };

  useEffect(() => {
    if (user) {
      try {
        console.log(user?.behavior_info.unban_timestamp);
        const startDate = new Date(user?.behavior_info?.unban_timestamp);
        const endDate = new Date();
        var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        setUnbanPrice((2.5 * diffDays).toFixed(2));
      } catch (err) {}
    }
  }, [user]);

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Unban ${user?.username}?`}
      description={`You are about to unban ${user?.username} for ${freeUnban ? 'Free' : `$${unbanPrice}`}.`}
      titleSize='large'
      primaryButton={
        <SecondaryButton
          label='Unban'
          loading={loading}
          bg={doubtedOrange}
          onClick={handleUnban}
        />
      }
      secondaryButton={<SecondaryButton label='Cancel' onClick={handleClose} />}
    >
      <Grid
        container
        direction='column'
        alignItems='start'
        justifyContent='center'
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: '100%', backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item>
          <Typography sx={styles.description}>
            {`You are about to unban ${user?.username} for ${freeUnban ? 'Free' : `$${unbanPrice}`}. Are you sure you want to `}
            continue?
          </Typography>
        </Grid>

        {store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN && (
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              gap={{ xs: 1 }}
            >
              <Grid>
                <CustomCheckbox
                  checked={freeUnban}
                  onChange={value => setFreeUnban(value)}
                  color={doubtedOrange}
                  disabled={loading}
                  checkColor={text}
                />
              </Grid>

              <Grid>
                <Typography
                  sx={{
                    fontSize: normal,
                    color: text,
                    fontWeight: 600,
                    maxWidth: 400,
                  }}
                >
                  Don't charge this user
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default UnbanModal;
